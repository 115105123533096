import { DateTime } from 'luxon'

export const isValidDate = (value: string | null): boolean =>
  !!value && !!DateTime.fromISO(value).toISO()

export const isOnOrAfterInception =
  (inceptionDate: string) =>
  (value: string | null): boolean =>
    !!value &&
    DateTime.fromISO(inceptionDate).startOf('day') <=
      DateTime.fromISO(value).startOf('day')

export const isWithin30Days = (value: string | null): boolean =>
  !!value && DateTime.now().plus({ days: 30 }) > DateTime.fromISO(value)

export const isBeforeExpiry =
  (expiryDate: string) =>
  (value: string | null): boolean =>
    !!value && DateTime.fromISO(expiryDate) > DateTime.fromISO(value)
