import { ResponsiveStyleValue } from '@styled-system/css'
import { variant } from 'styled-system'

export type ContainerStyleProperty = 'default' | 'raised' | 'prominent'

export interface ContainerStyleProps {
  variant?: ResponsiveStyleValue<ContainerStyleProperty>
}

export const containerStyle = variant({
  scale: 'containerStyle',
  variants: {
    default: {},
    raised: {
      borderRadius: 2,
      backgroundColor: 'chalk',
      boxShadow: 'default',
    },
    prominent: {},
  },
})
