import { BoxProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { PolicyDetail, Quote } from '../../../../domain'
import { ProductConfig, ProductKind } from '../../../../state/configuration'
import {
  AnnualAdjustedPremium,
  DriveawayAdjustedPremium,
  SubscriptionAdjustedPremium,
} from '../../../organisms/pricing'

export interface PricingAdjustmentFactoryProps extends BoxProps {
  product: ProductConfig
  policyDetail: PolicyDetail
  adjustedQuote: Quote
  originalQuote: Quote
}

export const PolicyAdjustmentPricing: FunctionComponent<
  PricingAdjustmentFactoryProps
> = ({ product, policyDetail, adjustedQuote, originalQuote, ...props }) => {
  switch (product.productKind) {
    case ProductKind.ANNUAL:
      return (
        <AnnualAdjustedPremium
          currentQuote={adjustedQuote}
          previousQuote={originalQuote}
          product={product}
          {...props}
        />
      )
    case ProductKind.SUBSCRIPTION:
      return (
        <SubscriptionAdjustedPremium
          currentQuote={adjustedQuote}
          previousQuote={originalQuote}
          product={product}
          {...props}
        />
      )
    case ProductKind.DRIVEAWAY:
      return (
        <DriveawayAdjustedPremium
          currentQuote={adjustedQuote}
          previousQuote={originalQuote}
          expiredAt={policyDetail.expiredAt}
          product={product}
          {...props}
        />
      )
  }
}
