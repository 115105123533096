import css from '@styled-system/css'
import {
  Flex,
  MainSection,
  PageContainer,
  SideSection,
  WideContainer,
} from '@wrisk/ui-components'
import React, { FunctionComponent, PropsWithChildren, ReactElement } from 'react'
import styled from 'styled-components'

import { Footer } from '../application/templates'

const SideBar = styled.div(
  css({
    display: ['none', 'none', 'block'],
    position: [undefined, undefined, 'fixed'],
    pt: [0, 0, 10, 13],
    pl: [0, 0, 10, 13],
  }),
)

export interface SideBarPageProps extends PropsWithChildren {
  pageId?: string
  sideContent?: ReactElement
}

export const SideBarPage: FunctionComponent<SideBarPageProps> = ({
  children,
  pageId,
  sideContent,
}) => {
  const sideSection = sideContent && (
    <SideSection display={['none', 'none', 'flex']}>
      <SideBar>{sideContent}</SideBar>
    </SideSection>
  )

  return (
    <Flex id={pageId} width={1} height='100%' flexDirection='column' alignItems='stretch'>
      <PageContainer>
        <WideContainer variant='sidebar'>
          {sideSection}
          <MainSection>{children}</MainSection>
        </WideContainer>
      </PageContainer>
      <Footer />
    </Flex>
  )
}
