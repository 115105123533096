import css, { ResponsiveStyleValue } from '@styled-system/css'
import styled from 'styled-components'
import { space, SpaceProps, variant } from 'styled-system'

export type StickySidebarProperty = 'default' | 'offset'

export interface StickySidebarProps extends SpaceProps {
  variant?: ResponsiveStyleValue<StickySidebarProperty>
}

export const StickySidebar = styled.div<StickySidebarProps>(
  css({
    position: [undefined, undefined, 'sticky'],
    display: ['none', 'none', 'block'],
    width: '100%',
    top: '4rem',
  }),
  variant({
    scale: 'StickySidebar',
    variants: {
      default: {},
      offset: {
        top: '4rem',
      },
    },
  }),
  space,
)

StickySidebar.defaultProps = {
  variant: 'default',
}
