import { Box } from '@wrisk/ui-components'
import { partition } from 'lodash'
import React, { FunctionComponent } from 'react'

import { CustomerStatement, isInvoiceGroup } from '../../../domain'
import { InvoiceGroup } from './InvoiceGroup'
import { PaymentGroup } from './PaymentGroup'

export interface StatementModalProps {
  hide: () => void
  statement: CustomerStatement
}

export const StatementModal: FunctionComponent<StatementModalProps> = ({
  statement: { statementSummary, lineItems },
}) => {
  const [invoiceGroupTransactions, paymentGroupTransactions] = partition(
    lineItems,
    isInvoiceGroup,
  )

  return (
    <Box>
      <InvoiceGroup
        lineItems={invoiceGroupTransactions}
        statementSummary={statementSummary}
      />
      <PaymentGroup
        transactions={paymentGroupTransactions}
        statementSummary={statementSummary}
      />
    </Box>
  )
}
