import { createContext, useContext } from 'react'

import { ProductConfig, Scheme } from '../../state/configuration'

export interface ProductContextModel {
  product: ProductConfig
  scheme: Scheme
}

export const ProductContext = createContext<ProductContextModel | undefined>(undefined)

export const useProduct = (): ProductContextModel => {
  const context = useContext(ProductContext)
  if (!context) {
    throw new Error('Cannot use ProductContext when undefined.')
  }
  return context
}

export const useScheme = (): Scheme | undefined => useContext(ProductContext)?.scheme
