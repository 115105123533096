import { Header, HeaderTitle, pageEntranceSpringValues } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { animated, useSpring } from 'react-spring'

import { usePolicyholderAdjustment } from '../../../../../../hooks/adjustments/policyholder'
import {
  TExtends,
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { PolicyholderDisclosureConfig } from '../../../../../../state/configuration'
import { usePolicyholder } from '../../../../../authentication'
import { FormErrors } from '../../../../../formBuilder'

export interface PolicyholderSectionFormProps {
  section: PolicyholderDisclosureConfig
  onComplete: () => void
}

const tKey = TKey('pages.disclosure')

export const PolicyholderSection: FunctionComponent<PolicyholderSectionFormProps> = ({
  section,
  onComplete,
}) => {
  const { t } = useWriskTranslation()
  const spring = useSpring(pageEntranceSpringValues)

  const { policyholder } = usePolicyholder()

  const onSubmitAdjustment = usePolicyholderAdjustment(onComplete)

  return (
    <animated.div style={spring}>
      <Header>
        <HeaderTitle>{t(tKey('headers', section.id))}</HeaderTitle>
      </Header>
      <FormErrors
        id={section.id}
        key={section.id}
        footerText={section.footerText}
        data={policyholder ?? {}}
        options={{ shouldUnregister: true }}
        inputs={section.inputs}
        onSubmit={onSubmitAdjustment.execute}
        loading={onSubmitAdjustment.loading}
        tKey={TExtends(tKey, 'form')}
        t={t}
      />
    </animated.div>
  )
}
