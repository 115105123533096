import React, { useMemo } from 'react'

import { ExternalLink } from './'

interface LinkMap {
  [key: string]: React.ReactElement
}

export const useLinks = (): LinkMap =>
  useMemo(
    () => ({
      ExternalLink: <ExternalLink />,
      BusinessTerms: <ExternalLink type='business-terms' />,
      CookiePolicy: <ExternalLink type='cookie-policy' />,
      PrivacyPolicy: <ExternalLink type='privacy-policy' />,
    }),
    [],
  )
