import { Flex, FooterAnchor, FooterTemplate, xxlBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { TKey, useWriskTranslation } from '../../infrastructure/internationalisation'
import { getFooter, useConfig } from '../../state/configuration'
import { ExternalLink } from '../organisms/links'

const tKey = TKey('components.footer')

export const Footer: FunctionComponent = () => {
  const { t } = useWriskTranslation()
  const footerLinks = useConfig(getFooter)

  return (
    <FooterTemplate
      content={t(tKey('content'))}
      poweredBy={t(tKey('powered-by'))}
      mt={xxlBumps}
    >
      <Flex width={1} justifyContent={['center', 'center', 'flex-end']} pt={8} gap={5}>
        {footerLinks.map((it, i) => (
          <ExternalLink key={i} type={it} text={t<string>(tKey(it))} as={FooterAnchor} />
        ))}
      </Flex>
    </FooterTemplate>
  )
}
