import { usePrincipal } from '../../../application/authentication'
import { usePolicy } from '../../../application/product/policy/policyContext'
import { isRenewalPolicy } from '../../../domain'

export const useIsMtaAllowed = () => {
  const { isAdmin } = usePrincipal()
  const { policy } = usePolicy()

  return isAdmin || !isRenewalPolicy(policy)
}
