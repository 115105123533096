import { Notifier } from '@airbrake/browser'

/**
 * Copied form @airbrake/browser/dist/notice
 */
export interface INoticeFrame {
  function: string
  file: string
  line: number
  column: number
}

/**
 * Copied form @airbrake/browser/dist/notice
 */
interface INoticeError {
  type: string
  message: string
  backtrace: INoticeFrame[]
}

/**
 * Initialize Airbrake client instance
 * Note: We create Airbrake instance only when CONFIG.airbrake section exists
 * Note: We don't create Airbrake instance for Admin applications
 */
export const airbrake = CONFIG.airbrake
  ? new Notifier({
      projectId: CONFIG.airbrake.id,
      projectKey: CONFIG.airbrake.key,
      keysBlocklist: ['CONFIG', 'password', /secret/],
      instrumentation: {},
    })
  : undefined

/**
 *  Add details about application and environment into every airbrake.notify() call
 */
airbrake?.addFilter((notice) => {
  notice.context.application = 'web-app' // Airbrake also automatically provides some data form package.json
  notice.context.environment = CONFIG.app?.provider || 'unknown' // 'bmw', 'mini', 'rac' etc.
  return notice
})

/**
 *  Skip specific and "annoying" errors.
 */
airbrake?.addFilter((notice) => {
  const error = notice?.errors?.[0] as INoticeError
  if (!error) {
    return notice // There is no error at all
  }

  if (
    [
      'Please check your internet connection and try again.',
      'Error: Invalid state',
      'Network Error',
      'If you already have an account with us, please sign in again.',
      'Wrong email or verification code',
    ].some((it) => error.message.includes(it))
  ) {
    return null
  }

  return notice // No filters were applied
})

export default airbrake
