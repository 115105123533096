import css from '@styled-system/css'
import styled from 'styled-components'
import { variant } from 'styled-system'

export type HelpTextProperty = 'default'

export interface MenuTextProps {
  variant?: HelpTextProperty
}

export const MenuText = styled.div<MenuTextProps>(
  css({
    display: ['none', 'none', 'block'],
  }),
  variant({
    scale: 'HelpText',
    variants: {
      default: {},
    },
  }),
)

MenuText.defaultProps = {
  variant: 'default',
}
