import { Flex, smBumps, Typo, xsBumps } from '@wrisk/ui-components'
import React from 'react'

import {
  getCoreAsset,
  getPolicyVersion,
  PolicyVersion,
} from '../../../../../../../../domain'
import {
  tDefaults,
  tFormats,
  useWriskTranslation,
} from '../../../../../../../../infrastructure/internationalisation'
import { usePolicy } from '../../../../../policyContext'

interface Props {
  updatedPolicyVersion: PolicyVersion
}

const ExcessChangesItem: React.FC<Props> = ({ updatedPolicyVersion }) => {
  const { t } = useWriskTranslation()

  const { policy } = usePolicy()

  const { quote: originalQuote } = getPolicyVersion(policy)
  const originalExcess = getCoreAsset(originalQuote).voluntaryExcess

  const { quote: updatedQuote } = updatedPolicyVersion
  const updatedExcess = getCoreAsset(updatedQuote).voluntaryExcess

  return (
    <Flex mx={2} p={smBumps}>
      <Typo fontWeight='bold' mr={2}>
        {t(tDefaults('product.headers.voluntary-excess'))}
      </Typo>
      <Flex>
        <Typo color='inactive' mr={xsBumps}>
          <s>{t(tFormats('currency.short'), { amount: originalExcess })}</s>
        </Typo>
        <Typo>{t(tFormats('currency.short'), { amount: updatedExcess })}</Typo>
      </Flex>
    </Flex>
  )
}

export default ExcessChangesItem
