import { Box, Checkbox, FlexAnchor, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { FormInputProps } from '../types'

export const CheckboxFormInput: FunctionComponent<FormInputProps<boolean>> = ({
  value,
  onChange,
  name,
  t,
  tKey,
  tName,
}) => {
  const id = `${name}-check`

  return (
    <Box variant='raised' p={2}>
      <FlexAnchor
        as='label'
        variant='highlight'
        justifyContent='flex-start'
        alignItems='flex-start'
        htmlFor={id}
        width={1}
        p={4}
      >
        <Checkbox name={id} id={id} value={value} onChange={onChange} mr={4} />
        <Typo as='pre'>{t(tKey(tName, 'label'))}</Typo>
      </FlexAnchor>
    </Box>
  )
}
