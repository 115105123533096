import React, { FunctionComponent } from 'react'

import { Data, Policy, PolicyAdjustmentResponse } from '../../../../../domain'
import { usePolicyAdjustment } from '../../../../../hooks/adjustments/policy'
import { QuoteManagementConfig } from '../../../../../state/configuration'
import { AdjustmentModal } from '../admin'

export interface PolicyAdjustmentModalProps {
  updatedData: Data
  policy: Policy
  config: QuoteManagementConfig
  onSuccess: (response: PolicyAdjustmentResponse | undefined) => void
  onCancel: () => void
}

export const PolicyAdjustmentModal: FunctionComponent<PolicyAdjustmentModalProps> = ({
  updatedData,
  policy,
  config,
  onSuccess,
  onCancel,
}) => {
  const onSubmitAdjustment = usePolicyAdjustment(config.inputs, onSuccess)

  return (
    <AdjustmentModal
      updatedData={updatedData}
      policy={policy}
      onCancel={onCancel}
      onSubmitAdjustment={onSubmitAdjustment}
    />
  )
}
