// eslint-disable-next-line import/no-unresolved
import { Property } from 'csstype'
import { ResponsiveValue, system } from 'styled-system'

export interface WhiteSpaceProps {
  whiteSpace?: ResponsiveValue<Property.WhiteSpace>
}

export const whiteSpace = system({
  whiteSpace: true,
})
