import { DateTime } from 'luxon'
import { default as React, FunctionComponent } from 'react'

import { tDefaults, tFormats } from '../../../infrastructure/internationalisation'
import { Formatter } from '../types'

export const YearMonthFormatter: FunctionComponent<Formatter<string>> = ({
  value,
  t,
}) => {
  return value ? (
    <>{t(tFormats('date.year-month'), { value: DateTime.fromISO(value) })}</>
  ) : (
    <>{t(tDefaults('inputs.no-answer'))}</>
  )
}
