import {
  Box,
  Section,
  SectionContent,
  SectionProps,
  SectionTitle,
} from '@wrisk/ui-components'
import React, { FunctionComponent, ReactNode } from 'react'

import { isAddOn, PriceOption, Quote } from '../../../../domain'
import { useOptionalCovers } from '../../../../hooks/covers'
import { OptionalFlexAnchor } from '../../../organisms/covers'
import { useProduct } from '../../productContext'

export interface RenewalAddOnSectionProps extends SectionProps {
  header: ReactNode
  quote: Quote
  onChange?: (cover: PriceOption) => void
}

export const RenewalAddOnSection: FunctionComponent<RenewalAddOnSectionProps> = ({
  header,
  quote,
  onChange,
  ...props
}) => {
  const { product } = useProduct()

  const covers = useOptionalCovers(quote, product).filter(isAddOn(product))

  return covers.length ? (
    <Section width={1} {...props}>
      <SectionTitle>{header}</SectionTitle>
      <SectionContent>
        <Box width={1} p={2} variant='raised'>
          {covers.map((cover) => (
            <OptionalFlexAnchor key={cover.coverCode} cover={cover} onChange={onChange} />
          ))}
        </Box>
      </SectionContent>
    </Section>
  ) : null
}
