import { Box, mdBumps, xxlBumps } from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import React, { FunctionComponent } from 'react'

import { TKeyBuilder } from '../../../infrastructure/internationalisation'
import { InputConfig } from '../../../state/configuration'
import { useConfiguredInputs } from '../hooks'
import { FormBuilderOptions } from '../types'
import { FormElement } from './FormElement'
import { FormHeader } from './FormHeader'
import { FormReadOnly } from './FormReadOnly'

export interface FormSectionsProps {
  inputs: InputConfig[]
  options: FormBuilderOptions
  sectionClassName?: string
  errorClassName?: string

  tKey: TKeyBuilder
  t: TFunction
}

export const FormSections: FunctionComponent<FormSectionsProps> = ({
  inputs,
  t,
  tKey,
  sectionClassName,
  options,
  errorClassName,
}) => {
  const configuredInputs = useConfiguredInputs(inputs)

  return (
    <>
      {configuredInputs.map(({ isVisible, isReadOnly, input }) => {
        if (!isVisible) {
          return null
        }

        const Element =
          options.disableReadOnly || !isReadOnly ? FormElement : FormReadOnly

        return input.type !== 'hidden' ? (
          <Box key={input.name} id={input.name} width={1} className={sectionClassName}>
            <FormHeader input={input} tKey={tKey} t={t} width={1} mb={mdBumps} />

            <Element
              input={input}
              tKey={tKey}
              t={t}
              errorClassName={errorClassName}
              mb={xxlBumps}
            />
          </Box>
        ) : null
      })}
    </>
  )
}
