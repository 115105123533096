import React, { FunctionComponent } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { AppPath } from '../../../AppPath'
import { ProposalExpiredPage } from '../disclosure'

export const ProposalExpiredRoutes: FunctionComponent = () => (
  <Routes>
    <Route key='expired-quote' index element={<ProposalExpiredPage />} />
    <Route path='*' element={<Navigate to={AppPath.HOME} />} />
  </Routes>
)
