import React, { FunctionComponent, ReactElement } from 'react'

import { Box, Flex, FlexProps, smBumps, Typo, xsBumps } from '../atoms'

export interface InvoiceItemCellProps extends FlexProps {
  header: string | ReactElement
  subheader?: string | ReactElement
  premium: string | ReactElement
}

export const InvoiceItemCell: FunctionComponent<InvoiceItemCellProps> = ({
  header,
  subheader,
  premium,
  ...props
}) => (
  <Flex width={1} px={smBumps} py={xsBumps} {...props}>
    <Box mr={2}>
      <Typo>{header}</Typo>
      {subheader && (
        <Typo color='bodySecondary' typoSize='xs'>
          {subheader}
        </Typo>
      )}
    </Box>
    <Typo whiteSpace='nowrap'>{premium}</Typo>
  </Flex>
)
