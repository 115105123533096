import React, { FunctionComponent, PropsWithChildren } from 'react'

import { getFeatureToggles, useConfig } from '../../state/configuration'
import { AdminManager } from './admin'
import { UserManager } from './user'

export const AuthenticationManager: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const features = useConfig(getFeatureToggles)

  const Manager = features.isAdmin ? AdminManager : UserManager

  return <Manager>{children}</Manager>
}
