import {
  Box,
  BoxProps,
  Icon,
  lgBumps,
  mdBumps,
  Notification,
  Typo,
  xsBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  arePremiumsEqual,
  getPolicyVersion,
  Policy,
  PolicyVersion,
} from '../../../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { ProductConfig } from '../../../../../state/configuration'
import { toDateTime } from '../../../../../util/date'
import { PolicyAdjustmentPricing } from '../../../../product/policy/pricing'

const tKey = TKey('components.future-premium-changes')

export interface FuturePremiumChangesProps extends BoxProps {
  policy: Policy
  updatedPolicyVersions: PolicyVersion[]
  product: ProductConfig
}

export const FuturePremiumChanges: FunctionComponent<FuturePremiumChangesProps> = ({
  product,
  policy,
  updatedPolicyVersions,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const { quote: originalQuote } = getPolicyVersion(policy)
  const [currentPolicyVersion, ...deferredPolicyVersions] = updatedPolicyVersions
  const { quote: updatedQuote } = currentPolicyVersion

  const relevantVersions = deferredPolicyVersions.filter(
    (it, i) =>
      !arePremiumsEqual(it.quote, deferredPolicyVersions[i - 1]?.quote ?? updatedQuote),
  )

  const futurePremiumSections = relevantVersions.map((it, index) => {
    return (
      <Box key={index} mt={lgBumps}>
        <Box mx={mdBumps} pb={xsBumps} borderBottomWidth={1}>
          <Typo typoSize='sm'>
            {t(tKey('effective'), {
              effectiveAt: t(tFormats('date.long'), { value: toDateTime(it.startedAt) }),
            })}
          </Typo>
        </Box>
        <PolicyAdjustmentPricing
          product={product}
          policyDetail={policy.policyDetail}
          adjustedQuote={it.quote}
          originalQuote={relevantVersions[index - 1]?.quote ?? updatedQuote}
        />
      </Box>
    )
  })

  const hasDeferredPremiumChanges =
    !arePremiumsEqual(originalQuote, updatedQuote) && futurePremiumSections.length

  return hasDeferredPremiumChanges ? (
    <Box pt={2} {...props}>
      <Notification
        mx={2}
        width='auto'
        justifyContent='flex-start'
        variant='info'
        layout='small'
      >
        <Icon icon='info' size='small' mr={3} />
        <Typo typoSize='sm'>{t(tKey('impact'))}</Typo>
      </Notification>
      {futurePremiumSections}
    </Box>
  ) : null
}
