import { Flex, SelectInput } from '@wrisk/ui-components'
import React, { FunctionComponent, useMemo } from 'react'

import { tEntries } from '../../../infrastructure/internationalisation'
import { FormInputProps } from '../types'

interface Meta {
  values: { value: string; text: string }[]
}

export const DropDownSelectFormInput: FunctionComponent<FormInputProps<string, Meta>> = ({
  meta: { values },
  value,
  tName,
  tKey,
  t,
  onChange,
}) => {
  const valuesTranslated = useMemo(() => {
    if (!tKey || !tName) {
      return values // No translation, use .values as is
    }
    return values.map(({ value, text }) => ({ value, text: t(tEntries(tName, text)) }))
  }, [tKey, tName, values, t])

  const placeholderTranslated = useMemo(
    () => t(tKey(tName, 'placeholder')),
    [t, tKey, tName],
  )

  return (
    <Flex justifyContent='flex-start'>
      <SelectInput<string>
        placeholder={placeholderTranslated}
        value={value}
        values={valuesTranslated}
        onChange={onChange}
      />
    </Flex>
  )
}
