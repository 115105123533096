import { useNavigate } from 'react-router-dom'

import { usePrincipal } from '../application/authentication'
import { usePolicy } from '../application/product/policy/policyContext'
import { useProduct } from '../application/product/productContext'
import { useProposal } from '../application/product/proposal/proposalContext'
import { ProposalPath } from '../application/product/proposal/ProposalPath'
import { withApiErrorHandling } from '../clients/api'
import {
  CustomerCheckResults,
  CustomerPayment,
  getCurrentQuote,
  isCheckoutSkippable,
} from '../domain'
import { useApiErrorHandlingAsync } from './auth'
import { useErrorHandlingAsyncCallback } from './errors'
import { useQuotePremiums } from './premiums'

export const useCheckoutAdjustment = (
  adjustmentId: string,
): { payment: CustomerPayment | undefined; loading: boolean } => {
  const { policy } = usePolicy()
  const { apiClient } = usePrincipal()

  const { result, loading } = useApiErrorHandlingAsync(async () => {
    return policy.policyDetail.monthlyBilling
      ? []
      : apiClient.checkoutMta({
          adjustmentId: adjustmentId,
        })
  }, [adjustmentId])

  return {
    payment: result?.[0],
    loading,
  }
}

export const useCheckoutBind = (
  onUnverified: (checks: CustomerCheckResults) => void,
  onOutstandingBalance: () => void,
) => {
  const navigate = useNavigate()

  const { apiClient } = usePrincipal()
  const { product } = useProduct()
  const { setProposal, proposal } = useProposal()

  const quote = getCurrentQuote(proposal)
  const premiums = useQuotePremiums(quote, product)

  return useErrorHandlingAsyncCallback(async () => {
    const customerVerified = await apiClient.verifyCustomer(proposal.proposalCode)

    customerVerified.isClear
      ? await withApiErrorHandling(
          async () => {
            const customerAccount = await apiClient.createCustomerAccount(
              proposal.proposalCode,
            )

            if (isCheckoutSkippable(customerAccount, premiums)) {
              setProposal(await apiClient.bindProposal(proposal.proposalCode))
            }

            navigate(['..', ProposalPath.CHECKOUT].join('/'))
          },
          onOutstandingBalance,
          [402],
        )
      : onUnverified(customerVerified.checks)
  })
}
