import { default as React, FunctionComponent, ReactElement, ReactNode } from 'react'

import { Box, Heading, mdBumps, Typo } from '../atoms'

export interface ModalHeaderProps {
  className?: string
  header: ReactNode
  subheader?: ReactNode
  upperContent?: ReactElement
  lowerContent?: ReactElement
  centered?: boolean
}

export const ModalHeader: FunctionComponent<ModalHeaderProps> = (props) => {
  const { className, header, subheader, lowerContent, upperContent } = props

  return (
    <Box pb={mdBumps} className={className}>
      {upperContent && <div className='upper-content'>{upperContent}</div>}

      <Heading typoSize='xl' as='h1'>
        {header}
      </Heading>

      {subheader && (
        <Typo className='sub-header' pt={[4, 4, 3]}>
          {subheader}
        </Typo>
      )}
      {lowerContent && (
        <Typo typoSize='sm' className='lower-content' mt={4} p={0}>
          {lowerContent}
        </Typo>
      )}
    </Box>
  )
}
