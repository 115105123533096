import { BoxProps, Flex, mdBumps, Typo, xsBumps } from '@wrisk/ui-components'
import { isNil } from 'lodash'
import React, { FunctionComponent } from 'react'

import { ExcessComparison } from '../../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'

const tKey = TKey('components.main-excess')

export interface MainExcessSummaryProps extends BoxProps {
  excessComparison: ExcessComparison
}

export const MainExcessSummary: FunctionComponent<MainExcessSummaryProps> = ({
  excessComparison: { previous, current },
}) => {
  const { t } = useWriskTranslation()

  const arePremiumsDifferent = !isNil(previous) && previous !== current

  return (
    <Flex
      variant='raised'
      flexDirection='column'
      alignItems='flex-start'
      justifyContent='center'
      width={1}
      rowGap={1}
      columnGap={2}
      p={mdBumps}
    >
      <Typo typoSize='xs'>{t(tKey('header'))}</Typo>

      <Flex columnGap={xsBumps}>
        {arePremiumsDifferent && (
          <Typo typoSize='sm' color='inactive'>
            <s>{t(tFormats('currency.short'), { amount: previous })}</s>
          </Typo>
        )}
        <Typo>{t(tFormats('currency.short'), { amount: current })}</Typo>
      </Flex>
    </Flex>
  )
}
