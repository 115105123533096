import { isNil } from 'lodash'

import { Proposal, ProposalStatus, RenewalProposal, SourceProposal } from './types'

export const getCurrentQuote = (proposal: Proposal) => proposal.quote

export const isProposalUpgradable = (proposal: Proposal): boolean =>
  [ProposalStatus.QUOTE].includes(proposal.status) && Boolean(getUpsellProposal(proposal))

export const getUpsellProposal = (proposal: Proposal) =>
  [ProposalStatus.QUOTE, ProposalStatus.QUOTE_EXPIRED].includes(
    proposal.upsellProposal?.status as ProposalStatus,
  )
    ? proposal.upsellProposal
    : undefined

export const isValidSourceProposal = (
  sourceProposal: SourceProposal | undefined,
): sourceProposal is SourceProposal =>
  [ProposalStatus.QUOTE, ProposalStatus.QUOTE_EXPIRED].includes(
    sourceProposal?.status as ProposalStatus,
  )

export const isRenewalProposal = (proposal: Proposal): proposal is RenewalProposal =>
  !isNil(proposal.sourcePolicy) && proposal.renewalCycle > 0
