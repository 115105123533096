export interface Policyholder {
  phCode: string
  firstName?: string
  lastName?: string
  email?: string
  phoneNumber?: string
  marketingStatus?: boolean
  marketingPreferences: Record<string, boolean>
  accountRegistered: boolean
}

export const getFullName = (
  user: { firstName: string; lastName: string } | undefined,
): string => (user ? `${user.firstName} ${user.lastName}` : 'Stranger')
