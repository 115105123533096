import {
  Box,
  Checkbox,
  FlexAnchor,
  LinkAnchor,
  Typo,
  useModal,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { Trans } from 'react-i18next'

import { getCurrentQuote } from '../../../domain'
import { PromotionTermsModal } from '../../organisms/promotion'
import { useProposal } from '../../product/proposal/proposalContext'
import { FormInputProps } from '../types'

export const CheckboxPromotionFormInput: FunctionComponent<FormInputProps<boolean>> = ({
  value,
  onChange,
  name,
  t,
  tKey,
  tName,
}) => {
  const modal = useModal()

  const { proposal } = useProposal()

  const { promoCode } = getCurrentQuote(proposal)

  const onOfferTermsClick = useCallback(() => {
    if (promoCode) {
      modal.show({
        content: <PromotionTermsModal promoCode={promoCode} />,
      })
    }
  }, [modal, promoCode])

  const label = (
    <Trans
      t={t}
      i18nKey={tKey(tName, 'label')}
      components={{
        OfferLink: <LinkAnchor onClick={onOfferTermsClick} />,
      }}
    />
  )

  const id = `${name}-check`

  return (
    <Box variant='raised' p={2}>
      <FlexAnchor
        as='label'
        variant='highlight'
        justifyContent='flex-start'
        htmlFor={id}
        width={1}
        p={4}
      >
        <Checkbox name={id} id={id} value={value} onChange={onChange} mr={4} />
        <Typo>{label}</Typo>
      </FlexAnchor>
    </Box>
  )
}
