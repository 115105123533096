import { Notification, NotificationProps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { getPolicyVersion, isPriceOptionRemoved, PolicyVersion } from '../../../domain'
import { useDiscountCovers } from '../../../hooks/covers'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { usePolicy } from '../../product/policy/policyContext'

interface PromoRemovedBannerProps extends NotificationProps {
  updatedPolicyVersion: PolicyVersion
}

const tKey = TKey('components.promotion-removed-banner')

export const PromotionRemovedBanner: FunctionComponent<PromoRemovedBannerProps> = ({
  updatedPolicyVersion,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const { policy } = usePolicy()

  const { quote: currentQuote } = getPolicyVersion(policy)
  const { quote: updatedQuote } = updatedPolicyVersion

  const [currentDiscount] = useDiscountCovers(currentQuote)
  const [updatedDiscount] = useDiscountCovers(updatedQuote)

  const isRemoved = isPriceOptionRemoved(currentDiscount, updatedDiscount)

  return isRemoved && currentDiscount ? (
    <Notification type='standalone' variant='warning' {...props}>
      <Typo>
        {t(tKey(currentQuote.promoCode ?? currentDiscount.productCode, 'header'))}
      </Typo>
    </Notification>
  ) : null
}
