import { Box, Form } from '@wrisk/ui-components'
import React, { FunctionComponent, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useApiErrorHandlingAsync } from '../../hooks/auth'
import { ActionBar } from '../organisms/form'
import { FormFooter, FormSections } from './components'
import { defaultOptions } from './options'
import { FormBuilderProps } from './types'

export const FormDisabled: FunctionComponent<FormBuilderProps> = ({
  id,
  footerText,
  data,
  inputs,
  onSubmit,
  onSkip,
  loading,
  options = {},
  tKey,
  t,
  ...props
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const finalOptions = useMemo(() => ({ ...defaultOptions, ...options }), [])

  const form = useForm({
    defaultValues: data,
    mode: 'onChange',
    shouldUnregister: options.shouldUnregister,
  })

  useApiErrorHandlingAsync(form.trigger, [])

  return (
    <Box width={1} {...props}>
      <FormProvider {...form}>
        <Form formId={id} onSubmit={form.handleSubmit(onSubmit)}>
          <FormSections inputs={inputs} options={finalOptions} tKey={tKey} t={t} />
          <FormFooter tKey={tKey} tName={footerText} t={t} />
          <ActionBar
            tKey={tKey}
            loading={loading}
            disabled={!form.formState.isValid}
            onDismiss={onSkip}
          />
        </Form>
      </FormProvider>
    </Box>
  )
}
