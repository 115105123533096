import { SingleSelect } from '@wrisk/ui-components'
import React, { FunctionComponent, useMemo } from 'react'

import { tEntries } from '../../../infrastructure/internationalisation'
import { FormInputProps } from '../types'

interface SingleSelectFormInputMeta {
  selectValues: Record<string, string>
}

export const SingleSelectFormInput: FunctionComponent<
  FormInputProps<string, SingleSelectFormInputMeta>
> = ({ name, onChange, value, meta: { selectValues }, tName, t }) => {
  const options = useMemo(
    () =>
      Object.entries(selectValues).reduce(
        (acc, [id, key]) => ({
          ...acc,
          [id]: t(tEntries(tName, key)),
        }),
        {},
      ),
    [selectValues, t, tName],
  )

  return <SingleSelect name={name} onChange={onChange} value={value} options={options} />
}
