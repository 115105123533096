import {
  Heading,
  Notification,
  NotificationProps,
  PrimaryButton,
  Typo,
  xlBumps,
  xxlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  useProposalAdjustment,
  useProposalFormData,
} from '../../../../hooks/adjustments/proposal'
import {
  TExtends,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { FormErrors } from '../../../formBuilder'
import { FullPage } from '../../../templates'
import { useProduct } from '../../productContext'
import { useProposal } from '../proposalContext'
import { ProposalPath } from '../ProposalPath'

const tKey = TKey('pages.before-we-begin')

const BeforeWeBeginNotification: FunctionComponent<NotificationProps> = (props) => {
  const { t } = useWriskTranslation()

  const { proposal } = useProposal()

  const header = t(tKey('notification', 'header'), { defaultValue: null })
  const content = t(
    [
      tKey('notification', 'sources', proposal.source ?? 'default'),
      tKey('notification', 'content'),
    ],
    {
      defaultValue: null,
    },
  )

  return content ? (
    <Notification
      variant='default'
      type='standalone'
      flexDirection='column'
      alignItems='flex-start'
      {...props}
    >
      {header && (
        <Heading variant='h3' typoSize='md' mb={4}>
          {header}
        </Heading>
      )}
      <Typo as='pre'>{content}</Typo>
    </Notification>
  ) : null
}

export const BeforeWeBeginPage: FunctionComponent = () => {
  const { t } = useWriskTranslation()
  const navigate = useNavigate()
  const {
    product: { beforeWeBegin },
  } = useProduct()

  const data = useProposalFormData(beforeWeBegin)

  const onContinue = useCallback(() => navigate(ProposalPath.DISCLOSURE), [navigate])
  const onSubmitAdjustment = useProposalAdjustment(beforeWeBegin ?? [], false, onContinue)

  return (
    <FullPage pageId='before-we-begin-page' header={t(tKey('header'))}>
      <BeforeWeBeginNotification mb={xlBumps} />
      <Typo mb={xxlBumps}>
        <Trans t={t} i18nKey={tKey('content')} />
      </Typo>
      {beforeWeBegin.length ? (
        <FormErrors
          id='before-we-begin'
          data={data}
          inputs={beforeWeBegin}
          onSubmit={onSubmitAdjustment.execute}
          loading={onSubmitAdjustment.loading}
          tKey={TExtends(tKey, 'form')}
          t={t}
        />
      ) : (
        <PrimaryButton layout='fixed' onClick={onContinue} data-testid='button-confirm'>
          {t('defaults.actions.continue')}
        </PrimaryButton>
      )}
    </FullPage>
  )
}
