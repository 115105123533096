import React, { FunctionComponent } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Page } from '../../../../../infrastructure/routing'
import { AdditionalProfileManagementConfig } from '../../../../../state/configuration'
import { AppPath } from '../../../../AppPath'
import { PolicyAdjustmentResultRouter } from '../results'
import {
  AdditionalProfileAdjustmentPage,
  AdditionalProfileCreateAdjustmentPage,
} from './AdditionalProfileAdjustmentPage'
import { AdditionalProfileAdjustmentPath } from './AdditionalProfileAdjustmentPath'

export interface DisclosureFormProps {
  config: AdditionalProfileManagementConfig
  policyPage: Page
  adjustmentPage: Page
}

export const AdditionalProfileAdjustmentRouter: FunctionComponent<
  DisclosureFormProps
> = ({ config, adjustmentPage, policyPage }) => (
  <Routes>
    <Route
      path={AdditionalProfileAdjustmentPath.Review + AppPath.WILDCARD}
      element={
        <PolicyAdjustmentResultRouter
          config={config}
          adjustmentPage={adjustmentPage}
          policyPage={policyPage}
        />
      }
    />

    <Route
      path={AdditionalProfileAdjustmentPath.Profile}
      element={
        <AdditionalProfileAdjustmentPage
          config={config}
          adjustmentPage={adjustmentPage}
        />
      }
    />

    <Route
      path={AdditionalProfileAdjustmentPath.NewProfile}
      element={
        <AdditionalProfileCreateAdjustmentPage
          config={config}
          adjustmentPage={adjustmentPage}
        />
      }
    />

    <Route path='*' element={<Navigate to={adjustmentPage.url} />} />
  </Routes>
)
