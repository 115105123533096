import { DropDownMenu } from '@wrisk/ui-components'
import { DateTime } from 'luxon'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { dateTimeFormatter } from '../../../domain'
import { getStartOfEachDay, toDateTime } from '../../../util/date'
import { FormInputProps } from '../types'

interface DatePickerFormInputMeta {
  daysInFuture?: number
}

export const DatePickerFormInput: FunctionComponent<
  FormInputProps<string, DatePickerFormInputMeta>
> = ({ value, onChange, meta, tKey, t, tName }) => {
  const values = getStartOfEachDay(
    { days: 30 },
    DateTime.local().plus({ days: meta?.daysInFuture ?? 0 }),
  )

  const currentValue = useMemo(
    () =>
      toDateTime(value) ??
      (() => {
        onChange(values[0].toISO() ?? undefined)
        return values[0]
      })(),
    [onChange, value, values],
  )

  const formatter = dateTimeFormatter(t(tKey(tName, 'today')))

  const onSelectCallback = useCallback(
    (selection: DateTime) => onChange(selection.toISO() ?? undefined),
    [onChange],
  )

  return (
    <DropDownMenu
      values={values}
      currentValue={currentValue ?? values[0]}
      formatter={formatter}
      onSelectCallback={onSelectCallback}
    />
  )
}
