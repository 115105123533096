import css from '@styled-system/css'
import styled from 'styled-components'
import { variant } from 'styled-system'

export type AccountIconProperty = 'default'

export interface AccountIconProps {
  variant?: AccountIconProperty
}

export const AccountIcon = styled.div<AccountIconProps>(
  css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2rem',
    height: '2rem',
    borderRadius: '2rem',
    backgroundColor: 'primaryAccent',
    borderColor: 'chalk',
    borderWidth: 1,
  }),
  variant({
    scale: 'AccountIcon',
    variants: {
      default: {},
    },
  }),
)

AccountIcon.defaultProps = {
  variant: 'default',
}
