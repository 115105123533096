import React, { FunctionComponent } from 'react'

import { Flex, Icon } from '../atoms'
import { IconsTheme } from '../icons'
import { MenuText } from './MenuText'

export interface MenuItemProps {
  icon?: keyof IconsTheme
  text?: string
}

export const MenuItem: FunctionComponent<MenuItemProps> = ({ text, icon = 'chat' }) => {
  return (
    <Flex>
      <Icon icon={icon} size='small' mr={2} />
      {text && <MenuText>{text}</MenuText>}
    </Flex>
  )
}
