import React, { FunctionComponent, useCallback } from 'react'
import { generatePath, Navigate, Route, Routes, useNavigate } from 'react-router-dom'

import { isProposalUpgradable, Proposal } from '../../../../../domain'
import { useRelevantDisclosures } from '../../../../../hooks/adjustments/proposal/disclosure/relevantDisclosures'
import { AppPath } from '../../../../AppPath'
import { useProduct } from '../../../productContext'
import { ProposalPath } from '../../ProposalPath'
import { DisclosurePage } from './DisclosurePage'
import { DisclosurePath } from './path'

export const DisclosureRouter: FunctionComponent = () => {
  const navigate = useNavigate()

  const {
    product: { disclosures },
    scheme: { productUrlName },
  } = useProduct()

  const sections = useRelevantDisclosures(disclosures)

  const onContinueProposalCallback = useCallback(
    (proposal: Proposal) => {
      const upgradable = isProposalUpgradable(proposal)

      navigate(
        [
          generatePath(AppPath.PROPOSAL, {
            proposalCode: proposal.proposalCode,
            productUrlName,
          }),
          upgradable && ProposalPath.UPGRADE,
        ]
          .filter(Boolean)
          .join('/'),
      )
    },
    [navigate, productUrlName],
  )

  return (
    <Routes>
      <Route
        index
        element={
          <Navigate
            to={generatePath(DisclosurePath.Section, { sectionId: sections[0]?.id })}
            replace={true}
          />
        }
      />
      <Route
        path={DisclosurePath.Section + AppPath.WILDCARD}
        element={<DisclosurePage onComplete={onContinueProposalCallback} />}
      />
      <Route path='*' element={<Navigate to='..' />} />
    </Routes>
  )
}
