import { Box, BoxProps, PremiumSummary, smBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { Quote } from '../../../domain'
import { useQuotePremiums } from '../../../hooks/premiums'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { ProductConfig } from '../../../state/configuration'
import { toDateTime } from '../../../util/date'

export interface DriveawayAdjustedPremiumsProps extends BoxProps {
  currentQuote: Quote
  previousQuote?: Quote
  product: ProductConfig

  policyTerm?: string
  expiredAt?: string
}

const tKey = TKey('components.driveaway-adjusted-premium')

export const DriveawayAdjustedPremium: FunctionComponent<
  DriveawayAdjustedPremiumsProps
> = ({ currentQuote, previousQuote, policyTerm, expiredAt, product, ...props }) => {
  const { t } = useWriskTranslation()

  const currentPremiums = useQuotePremiums(currentQuote, product)
  const previousPremiums = useQuotePremiums(previousQuote, product)

  const currentAmount =
    currentPremiums.totals.discounted === 0
      ? t(tKey('free'))
      : t(tFormats('currency.long'), { amount: currentPremiums.totals.discounted })

  const previousAmount =
    previousPremiums.totals.discounted === 0
      ? t(tKey('free'))
      : t(tFormats('currency.long'), { amount: previousPremiums.totals.discounted })

  const header = policyTerm
    ? t(tKey('header', 'term'), {
        period: t(tFormats('duration'), { value: policyTerm }),
      })
    : t(tKey('header', 'cover'))

  const subheader =
    expiredAt &&
    t(tKey('subheader'), {
      expiredAt: t(tFormats('datetime.medium'), { value: toDateTime(expiredAt) }),
    })

  return (
    <Box p={2} {...props}>
      <PremiumSummary
        p={smBumps}
        header={header}
        subheader={subheader}
        currentAmount={currentAmount}
        previousAmount={previousQuote && previousAmount}
      />
    </Box>
  )
}
