import { FilterInput, InputMessage, xsBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { ApiClient } from '../../../../../clients/api'
import { useApiErrorHandlingAsync } from '../../../../../hooks/auth'
import { usePrincipal } from '../../../../authentication'

interface Props {
  onSelect: (model: string | undefined) => void
  year: number
  make: string
  model?: string
}

const dataLookup = (client: ApiClient, year: number, make: string) =>
  client.lookupVehicleModels(year, make)

const renderOption = (model: string) => model

const matchOption = (text: string) => (option: string) =>
  option.toLowerCase().includes(text.toLowerCase())

export const VehicleModelsFilter: FunctionComponent<Props> = ({
  onSelect,
  year,
  make,
  model,
}) => {
  const { apiClient } = usePrincipal()

  const asyncDataEntries = useApiErrorHandlingAsync(dataLookup, [apiClient, year, make])

  return asyncDataEntries.result?.length === 0 ? (
    <InputMessage>{`We couldn't find any ${make} models manufactured in ${year}.`}</InputMessage>
  ) : (
    <FilterInput
      data-testid='vehicle-model'
      mb={xsBumps}
      placeholder='Model'
      value={model}
      onSelect={onSelect}
      options={asyncDataEntries.result || []}
      renderOption={renderOption}
      renderOptionText={renderOption}
      matchOption={matchOption}
    />
  )
}
