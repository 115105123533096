import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import {
  Flex,
  FlexAnchor,
  Header,
  HeaderLowerContent,
  HeaderSubtitle,
  HeaderTitle,
  HeaderUpperContent,
  Icon,
  LinkAnchor,
  MainSection,
  WideContainer,
} from '../atoms'
import { HelpLink } from '../molecules'
import { AnimatedPage } from './AnimatedPage'

export interface FullPageTemplateProps extends PropsWithChildren {
  pageId?: string
  header: ReactNode
  subheader?: ReactNode
  upperHeader?: ReactNode
  footer?: ReactNode
  helpLinkText?: ReactNode
  helpLinkUrl?: string
  upButton?: {
    to: string
    text: string
  }
}

export const FullPageTemplate: FunctionComponent<FullPageTemplateProps> = ({
  pageId,
  upButton,
  header,
  subheader,
  upperHeader,
  helpLinkUrl,
  helpLinkText,
  children,
  footer,
}) => {
  const backButtonComponent = upButton && (
    <Flex width={1}>
      <FlexAnchor
        fontFamily='buttons'
        mb={10}
        as={Link}
        to={upButton.to}
        data-testid='link-back'
      >
        <Icon color='hyperlink' size='medium' pl={2} icon='chevronRight' rotate={180} />
        <LinkAnchor as='span' typoSize='sm' variant='standalone'>
          {upButton.text}
        </LinkAnchor>
      </FlexAnchor>
    </Flex>
  )
  const upperComponent = upperHeader && (
    <HeaderUpperContent>{upperHeader}</HeaderUpperContent>
  )
  const subheaderComponent = subheader && <HeaderSubtitle>{subheader}</HeaderSubtitle>
  const helpLinkComponent = helpLinkUrl && helpLinkText && (
    <HeaderLowerContent>
      <HelpLink
        linkText={helpLinkText}
        link={helpLinkUrl}
        isOpenModal={true}
        data-testid='link-help'
      />
    </HeaderLowerContent>
  )

  return (
    <AnimatedPage pageId={pageId} footer={footer}>
      <WideContainer>
        <MainSection>
          <Header width={1}>
            {backButtonComponent}
            {upperComponent}
            <HeaderTitle>{header}</HeaderTitle>
            {subheaderComponent}
            {helpLinkComponent}
          </Header>
          {children}
        </MainSection>
      </WideContainer>
    </AnimatedPage>
  )
}
