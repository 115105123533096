import { LinkAnchor, LinkAnchorProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { useDependencies } from '../../state'

export const Chat: FunctionComponent<LinkAnchorProps> = ({ children, ...props }) => {
  const { chatClient } = useDependencies()

  return (
    <LinkAnchor onClick={chatClient.show} {...props}>
      {children}
    </LinkAnchor>
  )
}
