import {
  Section,
  SectionContent,
  SectionProps,
  SectionTitle,
  xlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { getPolicyVersion, PolicyVersion } from '../../../../domain'
import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { ProductKind } from '../../../../state/configuration'
import { usePolicy } from '../../../product/policy/policyContext'
import { useProduct } from '../../../product/productContext'
import { CurrentPremiumChange } from './components/CurrentPremiumChange'
import { FuturePremiumChanges } from './components/FuturePremiumChanges'

export interface AdjustedPremiumSectionProps extends SectionProps {
  updatedPolicyVersions: PolicyVersion[]
}

const tKey = TKey('components.adjusted-premium-section')

export const AdjustedPremiumSection: FunctionComponent<AdjustedPremiumSectionProps> = ({
  updatedPolicyVersions,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const { product } = useProduct()
  const { policy } = usePolicy()

  const { quote: originalQuote } = getPolicyVersion(policy)
  const [{ quote: updatedQuote }] = updatedPolicyVersions

  if (product?.productKind === ProductKind.DRIVEAWAY) {
    return null
  }

  return (
    <Section width={1} {...props}>
      <SectionTitle>{t(tKey('header'))}</SectionTitle>
      <SectionContent>
        <CurrentPremiumChange
          policyDetail={policy.policyDetail}
          product={product}
          originalQuote={originalQuote}
          updatedQuote={updatedQuote}
          variant='raised'
          p={2}
        />

        <FuturePremiumChanges
          policy={policy}
          updatedPolicyVersions={updatedPolicyVersions}
          product={product}
          variant='raised'
          mt={xlBumps}
        />
      </SectionContent>
    </Section>
  )
}
