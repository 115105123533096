import { LicencePlateInput } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { FormInputProps } from '../types'

export const VehicleRegistrationFormInput: FunctionComponent<FormInputProps<string>> = ({
  name,
  value,
  onChange,
  tKey,
  tName,
  t,
}) => (
  <LicencePlateInput
    name={name}
    onChange={onChange}
    initialValue={value}
    placeholder={t<string>(tKey(tName, 'placeholder'))}
  />
)
