import { FlexProps } from '@wrisk/ui-components'
import { DateTime, Duration } from 'luxon'
import React, { FunctionComponent, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import {
  getCurrentQuote,
  getPolicyholder,
  getUpsellProposal,
  Proposal,
} from '../../../../domain'
import { useAuth } from '../../../../hooks/auth'
import { useQuotePremiums } from '../../../../hooks/premiums'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { getSchemeByCode, useConfig } from '../../../../state/configuration'
import { AppPath } from '../../../AppPath'
import { usePrincipal } from '../../../authentication'
import { useProduct } from '../../productContext'
import BenefitListItem from './components/BenefitListItem'
import { UpsellSection } from './components/UpsellSection'

const tKey = TKey('components.checkout-upsell')

export interface CheckoutUpsellSectionProps extends FlexProps {
  proposal: Proposal
}

interface ContentProps extends CheckoutUpsellSectionProps {
  upsellProposal: Proposal
}

const Content: FunctionComponent<ContentProps> = ({
  upsellProposal,
  proposal,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()

  const { product } = useProduct()
  const { isAuthenticated } = usePrincipal()
  const { signIn } = useAuth()
  const { productUrlName, schemeCode } = useConfig(
    getSchemeByCode(upsellProposal.schemeCode),
  )

  const coveredDate = DateTime.fromISO(proposal.startAt ?? '').plus(
    Duration.fromISO(proposal.policyTerm),
  )

  const quote = getCurrentQuote(upsellProposal)

  const { totals } = useQuotePremiums(quote, product)

  const policyholder = getPolicyholder(quote)

  const onButtonClick = useCallback(() => {
    const path = generatePath(AppPath.PROPOSAL, {
      proposalCode: upsellProposal.proposalCode,
      productUrlName,
    })

    isAuthenticated ? navigate(path) : signIn(path)
  }, [isAuthenticated, upsellProposal, navigate, productUrlName, signIn])

  const upsells = t(tKey(schemeCode, 'upsell'), {
    returnObjects: true,
    defaultValue: [],
  }) as string[]

  const checkoutUpsellList = upsells.map((upsell, index) => (
    <BenefitListItem key={index}>{upsell}</BenefitListItem>
  ))

  return (
    <UpsellSection
      benefits={checkoutUpsellList}
      buttonText={t(tKey(schemeCode, 'actions.view'))}
      buttonSubText={t(tKey(schemeCode, 'actions.priced'), {
        premium: t(tFormats('currency.long'), {
          amount: totals.discounted,
        }),
      })}
      title={t(tKey(schemeCode, 'header'), { profile: policyholder })}
      text={
        <Trans
          t={t}
          i18nKey={tKey(schemeCode, 'content')}
          values={{ date: t(tFormats('datetime.medium'), { value: coveredDate }) }}
        />
      }
      onButtonClick={onButtonClick}
      {...props}
    />
  )
}

export const CheckoutUpsellSection: FunctionComponent<CheckoutUpsellSectionProps> = ({
  proposal,
  ...props
}) => {
  const upsellProposal = getUpsellProposal(proposal)

  return upsellProposal ? (
    <Content upsellProposal={upsellProposal} proposal={proposal} {...props} />
  ) : null
}
