import css from '@styled-system/css'
import { isNil } from 'lodash'
import styled from 'styled-components'
import {
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  variant,
} from 'styled-system'

import { TransitionProps } from '../../transition'
import { mdBumps } from '../layout'

type DragBoxProperty = 'default' | 'active'

export interface DragBoxProps extends TransitionProps, LayoutProps, FlexboxProps {
  isActive?: boolean
  variant?: DragBoxProperty
}

export const DragBox = styled.div.attrs<DragBoxProps>(({ isActive, variant }) => ({
  variant: isNil(isActive) ? variant : isActive ? 'active' : 'default',
}))<DragBoxProps>(
  css({
    borderRadius: 2,
    borderStyle: 'dashed',
    strokeDasharray: 5,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    p: mdBumps,
    cursor: 'pointer',
    minHeight: '128px',
    bg: 'chalk',
  }),
  variant({
    variants: {
      default: {
        borderWidth: '1px',
        borderColor: 'dragBoxBorderDefault',
      },
      active: {
        borderWidth: '2px',
        borderColor: 'dragBoxBorderActive',
      },
    },
  }),
  compose(layout, flexbox),
)

DragBox.defaultProps = {
  variant: 'default',
  transition: 'default',
}
