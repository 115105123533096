import {
  Flex,
  FlexAnchor,
  Icon,
  mdBumps,
  smBumps,
  Typo,
  useModal,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { with404ErrorHandling } from '../../../../clients/api'
import { GeneratedPolicyDocument } from '../../../../domain'
import { useApiErrorHandlingAsyncCallback } from '../../../../hooks/auth'
import {
  tDefaults,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { usePrincipal } from '../../../authentication'
import { MissingDocumentModal } from './MissingDocumentModal'

const useOpenPolicyDocument = (
  policyRefNumber: string,
  template: string,
  onMissing?: () => void,
) => {
  const { apiClient } = usePrincipal()

  return useApiErrorHandlingAsyncCallback(async () => {
    const document = await with404ErrorHandling(() =>
      apiClient.getPolicyDocument(policyRefNumber, template),
    )

    if (!document) {
      return onMissing?.()
    }

    // This has to be done as iPhone does not open the document after an async call otherwise and Safari blocks the pop-up
    setTimeout(() => {
      window.open(document.location)
    }, 0)
  })
}

export interface PolicyDocumentLinkProps extends GeneratedPolicyDocument {
  policyRefNumber: string
}

export const PolicyDocumentLink: FunctionComponent<PolicyDocumentLinkProps> = ({
  policyRefNumber,
  id,
  name,
}) => {
  const modal = useModal()

  const { t } = useWriskTranslation()

  const onOpenPolicyDocument = useOpenPolicyDocument(policyRefNumber, name, () =>
    modal.show({
      content: <MissingDocumentModal />,
    }),
  )

  return (
    <FlexAnchor
      key={name}
      width={1}
      variant='inset'
      px={mdBumps}
      py={3}
      onClick={onOpenPolicyDocument.execute}
      disabled={onOpenPolicyDocument.loading}
    >
      <Flex flexDirection='row' alignItems='center' mr={2}>
        <Icon icon='document' mr={smBumps} />
        <Typo>{t(tDefaults('documents', id))}</Typo>
      </Flex>
      <Flex flexDirection='row' alignItems='center'>
        {onOpenPolicyDocument.loading ? (
          <Icon icon='loadingDark' size='tiny' />
        ) : (
          <Icon icon='newTab' size='tiny' />
        )}
      </Flex>
    </FlexAnchor>
  )
}
