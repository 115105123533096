import { Box, Flex, FlexProps, mdBumps, PrimaryButton, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { getCurrentQuote } from '../../../../domain'
import { useContinueProposalCallback } from '../../../../hooks/proposal'
import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { useProposal } from '../../../product/proposal/proposalContext'
import { ManageProposalMenu } from '../../organisms/ManageProposalMenu'
import { QuoteHeader } from '../../organisms/QuoteHeader'

const tKey = TKey('components.expired-proposal')

export const ExpiredProposalCard: FunctionComponent<FlexProps> = (props) => {
  const { proposal } = useProposal()

  const { t } = useWriskTranslation()

  const quote = getCurrentQuote(proposal)

  const onContinueProposalCallback = useContinueProposalCallback(proposal)

  return (
    <Flex width={1} variant='raised' flexWrap='wrap' alignItems='stretch' {...props}>
      <Flex
        alignItems='flex-start'
        width={[1, 1, 3 / 5]}
        borderBottomWidth={[1, 1, 0]}
        borderRightWidth={[0, 0, 1]}
      >
        <QuoteHeader quote={quote} />
        <ManageProposalMenu />
      </Flex>
      <Flex width={[1, 1, 2 / 5]} flexDirection='column'>
        <Box width={1} p={mdBumps} borderBottomWidth={1}>
          <Typo>{t(tKey('header'))}</Typo>
        </Box>

        <Box width={1} p={mdBumps}>
          <PrimaryButton width={1} onClick={onContinueProposalCallback}>
            {t(tKey('actions.update'))}
          </PrimaryButton>
        </Box>
      </Flex>
    </Flex>
  )
}
