import { isNil } from 'lodash'
import React, { FunctionComponent } from 'react'

import { tDefaults, tFormats } from '../../../infrastructure/internationalisation'
import { Formatter } from '../types'

export const NumberTextFormatter: FunctionComponent<Formatter<number>> = ({
  value,
  input: { meta },
  t,
}) => {
  const content = !isNil(value)
    ? t(tFormats('number.standard'), {
        amount: value,
        ...meta?.formatParams,
      })
    : t(tDefaults('inputs.no-answer'))
  return <>{content}</>
}
