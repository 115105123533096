import {
  DisclosureSectionCondition,
  InputCondition,
  ProductCondition,
} from './conditionTypes'

export interface ConfigurationState {
  config: StateConfig
}

export enum DocumentType {
  PRODUCT = 'product',
  POLICY = 'policy',
  STATIC = 'static',
}

interface Document {
  id: string
  type: DocumentType
}

export interface ProductDocument extends Document {
  type: DocumentType.PRODUCT
  coverCode?: string
}

export interface PolicyDocument extends Document {
  type: DocumentType.POLICY
}

export interface StaticDocument extends Document {
  type: DocumentType.STATIC
}

export type DocumentConfig = ProductDocument | PolicyDocument | StaticDocument

export type Provider = string

interface StripeConfig {
  apiKey: string
  stripeAccount: string | undefined
}

export interface TwilioConfig {
  chatServer: string
}

export interface Localisation {
  locale: string
  currency: string
  timeZone: string
  fallbackLanguage: string
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface Config {
  localisation?: Partial<Localisation>
  sessionClient: any
  apiClient: any
  app: any
  auth0: any
  state: any
  stripe: StripeConfig
  airbrake: any // Actually AirbrakeConfig
  logLevel: string
  twilio?: Partial<TwilioConfig>
}

/* eslint-enable  @typescript-eslint/no-explicit-any */

export interface AppConfig {
  assetDomain: string
  cookieDomain: string
  auth0Domain: string
  provider: Provider
  title: string
  description: string
  themeColor: string
}

export interface Scheme {
  schemeCode: string
  productUrlName: string
  hidden?: boolean
}

export interface RetrieveConfig {
  backBooks?: string[]
  formats?: Record<string, string>
}

export interface FeatureToggles {
  // Long-running toggles
  renderCheckoutAction: boolean
  allowModifyBillingDay: boolean
  isAdmin: boolean
  multiQuote: boolean
  localisationSwitcher: boolean
  disableBind: boolean
  axitechFnol: boolean
  // Short-lived toggles
  uploadDocuments: boolean
}

export interface StateConfig {
  keys?: {
    maps?: string
  }
  productConfig: Record<string, ProductConfig>
  contentBaseUrl: string
  provider: Provider
  schemes: Scheme[]
  features: Partial<FeatureToggles>
  inputs: Record<string, InputConfig>
  adjustments: Record<string, ChangeConfig>
  account?: InputConfig[]
  proposalTracking?: string[]
  createProposal?: {
    lookups?: Record<string, string>
    passthroughs?: string[]
  }
  quoteData?: string[]
  retrieve?: RetrieveConfig
  footer?: string[]
}

export enum ProductKind {
  SUBSCRIPTION = 'SUBSCRIPTION',
  ANNUAL = 'ANNUAL',
  DRIVEAWAY = 'DRIVEAWAY',
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface InputConfig<TMeta = any | never> {
  name: string
  type: string
  meta?: TMeta
  validation?: Record<string, any>
  readOnly?: {
    default?: boolean
    conditions: InputCondition[]
  }
  conditional?: {
    preserve?: boolean
    conditions: InputCondition[]
  }
  options?: {
    hideFormHeader?: boolean
    helpLink?: boolean
    subheader?: boolean
    header?: boolean
  }
}

export type AdjustmentInputConfig<
  TChange extends ChangeConfig = ChangeConfig,
  TMeta = any | never,
> = InputConfig<TMeta> & {
  adjustment: TChange
}

export type AdjustmentUpdate = string | { id: string; path: string }[]

export enum ChangeType {
  ASSET_DISCLOSURE = 'ASSET_DISCLOSURE',
  ASSET_ATTRIBUTE = 'ASSET_ATTRIBUTE',
  PROFILE_DISCLOSURE = 'PROFILE_DISCLOSURE',
  PROFILE_ATTRIBUTE = 'PROFILE_ATTRIBUTE',
  QUOTE_DISCLOSURE = 'QUOTE_DISCLOSURE',
  QUOTE_ATTRIBUTE = 'QUOTE_ATTRIBUTE',
  START_DATE_CHANGE = 'START_DATE_CHANGE',
  VOLUNTARY_EXCESS = 'VOLUNTARY_EXCESS',
  REQUEST_COVER_OPTION = 'REQUEST_COVER_OPTION',
}

export interface QuoteChangeConfig {
  changeType: ChangeType.QUOTE_DISCLOSURE
  update: AdjustmentUpdate
}

export interface ProfileChangeConfig {
  changeType: ChangeType.PROFILE_DISCLOSURE
  update: AdjustmentUpdate
}

export interface AssetChangeConfig {
  changeType: ChangeType.ASSET_DISCLOSURE
  update: AdjustmentUpdate
}

export interface QuoteAttributeChangeConfig {
  changeType: ChangeType.QUOTE_ATTRIBUTE
  update: AdjustmentUpdate
}

export interface ProfileAttributeChangeConfig {
  changeType: ChangeType.PROFILE_ATTRIBUTE
  update: AdjustmentUpdate
}

export interface AssetAttributeChangeConfig {
  changeType: ChangeType.ASSET_ATTRIBUTE
  update: AdjustmentUpdate
}

export interface StartDateChangeConfig {
  changeType: ChangeType.START_DATE_CHANGE
}

export interface VoluntaryExcessChangeConfig {
  changeType: ChangeType.VOLUNTARY_EXCESS
}

export interface RequestCoverOptionChangeConfig {
  changeType: ChangeType.REQUEST_COVER_OPTION
  coverCode: string
}

export type ChangeConfig =
  | ProfileChangeConfig
  | AssetChangeConfig
  | QuoteChangeConfig
  | ProfileAttributeChangeConfig
  | AssetAttributeChangeConfig
  | QuoteAttributeChangeConfig
  | StartDateChangeConfig
  | VoluntaryExcessChangeConfig
  | RequestCoverOptionChangeConfig

export enum ManagementType {
  ADDITIONAL_PROFILE = 'ADDITIONAL_PROFILE',
  POLICYHOLDER = 'POLICYHOLDER',
  PROMOTION = 'PROMOTION',
}

export interface MainDisclosureConfig {
  id: string
  type: undefined
  inputs: AdjustmentInputConfig[]
  footerText?: string
  conditions?: DisclosureSectionCondition[]
}

export interface PolicyholderDisclosureConfig {
  id: string
  type: ManagementType.POLICYHOLDER
  inputs: InputConfig[]
  footerText?: string
  conditions?: DisclosureSectionCondition[]
}

export interface AdditionalProfileDisclosureConfig {
  id: string
  type: ManagementType.ADDITIONAL_PROFILE
  maxAdditionalProfiles: number
  inputs: AdjustmentInputConfig[]
  footerText?: string
  conditions?: DisclosureSectionCondition[]
}

export interface PromotionConfig {
  inputs: AdjustmentInputConfig[]
}

export interface PromotionDisclosureConfig {
  id: string
  type: ManagementType.PROMOTION
  promotions: Record<string, PromotionConfig>
}

export type DisclosureConfig =
  | MainDisclosureConfig
  | AdditionalProfileDisclosureConfig
  | PolicyholderDisclosureConfig
  | PromotionDisclosureConfig

export interface ProposalConfirmationConfig {
  conditions: ProductCondition[]
  inputs: AdjustmentInputConfig[]
}

export interface OptionalCoversConfig {
  inputs: AdjustmentInputConfig[]
}

export interface QuoteManagementConfig {
  id: string
  type: undefined
  inputs: AdjustmentInputConfig[]
  conditions?: ProductCondition[]
}

export interface AdditionalProfileManagementConfig {
  id: string
  type: ManagementType.ADDITIONAL_PROFILE
  maxAdditionalProfiles: number
  inputs: AdjustmentInputConfig[]
  conditions: ProductCondition[]
}

export interface FeedbackConfig {
  type: string
  actions: Record<string, string>
}

export type ManagementConfig = QuoteManagementConfig | AdditionalProfileManagementConfig

export type UploadsConfig = Record<
  string,
  Partial<{
    maxSize: number
    maxFiles: number
    accept: { [key: string]: string[] }
  }>
>

export interface ExcessConfig {
  voluntaryExcess?: number[]
  isAdjustable?: boolean
  endorsements: string[]
}

export enum CancellationType {
  Standard = 'Standard',
  CoolingOff = 'CoolingOff',
}

export enum CoolingOffStart {
  Inception = 'Inception',
  Bind = 'Bind',
}

export interface CancellationCoolingOffConfig {
  type: CancellationType.CoolingOff
  reasons?: string[]
  startAt: CoolingOffStart
  days: number
}

export interface CancellationStandardConfig {
  type: CancellationType.Standard
  reasons?: string[]
  daysToDefer?: number
}

export type CancellationConfig = CancellationCoolingOffConfig | CancellationStandardConfig

export interface RenewalConfig {
  reasons: string[]
  sections: ManagementConfig[]
}

export interface ProductConfig {
  productKind: ProductKind
  assetTypes: AssetType[]
  endorsements?: string[]
  excess?: ExcessConfig
  cancellation?: CancellationConfig
  documents: DocumentConfig[]
  uploads?: UploadsConfig
  howItWorks?: HowItWorksSection[]
  checkoutChecklist?: string[]
  startAtDayDelay?: number
  disclosures: DisclosureConfig[]
  proposalConfirmation?: ProposalConfirmationConfig
  feedback?: Record<string, FeedbackConfig>
  management: ManagementConfig[]
  summary: ManagementConfig[]
  renewal: RenewalConfig
  accountSetup: AdjustmentInputConfig[]
  expiredProposal: AdjustmentInputConfig[]
  beforeWeBegin: AdjustmentInputConfig[]
  hideBillingSidebar?: boolean
}

export interface OptionalCoverConfig {
  code: string
  inputs?: AdjustmentInputConfig[]
}

export interface AssetType {
  code: string
  core?: boolean
  covers: {
    subheader?: boolean
    withInfo?: boolean
    included: string[]
    optional?: OptionalCoverConfig[]
  }
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface Condition {
  path: string
  values: any[]
}

/* eslint-enable  @typescript-eslint/no-explicit-any */

export type ConditionParentNode = [string, ConditionNode, ConditionNode]
export type ConditionLeafNode = Condition
export type ConditionNode = ConditionParentNode | ConditionLeafNode

export interface HowItWorksSection {
  id: string
  illusId: string
}
