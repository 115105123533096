import { usePolicyholder, usePrincipal } from '../../../application/authentication'
import { AccountValidationStatus, Policyholder } from '../../../domain'
import { useApiErrorHandlingAsyncCallback } from '../../auth'
import { hasChanges } from './helpers'

export * from './data'

export const usePolicyholderAdjustment = (onComplete?: () => Promise<void> | void) => {
  const { apiClient } = usePrincipal()
  const { policyholder, setPolicyholder } = usePolicyholder()

  return useApiErrorHandlingAsyncCallback(async (data: Partial<Policyholder>) => {
    if (hasChanges(policyholder, data)) {
      setPolicyholder(await apiClient.updatePolicyholder(data))
    }

    await onComplete?.()
  })
}

export const useAccountValidation = (onSuccess?: () => void, onConflict?: () => void) => {
  const { apiClient } = usePrincipal()

  return useApiErrorHandlingAsyncCallback(async () => {
    const accountValidation = await apiClient.validateUserAccount('Final Mile')

    switch (accountValidation.validationStatus) {
      case AccountValidationStatus.OK:
        onSuccess?.()
        break
      case AccountValidationStatus.CONFLICT:
        onConflict?.()
        break
    }
  })
}
