import { Box, ModalHeader, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { Chat } from '../../Chat'
import { ExternalLink } from '../../links'

const tKey = TKey('components.missing-document-modal')

export const MissingDocumentModal: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  return (
    <Box>
      <ModalHeader header={t(tKey('header'))} />
      <Typo>
        <Trans
          t={t}
          i18nKey={tKey('content')}
          components={{
            Chat: <Chat />,
            ExternalLink: <ExternalLink />,
          }}
        />
      </Typo>
    </Box>
  )
}
