import React, { FunctionComponent, useCallback } from 'react'
import styled from 'styled-components'
import { compose, layout, LayoutProps, MarginProps, space } from 'styled-system'

import { Input } from './Input'

export interface CheckboxProps extends MarginProps, LayoutProps {
  id?: string
  name?: string
  value?: boolean | undefined
  onChange?: (checked: boolean) => void
  className?: string
}

const Component: FunctionComponent<CheckboxProps> = ({
  id,
  name,
  value,
  onChange,
  ...props
}) => {
  const onInputChange = useCallback(() => onChange?.(!value), [value, onChange])

  return (
    <div {...props}>
      <Input
        name={name}
        id={id}
        type='checkbox'
        onChange={onInputChange}
        checked={value ?? false}
        data-testid='checkbox-input'
      />
      <label htmlFor={id} />
    </div>
  )
}

export const Checkbox = styled(Component)(
  {
    position: 'relative',
    width: '1.5rem',
    height: '1.5rem',
    minWidth: '1.5rem',
    minHeight: '1.5rem',
    input: {
      width: '100%',
      height: '100%',
      opacity: 0,
      position: 'absolute',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    label: {
      width: '100%',
      height: '100%',
      display: 'block',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      zIndex: 1000,
    },
  },
  (props) => ({
    'input + label': {
      backgroundImage: props.value
        ? `url('${props.theme.icons.checkboxOn.url}')`
        : `url('${props.theme.icons.checkboxOff.url}')`,
    },
  }),
  compose(space, layout),
)
