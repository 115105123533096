import { AxiosInstance } from 'axios'

import { CustomerCheck } from '../../../domain/types/customerCheck'
import { withGenericErrorHandling } from '../errors'

export interface CustomerVerificationClient {
  verifyCustomer: (proposalCode: string) => Promise<CustomerCheck>
  idCheck: (proposalCode: string) => Promise<CustomerCheck>
}

export const createCustomerVerificationClient = (
  getInstance: () => Promise<AxiosInstance>,
): CustomerVerificationClient => ({
  verifyCustomer: (proposalCode) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.put<CustomerCheck>(
        `/proposals/${proposalCode}/verify`,
      )
      return response.data
    }),
  idCheck: (proposalCode) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.put<CustomerCheck>(
        `/proposals/${proposalCode}/id-check`,
      )
      return response.data
    }),
})
