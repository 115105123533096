import {
  Box,
  BoxProps,
  Flex,
  Heading,
  HelpFlexAnchor,
  Icon,
  smBumps,
  Typo,
  xsBumps,
} from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { TKeyBuilder } from '../../../infrastructure/internationalisation'
import { getHelpLink, InputConfig, useConfig } from '../../../state/configuration'
import { ContextualHelpLink } from '../../organisms/links'
import { useLinks } from '../../organisms/links/hooks'

export interface FormHeaderProps extends BoxProps {
  input: InputConfig
  tKey: TKeyBuilder
  t: TFunction
}

export const FormHeader: FunctionComponent<FormHeaderProps> = ({
  input: { options, name },
  tKey,
  t,
  ...props
}) => {
  const link = useConfig(getHelpLink(name))
  const links = useLinks()

  return (
    <Box width={1} {...props}>
      <Flex width={1}>
        {options?.header && (
          <Heading variant='h3' typoSize='md'>
            {t(tKey(name, 'header'))}
          </Heading>
        )}
        {options?.helpLink && (
          <HelpFlexAnchor
            variant='highlight'
            borderRadius='50%'
            justifyContent='center'
            ml={smBumps}
            p={2}
            href={link}
          >
            <Icon icon='help' size='small' />
          </HelpFlexAnchor>
        )}
      </Flex>
      {options?.subheader && (
        <Typo color='bodySecondary' mt={xsBumps}>
          <Trans
            t={t}
            i18nKey={tKey(name, 'subheader')}
            components={{
              ...links,
              ContextualHelp: <ContextualHelpLink link={link} />,
            }}
            values={{ name }}
          />
        </Typo>
      )}
    </Box>
  )
}
