import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { ConditionalOptions, InputConfig } from '../../state/configuration'
import useConditions from './conditions'

export interface ConfiguredInput {
  id: string
  input: InputConfig
  isVisible: boolean
  isReadOnly: boolean
}

export const useConfiguredInputs = (
  inputs: InputConfig[],
  options?: Partial<ConditionalOptions>,
): ConfiguredInput[] => {
  const { unregister, resetField } = useFormContext()
  const conditions = useConditions(inputs, options)

  return useMemo(
    () =>
      inputs.map((input) => {
        const { name, conditional, readOnly } = input

        const isVisible = conditional?.conditions?.every(conditions) ?? true
        const isReadOnly = readOnly?.conditions?.every(conditions) ?? !!readOnly?.default

        if (!isVisible && !conditional?.preserve) {
          const prefixedName = options?.inputValue?.prefix
            ? [options.inputValue.prefix, name].join('.')
            : undefined

          if (prefixedName) {
            resetField(prefixedName)
          }

          if (name) {
            unregister(name)
          }
        }

        return { id: name, input, isVisible, isReadOnly }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputs, conditions, resetField, unregister],
  )
}
