import { BoxProps, Flex, Icon, smBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  getCoreAsset,
  getExcessComparison,
  hasUniqueExcesses,
  isMainExcess,
  Quote,
} from '../../../../domain'
import { ExcessConfig } from '../../../../state/configuration'
import { VoluntaryExcess } from '../VoluntaryExcess'
import { MainExcessBreakdown } from './MainExcessBreakdown'
import { MainExcessSummary } from './MainExcessSummary'

export interface ExcessProps extends BoxProps {
  config: ExcessConfig
  currentQuote: Quote
  previousQuote?: Quote
  onChange?: () => void
}

export const MainExcesses: React.FC<ExcessProps> = ({
  config,
  currentQuote,
  previousQuote,
  onChange,
  ...props
}) => {
  const currentExcesses =
    getCoreAsset(currentQuote).mandatoryExcessLines.filter(isMainExcess)

  const previousExcesses = previousQuote
    ? getCoreAsset(previousQuote)?.mandatoryExcessLines.filter(isMainExcess)
    : []

  const excessComparisons = getExcessComparison(previousExcesses, currentExcesses)
  const hasBreakdown = hasUniqueExcesses(excessComparisons)

  return (
    <Flex
      flexDirection={hasBreakdown ? 'column' : ['column', 'column', 'row']}
      alignItems='stretch'
      {...props}
    >
      {hasBreakdown ? (
        <MainExcessBreakdown excessComparisons={excessComparisons} />
      ) : (
        <MainExcessSummary excessComparison={excessComparisons[0]} />
      )}
      {Boolean(config.voluntaryExcess?.length) && (
        <>
          <Divider />
          <VoluntaryExcess quote={currentQuote} onChange={onChange} />
        </>
      )}
    </Flex>
  )
}

const Divider: FunctionComponent = () => (
  <Flex justifyContent='center' alignItems='center'>
    <Icon m={smBumps} icon='plus' size='small' />
  </Flex>
)
