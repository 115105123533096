import { Flex, FlexProps, Icon, smBumps, Typo, xxsBumps } from '@wrisk/ui-components'
import React from 'react'
import { Trans } from 'react-i18next'

import {
  tCovers,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

interface IncludedCoverItemWithInfoProps extends Omit<FlexProps, 'ref'> {
  cover: string
}

export const IncludedCoverItemWithInfo: React.FC<IncludedCoverItemWithInfoProps> = ({
  cover,
  ...props
}) => {
  const { t } = useWriskTranslation()

  return (
    <Flex
      justifyContent='flex-start'
      alignItems='flex-start'
      py={3}
      px={smBumps}
      {...props}
    >
      <Icon icon='tick' mr={smBumps} />
      <Flex flexDirection='column' alignItems='flex-start'>
        <Typo fontWeight='bold' wordWrap='break-word'>
          {t(tCovers(cover, 'name'))}
        </Typo>
        <Typo mt={xxsBumps} color='bodySecondary' wordWrap='break-word' typoSize='sm'>
          <Trans t={t} i18nKey={tCovers(cover, 'info')} />
        </Typo>
      </Flex>
    </Flex>
  )
}
