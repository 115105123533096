import { Box, BoxProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { Quote } from '../../../domain'
import { useDiscountCovers } from '../../../hooks/covers'
import { useQuotePremiums } from '../../../hooks/premiums'
import { TKey } from '../../../infrastructure/internationalisation'
import { ProductConfig } from '../../../state/configuration'
import { StandardAdjustedPremium } from './StandardAdjustedPremium'

export interface AnnualAdjustedPremiumsProps extends BoxProps {
  currentQuote: Quote
  previousQuote?: Quote
  product: ProductConfig
  expanded?: boolean
}

const tKey = TKey('components.annual-adjusted-premium')

export const AnnualAdjustedPremium: FunctionComponent<AnnualAdjustedPremiumsProps> = ({
  currentQuote,
  previousQuote,
  product,
  expanded,
  ...props
}) => {
  const currentPremiums = useQuotePremiums(currentQuote, product)
  const previousPremiums = useQuotePremiums(previousQuote, product)

  const [discount] = useDiscountCovers(currentQuote)

  return (
    <Box p={2} {...props}>
      <StandardAdjustedPremium
        currentPremiums={currentPremiums}
        previousPremiums={previousQuote && previousPremiums}
        discount={discount}
        expanded={expanded}
        tKey={tKey}
      />
    </Box>
  )
}
