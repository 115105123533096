import { PageLoadingIndicator } from '@wrisk/ui-components'
import React, { FunctionComponent, PropsWithChildren } from 'react'

import { useApiErrorHandlingAsync } from '../../hooks/auth'
import { usePrincipal } from '../authentication'

export const RefreshAnonymous: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const principal = usePrincipal()

  const refreshAnonymous = useApiErrorHandlingAsync(principal.refreshAnonymous, [])

  return refreshAnonymous.loading ? <PageLoadingIndicator /> : <>{children}</>
}
