import { useModal } from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import {
  Data,
  getInsuredProfile,
  getInsuredProfileStrict,
  getPolicyVersion,
  Policy,
  PolicyAdjustmentResponse,
  Profile,
} from '../../../../../domain'
import {
  useAdditionalProfileFormData,
  useUpdateAdditionalProfilePolicyAdjustment,
} from '../../../../../hooks/adjustments/policy'
import {
  TExtends,
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import {
  Page,
  useRequiredParams,
  useUpButton,
} from '../../../../../infrastructure/routing'
import { AdditionalProfileManagementConfig } from '../../../../../state/configuration'
import { usePrincipal } from '../../../../authentication'
import { FormErrors } from '../../../../formBuilder'
import { FullPage } from '../../../../templates'
import { usePolicy } from '../../policyContext'
import { AdditionalProfileAdjustmentPath } from './AdditionalProfileAdjustmentPath'
import { UpdateAdditionalProfileAdjustmentModal } from './UpdateAdditionalProfileAdjustmentModal'

export interface AdditionalProfilePageProps {
  config: AdditionalProfileManagementConfig
  adjustmentPage: Page
}

const hasReadOnly = (profileCode: string, policy: Policy) => {
  const { quote } = getPolicyVersion(policy)
  return getInsuredProfile(quote, profileCode)
}

const tKey = TKey('pages.additional-profile')

const Content: FunctionComponent<AdditionalProfilePageProps & { profile: Profile }> = ({
  config,
  adjustmentPage,
  profile,
}) => {
  const { t } = useWriskTranslation()

  const { isAdmin } = usePrincipal()
  const { policy } = usePolicy()
  const navigate = useNavigate()
  const modal = useModal()
  const upButton = useUpButton(adjustmentPage)

  const data = useAdditionalProfileFormData(config.inputs, profile)

  const onSuccess = useCallback(
    (response: PolicyAdjustmentResponse | undefined) => {
      modal.hide()

      response
        ? navigate(
            [
              '..',
              generatePath(AdditionalProfileAdjustmentPath.Review, {
                adjustmentId: response.adjustmentId,
              }),
            ].join('/'),
          )
        : navigate(adjustmentPage.url)
    },
    [adjustmentPage.url, modal, navigate],
  )

  const onSubmitAdjustment = useUpdateAdditionalProfilePolicyAdjustment(
    config.inputs,
    profile,
    onSuccess,
  )

  const onSubmit = useCallback(
    (updatedData: Data) => {
      if (isAdmin) {
        modal.show({
          content: (
            <UpdateAdditionalProfileAdjustmentModal
              updatedData={updatedData}
              policy={policy}
              config={config}
              onSuccess={onSuccess}
              onCancel={modal.hide}
              profile={profile}
            />
          ),
        })
      } else {
        void onSubmitAdjustment.execute(updatedData)
      }
    },
    [config, isAdmin, modal, onSubmitAdjustment, onSuccess, policy, profile],
  )

  const options = useMemo(
    () =>
      hasReadOnly(profile.profileCode, policy) ? undefined : { disableReadOnly: true },
    [profile, policy],
  )

  return (
    <FullPage pageId='adjustment-section' header={t(tKey('header'))} upButton={upButton}>
      <FormErrors
        id='adjustmentSection'
        key='adjustmentSection'
        data={data}
        inputs={config.inputs}
        onSubmit={onSubmit}
        loading={onSubmitAdjustment.loading}
        options={options}
        tKey={TExtends(tKey, 'form')}
        t={t}
      />
    </FullPage>
  )
}

export const AdditionalProfileAdjustmentPage: FunctionComponent<
  AdditionalProfilePageProps
> = ({ config, adjustmentPage }) => {
  const { profileCode } = useRequiredParams<{ profileCode: string }>()

  const { policy } = usePolicy()

  const { quote } = getPolicyVersion(policy)

  const profile = getInsuredProfileStrict(quote, profileCode)

  return <Content config={config} adjustmentPage={adjustmentPage} profile={profile} />
}

export const AdditionalProfileCreateAdjustmentPage: FunctionComponent<
  AdditionalProfilePageProps
> = ({ config, adjustmentPage }) => {
  return (
    <Content
      config={config}
      adjustmentPage={adjustmentPage}
      profile={{ profileCode: 'p1', data: {} }}
    />
  )
}
