import { Box, BoxProps, Flex, mdBumps, smBumps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  CustomerStatementLineItem,
  CustomerStatementSummary,
  isJournalLineItem,
} from '../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { BroughtForwardItem } from './BroughtForwardItem'
import { InvoiceItem } from './InvoiceItem'

export interface SubTotalSectionProps extends BoxProps {
  summary: CustomerStatementSummary
  lineItems: CustomerStatementLineItem[]
}

const tKey = TKey('components.sub-total-section')

export const SubTotalSection: FunctionComponent<SubTotalSectionProps> = ({
  summary,
  lineItems,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const journals = lineItems.filter(isJournalLineItem)

  return summary.subtotalBalance !== summary.statementBalance ? (
    <Box {...props}>
      <Flex px={mdBumps} py={smBumps} borderBottomWidth={1}>
        <Typo fontWeight='bold'>{t(tKey('subtotal'))}</Typo>
        <Typo fontWeight='bold' whiteSpace='nowrap'>
          {t(tFormats('currency.long'), {
            amount: summary.subtotalBalance / 100,
          })}
        </Typo>
      </Flex>
      <Box borderBottomWidth={1} py={2}>
        {journals.map((item, index) => (
          <InvoiceItem item={item} key={index} />
        ))}
        <BroughtForwardItem summary={summary} />
      </Box>
    </Box>
  ) : null
}
