import {
  Box,
  InvoiceItemCell,
  InvoiceTotalCell,
  Section,
  SectionContent,
  SectionDescription,
  SectionProps,
  SectionTitle,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { IPT } from '../../../product'
import { PaymentType } from './paymentType'

export interface DeferredPremiumSectionProps extends SectionProps {
  prorataPremium: number
}

const tKey = TKey('components.deferred-premium-section')

export const DeferredPremiumSection: FunctionComponent<DeferredPremiumSectionProps> = ({
  prorataPremium,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const paymentType = prorataPremium < 0 ? PaymentType.Credit : PaymentType.Debit

  return prorataPremium ? (
    <Section width={1} {...props}>
      <SectionTitle>{t(tKey('header', paymentType))}</SectionTitle>
      <SectionDescription>{t(tKey('description', paymentType))}</SectionDescription>
      <SectionContent>
        <Box p={2} variant='raised'>
          <InvoiceItemCell
            header={t<string>(tKey('headers', 'prorata'))}
            premium={t<string>(tFormats('currency.long'), { amount: prorataPremium })}
          />
          <InvoiceTotalCell
            header={t<string>(tKey('headers', 'total', paymentType))}
            subheader={<IPT />}
            premium={t<string>(tFormats('currency.long'), {
              amount: Math.abs(prorataPremium),
            })}
          />
        </Box>
      </SectionContent>
    </Section>
  ) : null
}
