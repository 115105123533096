import {
  Box,
  BoxProps,
  EndorsementNotification,
  smBumps,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { Endorsement, getInsuredProfile, getProfileName, Quote } from '../../../domain'
import {
  tProduct,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

export interface EndorsementsProps extends BoxProps {
  quote: Quote
  endorsements: Endorsement[]
}

export const Endorsements: FunctionComponent<EndorsementsProps> = ({
  quote,
  endorsements,
  ...props
}) => {
  const { t } = useWriskTranslation()

  return endorsements?.length ? (
    <Box width={1} {...props}>
      {endorsements.map((it, i) => {
        const data = getInsuredProfile(quote, it.profileCode)?.data

        return data ? (
          <EndorsementNotification
            data-testid={`${it.code} ${it.profileCode}`}
            key={i}
            mt={i && smBumps}
          >
            <Typo>
              <Trans
                t={t}
                i18nKey={tProduct('endorsements', it.code)}
                values={{
                  nameOfDriver: getProfileName(data),
                }}
              />
            </Typo>
          </EndorsementNotification>
        ) : null
      })}
    </Box>
  ) : null
}
