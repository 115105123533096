import { memoize } from 'lodash'

import { TKeyBuilder } from './types'

export const TKey =
  (namespace: string): TKeyBuilder =>
  (...keys) =>
    [namespace, ...keys].filter((k) => k !== '').join('.')

export const tProduct = TKey('product')
export const tCovers = TKey('covers')
export const tFormats = TKey('formats')
export const tDefaults = TKey('defaults')
export const tEntries = TKey('entries')
export const tExcesses = TKey('excesses')

export const TExtends = memoize(
  (tKey: TKeyBuilder, ...args: string[]) => TKey(tKey(...args)),
  (tKey, ...args) => tKey(...args),
)

export const tPaymentMethods = TExtends(tDefaults, 'payment-methods')
