import {
  MainSection,
  pageEntranceSpringValues,
  SectionDescription,
  SectionTitle,
  SideSection,
  WideContainer,
  xlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { animated, useSpring } from 'react-spring'

import { getPolicyVersion } from '../../../../../../domain'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { getFeatureToggles, useConfig } from '../../../../../../state/configuration'
import { PolicyDocumentsSection } from '../../../../../organisms/product'
import { usePolicy } from '../../../policyContext'
import { UploadDocumentsSidebar } from '../components/UploadDocumentsSidebar'

const tKey = TKey('components.policy-documents-tab')

export const PolicyDocumentsTab: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  const { uploadDocuments } = useConfig(getFeatureToggles)

  const { policy } = usePolicy()
  const policyVersion = getPolicyVersion(policy)

  const spring = useSpring(pageEntranceSpringValues)

  return (
    <animated.div style={spring}>
      <WideContainer variant='sidebar' flexDirection={['column', 'column', 'row']}>
        <MainSection mb={xlBumps}>
          <SectionTitle>{t(tKey('header'))}</SectionTitle>
          <SectionDescription>{t(tKey('subheader'))}</SectionDescription>
          <PolicyDocumentsSection policyVersion={policyVersion} />
        </MainSection>

        <SideSection>
          {Boolean(uploadDocuments) && <UploadDocumentsSidebar />}
        </SideSection>
      </WideContainer>
    </animated.div>
  )
}
