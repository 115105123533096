import { default as React, FunctionComponent } from 'react'

import { Name } from '../../../domain'
import { tDefaults } from '../../../infrastructure/internationalisation'
import { Formatter } from '../types'

export const NameFormatter: FunctionComponent<Formatter<Name>> = ({ value, t }) => {
  return Boolean(value?.firstName) && Boolean(value?.lastName) ? (
    <>{[value?.firstName, value?.lastName].join(' ')}</>
  ) : (
    <>{t(tDefaults('inputs.no-answer'))}</>
  )
}
