import { Flex, FlexProps, Icon, Typo, xsBumps, xxsBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { useProduct } from '../../product/productContext'
import { ProposalSidebarPricing } from '../../product/proposal/pricing'
import { useProposal } from '../../product/proposal/proposalContext'
import { ProposalContinueButton } from './ProposalContinueButton'

const tKey = TKey('components.proposal-sidebar')

interface ProposalSideBarProps extends FlexProps {
  disabled?: boolean
}

export const ProposalSidebar: FunctionComponent<ProposalSideBarProps> = ({
  disabled = false,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const { product } = useProduct()
  const { proposal } = useProposal()

  const benefits = t(tKey('benefits'), {
    returnObjects: true,
    defaultValue: [],
  }) as string[]

  const benefitsSection = benefits.map((benefit, index) => (
    <Flex justifyContent='flex-start' width={1} my={xxsBumps} key={index}>
      <Icon icon='tick' size='small' mr={xsBumps} />
      <Typo typoSize='sm'>{benefit}</Typo>
    </Flex>
  ))

  return (
    <Flex
      data-testid='proposal-sidebar'
      width={1}
      flexDirection='column'
      variant='raised'
      {...props}
    >
      <ProposalSidebarPricing
        proposal={proposal}
        product={product}
        borderBottomWidth={1}
        width={1}
      />
      <Flex flexDirection='column' width={1} p={5}>
        <ProposalContinueButton tKey={tKey} width={1} disabled={disabled} />
      </Flex>
      {Boolean(benefitsSection.length) && (
        <Flex flexDirection='column' width={1} px={5} pb={5}>
          {benefitsSection}
        </Flex>
      )}
    </Flex>
  )
}
