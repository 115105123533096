import { isObject } from 'lodash'
import * as React from 'react'
import { FunctionComponent, useCallback, useMemo } from 'react'

import { GetDataEntry } from '../../../../domain'
import { FormInputProps } from '../../types'
import { LookupFinder } from './LookupFinder'

interface LookupFormInputMeta {
  tableCode: string
  minimumLookupChars: number
  store?: string
}

export const LookupFormInput: FunctionComponent<
  FormInputProps<string | GetDataEntry, LookupFormInputMeta>
> = ({
  value,
  onChange,
  meta: { tableCode, minimumLookupChars, store = 'code' },
  tKey,
  tName,
  t,
}) => {
  const valueToShow = useMemo(() => (isObject(value) ? value.name : value), [value])

  const getDataEntryToDataStore = useCallback(
    (getDataEntry: GetDataEntry | undefined) =>
      getDataEntry ? (store === 'code' ? getDataEntry.code : getDataEntry) : undefined,
    [store],
  )

  const onInternalChange = useCallback(
    (dataEntry: GetDataEntry | undefined) => {
      onChange(getDataEntryToDataStore(dataEntry))
    },
    [getDataEntryToDataStore, onChange],
  )

  return (
    <LookupFinder
      tName={tName}
      value={valueToShow}
      tKey={tKey}
      t={t}
      tableCode={tableCode}
      minimumLookupChars={minimumLookupChars}
      onChange={onInternalChange}
    />
  )
}
