import css, { ResponsiveStyleValue } from '@styled-system/css'
import styled from 'styled-components'
import { variant } from 'styled-system'

import { defaultConfig } from '../../extensions'
import { TypoSizeProps, typoSizes } from '../typography'
import { Anchor, AnchorProps } from './Anchor'

export type FooterAnchorProperty = 'default'

export interface FooterAnchorProps extends AnchorProps, TypoSizeProps {
  variant?: ResponsiveStyleValue<FooterAnchorProperty>
}

export const FooterAnchor = styled(Anchor).withConfig(defaultConfig)<FooterAnchorProps>(
  css({
    fontFamily: 'navbar',
    color: 'textFooter',
    whiteSpace: 'nowrap',
  }),
  typoSizes,
  variant({
    scale: 'FooterAnchor',
    variants: {
      default: {},
    },
  }),
)

FooterAnchor.defaultProps = {
  variant: 'default',
  typoSize: 'sm',
}
