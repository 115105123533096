import {
  Flex,
  FlexAnchor,
  FlexProps,
  Icon,
  Menu,
  Typo,
  xsBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback, useState } from 'react'

import {
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { TabItem } from '../types'

interface TabSelectProps extends FlexProps {
  tabs: TabItem[]
  current?: TabItem
  onClick: (tab: TabItem) => void
}

const tKey = TKey('pages.policy-configuration.sub-nav')

export const TabSelect: FunctionComponent<TabSelectProps> = ({
  tabs,
  current,
  onClick,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const [menuVisible, setMenuVisible] = useState(false)

  const onToggleMenu = useCallback(() => setMenuVisible((visible) => !visible), [])
  const onClickCallback = useCallback(
    (item: TabItem) => {
      setMenuVisible(false)
      onClick(item)
    },
    [onClick],
  )

  const menuItems = tabs.map((item, index) => (
    <MenuItem key={index} item={item} onClick={onClickCallback} />
  ))

  return (
    <Flex
      width={1}
      backgroundColor='background'
      position='relative'
      borderBottomWidth={1}
      borderRadius={0}
      {...props}
    >
      <FlexAnchor
        width={1}
        py={5}
        flexDirection='row'
        alignItems='center'
        justifyContent='flex-start'
        onClick={onToggleMenu}
      >
        <Icon icon={menuVisible ? 'cross' : 'menu'} size='small' mr={xsBumps} />
        <Typo fontFamily='buttons' fontWeight='bold'>
          {current && t(tKey(current.id))}
        </Typo>
      </FlexAnchor>

      <Menu onDismiss={onToggleMenu} visible={menuVisible} left='-12px' right='-12px'>
        <Flex variant='raised' p={2} flexDirection='column'>
          {menuItems}
        </Flex>
      </Menu>
    </Flex>
  )
}

interface MenuItemProps {
  item: TabItem
  onClick: (item: TabItem) => void
}

const MenuItem: FunctionComponent<MenuItemProps> = ({ item, onClick }) => {
  const { t } = useWriskTranslation()

  const onAnchorClick = useCallback(() => onClick(item), [onClick, item])

  return (
    <FlexAnchor variant='highlight' width={1} px={4} py={5} onClick={onAnchorClick}>
      <Typo fontFamily='buttons' fontWeight='bold'>
        {t(tKey(item.id))}
      </Typo>
    </FlexAnchor>
  )
}
