import { useMemo } from 'react'
import { UseAsyncCallbackOptions, UseAsyncOptions } from 'react-async-hook'
import { useNavigate } from 'react-router-dom'

import { isUnauthorizedApiException } from '../clients/api'
import {
  isAuthenticationLoginRequiredError,
  isAuthenticationUnauthorizedError,
} from '../clients/auth'
import { useAuthClient, useDependencies } from '../state'
import { useErrorHandlingAsync, useErrorHandlingAsyncCallback } from './errors'

export const useAuth = () => {
  const authClient = useAuthClient()
  const navigate = useNavigate()

  const { chatClient } = useDependencies()

  return useMemo(
    () => ({
      signIn: (returnTo: string = window.location.pathname + window.location.search) =>
        authClient.signIn({ returnTo }),

      handleSignInCallback: async () => {
        const { user, appState } = await authClient.handleSignInCallback()

        chatClient.updateUser(user)

        if (appState.returnTo) {
          navigate(appState.returnTo, { replace: true })
        }

        return user
      },

      signInSilently: async () => {
        const user = await authClient.signInSilently()

        if (user) {
          chatClient.updateUser(user)
        }

        return user
      },

      signOut: async () => {
        chatClient.shutDown()
        await authClient.signOut()
      },

      getTokenSilently: authClient.getTokenSilently,
    }),
    [authClient, chatClient, navigate],
  )
}

const useAcknowledgeUnauthorized = (onAcknowledge?: () => void) => {
  const auth = useAuth()

  return (error: Error) => {
    if (
      isUnauthorizedApiException(error) ||
      isAuthenticationLoginRequiredError(error) ||
      isAuthenticationUnauthorizedError(error)
    ) {
      void auth.signOut()
    } else {
      onAcknowledge?.()
    }
  }
}

export function useApiErrorHandlingAsync<R = unknown, Args extends unknown[] = unknown[]>(
  asyncFunction: (...args: Args) => Promise<R>,
  params: Args,
  options?: UseAsyncOptions<R>,
) {
  return useErrorHandlingAsync(
    asyncFunction,
    params,
    useAcknowledgeUnauthorized(),
    options,
  )
}

export const useApiErrorHandlingAsyncCallback = <
  R = unknown,
  Args extends unknown[] = unknown[],
>(
  asyncFunction: (...args: Args) => Promise<R> | R,
  options?: UseAsyncCallbackOptions<R>,
) => {
  return useErrorHandlingAsyncCallback(
    asyncFunction,
    useAcknowledgeUnauthorized(),
    options,
  )
}
