import { Flex, LinkAnchor, mdBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { FullPage } from './application/templates'
import { TKey, useWriskTranslation } from './infrastructure/internationalisation'

const tKey = TKey('pages.unsupported-browser')

export const UnsupportedBrowserPage: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  return (
    <FullPage
      pageId={'unsupported-browser-page'}
      header={t(tKey('header'))}
      subheader={t(tKey('subheader'))}
    >
      <Flex width={1} variant='raised' p={mdBumps}>
        <LinkAnchor
          variant='standalone'
          href={t<string>(tKey('firefox.link'))}
          target='_blank'
        >
          {t(tKey('firefox.name'))}
        </LinkAnchor>
        <LinkAnchor
          variant='standalone'
          href={t<string>(tKey('chrome.link'))}
          target='_blank'
        >
          {t(tKey('chrome.name'))}
        </LinkAnchor>
      </Flex>
    </FullPage>
  )
}
