import { Box, BoxProps, DragBox, Icon, LinkAnchor, Typo } from '@wrisk/ui-components'
import { groupBy } from 'lodash'
import React, { FunctionComponent, useCallback, useLayoutEffect, useReducer } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { Trans } from 'react-i18next'

import { useErrorHandlingAsync } from '../../../hooks/errors'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { useUpdateEffect } from '../../../util/useUpdateEffect'
import { usePrincipal } from '../../authentication'
import { AcceptedList } from './AcceptedList'
import { getUploadPercentage, hasFile, RejectFiles } from './actions'
import { reducer } from './reducer'
import { RejectionNotification } from './RejectionNotification'

export interface FileUploadProps extends BoxProps {
  reference: string
  maxSize?: number
  maxFiles?: number
  accept?: { [key: string]: string[] }
  onChange?: (files: File[]) => void
}

const tKey = TKey('components.file-upload')

export const FileUpload: FunctionComponent<FileUploadProps> = ({
  reference,
  onChange,
  maxSize,
  maxFiles,
  accept,
  ...props
}) => {
  const { t } = useWriskTranslation()
  const { apiClient } = usePrincipal()

  const [{ accepted, rejected, progress, loading }, dispatch] = useReducer(reducer, {
    accepted: [],
    rejected: [],
    progress: {},
    loading: false,
  })

  const {
    getRootProps,
    getInputProps,
    isFileDialogActive,
    isDragActive,
    acceptedFiles,
    fileRejections,
  } = useDropzone({ maxSize, maxFiles, accept, disabled: loading })

  useErrorHandlingAsync(async () => {
    const uploads = acceptedFiles.filter(hasFile(accepted))

    if (!uploads.length) {
      return
    }

    dispatch({ type: 'ADD_FILES', payload: uploads })

    try {
      for (const upload of uploads) {
        await apiClient.stageUpload(reference, upload, (data) => {
          dispatch({
            type: 'UPDATE_PROGRESS',
            payload: {
              file: upload,
              percentage: getUploadPercentage(data),
            },
          })
        })
      }
    } catch (e) {
      dispatch({ type: 'EXCEPTION', payload: uploads })
      throw e
    } finally {
      dispatch({ type: 'ADDED_FILES' })
    }
  }, [acceptedFiles])

  useLayoutEffect(() => {
    const payload: RejectFiles[] = Object.entries(
      groupBy<FileRejection>(fileRejections, (reject) => reject.file.name),
    ).map(([name, rejections]) => ({
      name,
      error: rejections[0]?.errors?.[0]?.code,
    }))

    dispatch({ type: 'REJECT_FILES', payload })
  }, [fileRejections])

  useUpdateEffect(() => {
    if (!loading) onChange?.(accepted)
  }, [accepted, loading])

  const onRemove = useCallback(
    (file: File) =>
      dispatch({
        type: 'REMOVE_FILE',
        payload: file,
      }),
    [],
  )

  const onDismiss = useCallback(() => {
    dispatch({ type: 'CLEAR_REJECTIONS' })
  }, [])

  return (
    <Box {...props}>
      <DragBox {...getRootProps()} isActive={isDragActive || isFileDialogActive}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <Box data-testid='active-content'>
            <Icon icon='upload' mb={2} size='medium' marginX='auto' />
            <Typo textAlign='center'>{t(tKey('drag', 'active'))}</Typo>
          </Box>
        ) : (
          <Box data-testid='default-content'>
            <Typo textAlign='center' mb={2}>
              <Trans
                t={t}
                i18nKey={tKey('drag', 'default', 'title')}
                components={{
                  Link: <LinkAnchor as='span' />,
                }}
              />
            </Typo>
            <Typo textAlign='center' color='bodySecondary' typoSize='xs'>
              {t(tKey('drag', 'default', 'subtitle'))}
            </Typo>
          </Box>
        )}
      </DragBox>
      {Boolean(accepted.length) && (
        <AcceptedList
          mt={2}
          t={t}
          tKey={tKey}
          accepted={accepted}
          progress={progress}
          loading={loading}
          onRemove={onRemove}
        />
      )}
      {Boolean(rejected.length) && (
        <RejectionNotification
          mt={2}
          t={t}
          tKey={tKey}
          loading={loading}
          rejected={rejected}
          onDismiss={onDismiss}
        />
      )}
    </Box>
  )
}
