import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'

import { CustomerStatement } from '../../../../domain'

export interface NextStatementContextObj {
  nextStatement: CustomerStatement | undefined
  setNextStatement: (statement: CustomerStatement | undefined) => void
}

const NextStatementContext = createContext<NextStatementContextObj | undefined>(undefined)

interface CustomerInvoicesProviderProps extends PropsWithChildren {
  nextStatement: CustomerStatement | undefined
}

export const NextStatementProvider: FunctionComponent<CustomerInvoicesProviderProps> = (
  props,
) => {
  const [nextStatement, setNextStatement] = useState(props.nextStatement)

  return (
    <NextStatementContext.Provider value={{ nextStatement, setNextStatement }}>
      {props.children}
    </NextStatementContext.Provider>
  )
}

export const useNextStatement = (): NextStatementContextObj => {
  const context = useContext(NextStatementContext)
  if (!context) {
    throw new Error('Cannot use NextStatementContext when undefined.')
  }
  return context
}
