import { Box, ModalHeader, Typo, useModal } from '@wrisk/ui-components'
import React from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { useAuth } from '../../hooks/auth'
import { useErrorHandlingAsync } from '../../hooks/errors'
import { useAnonymous } from '../../state'
import { AppPath } from '../AppPath'
import { usePrincipal } from './PrincipalProvider'

export const useAuthenticate = (withAnonymous = false) => {
  const { pathname } = useLocation()
  const auth = useAuth()
  const anonymous = useAnonymous()

  return useErrorHandlingAsync(
    async () => {
      const user = matchPath(pathname, AppPath.CALLBACK)
        ? await auth.handleSignInCallback()
        : await auth.signInSilently()

      if (withAnonymous && !user) {
        return await anonymous.signIn()
      } else if (!withAnonymous && !user) {
        await auth.signIn()
      }

      return user
    },
    [],
    () => auth.signOut(),
  )
}

export const useAdminUnauthorised = <
  R = unknown,
  Args extends unknown[] = unknown[],
>() => {
  const modal = useModal()
  const { isAdmin } = usePrincipal()

  return (asyncFunc: (...args: Args) => Promise<R> | R) => {
    return (...args: Args) =>
      isAdmin
        ? modal.show({
            content: (
              <Box>
                <ModalHeader header='Unauthorised' />
                <Typo>You are not authorised to perform this action.</Typo>
              </Box>
            ),
          })
        : asyncFunc(...args)
  }
}
