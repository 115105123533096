import css from '@styled-system/css'
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import {
  BackgroundColorProps,
  color,
  compose,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system'

import {
  Box,
  Flex,
  Heading,
  mdBumps,
  PrimaryButton,
  SecondaryButton,
  Typo,
  WideContainer,
  xlBumps,
  xxlBumps,
} from '../atoms'
import { createCookieManager } from '../extensions'

export interface CookieConsentProps extends ConsentWrapperProps {
  header: ReactNode
  content: ReactNode
  acceptAction: string | null
  dismissAction: string | null
  cookieDomain: string
}

export const CookieConsent: FunctionComponent<CookieConsentProps> = ({
  header,
  content,
  acceptAction,
  dismissAction,
  cookieDomain,
  ...props
}) => {
  const cookieConsentManager = useMemo(
    () => createCookieManager('cookieConsent', 365, cookieDomain),
    [cookieDomain],
  )

  const [consented, setConsented] = useState(cookieConsentManager.get())

  const storeResult = useCallback(
    (consent: boolean) => {
      setConsented(consent)
      cookieConsentManager.save(consent)
    },
    [cookieConsentManager],
  )

  const onDismiss = useCallback(() => storeResult(false), [storeResult])
  const onAccept = useCallback(() => storeResult(true), [storeResult])

  return consented === null ? (
    <ConsentWrapper data-testid='cookie-consent' {...props}>
      <WideContainer>
        <Box width={1} py={xxlBumps} px={mdBumps}>
          <Heading variant='h3' typoSize='lg' pb={mdBumps}>
            {header}
          </Heading>
          <Typo pb={xlBumps}>{content}</Typo>
          <Flex width={1} justifyContent='flex-start' gap={2}>
            {acceptAction && (
              <PrimaryButton data-testid='accept' layout='fixed' onClick={onAccept}>
                {acceptAction}
              </PrimaryButton>
            )}
            {dismissAction && (
              <SecondaryButton whiteSpace='nowrap' layout='fixed' onClick={onDismiss}>
                {dismissAction}
              </SecondaryButton>
            )}
          </Flex>
        </Box>
      </WideContainer>
    </ConsentWrapper>
  ) : null
}

export type ConsentWrapperProps = SpaceProps & LayoutProps & BackgroundColorProps

const ConsentWrapper = styled.div(
  css({
    zIndex: '3147483003',
    position: 'fixed',
    bottom: '0',
    left: '0',
    minHeight: '50vh',
    minWidth: '100%',
    backgroundColor: 'white',
    boxShadow: '0 0 5rem 0 rgba(0, 0, 0, 0.2)',
  }),
  compose(space, layout, color),
)

// Default export required to import lazy from other projects
export default CookieConsent
