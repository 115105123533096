import {
  Box,
  InvoiceItemCell,
  InvoiceTotalCell,
  Section,
  SectionContent,
  SectionDescription,
  SectionProps,
  SectionTitle,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { CustomerPayment } from '../../../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { IPT } from '../../../product'
import { PaymentType } from './paymentType'

export interface PaymentPremiumSectionProps extends SectionProps {
  payment: CustomerPayment
  prorataPremium: number
}

const tKey = TKey('components.payment-premium-section')

export const PaymentPremiumSection: FunctionComponent<PaymentPremiumSectionProps> = ({
  payment,
  prorataPremium,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const paymentAmount =
    payment.amount === 0 && prorataPremium < 0 ? prorataPremium : payment.amount / 100
  const paymentType = paymentAmount < 0 ? PaymentType.Credit : PaymentType.Debit

  const premiumDifference = paymentAmount - prorataPremium

  return prorataPremium ? (
    <Section width={1} {...props}>
      <SectionTitle>{t(tKey('header', paymentType))}</SectionTitle>
      <SectionDescription>{t(tKey('description', paymentType))}</SectionDescription>
      <SectionContent>
        <Box p={2} variant='raised'>
          <InvoiceItemCell
            header={t<string>(tKey('headers', 'prorata'))}
            premium={t<string>(tFormats('currency.long'), { amount: prorataPremium })}
          />
          {premiumDifference < 0 && (
            <InvoiceItemCell
              header={t<string>(tKey('headers', 'credit'))}
              premium={t<string>(tFormats('currency.long'), {
                amount: premiumDifference,
              })}
            />
          )}
          <InvoiceTotalCell
            header={t<string>(tKey('headers', 'total', paymentType))}
            subheader={<IPT />}
            premium={t<string>(tFormats('currency.long'), {
              amount: Math.abs(paymentAmount),
            })}
          />
        </Box>
      </SectionContent>
    </Section>
  ) : null
}
