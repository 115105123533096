import { useModal } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

import {
  useAccountValidation,
  usePolicyholderAdjustment,
} from '../../../../../hooks/adjustments/policyholder'
import {
  TExtends,
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { Page, useUpButton } from '../../../../../infrastructure/routing'
import { usePolicyholder } from '../../../../authentication'
import { FormErrors } from '../../../../formBuilder'
import { FullPage } from '../../../../templates'
import { useProduct } from '../../../productContext'
import { ProposalPath } from '../../ProposalPath'
import { SignInModalContent } from './components/SignInModalContent'

const tKey = TKey('pages.account-setup')

export interface AccountSetupPageProps {
  parent?: Page
}

const Content: FunctionComponent<AccountSetupPageProps> = ({ parent }) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()
  const modal = useModal()
  const {
    product: { accountSetup },
  } = useProduct()

  const upButton = useUpButton(parent)

  const { policyholder } = usePolicyholder()

  const onUseSubmitAccountSetupAdjustment = useAccountValidation(
    () => navigate(['..', ProposalPath.SUMMARY].join('/')),
    () => {
      modal.show({
        content: <SignInModalContent />,
      })
    },
  )

  const onSubmitAdjustment = usePolicyholderAdjustment(
    onUseSubmitAccountSetupAdjustment.execute,
  )

  return (
    <FullPage
      pageId='account-setup'
      header={t(tKey('header'))}
      subheader={t(tKey('subheader'))}
      upButton={upButton}
    >
      <FormErrors
        id='accountSetup'
        key='policy'
        data={policyholder ?? {}}
        inputs={accountSetup}
        onSubmit={onSubmitAdjustment.execute}
        loading={onSubmitAdjustment.loading}
        tKey={TExtends(tKey, 'form')}
        t={t}
      />
    </FullPage>
  )
}

export const AccountSetupPage: FunctionComponent<AccountSetupPageProps> = (props) => {
  const { policyholder } = usePolicyholder()
  return policyholder?.accountRegistered ? (
    <Navigate to={['..', ProposalPath.SUMMARY].join('/')} replace />
  ) : (
    <Content {...props} />
  )
}
