import { Box, mdBumps, ModalHeader, SelectInput } from '@wrisk/ui-components'
import { range } from 'lodash'
import { default as React, FunctionComponent, useMemo, useState } from 'react'

import { CustomerAccount } from '../../../domain'
import { useApiErrorHandlingAsyncCallback } from '../../../hooks/auth'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { usePrincipal } from '../../authentication'
import { usePolicy } from '../../product/policy/policyContext'
import { ActionBar } from '../form'

interface PaymentDayModalProps {
  customerAccount: CustomerAccount
  onUpdate: (customerAccount: CustomerAccount) => void
  onClose: () => void
}

const tKey = TKey('components.payment-day-modal')

const MAX_PAYMENT_DAY = 28

const isPaymentDayOutOfBounds = (paymentDay: number | undefined) =>
  !paymentDay || paymentDay > MAX_PAYMENT_DAY

export const PaymentDayModal: FunctionComponent<PaymentDayModalProps> = ({
  customerAccount,
  onUpdate,
  onClose,
}) => {
  const { t } = useWriskTranslation()

  const { apiClient } = usePrincipal()

  const { policy } = usePolicy()

  const [paymentDay, setPaymentDay] = useState<number | undefined>(
    customerAccount.paymentDay,
  )

  const values = useMemo(
    () =>
      range(1, MAX_PAYMENT_DAY + 1).map((it) => ({
        value: it,
        text: t(tFormats('number', 'ordinal'), { count: it, ordinal: true }),
      })),
    [t],
  )

  const onUpdatePaymentDay = useApiErrorHandlingAsyncCallback(async () => {
    if (paymentDay && paymentDay !== customerAccount.paymentDay) {
      onUpdate(
        await apiClient.updatePaymentDay(customerAccount.accountId, {
          policyId: policy.policyId,
          paymentDay,
        }),
      )
    }
    onClose()
  })

  const placeholder = isPaymentDayOutOfBounds(customerAccount.paymentDay)
    ? t(tKey('placeholder'))
    : undefined

  return (
    <Box>
      <ModalHeader header={t(tKey('title'))} subheader={t(tKey('subtitle'))} />

      <Box mb={mdBumps}>
        <SelectInput<number>
          onChange={setPaymentDay}
          placeholder={placeholder}
          values={values}
          value={paymentDay}
        />
      </Box>

      <ActionBar
        onConfirm={onUpdatePaymentDay.execute}
        onDismiss={onClose}
        loading={onUpdatePaymentDay.loading}
        disabled={isPaymentDayOutOfBounds(paymentDay)}
        tKey={tKey}
      />
    </Box>
  )
}
