import { Box, FlexProps, Heading, mdBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { PaymentSettings } from '../../../../../organisms/billing'

const tKey = TKey('components.billing-sidebar')

export const BillingSidebar: FunctionComponent<FlexProps> = (props) => {
  const { t } = useWriskTranslation()

  return (
    <Box width={1} variant='raised' {...props}>
      <Box width={1} borderBottomWidth={1} p={mdBumps}>
        <Heading variant='h3' typoSize='md'>
          {t(tKey('header'))}
        </Heading>
      </Box>
      <PaymentSettings />
    </Box>
  )
}
