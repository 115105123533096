import { Box, BoxProps, PremiumSummary, smBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { inForce, Quote } from '../../../domain'
import { useDiscountCovers } from '../../../hooks/covers'
import { useQuotePremiums } from '../../../hooks/premiums'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { ProductConfig } from '../../../state/configuration'
import { toDateTime } from '../../../util/date'

export interface AnnualSummaryPremiumsProps extends BoxProps {
  currentQuote: Quote
  product: ProductConfig
}

const tKey = TKey('components.annual-summary-premium')

export const AnnualSummaryPremium: FunctionComponent<AnnualSummaryPremiumsProps> = ({
  currentQuote,
  product,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const currentPremiums = useQuotePremiums(currentQuote, product)

  const [discount] = useDiscountCovers(currentQuote)

  const subheader =
    inForce(discount) && discount?.endAt
      ? t(tKey('promotion', 'until'), {
          date: t(tFormats('date.long'), {
            value: toDateTime(discount.endAt),
          }),
        })
      : undefined

  return (
    <Box p={2} {...props}>
      <PremiumSummary
        p={smBumps}
        header={t(tKey('header'))}
        subheader={subheader}
        currentAmount={t(tFormats('currency.long'), {
          amount: currentPremiums.totals.discounted,
        })}
      />
    </Box>
  )
}
