import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'

import { CustomerAccount } from '../../../../domain'

export interface CustomerAccountContextObj {
  customerAccount: CustomerAccount
  setCustomerAccount: (account: CustomerAccount) => void
}

const CustomerAccountContext = createContext<CustomerAccountContextObj | undefined>(
  undefined,
)

interface CustomerAccountProviderProps extends PropsWithChildren {
  customerAccount: CustomerAccount
}

export const CustomerAccountProvider: FunctionComponent<CustomerAccountProviderProps> = (
  props,
) => {
  const [customerAccount, setCustomerAccount] = useState(props.customerAccount)

  return (
    <CustomerAccountContext.Provider value={{ customerAccount, setCustomerAccount }}>
      {props.children}
    </CustomerAccountContext.Provider>
  )
}

export const useCustomerAccount = (): CustomerAccountContextObj => {
  const context = useContext(CustomerAccountContext)
  if (!context) {
    throw new Error('Cannot use CustomerAccountContext when undefined.')
  }
  return context
}
