import { Box, Form, Heading, mdBumps, SelectInput, xxlBumps } from '@wrisk/ui-components'
import { isNil } from 'lodash'
import { DateTime } from 'luxon'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  dateTimeFormatter,
  isCancellationReasonInvalid,
  PolicyStatus,
} from '../../../../domain'
import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { Page } from '../../../../infrastructure/routing'
import { CancellationStandardConfig } from '../../../../state/configuration'
import { getStartOfEachDay } from '../../../../util/date'
import { CancellationReasonInput } from '../../../organisms/cancellation'
import { ActionBar } from '../../../organisms/form'
import { FullPage } from '../../../templates'
import { usePolicy } from '../policyContext'
import { CancellationPath } from './CancellationPath'

export interface CancellationStandardPageProps {
  config: CancellationStandardConfig
  parent: Page
}

const tKey = TKey('pages.cancel')

export const CancellationStandardPage: FunctionComponent<
  CancellationStandardPageProps
> = ({ parent, config }) => {
  const navigate = useNavigate()

  const {
    policy: { policyDetail },
  } = usePolicy()
  const { t } = useWriskTranslation()

  const cancellationDates = useMemo(() => {
    const formatter = dateTimeFormatter('Immediately')

    const daysToDefer =
      isNil(config.daysToDefer) || policyDetail.policyStatus === PolicyStatus.PENDING
        ? 1
        : config.daysToDefer

    const days = Math.min(
      DateTime.fromISO(policyDetail.expiredAt).diff(DateTime.now(), 'days').days,
      daysToDefer,
    )

    return getStartOfEachDay({ days }).map((it: DateTime<true>) => ({
      value: it.toISO(),
      text: formatter(it),
    }))
  }, [config, policyDetail])

  const [cancellationReason, onReasonChange] = useState<string | null>(null)
  const [cancellationDate, onDateChange] = useState<string | undefined>(
    cancellationDates[0]?.value,
  )

  const onDismiss = useCallback(() => navigate(parent.url), [navigate, parent])

  const onSubmit = useCallback(() => {
    cancellationDate &&
      navigate(CancellationPath.CONFIRM, {
        state: {
          cancellationReason,
          dateTime:
            DateTime.fromISO(cancellationDate) < DateTime.local()
              ? undefined
              : cancellationDate,
        },
      })
  }, [cancellationDate, cancellationReason, navigate])

  return (
    <FullPage
      pageId='policy-cancellation-page'
      header={t(tKey('header'))}
      subheader={t(tKey('subheader'))}
      helpLinkText={t(tKey('help-link-message'), { defaultValue: null })}
      helpLinkUrl={t<string>('links.cancellationTerms', { defaultValue: null })}
    >
      <Form formId='policy-cancellation-form' onSubmit={onSubmit}>
        {!isNil(config.reasons) && (
          <CancellationReasonInput
            onChange={onReasonChange}
            reasons={config.reasons}
            value={cancellationReason}
            mb={xxlBumps}
          />
        )}

        <Box width={1} mb={xxlBumps}>
          <Heading variant='h3' typoSize='md' pb={mdBumps}>
            {t(tKey('effective-header'))}
          </Heading>
          <Box width={1}>
            <SelectInput<string>
              value={cancellationDate}
              values={cancellationDates}
              onChange={onDateChange}
            />
          </Box>
        </Box>

        <ActionBar
          tKey={tKey}
          destructive
          disabled={isCancellationReasonInvalid(config.reasons, cancellationReason)}
          onDismiss={onDismiss}
        />
      </Form>
    </FullPage>
  )
}
