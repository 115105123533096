import { RefObject, useCallback, useEffect, useRef, useState } from 'react'

export const useOutsideClickRef = <T extends HTMLElement>(
  onClickOutside: () => void,
): RefObject<T> => {
  const ref = useRef<T>(null)

  const handleClick = (e: MouseEvent) => {
    if (ref?.current?.contains(e.target as Node)) {
      return
    }

    onClickOutside()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return ref
}

export const useNotifyingState = <T>(
  initialValue: T,
  notifiers: ((value: T) => void)[],
): [T, (value: T) => void] => {
  const [state, setState] = useState(initialValue)

  const setStateAndNotify = useCallback(
    (nextValue: T) => {
      setState(nextValue)
      notifiers.forEach((notifier) => notifier(nextValue))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...notifiers],
  )

  return [state, setStateAndNotify]
}
