import React, { FunctionComponent, useCallback, useState } from 'react'

import { InputWrapper, InputWrapperProps, TextInput, Typo } from '../atoms'

export interface PhoneNumberInputProps extends Omit<InputWrapperProps, 'onChange'> {
  initialValue?: string
  onChange: (value: string) => void
  placeholder: string
  internationalCode: string
}

export const formatPartialPhoneNumber = (input: string): string =>
  input.replace(/\D/g, '')

export const toInternationalPhoneNumber = (
  phoneNumber: string,
  internationalCode: string,
): string => {
  const nsn = phoneNumber.startsWith('0') ? phoneNumber.substring(1) : phoneNumber
  return `${internationalCode}${nsn}`
}

export const PhoneNumberInput: FunctionComponent<PhoneNumberInputProps> = ({
  initialValue,
  placeholder,
  onChange,
  internationalCode,
  ...props
}) => {
  const [phoneNumber, setPhoneNumber] = useState(initialValue ?? '')

  const onInputChange = useCallback(
    (e) => {
      const formattedNumber = formatPartialPhoneNumber(e.target.value)
      setPhoneNumber(formattedNumber)

      const internationalPhoneNumber = toInternationalPhoneNumber(
        formattedNumber,
        internationalCode,
      )
      onChange(internationalPhoneNumber)
    },
    [internationalCode, onChange],
  )

  return (
    <InputWrapper {...props}>
      <Typo fontFamily='input' p={2}>
        {internationalCode}
      </Typo>
      <TextInput
        width={1}
        type='tel'
        variant='ghost'
        name='phoneNumber'
        placeholder={placeholder}
        value={phoneNumber.replace(internationalCode, '')}
        onChange={onInputChange}
      />
    </InputWrapper>
  )
}
