import React, { FunctionComponent, useCallback } from 'react'

import { FlexAnchor, FlexAnchorProps } from '../atoms'
import { HelpLinkIFrame } from './HelpLinkIFrame'
import { useModal } from './Modal'

export const HelpFlexAnchor: FunctionComponent<Omit<FlexAnchorProps, 'ref'>> = ({
  children,
  ...props
}) => {
  const modal = useModal()

  const openModal = useCallback(
    () =>
      modal.show({
        content: <HelpLinkIFrame src={props.href} />,
        variant: 'iframe',
      }),
    [modal, props.href],
  )

  return (
    <FlexAnchor {...props} onClick={openModal}>
      {children}
    </FlexAnchor>
  )
}
