import { AxiosInstance } from 'axios'

import { withSilentErrorHandling } from '../errors'

interface CreateImpressionRequest {
  type: string
  values: Record<string, string>
  tags: Record<string, string>
}

interface CreateInsightRequest {
  type: string
  rating: number
  comment?: string
  tags: Record<string, string>
}

export interface InsightsClient {
  postImpression: (createImpressionRequest: CreateImpressionRequest) => Promise<void>
  postInsight: (createInsightRequest: CreateInsightRequest) => Promise<void>
}

export const createInsightsClient = (
  getInstance: () => Promise<AxiosInstance>,
): InsightsClient => ({
  postImpression: (createImpressionRequest) =>
    withSilentErrorHandling(async () => {
      const instance = await getInstance()
      await instance.post<CreateImpressionRequest>(
        '/insights/impression',
        createImpressionRequest,
      )
    }),
  postInsight: (createInsightRequest) =>
    withSilentErrorHandling(async () => {
      const instance = await getInstance()
      await instance.post<CreateImpressionRequest>(
        '/insights/feedback',
        createInsightRequest,
      )
    }),
})
