import { Box } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  Specification,
  toVehicleEngine,
  toVehicleEnginePower,
  toVehicleFuelDescription,
} from '../../../../domain'
import { AttributeRow } from './AttributeRow'

interface Props {
  vehicle: Specification
}

export const VehicleCardDetails: FunctionComponent<Props> = ({ vehicle }) => {
  const engine = toVehicleEngine(vehicle)
  const enginePower = toVehicleEnginePower(vehicle)

  return (
    <Box width={1} pt={3}>
      <AttributeRow name='Manufactured' value={vehicle.year.toString()} />
      <AttributeRow name='Fuel' value={toVehicleFuelDescription(vehicle)} />
      <AttributeRow name='Electric type' value={vehicle.electricVehicleType} />
      <AttributeRow name='Body style' value={vehicle.bodyStyle} />
      <AttributeRow name='Transmission' value={vehicle.transmission} />
      <AttributeRow name='Drive train' value={vehicle.driveTrain} />
      <AttributeRow name='Engine' value={engine} />
      <AttributeRow name='Performance' value={enginePower} />
    </Box>
  )
}
