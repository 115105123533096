import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { Icon } from '../../atoms'

export const PageLoadingIndicator: FunctionComponent = () => (
  <FixedWrapper>
    <Icon icon='loadingDark' />
  </FixedWrapper>
)

const FixedWrapper = styled.div({
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
})
