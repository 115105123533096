import { TextInput } from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'

import { FormInputProps } from '../types'

export interface TextInputMeta {
  mask?: string
}

export const TextFormInput: FunctionComponent<FormInputProps<string, TextInputMeta>> = ({
  name,
  value,
  onChange,
  tKey,
  t,
  tName,
  meta,
}) => {
  const mask = meta?.mask ? RegExp(`^$|${meta.mask}`) : undefined

  const onInputChange = useCallback(
    (e) => {
      if (mask?.test(e.target.value) ?? true) {
        onChange(e.target.value)
      }
    },
    [mask, onChange],
  )

  return (
    <TextInput
      width={1}
      name={name}
      value={value ?? ''}
      onChange={onInputChange}
      placeholder={t<string>(tKey(tName, 'placeholder'))}
    />
  )
}
