import {
  Icon,
  Notification,
  PageLoadingIndicator,
  smBumps,
  Typo,
  xxlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  getCurrentPolicyVersion,
  isRenewalPolicy,
  PolicyAdjustmentResponse,
} from '../../../../../domain'
import { useCheckoutAdjustment } from '../../../../../hooks/checkout'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { Page } from '../../../../../infrastructure/routing'
import { ManagementConfig, ProductKind } from '../../../../../state/configuration'
import {
  AdjustedPremiumSection,
  ProrataPremiumSection,
} from '../../../../organisms/policy'
import { PromotionRemovedBanner } from '../../../../organisms/promotion'
import { FullPage } from '../../../../templates'
import { useProduct } from '../../../productContext'
import { usePolicy } from '../../policyContext'
import { AdjustmentActionBar } from './AdjustmentActionBar'
import { AdjustmentConfigChanges } from './AdjustmentConfigChanges'
import { AdjustmentResponseChanges } from './AdjustmentResponseChanges'

export interface AllowedPolicyAdjustmentPageProps {
  adjustmentResponse: PolicyAdjustmentResponse
  config?: ManagementConfig
  adjustmentPage: Page
}

const tKey = TKey('pages.allowed-policy-adjustment')

export const AllowedPolicyAdjustmentPage: FunctionComponent<
  AllowedPolicyAdjustmentPageProps
> = ({ adjustmentResponse, config, adjustmentPage }) => {
  const { t } = useWriskTranslation()
  const { product } = useProduct()
  const { policy } = usePolicy()

  const { payment, loading } = useCheckoutAdjustment(adjustmentResponse.adjustmentId)

  if (loading) {
    return <PageLoadingIndicator />
  }

  const updatedPolicyVersion = getCurrentPolicyVersion(
    adjustmentResponse.updatedPolicyVersions,
  )

  return (
    <FullPage
      pageId='allowed-adjustment-page'
      header={t(tKey('header'))}
      subheader={t(tKey('subheader'))}
    >
      {config ? (
        <AdjustmentConfigChanges
          config={config}
          result={adjustmentResponse}
          mb={xxlBumps}
        />
      ) : (
        <AdjustmentResponseChanges result={adjustmentResponse} mb={xxlBumps} />
      )}

      <PromotionRemovedBanner updatedPolicyVersion={updatedPolicyVersion} mb={xxlBumps} />

      <AdjustedPremiumSection
        updatedPolicyVersions={adjustmentResponse.updatedPolicyVersions}
        mb={xxlBumps}
      />
      <ProrataPremiumSection
        payment={payment}
        prorataPremium={adjustmentResponse.prorataPremium}
        mb={xxlBumps}
      />

      {product.productKind === ProductKind.DRIVEAWAY && (
        <Notification type='standalone' mb={xxlBumps}>
          <Typo>{t(tKey(`driveaway-only`))}</Typo>
        </Notification>
      )}

      {isRenewalPolicy(policy) && (
        <Notification type='standalone' variant='warning' mb={xxlBumps}>
          <Icon icon='info' mr={smBumps} /> {t(tKey(`renewal-window`))}
        </Notification>
      )}

      <AdjustmentActionBar
        payment={payment}
        adjustmentResponse={adjustmentResponse}
        parent={adjustmentPage}
      />
    </FullPage>
  )
}
