import React, { FunctionComponent, MouseEvent, useCallback, useEffect } from 'react'
import { animated, config, useTransition } from 'react-spring'
import styled from 'styled-components'

import { ModalProps } from './types'

const disablePageScroll = () => {
  document.body.style.marginRight = `${window.innerWidth - document.body.clientWidth}px`
  document.body.style.overflow = 'hidden'
}

const enablePageScroll = () => {
  document.body.style.marginRight = '0px'
  document.body.style.overflow = 'visible'
}

export const Modal: FunctionComponent<ModalProps> = ({ children, open, onFogClick }) => {
  useEffect(() => {
    if (open) disablePageScroll()
  }, [open])

  useEffect(enablePageScroll, [])

  const frameTransitions = useTransition(open, {
    config: config.stiff,
    from: { opacity: 0, transform: 'translateY(20px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(20px)' },
  })

  const fogTransitions = useTransition(open, {
    config: config.stiff,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    onDestroyed: () => {
      if (!open) enablePageScroll()
    },
  })

  const onKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        // Esc button pressed
        onFogClick()
      }
    },
    [onFogClick],
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false)

    return () => {
      document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [onKeyDown])

  const onChildClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => e.stopPropagation(),
    [],
  )

  const maybeFrame = frameTransitions(
    (props, item) =>
      item && (
        <AnimatedFrame style={props} onClick={onFogClick}>
          <div onClick={onChildClick}>{children}</div>
        </AnimatedFrame>
      ),
  )

  const maybeFog = fogTransitions((props, item) => item && <AnimatedFog style={props} />)

  return (
    <>
      {maybeFog}
      {maybeFrame}
    </>
  )
}

const AnimatedFrame = styled(animated.div)({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1001,
})

const AnimatedFog = styled(animated.div)({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.65)',
  zIndex: 1000,
})
