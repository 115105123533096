import { PageLoadingIndicator } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { PaymentMethodType } from '../../../../../domain'
import { useApiErrorHandlingAsync } from '../../../../../hooks/auth'
import { usePrincipal } from '../../../../authentication'
import { useProposal } from '../../proposalContext'
import { CheckoutBACS } from './CheckoutBACS'
import { CheckoutCard } from './CheckoutCard'

export const CheckoutPage: FunctionComponent = () => {
  const { proposal } = useProposal()

  const { apiClient } = usePrincipal()

  const { result, loading } = useApiErrorHandlingAsync(
    async () =>
      Promise.all([
        apiClient.getCustomerAccounts({ policyNumber: proposal.policyNumber }),
        apiClient.checkoutBind({ proposalCode: proposal.proposalCode }),
        apiClient.getPaymentMethods(),
      ]),
    [proposal],
  )

  if (loading || !result) {
    return <PageLoadingIndicator />
  }

  const [[customerAccount], [customerPayment], paymentMethods] = result

  switch (customerAccount?.paymentMethodType) {
    case PaymentMethodType.BACS_DEBIT:
      return (
        <CheckoutBACS customerPayment={customerPayment} paymentMethods={paymentMethods} />
      )
    default:
      return (
        <CheckoutCard customerPayment={customerPayment} paymentMethods={paymentMethods} />
      )
  }
}
