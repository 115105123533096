import { findBestMatch, isExactMatchForAny, isMatchForAny } from './string'

const current = /^([A-Z]{2}[0-9]{2}) ?([A-Z]{3})/
const prefix = /^([A-Z][0-9]{1,3}) ?([A-Z]{3})/
const suffix = /^([A-Z]{3}) ?([0-9]{1,3}[A-Z])/
const dateless1 = /^([A-Z]{1,2}) ?([0-9]{1,4})/
const dateless1Reversed = /^([0-9]{1,4}) ?([A-Z]{1,2})/
const dateless2 = /^([A-Z]{1,3}) ?([0-9]{1,3})/
const dateless2Reversed = /^([0-9]{1,3}) ?([A-Z]{1,3})/

const northernIrelandCurrent = /^([A-Z]{3}) ?([0-9]{1,4})/
const northernIrelandOld = /^([A-Z]{2}) ?([0-9]{1,4})/
const northernIrelandOldReversed = /^([0-9]{1,4}) ?([A-Z]{2})/

const currentPartial = /^([A-Z]{2}[0-9]{2}) ?([A-Z]{1,3})/
const prefixPartial = /^([A-Z][0-9]{1,3}) ?([A-Z]{1,3})/
const suffixPartial = /^([A-Z]{3}) ?([0-9]{1,3}[A-Z]?)/

const initialInputAlphabetic = /^([A-Z]{1,3}) ?/
const initialInputNumeric = /^([0-9]{1,4}) ?/

const exactPatterns = [
  current,
  prefix,
  suffix,
  dateless1,
  dateless1Reversed,
  dateless2,
  dateless2Reversed,
  northernIrelandCurrent,
  northernIrelandOld,
  northernIrelandOldReversed,
]

const partialPatterns = [
  currentPartial,
  prefixPartial,
  suffixPartial,
  dateless1,
  dateless1Reversed,
  dateless2,
  dateless2Reversed,
  northernIrelandCurrent,
]

const rejectedPatterns = [/^Q/]

const initialInputPatterns = [initialInputAlphabetic, initialInputNumeric]

export const isValidLicencePlate = (licencePlate: string | undefined): boolean =>
  licencePlate
    ? !isMatchForAny(licencePlate, rejectedPatterns) &&
      isExactMatchForAny(licencePlate, exactPatterns)
    : false

export const formatPartialLicencePlate = (licencePlate: string): string => {
  const upperCasePlate = licencePlate.replace(/\s/g, '').toUpperCase()

  const bestMatch = findBestMatch(upperCasePlate, partialPatterns)

  return bestMatch
    ? bestMatch.slice(1).reduce((acc, next) => `${acc} ${next}`)
    : sanitiseInitialInput(upperCasePlate)
}

const sanitiseInitialInput = (input: string) => {
  const bestMatch = findBestMatch(input, initialInputPatterns)
  return bestMatch ? bestMatch[1] : ''
}
