import { Box, BoxProps, Flex, Icon, mdBumps, smBumps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { Quote } from '../../../domain'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'

export interface ReferralReasonsProps extends BoxProps {
  quote: Quote
}

const tKey = TKey('components.referral-reasons')

export const ReferralReasons: FunctionComponent<ReferralReasonsProps> = ({
  quote,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const referralReasons = quote.referralReasons
  const referralDetails = referralReasons.map((it, index) => (
    <Flex key={index} justifyContent='flex-start' mb={3}>
      <Icon icon='warning' size='small' mr={smBumps} />
      <Typo>{it.message}</Typo>
    </Flex>
  ))

  return referralDetails.length ? (
    <Box
      borderWidth={1}
      borderRadius={2}
      borderColor='surfaceCritical'
      bg='surfaceCriticalSubdued'
      px={mdBumps}
      py={smBumps}
      {...props}
    >
      <Typo as='pre' mb={smBumps}>
        {t(tKey('header'))}
      </Typo>
      <Box width={1}>{referralDetails}</Box>
    </Box>
  ) : null
}
