import {
  Box,
  Flex,
  FlexAnchor,
  FlexAnchorProps,
  Icon,
  lgBumps,
  mdBumps,
  ModalHeader,
  Typo,
  useModal,
} from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import React, { FunctionComponent, useCallback } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { Trans } from 'react-i18next'

import { tDefaults, TKeyBuilder } from '../../../infrastructure/internationalisation'
import { InputConfig } from '../../../state/configuration'
import { ExternalLink } from '../../organisms/links'
import { getFormatter } from '../maps'
import { QuestionLoopInputFormMeta } from './QuestionLoop'

export interface FormReadOnlyProps extends Omit<FlexAnchorProps, 'ref' | 'onClick'> {
  input: InputConfig
  tKey: TKeyBuilder
  t: TFunction
}

export interface ReadOnlyInputProps extends Omit<FlexAnchorProps, 'ref' | 'onClick'> {
  input: InputConfig
  onClick: () => void
  tKey: TKeyBuilder
  t: TFunction
}

export const ReadOnlyInput: FunctionComponent<ReadOnlyInputProps> = ({
  input,
  onClick,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  tKey,
  t,
  ...props
}) => {
  const { control } = useFormContext()

  const Formatter = getFormatter(input.type)

  return (
    <FlexAnchor
      backgroundColor='surfaceDisabled'
      p={mdBumps}
      onClick={onClick}
      {...props}
    >
      <Typo fontFamily='input' color='textDisabled'>
        <Controller
          name={input.name}
          control={control}
          render={({ field: { value } }) => (
            <Formatter value={value} input={input} t={t} />
          )}
        />
      </Typo>
      <Icon icon='info' size='tiny' ml={lgBumps} />
    </FlexAnchor>
  )
}

export const ReadOnlyInputLoop: FunctionComponent<ReadOnlyInputProps> = ({
  input,
  onClick,
  tKey,
  t,
  ...props
}) => {
  const { control } = useFormContext()
  const { fields } = useFieldArray({
    name: input.name,
    control,
  })

  const { inputs } = input.meta as QuestionLoopInputFormMeta

  if (fields.length === 0) {
    return (
      <FlexAnchor
        backgroundColor='surfaceDisabled'
        p={mdBumps}
        onClick={onClick}
        {...props}
      >
        <Typo fontFamily='input' color='textDisabled'>
          {t(tDefaults('inputs.none'))}
        </Typo>
        <Icon icon='info' size='tiny' ml={lgBumps} />
      </FlexAnchor>
    )
  }

  const elements = fields.map((field, index) => (
    <Flex
      key={field.id}
      borderBottomWidth={index === fields.length - 1 ? 0 : 1}
      width={1}
    >
      <Flex
        width={1}
        p={mdBumps}
        gap={[2, 0]}
        flexDirection='column'
        alignItems='flex-start'
        justifyContent='flex-start'
      >
        {inputs?.map((it, inputIndex) => {
          const Formatter = getFormatter(it.type)
          return (
            <Controller
              key={`${field.id}-${inputIndex}`}
              control={control}
              name={`${input.name}.${index}.${it.name}`}
              defaultValue={field?.[it.name]}
              render={({ field: { value } }) => (
                <Typo fontFamily='input' color='textDisabled'>
                  <Typo
                    fontFamily='input'
                    fontWeight='bold'
                    display={['block', 'inline']}
                    pr={1}
                  >
                    {t(tKey(input.name, 'inputs', it.name, 'summary'))}:
                  </Typo>
                  <Formatter input={it} value={value} t={t} />
                </Typo>
              )}
            />
          )
        })}
      </Flex>
      <Icon icon='info' size='tiny' ml={lgBumps} mr={mdBumps} />
    </Flex>
  ))

  return (
    <FlexAnchor
      width={1}
      backgroundColor='surfaceDisabled'
      flexDirection='column'
      alignItems='flex-start'
      onClick={onClick}
      {...props}
    >
      {elements}
    </FlexAnchor>
  )
}

export const FormReadOnly: FunctionComponent<FormReadOnlyProps> = ({
  input,
  tKey,
  t,
  ...props
}) => {
  const modal = useModal()

  const onClick = useCallback(() => {
    modal.show({
      content: (
        <Box>
          <ModalHeader header={t('defaults.inputs.readonly.header')} />
          <Typo>
            <Trans
              t={t}
              i18nKey={'defaults.inputs.readonly.content'}
              components={{
                ExternalLink: <ExternalLink />,
              }}
            />
          </Typo>
        </Box>
      ),
    })
  }, [modal, t])

  const Element = input.type === 'questionLoop' ? ReadOnlyInputLoop : ReadOnlyInput

  return <Element input={input} onClick={onClick} tKey={tKey} t={t} {...props} />
}
