import { SystemStyleObject } from '@styled-system/css'
import { ResponsiveValue, variant } from 'styled-system'

export type ButtonLayoutProperty = 'default' | 'fixed' | 'small'

export interface ButtonLayoutProps {
  layout?: ResponsiveValue<ButtonLayoutProperty>
}

export const buttonLayoutVariants: Record<ButtonLayoutProperty, SystemStyleObject> = {
  default: {
    px: 5,
    py: 3,
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  fixed: {
    px: 5,
    py: 3,
    width: '85%',
    maxWidth: '262px',
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  small: {
    px: 4,
    py: 2,
    fontSize: '0.85rem',
    lineHeight: '1.5em',
  },
}

export const buttonLayoutVariant = variant({
  prop: 'layout',
  scale: 'Button.layout',
  variants: buttonLayoutVariants,
})
