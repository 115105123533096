import {
  ExpandableSection,
  ExpandableSectionProps,
  Flex,
  Icon,
  smBumps,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  tProduct,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

export const ExclusionsExpandable: FunctionComponent<ExpandableSectionProps> = ({
  header,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const exclusions = t(tProduct('exclusions'), {
    returnObjects: true,
    defaultValue: [],
  }) as string[]

  const cells = exclusions.map((exclusion, index) => {
    return (
      <Flex
        key={index}
        alignItems='flex-start'
        justifyContent='flex-start'
        px={smBumps}
        py={2}
      >
        <Icon icon='cross' size='small' mr={smBumps} />
        <Typo typoSize='sm'>{exclusion}</Typo>
      </Flex>
    )
  })

  return cells.length ? (
    <ExpandableSection header={header} {...props}>
      {cells}
    </ExpandableSection>
  ) : null
}
