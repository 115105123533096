import css from '@styled-system/css'
import { ComponentType, DetailedHTMLProps, ImgHTMLAttributes } from 'react'
import styled from 'styled-components'
import {
  compose,
  flex,
  flexbox,
  FlexboxProps,
  FlexProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from 'styled-system'

export interface ImageProps
  extends Omit<
      DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
      keyof LayoutProps
    >,
    LayoutProps,
    SpaceProps,
    FlexboxProps,
    FlexProps,
    PositionProps {}

export const Image = styled.img<ImageProps>(
  css({
    display: 'inline-block',
    width: '100%',
  }),
  compose(layout, space, flexbox, flex, position),
) as ComponentType<ImageProps>
