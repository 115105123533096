import {
  Box,
  Flex,
  LinkAnchor,
  SectionContent,
  SectionDescription,
  SectionTitle,
  xxlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { Quote } from '../../../../../../domain'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import {
  getProduct,
  ManagementType,
  useConfig,
} from '../../../../../../state/configuration'
import {
  AdditionalProfilesInformation,
  QuoteInformation,
} from '../../../../../organisms/quote'
import { useProduct } from '../../../../productContext'

const tKey = TKey('components.quote-information')

interface QuoteInformationSectionProps {
  onEditDetails: () => void
  quote: Quote
}

export const QuoteInformationSection: FunctionComponent<QuoteInformationSectionProps> = ({
  onEditDetails,
  quote,
}) => {
  const { t } = useWriskTranslation()

  const { scheme } = useProduct()

  const { summary } = useConfig(getProduct(scheme.schemeCode))

  const sections = summary.map((it) => {
    return (() => {
      switch (it.type) {
        case ManagementType.ADDITIONAL_PROFILE:
          return <AdditionalProfilesInformation key={it.id} config={it} quote={quote} />
        default:
          return <QuoteInformation key={it.id} config={it} quote={quote} />
      }
    })()
  })

  return (
    <Box width={1} mb={xxlBumps}>
      <Flex width={1}>
        <SectionTitle mr={4}>{t(tKey('header'))}</SectionTitle>
        <LinkAnchor variant='standalone' typoSize='sm' onClick={onEditDetails}>
          {t(tKey('update-details'))}
        </LinkAnchor>
      </Flex>
      <SectionDescription>{t(tKey('subheader'))}</SectionDescription>
      <SectionContent>
        <Box width={1} p={2} variant='raised'>
          {sections}
        </Box>
      </SectionContent>
    </Box>
  )
}
