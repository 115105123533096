import React, { FunctionComponent } from 'react'

import { Box } from '../atoms'
import { useNotifyingState } from '../extensions'
import {
  DateInput,
  DateInputs,
  fromInputsToIsoDate,
  fromIsoDateToInputs,
} from './DateInput'
import { InputMessage } from './InputMessage'

export interface DateFeedbackInputProps {
  error: string | undefined
  onChange: (date: string | undefined) => void
  initialValue?: string
  placeholders?: DateInputs
}

export const DateFeedbackInput: FunctionComponent<DateFeedbackInputProps> = (props) => {
  const { initialValue, onChange, error, placeholders } = props

  const initialDateInputs = fromIsoDateToInputs(initialValue)

  const [dateInputs, setDate] = useNotifyingState(initialDateInputs, [
    (d) => onChange(fromInputsToIsoDate(d)),
  ])

  return (
    <Box>
      <DateInput onChange={setDate} dateInputs={dateInputs} placeholders={placeholders} />
      {error && <InputMessage>{error}</InputMessage>}
    </Box>
  )
}
