import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'

import { Policy } from '../../../domain'

interface PolicyContextObj {
  policy: Policy
  setPolicy: (policy: Policy) => void
}

const PolicyContext = createContext<PolicyContextObj | undefined>(undefined)

export interface PolicyProviderProps extends PropsWithChildren {
  policy: Policy
}

export const PolicyProvider: FunctionComponent<PolicyProviderProps> = (props) => {
  const [policy, setPolicy] = useState(props.policy)

  return (
    <PolicyContext.Provider value={{ policy, setPolicy }}>
      {props.children}
    </PolicyContext.Provider>
  )
}

export const usePolicy = (): PolicyContextObj => {
  const context = useContext(PolicyContext)
  if (!context) {
    throw new Error('Cannot use PolicyContext when undefined.')
  }
  return context
}

export const useMaybePolicy = (): Policy | undefined => useContext(PolicyContext)?.policy
