export interface CookieManager {
  save: (item: boolean) => void
  get: () => boolean | null
  remove: () => void
}

export const createCookieManager = (
  name: string,
  expiryInDays: number,
  domain?: string,
): CookieManager => {
  const cookieDomain = domain ? `; domain=${domain}` : ''

  return {
    save: (item: boolean) => {
      const d = new Date()
      d.setTime(d.getTime() + expiryInDays * 24 * 60 * 60 * 1000)
      document.cookie = `${name}=${JSON.stringify(
        item,
      )}; expires=${d.toUTCString()}; path=/${cookieDomain}`
    },

    get: () => {
      const suffixedName = name + '='
      const cookieArray = decodeURIComponent(document.cookie).split(';')
      for (let i = 0; i < cookieArray.length; i++) {
        let c = cookieArray[i]
        while (c.charAt(0) == ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(suffixedName) == 0) {
          const value = c.substring(suffixedName.length, c.length)
          return value.length > 0 ? (JSON.parse(value) as boolean) : null
        }
      }
      return null
    },

    remove: () =>
      (document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/${cookieDomain}`),
  }
}
