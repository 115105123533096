import { PrimaryButton, PrimaryButtonProps, useModal } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'

import { getCurrentQuote, isAddOn } from '../../../domain'
import { useDiscountCovers, useOptionalCovers } from '../../../hooks/covers'
import { useErrorHandlingAsyncCallback } from '../../../hooks/errors'
import {
  TKeyBuilder,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { usePolicyholder, usePrincipal } from '../../authentication'
import { useProduct } from '../../product/productContext'
import { useProposal } from '../../product/proposal/proposalContext'
import { ProposalPath } from '../../product/proposal/ProposalPath'
import { IdCheckFailedModal } from './IdCheckFailedModal'

export interface ProposalContinueButtonProps extends PrimaryButtonProps {
  tKey: TKeyBuilder
  disabled?: boolean
}

export const ProposalContinueButton: FunctionComponent<ProposalContinueButtonProps> = ({
  tKey,
  disabled = false,
  ...props
}) => {
  const { t } = useWriskTranslation()
  const { apiClient } = usePrincipal()
  const modal = useModal()

  const navigate = useNavigate()

  const { proposal } = useProposal()
  const { product } = useProduct()
  const { policyholder } = usePolicyholder()

  const quote = getCurrentQuote(proposal)
  const covers = useOptionalCovers(quote, product).filter(isAddOn(product))

  const [discount] = useDiscountCovers(quote)

  const onButtonClick = useErrorHandlingAsyncCallback(async () => {
    const idCheckResponse = await apiClient.idCheck(proposal.proposalCode)

    idCheckResponse.isClear
      ? navigate(
          covers.length
            ? ProposalPath.ADD_ONS
            : policyholder?.accountRegistered
            ? ProposalPath.SUMMARY
            : ProposalPath.ACCOUNT_SETUP,
        )
      : modal.show({
          content: <IdCheckFailedModal />,
        })
  })

  const buttonKey = discount
    ? discount.active
      ? 'actions.active-promo'
      : 'actions.inactive-promo'
    : 'actions.continue'

  return (
    <PrimaryButton
      data-testid='proposal-continue'
      onClick={onButtonClick.execute}
      disabled={disabled}
      loading={onButtonClick.loading}
      {...props}
    >
      {t(tKey(buttonKey))}
    </PrimaryButton>
  )
}
