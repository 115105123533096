import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import {
  tDefaults,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

export const IPT: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  return (
    <Trans
      t={t}
      i18nKey={tDefaults('ipt')}
      components={{
        abbr: <abbr />,
      }}
    />
  )
}
