import React, { FunctionComponent } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { getFeatureToggles, useConfig } from '../../../../state/configuration'
import { BindDisabled } from './BindDisabled'
import { CheckoutPage } from './CheckoutPage'
import { CheckoutPath } from './CheckoutPath'
import { PaymentResultErrorPage } from './PaymentResultErrorPage'

export const CheckoutRouter: FunctionComponent = () => {
  const { disableBind } = useConfig(getFeatureToggles)

  return disableBind ? (
    <BindDisabled />
  ) : (
    <Routes>
      <Route index element={<CheckoutPage />} />
      <Route
        path={CheckoutPath.PAYMENT_RESULT_CALLBACK}
        element={<PaymentResultErrorPage />}
      />
      <Route path='*' element={<Navigate to='..' />} />
    </Routes>
  )
}
