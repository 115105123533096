import css from '@styled-system/css'
import styled from 'styled-components'
import { variant } from 'styled-system'

export type AppNameProperty = 'default'

export interface AppNameProps {
  variant?: AppNameProperty
}

export const AppName = styled.div<AppNameProps>(
  css({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'navbar',
    width: '100%',
  }),
  variant({
    scale: 'AppName',
    variants: {
      default: {
        display: 'none',
      },
    },
  }),
)

AppName.defaultProps = {
  variant: 'default',
}
