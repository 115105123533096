import {
  Box,
  Flex,
  FlexProps,
  mdBumps,
  SecondaryButton,
  smBumps,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { InsuredProfile, Profile } from '../../../../domain'
import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import EditProfileFlex from './components/EditProfileFlex'

export interface AdditionalProfileViewProps extends FlexProps {
  insuredProfiles: InsuredProfile[]
  onAdd: () => void
  onUpdate: (profile: Profile) => void
  onRemove: (profile: Profile) => void
  loading: boolean
  maxAdditionalProfiles: number
}

const tKey = TKey('components.additional-profile-view')

export const AdditionalProfileView: FunctionComponent<AdditionalProfileViewProps> = ({
  insuredProfiles,
  onAdd,
  onUpdate,
  onRemove,
  loading,
  maxAdditionalProfiles,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const additionalProfiles = insuredProfiles.filter((it) => !it.policyholder)

  return (
    <Flex width={1} variant='raised' flexDirection='column' {...props}>
      {additionalProfiles.length > 0 && (
        <Box width={1} py={3} borderBottomWidth={1}>
          {additionalProfiles.map((profile) => (
            <EditProfileFlex
              key={profile.profileCode}
              profile={profile}
              onUpdate={onUpdate}
              onRemove={onRemove}
              loading={loading}
              tKey={tKey}
            />
          ))}
        </Box>
      )}
      <Flex px={mdBumps} py={3} width={1}>
        <Typo pr={smBumps} py={3}>
          <Trans
            t={t}
            i18nKey={tKey('header')}
            values={{
              count: maxAdditionalProfiles - additionalProfiles.length,
              maxAdditionalProfiles,
            }}
          />
        </Typo>
        {additionalProfiles.length < maxAdditionalProfiles && (
          <SecondaryButton
            flexShrink='0'
            layout='small'
            onClick={onAdd}
            data-testid='add-driver'
          >
            {t(tKey('actions.add'))}
          </SecondaryButton>
        )}
      </Flex>
    </Flex>
  )
}
