import React, { FunctionComponent, ReactElement } from 'react'

import { Flex, FlexProps, mdBumps, smBumps, Typo, xsBumps } from '../atoms'

export interface PaymentItemCellProps extends FlexProps {
  date: string | ReactElement
  description: string | ReactElement
  amount: string | ReactElement
}

export const PaymentItemCell: FunctionComponent<PaymentItemCellProps> = ({
  date,
  description,
  amount,
  ...props
}) => (
  <Flex width={1} px={mdBumps} py={xsBumps} alignItems='start' {...props}>
    <Flex flex={0.25} mr={smBumps}>
      <Typo whiteSpace='nowrap' typoSize='sm'>
        {date}
      </Typo>
    </Flex>
    <Flex flexWrap='wrap' mr={smBumps} justifyContent='flex-start' flex={0.55}>
      <Typo whiteSpace='wrap' typoSize='sm'>
        {description}
      </Typo>
    </Flex>
    <Flex flex={0.2} justifySelf='end' justifyContent='flex-end'>
      <Typo whiteSpace='nowrap' typoSize='sm'>
        {amount}
      </Typo>
    </Flex>
  </Flex>
)
