import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Data } from '../../../../../domain'
import { useConfig } from '../../../../../state/configuration'

const DEFAULT_PASSTHROUGHS = []

export const usePassthroughsReducer = () => {
  const [params] = useSearchParams()
  const passthroughs = useConfig(
    ({ config }) => config.createProposal?.passthroughs ?? DEFAULT_PASSTHROUGHS,
  )

  return useCallback<() => Data>(() => {
    return passthroughs.reduce((acc, name) => {
      const parameter = params.get(name)
      return parameter
        ? {
            ...acc,
            [name]: parameter,
          }
        : acc
    }, {})
  }, [passthroughs, params])
}
