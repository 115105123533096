import css from '@styled-system/css'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import {
  border,
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  ResponsiveValue,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from 'styled-system'

import { defaultConfig } from '../../extensions'
import { transition, TransitionProps } from '../../transition'
import { mdBumps } from '../layout'
import { TypoSizeProps, typoSizes } from '../typography'

export type TextAreaProperty = 'default'

export type TextAreaProps = Omit<
  React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >,
  'width' | 'ref'
> &
  TransitionProps &
  SpaceProps &
  LayoutProps &
  TypographyProps &
  ColorProps &
  TypoSizeProps &
  FlexboxProps & {
    variant?: ResponsiveValue<TextAreaProperty>
  }

const Component: FunctionComponent<TextAreaProps> = ({ ...props }) => {
  return <textarea {...props} />
}

export const TextArea = styled(Component).withConfig(defaultConfig)<TextAreaProps>(
  {
    '-webkit-appearance': 'none',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
  },
  css({
    borderRadius: 2,
    fontFamily: 'input',
    border: 0,
    zIndex: 100,
    resize: 'none',
    outline: 0,
  }),
  variant({
    scale: 'TextArea',
    variants: {
      default: {
        boxShadow: 'default',
        p: mdBumps,
        bg: 'chalk',
        '&:focus': {
          boxShadow: 'input',
        },
      },
    },
  }),
  typoSizes,
  compose(transition, border, space, layout, typography, color, flexbox),
)

TextArea.defaultProps = {
  variant: 'default',
  typoSize: 'base',
}
