import css from '@styled-system/css'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import { useTransition } from 'react-spring'
import styled from 'styled-components'

import { AnimatedMenu, AnimatedMenuProps } from '../atoms'

export interface PopupMenuProps extends PropsWithChildren {
  className?: string
  onDismiss: () => void
  visible: boolean
  variant?: AnimatedMenuProps['variant']
}

export const PopupMenu: FunctionComponent<PopupMenuProps> = ({
  onDismiss,
  children,
  visible,
  variant,
}) => {
  const transitions = useTransition(visible, {
    from: { opacity: 0, transform: `translateY(-8px)` },
    enter: { opacity: 1, transform: `translateY(0px)` },
    leave: { opacity: 0, transform: `translateY(-8px)` },
  })

  const menu = transitions((props, item) => {
    return (
      item && (
        <AnimatedMenu variant={variant} style={props}>
          {children}
        </AnimatedMenu>
      )
    )
  })

  return visible ? (
    <>
      <MenuFog onClick={onDismiss} />
      {menu}
    </>
  ) : null
}

const MenuFog = styled.div(
  css({
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 999,
  }),
)
