import {
  ExpandableSection,
  PremiumBreakdown,
  PremiumLineItem,
  PremiumSummary,
  smBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useMemo } from 'react'

import { inForce, PriceOption } from '../../../domain'
import { QuotePremiums } from '../../../hooks/premiums'
import {
  tFormats,
  TKeyBuilder,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { toDateTime } from '../../../util/date'
import { IPT } from '../product'
import { coreLineItems, discountLineItems, optionalLineItems } from './lineItems'

export interface StandardAdjustedPremiumProps {
  currentPremiums: QuotePremiums
  previousPremiums?: QuotePremiums
  discount?: PriceOption
  expanded?: boolean
  tKey: TKeyBuilder
}

export const StandardAdjustedPremium: FunctionComponent<StandardAdjustedPremiumProps> = ({
  currentPremiums,
  previousPremiums,
  discount,
  expanded,
  tKey,
}) => {
  const { t } = useWriskTranslation()

  const subheader = inForce(discount)
    ? t(tKey('promotion'), {
        date: t(tFormats('date.long'), { value: toDateTime(discount?.endAt) }),
      })
    : undefined

  const header = (
    <PremiumSummary
      header={t<string>(tKey('header'))}
      subheader={subheader}
      currentAmount={t(tFormats('currency.long'), {
        amount: currentPremiums.totals.discounted,
      })}
      previousAmount={
        previousPremiums &&
        t<string>(tFormats('currency.long'), {
          amount: previousPremiums.totals.discounted,
        })
      }
    />
  )

  const lineItems = useMemo<PremiumLineItem[]>(
    () => [
      ...coreLineItems(t, tKey, currentPremiums, previousPremiums),
      ...discountLineItems(t, tKey, currentPremiums, previousPremiums),
      ...optionalLineItems(t, currentPremiums, previousPremiums),
    ],
    [currentPremiums, previousPremiums, t, tKey],
  )

  return (
    <ExpandableSection header={header} open={expanded} reversed>
      <PremiumBreakdown lineItems={lineItems} ipt={<IPT />} mx={smBumps} />
    </ExpandableSection>
  )
}
