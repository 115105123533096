import React, { FunctionComponent } from 'react'

import { Address } from '../../../domain'
import {
  tDefaults,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { AddressLookup } from '../../organisms/address'
import { FormInputProps } from '../types'

export interface AddressFormMeta {
  countries?: string[]
}

export const AddressFormInput: FunctionComponent<
  FormInputProps<Address, AddressFormMeta | undefined>
> = ({ name, value, onChange, meta, tKey, tName }) => {
  const { t } = useWriskTranslation()

  return (
    <AddressLookup
      name={name}
      value={value}
      onChange={onChange}
      placeholder={t<string>(tKey(tName, 'placeholder'))}
      notFound={t<string>(tKey(tName, 'validation', 'notFound'))}
      countries={meta?.countries}
      changeAction={t(tDefaults('actions.change'))}
    />
  )
}
