// eslint-disable-next-line import/no-unresolved
import { Property } from 'csstype'
import { ResponsiveValue, system } from 'styled-system'

export interface TextTransformProps {
  textTransform?: ResponsiveValue<Property.TextTransform>
}

export const textTransform = system({
  textTransform: true,
})
