import {
  Flex,
  FlexAnchor,
  FlexAnchorProps,
  Icon,
  smBumps,
  Status,
  Typo,
  useModal,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { CustomerStatement } from '../../../domain'
import { useErrorHandlingAsyncCallback } from '../../../hooks/errors'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { toDateTime } from '../../../util/date'
import { NextStatementModal } from './NextStatementModal'

export interface NextStatementSummaryProps extends Omit<FlexAnchorProps, 'ref'> {
  statement: CustomerStatement
}

const tKey = TKey('components.next-statement-summary')

export const NextStatementSummary: FunctionComponent<NextStatementSummaryProps> = ({
  statement,
  ...props
}) => {
  const { t } = useWriskTranslation()
  const { hide, show } = useModal()

  const { createdAt, statementBalance } = statement.statementSummary

  const { execute, loading } = useErrorHandlingAsyncCallback(async () => {
    show({
      content: <NextStatementModal hide={hide} statement={statement} />,
    })
  })

  return (
    <FlexAnchor
      variant='highlight'
      width={1}
      py={3}
      px={smBumps}
      onClick={execute}
      disabled={loading}
      {...props}
    >
      <Typo>{t(tFormats('date.medium'), { value: toDateTime(createdAt) })}</Typo>
      <Flex>
        <Status typoSize='xs' variant='default' mr={3}>
          {t(tKey('status'))}
        </Status>
        <Typo mr={2}>
          {t(tFormats('currency.long'), { amount: statementBalance / 100 })}
        </Typo>
        <Icon icon={loading ? 'loadingDark' : 'chevronRight'} size='tiny' />
      </Flex>
    </FlexAnchor>
  )
}
