import { Icon, Notification, NotificationProps, Typo } from '@wrisk/ui-components'
import { round } from 'lodash'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { CustomerAccount, isAccountActive } from '../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

const tKey = TKey('components.credit-notification')

interface ContentProps extends NotificationProps {
  customerAccount: CustomerAccount
}

export const Content: FunctionComponent<ContentProps> = ({
  customerAccount,
  ...props
}) => {
  const { t } = useWriskTranslation()
  const textKey = isAccountActive(customerAccount) ? 'active' : 'inactive'

  return (
    <Notification type='standalone' variant='info' justifyContent='flex-start' {...props}>
      <Icon icon='info' display={['none', 'none', 'block']} mr={4} />
      <Typo>
        <Trans
          t={t}
          i18nKey={tKey(textKey)}
          values={{
            credit: t(tFormats('currency.long'), {
              amount: String(round(customerAccount.accountBalance / 100, 2)),
            }),
          }}
        />
      </Typo>
    </Notification>
  )
}

export interface CreditNotificationProps extends NotificationProps {
  customerAccount: CustomerAccount
}

export const CreditNotification: FunctionComponent<CreditNotificationProps> = ({
  customerAccount,
  ...props
}) => {
  return customerAccount.accountBalance > 0 ? (
    <Content customerAccount={customerAccount} {...props} />
  ) : null
}
