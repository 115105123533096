import { SourcePolicy } from './policy'
import { Quote } from './quote'
import { RenewalType } from './renewalType'

export enum ProposalStatus {
  INSUFFICIENT_ACCURACY = 'INSUFFICIENT_ACCURACY',
  QUOTE = 'QUOTE',
  QUOTE_EXPIRED = 'QUOTE_EXPIRED',
  REFERRAL = 'REFERRAL',
  POLICY = 'POLICY',
}

export interface SourceProposal {
  schemeCode: string
  proposalCode: string
  status: ProposalStatus
}

export interface Proposal {
  policyId?: string
  schemeCode: string
  proposalCode: string
  policyNumber: string

  renewalCycle: number
  upsellProposal?: Proposal
  sourceProposal?: SourceProposal
  sourcePolicy?: SourcePolicy

  startAt?: string
  endAt?: string
  policyTerm: string
  renewalType: RenewalType
  timezone: string

  status: ProposalStatus
  quote: Quote
  source?: string

  monthlyBilling: boolean
}

export interface RenewalProposal extends Proposal {
  sourcePolicy: SourcePolicy
}
