import { createContext, useContext } from 'react'

import { Dependencies } from './types'

const DependenciesContext = createContext<Dependencies | undefined>(undefined)

export const DependenciesProvider = DependenciesContext.Provider

export const useDependencies = (): Dependencies => {
  const context = useContext(DependenciesContext)
  if (!context) {
    throw new Error('Cannot use DependenciesContext when undefined.')
  }
  return context
}

export const useApiClientFactory = () => useDependencies().apiClientFactory

export const useAnonymous = () => useDependencies().anonymousClient

export const useAuthClient = () => useDependencies().authClient
