import { Box, mdBumps, SectionProps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  getEndorsementDifference,
  isProductEndorsement,
  PolicyVersion,
} from '../../../domain'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { usePolicy } from '../../product/policy/policyContext'
import { useProduct } from '../../product/productContext'
import { Endorsements } from '../endorsements'

export interface ProposalCoverSectionProps extends SectionProps {
  updatedPolicyVersion: PolicyVersion
}

const tKey = TKey('components.cover-changes')

export const CoverChanges: FunctionComponent<ProposalCoverSectionProps> = ({
  updatedPolicyVersion: { quote: updatedQuote },
  ...props
}) => {
  const { t } = useWriskTranslation()

  const {
    policy: {
      currentPolicyVersion: { quote: currentQuote },
    },
  } = usePolicy()
  const { product } = useProduct()

  const endorsements = getEndorsementDifference(
    updatedQuote.endorsements.filter(isProductEndorsement(product)),
    currentQuote.endorsements.filter(isProductEndorsement(product)),
  )

  return endorsements.length ? (
    <Box variant='raised' {...props}>
      <Box p={mdBumps} borderBottomWidth={1}>
        <Typo fontWeight='bold'>{t(tKey('header'))}</Typo>
      </Box>
      <Endorsements p={mdBumps} quote={updatedQuote} endorsements={endorsements} />
    </Box>
  ) : null
}
