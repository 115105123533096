import React, { ButtonHTMLAttributes, DetailedHTMLProps, FunctionComponent } from 'react'

import { Icon } from '../images'

export interface ButtonProps
  extends Omit<
    DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    'color' | 'ref'
  > {
  loading?: boolean
}

export const Button: FunctionComponent<ButtonProps> = ({
  loading,
  children,
  disabled,
  type,
  ...props
}) => (
  <button
    disabled={loading || disabled}
    autoFocus={false}
    type={type ?? 'button'}
    {...props}
  >
    {children}
    {loading && <Icon className='loading' icon='loadingDark' />}
  </button>
)
