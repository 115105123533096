import React, { FunctionComponent } from 'react'

import { tDefaults, tEntries } from '../../../infrastructure/internationalisation'
import { Formatter } from '../types'

export const BooleanFormatter: FunctionComponent<Formatter<boolean>> = ({
  input,
  value,
  t,
}) => (
  <>
    {value !== undefined
      ? value
        ? t([tEntries(input.name, 'yes'), tEntries('boolean.yes')])
        : t([tEntries(input.name, 'no'), tEntries('boolean.no')])
      : t(tDefaults('inputs.no-answer'))}
  </>
)
