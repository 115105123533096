import { Flex, TabAnchor, WideContainer } from '@wrisk/ui-components'
import { TabBar, TabBarProps } from '@wrisk/ui-components/src/molecules/TabBar'
import React, { FunctionComponent, useCallback } from 'react'

import {
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { TabItem } from '../types'

const tKey = TKey('pages.policy-configuration.sub-nav')

export interface TabHeaderProps extends TabBarProps {
  tabs: TabItem[]
  current?: TabItem
  onClick: (tab: TabItem) => void
}

interface TabProps {
  selected: boolean
  tab: TabItem
  onClick: (tab: TabItem) => void
}

const Tab: FunctionComponent<TabProps> = ({ selected, tab, onClick }) => {
  const { t } = useWriskTranslation()

  const onClickCallback = useCallback(() => onClick(tab), [onClick, tab])

  return (
    <TabAnchor
      data-testid='tab-anchor'
      selected={selected}
      mr={6}
      onClick={onClickCallback}
    >
      {t(tKey(tab.id))}
    </TabAnchor>
  )
}

export const TabHeader: FunctionComponent<TabHeaderProps> = ({
  tabs,
  current,
  onClick,
  ...props
}) => {
  return (
    <TabBar width={1} {...props}>
      <WideContainer>
        <Flex flexDirection='row' alignItems='stretch'>
          {tabs.map((tab) => (
            <Tab
              selected={tab.id === current?.id}
              key={tab.id}
              tab={tab}
              onClick={onClick}
            />
          ))}
        </Flex>
      </WideContainer>
    </TabBar>
  )
}
