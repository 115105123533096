import { ExpandableSection, ExpandableSectionProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { Quote } from '../../../domain'
import { ExcessSummary } from './ExcessSummary'

export interface ExcessExpandableProps extends ExpandableSectionProps {
  quote: Quote
}

export const ExcessExpandable: FunctionComponent<ExcessExpandableProps> = ({
  quote,
  header,
  ...props
}) => {
  return (
    <ExpandableSection header={header} {...props}>
      <ExcessSummary quote={quote} />
    </ExpandableSection>
  )
}
