import { Box, Flex, ModalHeader, PrimaryButton, xlBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { useAuth } from '../../../../../../hooks/auth'
import { useErrorHandlingAsyncCallback } from '../../../../../../hooks/errors'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'

const tKey = TKey('components.sign-in-modal')

export const SignInModalContent: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  const auth = useAuth()
  const onSignInCallback = useErrorHandlingAsyncCallback(() => auth.signIn())

  return (
    <Box>
      <ModalHeader header={t(tKey('header'))} subheader={t(tKey('subheader'))} />
      <Flex width={1} mt={xlBumps}>
        <PrimaryButton
          width={1}
          id='signIn-button'
          onClick={onSignInCallback.execute}
          loading={onSignInCallback.loading}
        >
          {t(tKey('actions.confirm'))}
        </PrimaryButton>
      </Flex>
    </Box>
  )
}
