import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'

import { CustomerStatementSummary } from '../../../../domain'

interface StatementSummaryContextObj {
  statementSummaries: CustomerStatementSummary[]
  setStatementSummaries: (statementSummaries: CustomerStatementSummary[]) => void
}

const StatementSummaryContext = createContext<StatementSummaryContextObj | undefined>(
  undefined,
)

export interface StatementSummariesProviderProps extends PropsWithChildren {
  statementSummaries: CustomerStatementSummary[]
}

export const StatementSummaryProvider: FunctionComponent<
  StatementSummariesProviderProps
> = (props) => {
  const [statementSummaries, setStatementSummaries] = useState(props.statementSummaries)

  return (
    <StatementSummaryContext.Provider
      value={{ statementSummaries, setStatementSummaries }}
    >
      {props.children}
    </StatementSummaryContext.Provider>
  )
}

export const useStatementSummaries = (): StatementSummaryContextObj => {
  const context = useContext(StatementSummaryContext)
  if (!context) {
    throw new Error('Cannot use StatementSummaryContext when undefined.')
  }
  return context
}
