import {
  Section,
  SectionContent,
  SectionProps,
  SectionTitle,
  useModal,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import {
  getPolicyVersion,
  PolicyAdjustmentResponse,
  Profile,
} from '../../../../../../domain'
import { useSubmitRemoveProfilePolicyAdjustment } from '../../../../../../hooks/adjustments/policy'
import {
  TKeyBuilder,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { AdditionalProfileManagementConfig } from '../../../../../../state/configuration'
import { usePrincipal } from '../../../../../authentication'
import { AdditionalProfileView } from '../../../../../organisms/quote'
import {
  AdditionalProfileAdjustmentPath,
  PolicyAdjustmentPath,
  RemoveAdditionalProfileAdjustmentModal,
} from '../../../adjustments'
import { usePolicy } from '../../../policyContext'

export interface AdditionalProfileSectionProps extends SectionProps {
  config: AdditionalProfileManagementConfig
  tKey: TKeyBuilder
}

export const AdditionalProfileSection: FunctionComponent<
  AdditionalProfileSectionProps
> = ({ config, tKey, ...props }) => {
  const { t } = useWriskTranslation()

  const { policy } = usePolicy()
  const navigate = useNavigate()
  const modal = useModal()
  const { isAdmin } = usePrincipal()

  const { quote } = getPolicyVersion(policy)

  const adjustmentPath = generatePath(PolicyAdjustmentPath.Section, {
    sectionId: config.id,
  })

  const onUpdateProfile = useCallback(
    (profile: Profile) =>
      navigate(
        [
          adjustmentPath,
          generatePath(AdditionalProfileAdjustmentPath.Profile, {
            profileCode: profile.profileCode,
          }),
        ].join('/'),
      ),
    [adjustmentPath, navigate],
  )

  const onAddProfile = useCallback(
    () =>
      navigate(
        [adjustmentPath, generatePath(AdditionalProfileAdjustmentPath.NewProfile)].join(
          '/',
        ),
      ),
    [adjustmentPath, navigate],
  )

  const onRemoveProfileSuccess = useCallback(
    (response: PolicyAdjustmentResponse) => {
      modal.hide()
      navigate(
        [
          adjustmentPath,
          generatePath(AdditionalProfileAdjustmentPath.Review, {
            adjustmentId: response.adjustmentId,
          }),
        ].join('/'),
      )
    },
    [adjustmentPath, navigate, modal],
  )

  const onSubmitRemoveProfile =
    useSubmitRemoveProfilePolicyAdjustment(onRemoveProfileSuccess)

  const onRemoveProfile = useCallback(
    (profile: Profile) => {
      if (isAdmin) {
        modal.show({
          content: (
            <RemoveAdditionalProfileAdjustmentModal
              updatedData={profile}
              policy={policy}
              onSuccess={onRemoveProfileSuccess}
              onCancel={modal.hide}
            />
          ),
        })
      } else {
        void onSubmitRemoveProfile.execute(profile)
      }
    },
    [isAdmin, modal, onSubmitRemoveProfile, onRemoveProfileSuccess, policy],
  )

  return (
    <Section width={1} {...props}>
      <SectionTitle>{t(tKey('sections', config.id, 'header'))}</SectionTitle>
      <SectionContent>
        <AdditionalProfileView
          insuredProfiles={quote.insuredProfiles}
          onAdd={onAddProfile}
          onUpdate={onUpdateProfile}
          onRemove={onRemoveProfile}
          loading={onSubmitRemoveProfile.loading}
          maxAdditionalProfiles={config.maxAdditionalProfiles}
        />
      </SectionContent>
    </Section>
  )
}
