import { Box, BoxProps, mdBumps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { arePremiumsEqual, PolicyDetail, Quote } from '../../../../../domain'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { ProductConfig } from '../../../../../state/configuration'
import { PolicyAdjustmentPricing } from '../../../../product/policy/pricing'

const tKey = TKey('components.premium')

export interface AdjustedPremiumSectionProps extends BoxProps {
  product: ProductConfig
  policyDetail: PolicyDetail
  originalQuote: Quote
  updatedQuote: Quote
}

export const CurrentPremiumChange: FunctionComponent<AdjustedPremiumSectionProps> = ({
  product,
  policyDetail,
  originalQuote,
  updatedQuote,
  ...props
}) => {
  const { t } = useWriskTranslation()

  return arePremiumsEqual(originalQuote, updatedQuote) ? (
    <Box px={mdBumps} py={5} variant='raised'>
      <Typo>{t(tKey('same'))}</Typo>
    </Box>
  ) : (
    <PolicyAdjustmentPricing
      product={product}
      policyDetail={policyDetail}
      adjustedQuote={updatedQuote}
      originalQuote={originalQuote}
      {...props}
    />
  )
}
