import { DateTime, Duration, DurationObjectUnits, Interval } from 'luxon'

export const toDateTime = (value: string | undefined | null): DateTime | undefined =>
  value ? DateTime.fromISO(value) : undefined

export const getStartOfEachDay = (
  duration: DurationObjectUnits,
  date: DateTime = DateTime.local(),
): DateTime[] =>
  Interval.after(getStartOfDay(date), Duration.fromObject(duration))
    .splitBy({ days: 1 })
    .map((d) => d.start as DateTime)

export const getStartOfDay = (date: DateTime = DateTime.local()): DateTime =>
  date.startOf('day')

export const isISOToday = (date: string) =>
  DateTime.fromISO(date).startOf('day').toISO() === DateTime.now().startOf('day').toISO()
export const isDateTimeToday = (date: DateTime) =>
  date.startOf('day').toISO() === DateTime.now().startOf('day').toISO()

export const toFormattedDate = (value: string) =>
  DateTime.fromISO(value).toFormat('MMM yyyy')
export const toFormattedDateWithDay = (value: string) =>
  DateTime.fromISO(value).toFormat('dd MMM yyyy')
