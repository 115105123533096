import css from '@styled-system/css'
import {
  Box,
  Flex,
  Icon,
  mdBumps,
  MenuBox,
  MenuText,
  NavAnchor,
  NavBar,
  Navigation,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { AppPath } from '../application/AppPath'
import { usePolicyholder } from '../application/authentication'
import { useImpersonate } from '../application/authentication/admin/impersonateContext'
import { TKey, useWriskTranslation } from '../infrastructure/internationalisation'
import { AppNameBox } from './UserNav/AppNameBox'
import { LogoNavBox } from './UserNav/LogoNavBox'

const AdminBar = styled.div(
  css({
    bg: '#ba0004',
    color: 'chalk',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    px: mdBumps,
  }),
)

const tKey = TKey('components.main-menu')

export const AdminNav: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  const { impersonate } = useImpersonate()
  const { policyholder } = usePolicyholder()

  const navigate = useNavigate()

  const onAccountClick = useCallback(() => navigate(AppPath.ACCOUNT), [navigate])

  return (
    <Navigation>
      <NavBar>
        <LogoNavBox />
        <AppNameBox />
        <MenuBox>
          <NavAnchor id='accountSettingAction' onClick={onAccountClick}>
            <MenuText>{t(tKey('account'))}</MenuText>
          </NavAnchor>
        </MenuBox>
      </NavBar>
      <AdminBar>
        <Flex mr={2}>
          <Icon icon='warning' color='chalk' mr={2} display={['none', 'block']} />
          <Typo typoSize='sm' fontWeight='bold'>
            Admin view
          </Typo>
        </Flex>
        <Box>
          <Typo typoSize='xs' fontWeight='bold'>
            {impersonate}
          </Typo>
          {policyholder?.email && <Typo typoSize='xs'>{policyholder?.email}</Typo>}
        </Box>
      </AdminBar>
    </Navigation>
  )
}
