import css from '@styled-system/css'
import styled from 'styled-components'
import { compose, layout, LayoutProps, variant } from 'styled-system'

import { Anchor, AnchorProps, mdBumps } from '../atoms'

export type CollapsibleSectionHeaderProperty = 'default' | 'visible'

export interface CollapsibleSectionHeaderProps extends AnchorProps, LayoutProps {
  variant?: CollapsibleSectionHeaderProperty
}

export const CollapsibleSectionHeader = styled(Anchor)<CollapsibleSectionHeaderProps>(
  css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderStyle: 'solid',
    borderColor: 'border',
    borderWidth: 0,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    py: mdBumps,
  }),
  variant({
    scale: 'CollapsibleSectionHeader',
    variants: {
      default: {},
      active: {},
    },
  }),
  compose(layout),
)

CollapsibleSectionHeader.defaultProps = {
  variant: 'default',
}
