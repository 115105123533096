import { TextInput } from '@wrisk/ui-components'
import React, { ChangeEvent, FunctionComponent, useCallback } from 'react'

import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { FormInputProps } from '../types'

const tKey = TKey('components.email-form-input')

export const EmailFormInput: FunctionComponent<FormInputProps<string>> = ({
  value,
  onChange,
}) => {
  const { t } = useWriskTranslation()

  const onInternalChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value)
    },
    [onChange],
  )

  return (
    <TextInput
      width={[1, 1, 4 / 5]}
      type='email'
      value={value}
      placeholder={t<string>(tKey('placeholder'))}
      onChange={onInternalChange}
    />
  )
}
