import { SectionProps, useModal } from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'

import { getPolicyVersion } from '../../../domain'
import { usePolicyExcessAdjustment } from '../../../hooks/adjustments/policy'
import { useIsMtaAllowed } from '../../../hooks/adjustments/policy/renewal'
import { usePrincipal } from '../../authentication'
import { AdjustmentModal } from '../../product/policy/adjustments/admin'
import { usePolicy } from '../../product/policy/policyContext'
import { useProduct } from '../../product/productContext'
import { ExcessSection, VoluntaryExcessModal } from '../excess'
import { InRenewalMtaBlockedModal } from '../InRenewalMtaBlockedModal'

export const PolicyExcessSection: FunctionComponent<SectionProps> = (props) => {
  const modal = useModal()

  const { isAdmin } = usePrincipal()

  const { policy } = usePolicy()
  const { product } = useProduct()

  const isMtaAllowed = useIsMtaAllowed()
  const isAdjustable = Boolean(product.excess?.isAdjustable || isAdmin)

  const { quote } = getPolicyVersion(policy)

  const onEditExcess = useCallback(() => {
    isMtaAllowed
      ? modal.show({
          content: <PolicyVoluntaryExcessModal onComplete={modal.hide} />,
        })
      : modal.show({
          content: <InRenewalMtaBlockedModal />,
        })
  }, [isMtaAllowed, modal])

  return (
    <ExcessSection
      config={product.excess}
      currentQuote={quote}
      onChange={isAdjustable ? onEditExcess : undefined}
      {...props}
    />
  )
}

const PolicyVoluntaryExcessModal: FunctionComponent<{
  onComplete: () => void
}> = ({ onComplete }) => {
  const onSubmitAdjustment = usePolicyExcessAdjustment(onComplete)
  const { isAdmin } = usePrincipal()
  const modal = useModal()
  const { policy } = usePolicy()
  const { quote } = policy.currentPolicyVersion

  const onChange = useCallback(
    (excess: number) => {
      if (isAdmin) {
        modal.show({
          content: (
            <AdjustmentModal
              updatedData={{ excess }}
              policy={policy}
              onCancel={modal.hide}
              onSubmitAdjustment={onSubmitAdjustment}
            />
          ),
        })
      } else {
        void onSubmitAdjustment.execute({ excess })
      }
    },
    [isAdmin, modal, onSubmitAdjustment, policy],
  )

  return (
    <VoluntaryExcessModal
      loading={onSubmitAdjustment.loading}
      onChange={onChange}
      quote={quote}
    />
  )
}
