import {
  Box,
  BoxProps,
  Flex,
  Heading,
  Illus,
  SectionContent,
  SectionTitle,
  Typo,
  xsBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { useProduct } from '../../../product/productContext'

const tKey = TKey('components.how-it-works')

export const HowItWorksSection: FunctionComponent<BoxProps> = (props) => {
  const { t } = useWriskTranslation()

  const { product } = useProduct()

  const steps =
    product.howItWorks?.map((step, index) => (
      <Flex key={index} flexDirection={['row', 'column']} flex={1} p={xsBumps}>
        <Box width={[1 / 4, 1]} mr={[3, 0]}>
          <Illus illus={step.illusId} />
        </Box>
        <Box width={[3 / 4, 1]}>
          <Heading textAlign={['unset', 'center']} mt={[0, 3]} mb={2}>
            {t(tKey(`${step.illusId}.header`))}
          </Heading>
          <Typo typoSize='sm' textAlign={['unset', 'center']}>
            {t(tKey(`${step.illusId}.description`))}
          </Typo>
        </Box>
      </Flex>
    )) ?? []

  return steps.length ? (
    <Box width={1} {...props}>
      <SectionTitle>{t(tKey('header'))}</SectionTitle>
      <SectionContent>
        <Flex
          variant='raised'
          flexDirection={['column', 'row']}
          alignItems='flex-start'
          p={xsBumps}
        >
          {steps}
        </Flex>
      </SectionContent>
    </Box>
  ) : null
}
