import { DateTime } from 'luxon'
import React, { FunctionComponent } from 'react'

import { tDefaults, tFormats } from '../../../infrastructure/internationalisation'
import { Formatter } from '../types'

export const DateFormatter: FunctionComponent<Formatter<string>> = ({ value, t }) => (
  <>
    {value
      ? t(tFormats('date.long'), { value: DateTime.fromISO(value) })
      : t(tDefaults('inputs.no-answer'))}
  </>
)
