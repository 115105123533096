import {
  Flex,
  FlexAnchor,
  FlexProps,
  Icon,
  smBumps,
  Typo,
  useModal,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { CustomerStatementSummary } from '../../../domain'
import { useErrorHandlingAsyncCallback } from '../../../hooks/errors'
import {
  tFormats,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { toDateTime } from '../../../util/date'
import { usePrincipal } from '../../authentication'
import { PaymentPendingInfo } from './PaymentPendingInfo'
import { StatementModal } from './StatementModal'
import { StatementStatusIcon } from './StatementStatusIcon'

export interface StatementSummaryProps extends FlexProps {
  accountId: string
  statementSummary: CustomerStatementSummary
}

export const StatementSummary: FunctionComponent<StatementSummaryProps> = ({
  accountId,
  statementSummary,
  ...props
}) => {
  const { t } = useWriskTranslation()
  const { apiClient } = usePrincipal()
  const { hide, show } = useModal()

  const { createdAt, status, statementBalance, statementNumber } = statementSummary

  const { execute, loading } = useErrorHandlingAsyncCallback(async () => {
    const statement = await apiClient.getCustomerStatement(accountId, statementNumber)

    show({
      content: <StatementModal hide={hide} statement={statement} />,
    })
  })

  return (
    <Flex flexDirection='column' gap={2} {...props}>
      <FlexAnchor
        variant='highlight'
        width={1}
        py={3}
        px={smBumps}
        onClick={execute}
        disabled={loading}
      >
        <Typo>{t(tFormats('date.medium'), { value: toDateTime(createdAt) })}</Typo>
        <Flex>
          <StatementStatusIcon status={status} mr={3} />
          <Typo mr={2}>
            {t(tFormats('currency.long'), { amount: statementBalance / 100 })}
          </Typo>
          <Icon icon={loading ? 'loadingDark' : 'chevronRight'} size='tiny' />
        </Flex>
      </FlexAnchor>
      <PaymentPendingInfo summary={statementSummary} isHighlighted />
    </Flex>
  )
}
