import { AxiosInstance } from 'axios'

import {
  AccountValidation,
  Data,
  Proposal,
  ProposalAdjustmentRequest,
} from '../../../domain'
import { withGenericErrorHandling, withSilentErrorHandling } from '../errors'

interface CreateProposalRequest {
  schemeCode: string
  source?: string
  promoCode?: string
  externalReference?: string
  data?: Data
}

export interface ProposalsClient {
  getProposals: (params?: {
    schemeCode?: string
    externalReference?: string
  }) => Promise<Proposal[]>
  getProposal: (proposalCode: string) => Promise<Proposal>
  deleteProposal: (proposalCode: string) => Promise<Proposal>
  createProposal: (request: CreateProposalRequest) => Promise<Proposal>
  updateProposal: (
    proposalCode: string,
    adjustment: ProposalAdjustmentRequest,
  ) => Promise<Proposal>
  bindProposal: (proposalCode: string) => Promise<Proposal>
  retainEmail: (proposalCode: string, email: string) => Promise<Proposal>
  trackProposal: (proposalCode: string, values: Record<string, string>) => Promise<void>
  updatePromoStatus: (
    proposalCode: string,
    promoCode: string,
    promotionActivationCode?: string,
  ) => Promise<Proposal>
  createUserAccount: (proposalCode: string) => Promise<void>
  validateUserAccount: (source: string) => Promise<AccountValidation>
  mergeProposal: (proposalCode: string, sourceAccessToken: string) => Promise<Proposal>
}

export const createProposalsClient = (
  getInstance: () => Promise<AxiosInstance>,
  brand: string,
): ProposalsClient => ({
  getProposals: (params: { schemeCode?: string; externalReference?: string } = {}) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.get<Proposal[]>(`/proposals`, {
        params,
      })
      return response.data
    }),

  getProposal: (proposalCode) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.get<Proposal>(`/proposals/${proposalCode}`)
      return response.data
    }),

  deleteProposal: (proposalCode) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.delete<Proposal>(`/proposals/${proposalCode}`)
      return response.data
    }),

  createProposal: (request) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<Proposal>(`/proposals`, { ...request, brand })
      return response.data
    }),

  updateProposal: (proposalCode, adjustment) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.patch<Proposal>(
        `/proposals/${proposalCode}`,
        adjustment,
      )
      return response.data
    }),

  createUserAccount: (proposalCode) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      await instance.post<Proposal>(`/proposals/${proposalCode}/account`)
    }),

  validateUserAccount: (source) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<AccountValidation>(
        `/policyholder/validateAccount`,
        {
          source,
        },
      )
      return response.data
    }),

  bindProposal: (proposalCode) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<Proposal>(`/proposals/${proposalCode}/bind`)
      return response.data
    }),

  retainEmail: (proposalCode, email) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<Proposal>(
        `/proposals/${proposalCode}/retain`,
        { email },
      )
      return response.data
    }),

  trackProposal: (proposalCode, values) =>
    withSilentErrorHandling(async () => {
      const instance = await getInstance()
      await instance.post<Proposal>(`/proposals/${proposalCode}/tracking`, { values })
    }),

  updatePromoStatus: (proposalCode, promoCode, promotionActivationCode?: string) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.patch<Proposal>(
        `/proposals/${proposalCode}/promotions/${promoCode}`,
        {
          promotionActivationCode,
        },
      )
      return response.data
    }),

  mergeProposal: (proposalCode, sourceAccessToken: string) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<Proposal>(`/proposals/merge`, {
        proposalCode,
        sourceAccessToken,
      })
      return response.data
    }),
})
