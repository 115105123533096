import { FlexProps } from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { getCurrentQuote, getUpsellProposal, Proposal } from '../../../../domain'
import { useQuotePremiums } from '../../../../hooks/premiums'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { getProduct, getSchemeByCode, useConfig } from '../../../../state/configuration'
import { AppPath } from '../../../AppPath'
import BenefitListItem from './components/BenefitListItem'
import { UpsellSection } from './components/UpsellSection'

export interface ReferralUpsellSectionProps extends FlexProps {
  proposal: Proposal
}

interface ContentProps {
  upsellProposal: Proposal
}

const tKey = TKey('components.referral-upsell')

const Content: FunctionComponent<ContentProps> = ({ upsellProposal, ...props }) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()

  const product = useConfig(getProduct(upsellProposal.schemeCode))
  const { productUrlName, schemeCode } = useConfig(
    getSchemeByCode(upsellProposal.schemeCode),
  )

  const quote = getCurrentQuote(upsellProposal)

  const { totals } = useQuotePremiums(quote, product)

  const onButtonClick = useCallback(() => {
    navigate(
      generatePath(AppPath.PROPOSAL, {
        proposalCode: upsellProposal.proposalCode,
        productUrlName,
      }),
    )
  }, [upsellProposal, navigate, productUrlName])

  const upsells = t(tKey(schemeCode, 'upsell'), {
    returnObjects: true,
    defaultValue: [],
  }) as string[]

  const checkoutUpsellList = upsells.map((upsell, index) => (
    <BenefitListItem key={index}>{upsell}</BenefitListItem>
  ))

  return (
    <UpsellSection
      benefits={checkoutUpsellList}
      buttonText={t(tKey(schemeCode, 'actions.view'))}
      buttonSubText={t(tKey(schemeCode, 'actions.priced'), {
        premium: t(tFormats('currency.long'), {
          amount: totals.discounted,
        }),
      })}
      title={t(tKey(schemeCode, 'header'))}
      text={t(tKey(schemeCode, 'content'))}
      onButtonClick={onButtonClick}
      {...props}
    />
  )
}

export const ReferralUpsellSection: FunctionComponent<ReferralUpsellSectionProps> = ({
  proposal,
  ...props
}) => {
  const upsellProposal = getUpsellProposal(proposal)

  return upsellProposal ? <Content upsellProposal={upsellProposal} {...props} /> : null
}
