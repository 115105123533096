import { SystemStyleObject } from '@styled-system/css'

import { ButtonProps } from '../Button'

export type ButtonDisabledProperty = 'default'

export const buttonDisabledMapping = ({ disabled }: ButtonProps) => ({
  disabledState: disabled ? 'default' : undefined,
})

export const buttonDisabledVariants: Record<ButtonDisabledProperty, SystemStyleObject> = {
  default: {
    opacity: 0.35,
  },
}
