import { generatePath, useNavigate } from 'react-router-dom'

import { AppPath } from '../application/AppPath'
import { usePrincipal } from '../application/authentication'
import { ProposalPath } from '../application/product/proposal/ProposalPath'
import { useProposalTracking } from '../application/tracking'
import { with404ErrorHandling } from '../clients/api'
import {
  isProposalUpgradable,
  isRenewalProposal,
  isValidSourceProposal,
  Proposal,
} from '../domain'
import { useAnonymous } from '../state'
import { getSchemeByCode, Scheme, useConfig } from '../state/configuration'
import { useErrorHandlingAsync, useErrorHandlingAsyncCallback } from './errors'
import { useNavigateCallback } from './navigation'

export const useCreateProposalCallback = ({
  productUrlName: productUrlName,
  schemeCode,
}: Scheme) => {
  const navigate = useNavigate()

  const { refreshAnonymous, apiClient } = usePrincipal()

  return useErrorHandlingAsyncCallback(async () => {
    await refreshAnonymous()

    const proposal = await apiClient.createProposal({ schemeCode })

    const basePath = isRenewalProposal(proposal) ? AppPath.RENEWAL : AppPath.PROPOSAL

    navigate(
      generatePath(basePath, {
        productUrlName,
        proposalCode: proposal.proposalCode,
      }),
    )
    return proposal
  })
}

export const useContinueProposalCallback = (proposal: Proposal) => {
  const { productUrlName } = useConfig(getSchemeByCode(proposal.schemeCode))
  const upgradable = isProposalUpgradable(proposal)

  const basePath = isRenewalProposal(proposal) ? AppPath.RENEWAL : AppPath.PROPOSAL

  const paths = [
    generatePath(basePath, {
      proposalCode: proposal.proposalCode,
      productUrlName,
    }),
    upgradable && ProposalPath.UPGRADE,
  ].filter((it): it is string => Boolean(it))

  return useNavigateCallback(...paths)
}

export const useEditProposalCallback = (proposal: Proposal) => {
  const { proposalCode, schemeCode } = isValidSourceProposal(proposal.sourceProposal)
    ? proposal.sourceProposal
    : proposal

  const { productUrlName } = useConfig(getSchemeByCode(schemeCode))

  const basePath = isRenewalProposal(proposal) ? AppPath.RENEWAL : AppPath.PROPOSAL

  const paths = [
    generatePath(basePath, {
      productUrlName,
      proposalCode,
    }),
    ProposalPath.DISCLOSURE,
  ]

  return useNavigateCallback(...paths)
}

export const useGetProposal = (proposalCode: string) => {
  const { apiClient, isAuthenticated } = usePrincipal()
  const { getToken } = useAnonymous()
  const tracking = useProposalTracking()

  return useErrorHandlingAsync(async () => {
    let proposal = await with404ErrorHandling(
      async () => await apiClient.getProposal(proposalCode),
    )

    if (!proposal && isAuthenticated) {
      const anonymousToken = await getToken()
      proposal = anonymousToken
        ? await with404ErrorHandling(
            async () => await apiClient.mergeProposal(proposalCode, anonymousToken),
          )
        : undefined
    }

    tracking.trackImpression(proposal)

    return proposal
  }, [proposalCode])
}
