import { differenceWith } from 'lodash'

import { ProductConfig } from '../state/configuration'
import { Endorsement } from './types'

export const isProductEndorsement =
  (product: ProductConfig) =>
  (endorsement: Endorsement): boolean =>
    Boolean(product.endorsements?.some((it) => endorsement.code === it))

export const isExcessEndorsement =
  (product: ProductConfig) =>
  (endorsement: Endorsement): boolean =>
    Boolean(product.excess?.endorsements.some((it) => endorsement.code === it))

export const getEndorsementDifference = (left: Endorsement[], right: Endorsement[]) =>
  differenceWith(
    left,
    right,
    (l, r) => l.code === r.code && l.profileCode === r.profileCode,
  )
