import React, { ChangeEvent, FunctionComponent, useCallback } from 'react'

import { TextInput, TextInputProps } from '../atoms'

export interface FreeTextInputProps extends Omit<TextInputProps, 'onChange' | 'value'> {
  onChange: (value: string) => void
  value?: string
}

export const FreeTextInput: FunctionComponent<FreeTextInputProps> = ({
  onChange,
  value,
  ...props
}) => {
  const onInputChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => onChange(target.value),
    [onChange],
  )

  return <TextInput variant='ghost' value={value} onChange={onInputChange} {...props} />
}
