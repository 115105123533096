import React, { FunctionComponent } from 'react'

import { tDefaults, tEntries } from '../../../infrastructure/internationalisation'
import { Formatter } from '../types'

export const SingleSelectFormatter: FunctionComponent<Formatter<string>> = ({
  value,
  input: { meta, name },
  t,
}) => (
  <>
    {value && meta?.selectValues[value]
      ? t<string>(tEntries(name, meta?.selectValues[value]))
      : t<string>(tDefaults('inputs.no-answer'))}
  </>
)
