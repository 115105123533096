import css, { ResponsiveStyleValue } from '@styled-system/css'
import styled from 'styled-components'
import { compose, space, SpaceProps, variant } from 'styled-system'

export type StickyHeaderContainerProperty = 'default' | 'mobile'

export interface StickyHeaderContainerProps extends SpaceProps {
  variant?: ResponsiveStyleValue<StickyHeaderContainerProperty>
}

export const StickyHeaderContainer = styled.div<StickyHeaderContainerProps>(
  css({
    position: 'sticky',
    width: '100%',
    top: 0,
    zIndex: 998,
  }),
  variant({
    variants: {
      default: {
        display: ['none', 'block'],
      },
      mobile: {
        display: ['block', 'none'],
      },
    },
  }),
  compose(space),
)

StickyHeaderContainer.defaultProps = {
  variant: 'default',
}
