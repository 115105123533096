import { useSectionConditions } from '../../../../application/product/proposal/disclosure/conditions'
import { useProposal } from '../../../../application/product/proposal/proposalContext'
import { getCurrentQuote, Proposal } from '../../../../domain'
import {
  DisclosureConfig,
  DisclosureSectionCondition,
  ManagementType,
  PromotionDisclosureConfig,
} from '../../../../state/configuration'

const isConditionallyRelevant = (
  config: { conditions?: DisclosureSectionCondition[] },
  conditions: (it: DisclosureSectionCondition) => boolean,
) =>
  config.conditions
    ? config.conditions
        ?.map((it) => {
          return conditions(it)
        })
        .some((it) => it)
    : true

const isPromotionRelevant = (config: PromotionDisclosureConfig, proposal: Proposal) => {
  const quote = getCurrentQuote(proposal)
  return quote.promoCode && config.promotions[quote.promoCode]
}

export const useRelevantDisclosures = (disclosures: DisclosureConfig[]) => {
  const conditions = useSectionConditions()
  const { proposal } = useProposal()

  return disclosures.filter((it) => {
    switch (it.type) {
      case ManagementType.PROMOTION:
        return isPromotionRelevant(it, proposal)
      default:
        return isConditionallyRelevant(it, conditions)
    }
  })
}
