import { loadStripe } from '@stripe/stripe-js'
import { WriskTheme } from '@wrisk/ui-components'
import { useTheme } from 'styled-components'

import { useAdminUnauthorised, usePrincipal } from '../application/authentication'
import { TKey, useWriskTranslation } from '../infrastructure/internationalisation'
import { pathWithOrigin } from '../util/dom'
import { useApiErrorHandlingAsyncCallback } from './auth'

const tKey = TKey('components.pay-now')

const {
  stripe: { apiKey, stripeAccount },
} = CONFIG

interface StripePayment {
  successPath: string
  cancelPath: string
}

interface StripeSetup extends StripePayment {
  clientReference?: string
}

export const useSettleStripe = ({ successPath, cancelPath }: StripePayment) => {
  const { t } = useWriskTranslation()
  const theme = useTheme() as WriskTheme

  const { apiClient } = usePrincipal()

  const adminUnauthorised = useAdminUnauthorised()

  return useApiErrorHandlingAsyncCallback(
    adminUnauthorised(async (accountId: string) => {
      const stripe = await loadStripe(apiKey, { stripeAccount })

      await stripe?.redirectToCheckout(
        await apiClient.stripeSettleSession({
          accountId,
          cancelUrl: pathWithOrigin(cancelPath),
          successUrl: pathWithOrigin(successPath),
          lineItem: {
            description: t<string>(tKey('line-item.outstanding-description')),
            name: t(tKey('line-item.name')),
            imageUrl: theme.illus.lineItem
              ? pathWithOrigin(theme.illus.lineItem)
              : undefined,
          },
        }),
      )
    }),
  )
}

export const useSetupStripe = ({
  successPath,
  cancelPath,
  clientReference,
}: StripeSetup) => {
  const { apiClient } = usePrincipal()

  const adminUnauthorised = useAdminUnauthorised()

  return useApiErrorHandlingAsyncCallback(
    adminUnauthorised(async () => {
      const stripe = await loadStripe(apiKey, { stripeAccount })

      await stripe?.redirectToCheckout(
        await apiClient.stripeSetupSession({
          clientReference: clientReference,
          cancelUrl: pathWithOrigin(cancelPath),
          successUrl: pathWithOrigin(successPath),
        }),
      )
    }),
  )
}

export const useCheckoutStripe = ({ successPath, cancelPath }: StripePayment) => {
  const { t, i18n } = useWriskTranslation()
  const theme = useTheme() as WriskTheme

  const { apiClient } = usePrincipal()

  const adminUnauthorised = useAdminUnauthorised()

  return useApiErrorHandlingAsyncCallback(
    adminUnauthorised(async (paymentId: string) => {
      const stripe = await loadStripe(apiKey, { stripeAccount })

      await stripe?.redirectToCheckout(
        await apiClient.stripeCheckoutSession({
          paymentId,
          cancelUrl: pathWithOrigin(cancelPath),
          successUrl: pathWithOrigin(successPath),
          lineItem: {
            description: t<string>(tKey('line-item.description')),
            name: t(tKey('line-item.name')),
            imageUrl: theme.illus.lineItem
              ? pathWithOrigin(theme.illus.lineItem)
              : undefined,
          },
          locale: i18n.language,
        }),
      )
    }),
  )
}
