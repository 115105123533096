import { InvoiceItemCell, mdBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { CustomerStatementSummary } from '../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

export interface BroughtForwardItemProps {
  summary: CustomerStatementSummary
}

const tKey = TKey('components.brought-forward-item')

export const BroughtForwardItem: FunctionComponent<BroughtForwardItemProps> = ({
  summary: { broughtForwardBalance },
}) => {
  const { t } = useWriskTranslation()

  const header = t<string>(tKey(broughtForwardBalance < 0 ? 'credit' : 'debit'))
  const premium = t<string>(tFormats('currency.long'), {
    amount: broughtForwardBalance / 100,
  })

  return broughtForwardBalance !== 0 ? (
    <InvoiceItemCell header={header} premium={premium} px={mdBumps} />
  ) : null
}
