import { isString } from '../util/string'
import { Profile } from './types'

export interface Name {
  firstName: string
  lastName: string
}

export const hasName = ({ data: { firstName, lastName } }: Profile) =>
  isString(firstName) && isString(lastName)

export const getName = (profile: Profile, defaultName = 'No name') =>
  hasName(profile) ? `${profile.data.firstName} ${profile.data.lastName}` : defaultName
