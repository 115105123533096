import { isNil } from 'lodash'
import { default as React, FunctionComponent } from 'react'

import { tDefaults, tEntries } from '../../../infrastructure/internationalisation'
import { Formatter } from '../types'

export const BooleanListFormatter: FunctionComponent<
  Formatter<Record<string, boolean>>
> = ({ value, t, input: { name } }) => {
  if (isNil(value) || !Object.values(value).includes(true)) {
    return <>{t(tDefaults('inputs.none'))}</>
  }

  return (
    <>
      {Object.entries(value)
        .filter(([, option]) => option)
        .map(([key]) => t(tEntries(name, key)))
        .join(', ')}
    </>
  )
}
