import {
  AnimatedPage,
  Banner,
  BannerTitle,
  mdBumps,
  PageLoadingIndicator,
  WideContainer,
  xxlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { useApiErrorHandlingAsync } from '../../hooks/auth'
import { TKey, useWriskTranslation } from '../../infrastructure/internationalisation'
import { getProducts, getSchemes, useConfig } from '../../state/configuration'
import { usePrincipal } from '../authentication'
import { Footer } from '../templates'
import { CreateProposalSections } from './organisms/CreateProposalSections'
import { SignInSection } from './organisms/SignInSection'
import { getProposalInformation, ProposalsView } from './ProposalsView'

const tKey = TKey('pages.home-anonymous')

export const AnonymousHomePage: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  const { apiClient } = usePrincipal()
  const schemes = useConfig(getSchemes)
  const products = useConfig(getProducts)

  const { loading, result: proposals } = useApiErrorHandlingAsync(
    apiClient.getProposals,
    [],
  )

  if (loading || !proposals) {
    return <PageLoadingIndicator />
  }

  const proposalInformation = getProposalInformation(schemes, products)(proposals)

  return (
    <AnimatedPage pageId='home-page' footer={<Footer />}>
      <Banner>
        <WideContainer flexDirection='column'>
          <BannerTitle>
            <Trans i18nKey={tKey('header')} t={t} />
          </BannerTitle>
        </WideContainer>
      </Banner>
      <WideContainer>
        {Boolean(proposalInformation.length) && (
          <ProposalsView
            proposalInformation={proposalInformation}
            width={1}
            mb={mdBumps}
          />
        )}
        <SignInSection mb={xxlBumps} />
        {proposals.length === 0 && (
          <CreateProposalSections schemes={schemes} proposals={proposals} policies={[]} />
        )}
      </WideContainer>
    </AnimatedPage>
  )
}
