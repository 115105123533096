import React, { FunctionComponent } from 'react'
import styled, { useTheme } from 'styled-components'

import { WriskTheme } from '../../themes'

export const IllusComponent: FunctionComponent<IllusProps> = ({
  className,
  illus,
  alt,
  ...props
}) => {
  const theme = useTheme() as WriskTheme

  return theme.illus[illus] ? (
    <img className={className} alt={alt} src={theme.illus[illus]} {...props} />
  ) : null
}

export interface IllusProps {
  className?: string
  illus: keyof IllusType
  alt?: string
}

export type IllusType = Record<
  | 'bindError'
  | 'bindSuccess'
  | 'cancelSuccess'
  | 'paymentSuccess'
  | 'paymentError'
  | 'lineItem'
  | string,
  string
>

export const Illus = styled(IllusComponent)({
  maxWidth: '100%',
  verticalAlign: 'middle',
})
