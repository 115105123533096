import {
  Box,
  Flex,
  FlexAnchor,
  FlexProps,
  Icon,
  smBumps,
  Typo,
  xsBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Link, useParams } from 'react-router-dom'

import { useRelevantDisclosures } from '../../../../../../hooks/adjustments/proposal/disclosure/relevantDisclosures'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { useProduct } from '../../../../productContext'

const tKey = TKey('components.progress-bar')

export const ProgressBar: FunctionComponent<FlexProps> = (props) => {
  const { t } = useWriskTranslation()

  const { sectionId } = useParams()

  const {
    product: { disclosures },
  } = useProduct()
  const relevantDisclosures = useRelevantDisclosures(disclosures)

  const sectionIndex = relevantDisclosures.findIndex((it) => it.id === sectionId)

  const progressBarItems = relevantDisclosures.map((item, index) => {
    if (index < sectionIndex) {
      return (
        <FlexAnchor
          as={Link}
          to={['..', item.id].join('/')}
          justifyContent='start'
          variant='highlight'
          width={1}
          key={item.id}
          p={xsBumps}
        >
          <Icon icon='tick' size='small' />
          <Typo pl={smBumps}>{t(tKey(item.id))}</Typo>
        </FlexAnchor>
      )
    } else if (sectionIndex === index) {
      return (
        <Flex justifyContent='start' width={1} key={item.id} p={xsBumps}>
          <Icon icon='minus' size='small' />
          <Typo fontWeight='bold' pl={smBumps}>
            {t(tKey(item.id))}
          </Typo>
        </Flex>
      )
    } else {
      return (
        <Flex justifyContent='start' width={1} key={item.id} p={xsBumps}>
          <Box minWidth='1.25rem' />
          <Typo color='inactive' pl={smBumps}>
            {t(tKey(item.id))}
          </Typo>
        </Flex>
      )
    }
  })

  return (
    <Flex flexDirection='column' {...props}>
      {progressBarItems}
    </Flex>
  )
}
