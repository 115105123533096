import css from '@styled-system/css'
import { animated } from 'react-spring'
import styled from 'styled-components'

import { mdBumps } from '../../atoms'

export const AnimatedSnackBar = styled(animated.div)(
  css({
    p: mdBumps,
    mt: mdBumps,
    boxShadow: 'popupMenu',
    borderRadius: 1,
    zIndex: 1001,
    left: '50%',
    position: 'fixed',
    top: 0,
    minWidth: '380px',
    bg: 'chalk',
  }),
)
