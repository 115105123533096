import { AppName, AppNameProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { AppPath } from '../../application/AppPath'
import { TKey, useWriskTranslation } from '../../infrastructure/internationalisation'

const tKey = TKey('components.app-name')

export const AppNameBox: FunctionComponent<AppNameProps> = (props) => {
  const { t } = useWriskTranslation()

  return (
    <AppName as={Link} to={AppPath.HOME} id='homeAction' {...props}>
      {t(tKey('header'), { defaultValue: null })}
    </AppName>
  )
}
