import {
  Box,
  Flex,
  FlexProps,
  Icon,
  mdBumps,
  SecondaryButton,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { useNavigateCallback } from '../../../hooks/navigation'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { AppPath } from '../../AppPath'

const tKey = TKey('components.sign-in')

export const SignInSection: FunctionComponent<FlexProps> = (props) => {
  const { t } = useWriskTranslation()

  const onSignInCallback = useNavigateCallback(AppPath.SIGN_IN)

  const features = t(tKey('features'), { returnObjects: true }) as Record<string, string>

  const featureSections = Object.entries(features).map(([icon, text]) => (
    <Flex
      key={icon}
      flexDirection='column'
      mb={[3, 3, 0]}
      px={mdBumps}
      width={[1 / 2, 1 / 2, 1 / 4]}
    >
      <Icon icon={icon} />
      <Box pt={2} width={1}>
        <Typo typoSize='sm' textAlign='center'>
          {text}
        </Typo>
      </Box>
    </Flex>
  ))

  return (
    <Flex width={1} variant='raised' flexDirection='column' {...props}>
      <Flex width={1} flexWrap='wrap' borderBottomWidth={1}>
        <Box width={[1, 1, 3 / 5]} p={mdBumps}>
          <Typo>{t(tKey('header'))}</Typo>
        </Box>
        <Box width={[1, 1, 2 / 5]} p={mdBumps}>
          <SecondaryButton
            width={1}
            onClick={onSignInCallback}
            data-testid='sign-in-button'
          >
            {t(tKey('actions.sign-in'))}
          </SecondaryButton>
        </Box>
      </Flex>

      <Flex width={1} p={mdBumps} flexWrap='wrap' alignItems='flex-start'>
        {featureSections}
      </Flex>
    </Flex>
  )
}
