import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'

import { Proposal } from '../../../domain'

interface ProposalContextObj {
  proposal: Proposal
  setProposal: (proposal: Proposal) => void
}

const ProposalContext = createContext<ProposalContextObj | undefined>(undefined)

export interface ProposalProviderProps extends PropsWithChildren {
  proposal: Proposal
}

export const ProposalProvider: FunctionComponent<ProposalProviderProps> = (props) => {
  const [proposal, setProposal] = useState(props.proposal)

  return (
    <ProposalContext.Provider value={{ proposal, setProposal }}>
      {props.children}
    </ProposalContext.Provider>
  )
}

export const useProposal = (): ProposalContextObj => {
  const context = useContext(ProposalContext)
  if (!context) {
    throw new Error('Cannot use ProposalContext when undefined.')
  }
  return context
}

export const useMaybeProposal = (): Proposal | undefined =>
  useContext(ProposalContext)?.proposal
