import css from '@styled-system/css'
import {
  PrimaryButton,
  transition,
  TransitionProps,
  Typo,
  WideContainer,
} from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { compose } from 'styled-system'

import { TKeyBuilder } from '../../../infrastructure/internationalisation'
import { usePortal } from '../../../util/portal'

const ErrorContainer = styled.div<TransitionProps>(
  css({
    position: 'fixed',
    width: '100%',
    bottom: 0,
    left: 0,
    py: 6,
    bg: 'chalk',
    boxShadow: 'default',
    zIndex: 200,
  }),
  compose(transition),
)

ErrorContainer.defaultProps = {
  transition: 'default',
}

export interface FormErrorsProps {
  scrollToClass: string
  errorClass: string
  tKey: TKeyBuilder
  t: TFunction
}

export const FormErrorsPortal: FunctionComponent<FormErrorsProps> = ({
  t,
  tKey,
  scrollToClass,
  errorClass,
}) => {
  const portal = usePortal()
  const { formState } = useFormContext()

  const values = useMemo(() => Object.values(formState.errors), [formState])

  const onShowMe = useCallback(() => {
    const [firstError] = document.getElementsByClassName(errorClass)
    firstError?.closest(`.${scrollToClass}`)?.scrollIntoView({ behavior: 'smooth' })
  }, [errorClass, scrollToClass])

  return createPortal(
    values.length > 0 ? (
      <ErrorContainer>
        <WideContainer
          flexDirection='row'
          justifyContent='space-between'
          flexWrap='wrap'
          alignItems='center'
        >
          <Typo fontWeight='bold' pr={4} pb={[4, 4, 0]}>
            {t([tKey('validation.summary'), 'defaults.validation.summary'], {
              count: values.length,
            })}
          </Typo>
          <PrimaryButton mr={13} whiteSpace='nowrap' onClick={onShowMe}>
            {t([tKey('validation.actions.show-me'), 'defaults.actions.show-me'])}
          </PrimaryButton>
        </WideContainer>
      </ErrorContainer>
    ) : null,
    portal,
  )
}
