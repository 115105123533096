import { Name, NameInput } from '@wrisk/ui-components'
import React, { FunctionComponent, useMemo } from 'react'

import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { FormInputProps } from '../types'

const nullName = { firstName: '', lastName: '' }

const tKey = TKey('components.name-form-input')

export const NameFormInput: FunctionComponent<FormInputProps<Name>> = ({
  value,
  onChange,
}) => {
  const { t } = useWriskTranslation()

  const placeholders = useMemo(
    () => ({
      firstName: t(tKey('first-name')),
      lastName: t(tKey('last-name')),
    }),
    [t],
  )

  return (
    <NameInput name={value ?? nullName} placeholders={placeholders} onChange={onChange} />
  )
}
