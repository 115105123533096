import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { with404ErrorHandling } from '../../../../../clients/api'
import { Data } from '../../../../../domain'
import { useConfig } from '../../../../../state/configuration'
import { usePrincipal } from '../../../../authentication'

const DEFAULT_LOOKUPS = {}

export const useLookupsReducer = () => {
  const lookups = useConfig(
    ({ config }) => config.createProposal?.lookups ?? DEFAULT_LOOKUPS,
  )

  const { apiClient } = usePrincipal()
  const [params] = useSearchParams()

  return useCallback<() => Promise<Data>>(
    async () =>
      Object.entries(lookups).reduce(async (acc, [key, tableCode]: [string, string]) => {
        const parameter = params.get(key)
        return parameter
          ? {
              ...(await acc),
              [key]: await with404ErrorHandling(() =>
                apiClient.getDataEntry(tableCode, parameter),
              ),
            }
          : await acc
      }, Promise.resolve({})),
    [apiClient, lookups, params],
  )
}
