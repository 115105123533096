import { useCallback } from 'react'

import { useLookupsReducer, usePassthroughsReducer } from './reducers'

export const useCreateProposalDataReducers = () => {
  const reducers = [usePassthroughsReducer(), useLookupsReducer()]

  return useCallback(
    async () =>
      reducers.reduce(async (data, reducer) => {
        return {
          ...(await data),
          ...(await reducer()),
        }
      }, Promise.resolve({})),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    reducers,
  )
}
