import { useMemo } from 'react'

import { isPolicyRenewalBound, Policy, PolicyStatus } from '../../../../../domain'
import { ProductConfig } from '../../../../../state/configuration'
import { billing, claims, documents, policyDetails } from './tabs'
import { TabItem } from './types'

export const useTabs = (policy: Policy, product: ProductConfig): TabItem[] => {
  return useMemo(() => {
    const tabLoaders = (() => {
      switch (policy.policyDetail.policyStatus) {
        case PolicyStatus.ACTIVE:
          return isPolicyRenewalBound(policy)
            ? [documents, billing, claims]
            : [policyDetails, documents, billing, claims]
        case PolicyStatus.PENDING:
          return [policyDetails, documents, billing, claims]
        case PolicyStatus.CANCELLED:
        case PolicyStatus.LAPSED:
          return [billing, claims]
        case PolicyStatus.VOIDED:
          return [billing]
        case PolicyStatus.RENEWED:
          throw new Error(
            `Cannot show policy ${policy.policyId} as it is in status RENEWED`,
          )
      }
    })()

    return tabLoaders.map((tab) => tab(product)).filter(Boolean) as TabItem[]
  }, [policy, product])
}
