import React, { FunctionComponent } from 'react'

import { ProposalStatus } from '../../../domain'
import { Page } from '../../../infrastructure/routing'
import { useProposal } from '../proposal/proposalContext'
import { RenewalQuotePage } from './RenewalQuotePage'
import { RenewalReferralPage } from './RenewalReferralPage'

export const RenewalSummaryPage: FunctionComponent<{ home: Page }> = ({ home }) => {
  const { proposal } = useProposal()

  switch (proposal.status) {
    case ProposalStatus.POLICY:
    case ProposalStatus.QUOTE:
      return <RenewalQuotePage home={home} />
    case ProposalStatus.REFERRAL:
      return <RenewalReferralPage home={home} />
    case ProposalStatus.QUOTE_EXPIRED:
    case ProposalStatus.INSUFFICIENT_ACCURACY:
      throw new Error(`Renewal unsupported in status ${proposal.status}`)
  }
}
