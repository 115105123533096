import {
  Box,
  Checkbox,
  Flex,
  FlexAnchor,
  mdBumps,
  Typo,
  xxlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Quote } from '../../../../../domain'
import { useCancelPolicyAdjustment } from '../../../../../hooks/actions/cancel'
import { useNavigateCallback } from '../../../../../hooks/navigation'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { Page } from '../../../../../infrastructure/routing'
import { AppPath } from '../../../../AppPath'
import { ActionBar } from '../../../../organisms/form'
import { ReferralReasons } from '../../../../organisms/quote'
import { FullPage } from '../../../../templates'

const tKey = TKey('pages.referred-adjustment')

interface ReferredPolicyAdjustmentPageProps {
  quote: Quote
  adjustmentPage: Page
}

export const ReferredPolicyAdjustmentPage: FunctionComponent<
  ReferredPolicyAdjustmentPageProps
> = ({ quote, adjustmentPage }) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()

  const [cancellationCheck, setCancellationCheck] = useState(false)
  const onConfirmClick = useCallback(
    () => setCancellationCheck((previous) => !previous),
    [setCancellationCheck],
  )

  const onSubmit = useCancelPolicyAdjustment('Referral', undefined, () =>
    navigate(AppPath.HOME),
  )
  const onDismiss = useNavigateCallback(adjustmentPage.url)

  return (
    <FullPage pageId='referred-adjustment-page' header={t(tKey('header'))}>
      <Box mb={xxlBumps}>
        <Typo as='pre'>{t(tKey('subheader'))}</Typo>
      </Box>
      <ReferralReasons quote={quote} mb={xxlBumps} width={1} />
      <Flex
        mb={xxlBumps}
        width={1}
        py={2}
        flexWrap='wrap'
        variant='raised'
        flexDirection='column'
      >
        <FlexAnchor
          key='cancellation'
          as='label'
          htmlFor='cancellation'
          justifyContent='flex-start'
          width={1}
          variant='inset'
          px={mdBumps}
          py={3}
        >
          <Checkbox
            id='cancellation'
            mr={4}
            value={cancellationCheck}
            onChange={onConfirmClick}
          />
          <Typo>{t(tKey('agreement.cancellation'))}</Typo>
        </FlexAnchor>
      </Flex>
      <Flex width={1}>
        <ActionBar
          tKey={tKey}
          loading={onSubmit.loading}
          onConfirm={onSubmit.execute}
          onDismiss={onDismiss}
          disabled={!cancellationCheck}
          destructive={true}
        ></ActionBar>
      </Flex>
    </FullPage>
  )
}
