import React, {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  useCallback,
  useState,
} from 'react'

import { InputWrapper, InputWrapperProps, TextInput } from '../atoms'
import {
  formatPartialLicencePlate,
  isValidLicencePlate,
  useNotifyingState,
} from '../extensions'

export const useLicencePlate = (
  initialLicencePlate = '',
  notifier: (value: string) => void,
): [string, boolean, (s: string) => void] => {
  const [licencePlate, setLicencePlate] = useNotifyingState(initialLicencePlate, [
    notifier,
  ])
  return [licencePlate, isValidLicencePlate(licencePlate), setLicencePlate]
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface LicencePlateInputProps extends Omit<InputWrapperProps, 'onChange'> {
  disabled?: boolean
  initialValue?: string
  onChange: (licencePlate: string) => void
  onEnterPress?: (licencePlate?: string) => void
  placeholder?: string
}

/* eslint-enable @typescript-eslint/no-explicit-any */

export const LicencePlateInput: FunctionComponent<LicencePlateInputProps> = ({
  disabled,
  initialValue,
  onChange,
  placeholder,
  onEnterPress,
  name,
  ...props
}) => {
  const [value, setValue] = useState(initialValue ?? '')

  const onInputChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const formattedValue = formatPartialLicencePlate(target.value)
      setValue(formattedValue)
      onChange(formattedValue)
    },
    [onChange],
  )

  const onInputKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event?.preventDefault?.()
        onEnterPress?.(value)
      }
    },
    [value, onEnterPress],
  )

  return (
    <InputWrapper {...props}>
      <TextInput
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onInputChange}
        onKeyPress={onInputKeyPress}
        variant='licencePlate'
        width={1}
        typoSize='lg'
      />
      {props.children}
    </InputWrapper>
  )
}
