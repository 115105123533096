import React, { FunctionComponent, PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'

import { PolicyStatus } from '../../../domain'
import { AppPath } from '../../AppPath'
import { usePolicy } from './policyContext'
import { PolicyActiveRoutes, PolicyEndedRoutes } from './routes'

export const PolicyRouter: FunctionComponent<PropsWithChildren> = () => {
  const {
    policy: { policyDetail },
  } = usePolicy()

  switch (policyDetail.policyStatus) {
    case PolicyStatus.ACTIVE:
    case PolicyStatus.PENDING:
      return <PolicyActiveRoutes />

    case PolicyStatus.VOIDED:
    case PolicyStatus.CANCELLED:
    case PolicyStatus.LAPSED:
      return <PolicyEndedRoutes />

    case PolicyStatus.RENEWED:
      return <Navigate to={AppPath.HOME} />
  }
}
