import { Box, BoxProps, Flex, Heading, mdBumps, smBumps } from '@wrisk/ui-components'
import { negate } from 'lodash'
import React from 'react'

import { getCoreAsset, getExcessComparison, isMainExcess, Quote } from '../../../domain'
import {
  tExcesses,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { ExcessRow } from './ExcessRow'

export interface OtherExcessProps extends BoxProps {
  currentQuote: Quote
  previousQuote?: Quote
}

const tKey = TKey('components.other-excesses')

export const OtherExcesses: React.FC<OtherExcessProps> = ({
  currentQuote,
  previousQuote,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const currentExcesses = getCoreAsset(currentQuote).mandatoryExcessLines.filter(
    negate(isMainExcess),
  )

  const previousExcesses = previousQuote
    ? getCoreAsset(previousQuote).mandatoryExcessLines.filter(negate(isMainExcess))
    : []

  const excessComparisons = getExcessComparison(previousExcesses, currentExcesses)

  return excessComparisons.length ? (
    <Box width={1} {...props}>
      <Box mb={smBumps}>
        <Heading typoSize='md'>{t(tKey('header'))}</Heading>
      </Box>
      <Flex
        variant='raised'
        flexDirection='column'
        width={1}
        px={mdBumps}
        py={5}
        rowGap={mdBumps}
      >
        {excessComparisons.map((it, index) => (
          <ExcessRow
            key={`${it.category}-${index}`}
            header={t(tExcesses(it.category, 'name'))}
            currentExcess={it.current}
            previousExcess={it.previous}
          />
        ))}
      </Flex>
    </Box>
  ) : null
}
