import css, { ResponsiveStyleValue } from '@styled-system/css'
import styled from 'styled-components'
import { compose, typography, TypographyProps, variant } from 'styled-system'

import { TypoSizeProps, typoSizes } from './typoSizes'

export type StyledPremiumProperty = 'default' | 'inverted'

export interface StyledPremiumProps extends TypoSizeProps, TypographyProps {
  variant?: ResponsiveStyleValue<StyledPremiumProperty>
}

export const StyledPremium = styled.div<StyledPremiumProps>(
  css({
    display: 'inline-block',
    whiteSpace: 'nowrap',
    my: -1,
    py: 1,
  }),
  variant({
    scale: 'StyledPremium',
    variants: {
      default: {},
      inverted: {
        backgroundColor: 'body',
        color: 'chalk',
        px: 2,
        borderRadius: 2,
      },
    },
  }),
  compose(typoSizes, typography),
)

StyledPremium.defaultProps = {
  variant: 'default',
  typoSize: 'base',
}
