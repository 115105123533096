// eslint-disable-next-line import/no-unresolved
import { Properties } from 'csstype'
import { system } from 'styled-system'

export interface CollapseProps {
  collapseWhenEmpty?: boolean
}

export const collapse = system({
  collapseWhenEmpty: {
    property: '&:empty' as keyof Properties,
    transform: (value: boolean) =>
      value
        ? {
            marginTop: 0,
            marginBottom: 0,
          }
        : {},
  },
})
