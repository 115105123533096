import { omit } from 'lodash'

import { FileUploadActions, hasFile, RejectFiles } from './actions'

export interface FileUploadState {
  accepted: File[]
  rejected: Array<RejectFiles>
  progress: Record<string, number>
  loading: boolean
}

export const reducer = (state: FileUploadState, { type, payload }: FileUploadActions) => {
  switch (type) {
    case 'ADD_FILES':
      return {
        ...state,
        accepted: [...state.accepted, ...payload],
        progress: {
          ...state.progress,
          ...payload.reduce((acc, it) => ({ ...acc, [it.name]: 0 }), {}),
        },
        loading: true,
      }
    case 'REJECT_FILES':
      return {
        ...state,
        rejected: payload,
      }
    case 'CLEAR_REJECTIONS':
      return {
        ...state,
        rejected: [],
      }
    case 'ADDED_FILES':
      return {
        ...state,
        loading: false,
      }
    case 'REMOVE_FILE':
      return {
        ...state,
        accepted: state.accepted.filter((it) => it.name !== payload.name),
        rejected: [],
        progress: omit(state.progress, payload.name),
      }
    case 'UPDATE_PROGRESS':
      return {
        ...state,
        progress: {
          ...state.progress,
          [payload.file.name]: payload.percentage,
        },
      }
    case 'EXCEPTION':
      return {
        ...state,
        accepted: state.accepted.filter(hasFile(payload)),
      }
    default:
      return state
  }
}
