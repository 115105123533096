import { get, isNil } from 'lodash'
import { useMemo } from 'react'

import { hasPolicyStarted } from '../../domain'
import {
  InputValueCondition,
  PolicyStartedCondition,
  ProductCondition,
} from '../../state/configuration'
import { useMaybePolicy } from './policy/policyContext'

const useInputValueCondition = (formData: Record<string, unknown>) => {
  return useMemo(
    () => (condition: InputValueCondition) =>
      condition.isDefined === !isNil(get(formData, condition.name)),
    [formData],
  )
}

const usePolicyStartedCondition = () => {
  const policy = useMaybePolicy()

  return useMemo(
    () => (condition: PolicyStartedCondition) => {
      return condition.hasPolicyStarted === (policy && hasPolicyStarted(policy))
    },
    [policy],
  )
}

export const useProductConditions = (data: Record<string, unknown> = {}) => {
  const inputValueCondition = useInputValueCondition(data)
  const policyStartedCondition = usePolicyStartedCondition()

  return useMemo(
    () => (it: ProductCondition) => {
      switch (it.type) {
        case 'input':
          return inputValueCondition(it as InputValueCondition)
        case 'policyStarted':
          return policyStartedCondition(it as PolicyStartedCondition)
        default:
          return false
      }
    },
    [inputValueCondition, policyStartedCondition],
  )
}
