import React, { FunctionComponent, useCallback } from 'react'

import { Specification } from '../../../../domain'
import { VehicleCard, VehicleLookupWrapper } from '../../../organisms/form'
import { FormInputProps } from '../../types'
import { NULL_VEHICLE, VehicleInputProps } from './types'

export const VehicleLookupFormInput: FunctionComponent<
  FormInputProps<VehicleInputProps>
> = ({ t, tKey, tName, value, onChange, meta }) => {
  const onChangeButtonClick = useCallback(() => {
    onChange(NULL_VEHICLE)
  }, [onChange])

  const onVehicleSelect = useCallback(
    (specification?: Specification, vrn?: string) => {
      if (specification) {
        onChange({
          specification,
          vrn,
          regLookup: true,
        })
      } else {
        onChange(NULL_VEHICLE)
      }
    },
    [onChange],
  )

  return value?.specification ? (
    <VehicleCard
      showReg={meta?.showReg}
      specification={value?.specification}
      onChangeButtonClick={onChangeButtonClick}
    />
  ) : (
    <VehicleLookupWrapper
      onLookupComplete={onVehicleSelect}
      value={value?.specification}
      error={t(tKey(tName, 'validation', 'notFoundLookup'))}
    />
  )
}
