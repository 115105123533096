import * as React from 'react'
import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { SnackBar } from './SnackBar'

export interface SnackBarContextProps {
  content: string | ReactElement
}

interface SnackBarContextObj {
  show: (props: SnackBarContextProps) => void
}

const SnackBarContext = createContext<SnackBarContextObj>({ show: () => undefined })

export const SnackBarProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [props, setProps] = useState<SnackBarContextProps>()

  const show = useMemo(
    () => (newProps: SnackBarContextProps) => {
      setProps(newProps)
      setOpen(true)
    },
    [],
  )
  const hide = useMemo(
    () => () => {
      setOpen(false)
    },
    [],
  )
  useEffect(() => {
    const timer = open ? setTimeout(hide, 4000) : undefined
    return () => timer && clearTimeout(timer)
  }, [open, hide])

  return (
    <SnackBarContext.Provider value={{ show }}>
      {children}
      <SnackBar open={open}>{props?.content}</SnackBar>
    </SnackBarContext.Provider>
  )
}

export const useSnackBar = (): SnackBarContextObj => useContext(SnackBarContext)
