import { FlexProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { Proposal, Quote } from '../../../../domain'
import { ProductConfig, ProductKind } from '../../../../state/configuration'
import {
  AnnualAdjustedPremium,
  DriveawayAdjustedPremium,
  SubscriptionAdjustedPremium,
} from '../../../organisms/pricing'

export interface PricingAdjustmentFactoryProps extends FlexProps {
  product: ProductConfig
  proposal: Proposal
  adjustedQuote: Quote
  originalQuote: Quote
}

export const ProposalAdjustmentPricing: FunctionComponent<
  PricingAdjustmentFactoryProps
> = ({ product, proposal, adjustedQuote, originalQuote, ...props }) => {
  switch (product.productKind) {
    case ProductKind.ANNUAL:
      return (
        <AnnualAdjustedPremium
          currentQuote={adjustedQuote}
          previousQuote={originalQuote}
          product={product}
          {...props}
        />
      )
    case ProductKind.SUBSCRIPTION:
      return (
        <SubscriptionAdjustedPremium
          currentQuote={adjustedQuote}
          previousQuote={originalQuote}
          product={product}
          {...props}
        />
      )
    case ProductKind.DRIVEAWAY:
      return (
        <DriveawayAdjustedPremium
          currentQuote={adjustedQuote}
          previousQuote={originalQuote}
          product={product}
          policyTerm={proposal.policyTerm}
          {...props}
        />
      )
  }
}
