import { isNil } from 'lodash'
import { DateTime } from 'luxon'

import { DateInputs } from './types'

export const fromIsoDateToInputs = (date: string | undefined): DateInputs => {
  if (isNil(date)) {
    return {
      day: undefined,
      month: undefined,
      year: undefined,
    }
  }

  const dateTime = DateTime.fromISO(date)

  return {
    day: dateTime.isValid ? dateTime.toFormat('dd') : undefined,
    month: dateTime.isValid ? dateTime.toFormat('MM') : undefined,
    year: dateTime.isValid ? dateTime.toFormat('yyyy') : undefined,
  }
}

export const fromInputsToIsoDate: (dateInputs: DateInputs) => string | undefined = (
  inputs,
) => {
  if (
    inputs.day === undefined ||
    inputs.month === undefined ||
    inputs.year === undefined
  ) {
    return undefined
  }

  if (inputs.year.length < 4) {
    return undefined
  }

  return `${inputs.year}-${inputs.month.padStart(2, '0')}-${inputs.day.padStart(2, '0')}`
}
