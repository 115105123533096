import {
  CascadingFilterInput,
  CascadingFilterInputConfigItem,
  CascadingFilterInputOption,
} from '@wrisk/ui-components'
import { isNil, negate, uniq } from 'lodash'
import React, { FunctionComponent, useEffect } from 'react'

import { ApiClient } from '../../../../../clients/api'
import {
  Specification,
  toVehicleEngine,
  toVehicleEnginePower,
  toVehicleManufacturePeriod,
  toVehicleYearRange,
} from '../../../../../domain'
import { useApiErrorHandlingAsync } from '../../../../../hooks/auth'
import { usePrincipal } from '../../../../authentication'

interface Props {
  onSelect: (specification?: Specification) => void
  initialValue?: Specification
  year: number
  make: string
  model: string
}

const dataLookup = (client: ApiClient, year: number, make: string, model: string) =>
  client.lookupVehicles(year, make, model)

const createConfigItem = (
  id: string,
  placeholder: string,
  expression: (vehicle: Specification) => string | null | undefined,
  comparator: (a: string, b: string) => number = (a, b) => a.localeCompare(b),
): CascadingFilterInputConfigItem<Specification> => ({
  id,
  placeholder,
  mapItemsToOptions: (vehicles: Specification[]) => {
    const attributes = uniq(vehicles.map(expression))
    if (attributes.some(isNil)) {
      return []
    }
    return attributes
      .filter(negate(isNil))
      .sort(comparator)
      .map((id: string) => ({ id, text: id }))
  },
  matchItemToOption:
    ({ id }: CascadingFilterInputOption) =>
    (vehicle: Specification) =>
      expression(vehicle) === id,
  matchOptionToItem:
    (vehicle: Specification) =>
    ({ id }: CascadingFilterInputOption) =>
      expression(vehicle) === id,
})

const config = [
  createConfigItem('vehicle-fuel', 'Fuel', (vehicle) => vehicle.primaryFuel),
  createConfigItem('vehicle-body', 'Body style', (vehicle) => vehicle.bodyStyle),
  createConfigItem(
    'vehicle-trim',
    'Trim',
    (vehicle) => vehicle.trim ?? 'None of the above',
    (a, b) => (b === 'None of the above' ? -1 : a.localeCompare(b)),
  ),
  createConfigItem(
    'vehicle-transmission',
    'Transmission',
    (vehicle) => vehicle.transmission,
  ),
  createConfigItem('vehicle-drive-chain', 'Drive train', (vehicle) => vehicle.driveTrain),
  createConfigItem('vehicle-engine', 'Engine', toVehicleEngine),
  createConfigItem('vehicle-power', 'Engine power', toVehicleEnginePower),
  createConfigItem('vehicle-seats', 'Number of seats', (vehicle) =>
    vehicle.numberOfSeats?.toString(),
  ),
  createConfigItem('vehicle-year-range', 'Year range', toVehicleYearRange, (a, b) =>
    b.localeCompare(a),
  ),
  createConfigItem(
    'vehicle-secondary-fuel',
    'Secondary fuel',
    (vehicle) => vehicle.secondaryFuel ?? 'None',
  ),
  createConfigItem(
    'vehicle-electric-type',
    'Electric vehicle type',
    (vehicle) => vehicle.electricVehicleType ?? 'None',
  ),
  createConfigItem(
    'vehicle-manufacture-period',
    'Manufacture period',
    toVehicleManufacturePeriod,
  ),
  createConfigItem(
    'vehicle-aspiration',
    'Aspiration',
    (vehicle) => vehicle.aspirationType,
  ),
]

export const VehicleResultsFilter: FunctionComponent<Props> = ({
  onSelect,
  year,
  make,
  model,
  initialValue,
}) => {
  const { apiClient } = usePrincipal()

  const asyncDataEntries = useApiErrorHandlingAsync(dataLookup, [
    apiClient,
    year,
    make,
    model,
  ])

  useEffect(() => {
    if (asyncDataEntries.result?.length === 1) {
      onSelect(asyncDataEntries.result[0])
    }
  }, [asyncDataEntries.result, onSelect])

  return asyncDataEntries.result ? (
    <CascadingFilterInput
      value={initialValue}
      items={asyncDataEntries.result}
      onSelect={onSelect}
      config={config}
      errorMessage={
        "We couldn't determine the insurance group for your vehicle. Please get in touch via chat so we can fix this."
      }
    />
  ) : null
}
