import { AxiosInstance, AxiosResponse } from 'axios'

import { Policyholder } from '../../../domain'
import { withGenericErrorHandling } from '../errors'

export interface PolicyholderClient {
  getPolicyholder: () => Promise<Policyholder>
  updatePolicyholder: (policyholder: Partial<Policyholder>) => Promise<Policyholder>
}

export const createPolicyholderClient = (
  getInstance: () => Promise<AxiosInstance>,
): PolicyholderClient => ({
  getPolicyholder: () =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response: AxiosResponse<Policyholder> = await instance.get(`/policyholder`)
      return response.data
    }),
  updatePolicyholder: (policyholder) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response: AxiosResponse<Policyholder> = await instance.patch(
        `/policyholder`,
        policyholder,
      )
      return response.data
    }),
})
