import { Illus, PrimaryButton } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { useDependencies } from '../../../../state'
import { ExternalLink } from '../../../organisms/links'
import { FullPage } from '../../../templates'

const tKey = TKey('pages.payment-result-error')

export const PaymentResultErrorPage: FunctionComponent = () => {
  const { chatClient } = useDependencies()

  const { t } = useWriskTranslation()

  return (
    <FullPage
      pageId='checkout-page'
      header={t(tKey('header'))}
      subheader={
        <Trans
          t={t}
          i18nKey={tKey('subheader')}
          components={{
            ExternalLink: <ExternalLink />,
          }}
        />
      }
      upperHeader={<Illus alt='Failure' illus='bindError' />}
    >
      <PrimaryButton layout='fixed' type='submit' onClick={chatClient.show}>
        {t(tKey('actions.continue'))}
      </PrimaryButton>
    </FullPage>
  )
}
