import React, { FunctionComponent } from 'react'

import { tDefaults, tEntries } from '../../../infrastructure/internationalisation'
import { CheckboxListMeta, CheckboxListValues } from '../inputs'
import { Formatter } from '../types'

export const CheckboxListFormatter: FunctionComponent<
  Formatter<CheckboxListValues, CheckboxListMeta>
> = ({ value, input: { name, meta }, t }) => {
  const selections = meta?.items
    .filter((it) => value?.[it])
    .map((it) => t(tEntries(name, it)))
  const text = selections?.length ? selections.join(', ') : t(tDefaults('inputs.none'))
  return <>{text}</>
}
