import { CookieConsent } from '@wrisk/ui-components'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import { Trans } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'

import { AppPath } from '../application/AppPath'
import { usePrincipal } from '../application/authentication'
import { useLinks } from '../application/organisms/links/hooks'
import { TKey, useWriskTranslation } from '../infrastructure/internationalisation'
import { AdminNav } from './AdminNav'
import { UserNav } from './UserNav'

const tKey = TKey('components.cookie-consent')

const UserNavRoutes: FunctionComponent = () => (
  <Routes>
    <Route
      key='retrieve'
      path={AppPath.RETRIEVE}
      element={<UserNav accountNavEnabled={false} />}
    />
    <Route key='default' path='*' element={<UserNav accountNavEnabled={true} />} />
  </Routes>
)

export interface BasePageProps extends PropsWithChildren {
  cookieDomain: string
}

export const BasePage: FunctionComponent<BasePageProps> = ({
  cookieDomain,
  children,
}) => {
  const { t } = useWriskTranslation()
  const { isAdmin, isAuthenticated } = usePrincipal()
  const links = useLinks()

  const Nav = isAdmin ? AdminNav : UserNavRoutes

  const content = <Trans t={t} i18nKey={tKey('content')} components={links} />

  return (
    <>
      <Nav />
      {children}
      {!isAuthenticated && !isAdmin && (
        <CookieConsent
          header={t(tKey('header'))}
          content={content}
          acceptAction={t(tKey('actions.accept'), { defaultValue: null })}
          dismissAction={t(tKey('actions.dismiss'), { defaultValue: null })}
          cookieDomain={cookieDomain}
        />
      )}
    </>
  )
}
