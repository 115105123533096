import { AxiosInstance } from 'axios'

import { CustomerStatement, CustomerStatementSummary } from '../../../domain'
import { withGenericErrorHandling } from '../errors'

export interface CustomerStatementClient {
  getStatementSummaries: (accountId: string) => Promise<CustomerStatementSummary[]>
  getCustomerStatement: (
    accountId: string,
    statementNumber: number,
  ) => Promise<CustomerStatement>
  getNextStatement: (accountId: string) => Promise<CustomerStatement | undefined>
}

export const createCustomerStatementClient = (
  getInstance: () => Promise<AxiosInstance>,
): CustomerStatementClient => ({
  getStatementSummaries: (accountId) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.get<CustomerStatementSummary[]>(
        `/customerAccounts/${accountId}/statements`,
      )
      return response.data
    }),
  getCustomerStatement: (accountId, statementNumber) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.get<CustomerStatement>(
        `/customerAccounts/${accountId}/statements/${statementNumber}`,
      )
      return response.data
    }),
  getNextStatement: (accountId) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.get<CustomerStatement[]>(
        `/customerAccounts/${accountId}/nextStatementEstimate`,
      )
      return response.data[0]
    }),
})
