import { Box, BoxProps, ModalHeader, Typo, useModal } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'

import { with410ErrorHandling } from '../../../../../clients/api'
import { CustomerPayment, PolicyAdjustmentResponse } from '../../../../../domain'
import { useErrorHandlingAsyncCallback } from '../../../../../hooks/errors'
import { useNavigateCallback } from '../../../../../hooks/navigation'
import { useCheckoutStripe } from '../../../../../hooks/stripe'
import {
  TExtends,
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { Page, useCurrentPath } from '../../../../../infrastructure/routing'
import { usePrincipal } from '../../../../authentication'
import { ActionBar } from '../../../../organisms/form'
import { usePolicy } from '../../policyContext'
import { PolicyAdjustmentResultPath } from './PolicyAdjustmentResultPath'

interface AdjustmentActionBarProps extends BoxProps {
  payment: CustomerPayment | undefined
  adjustmentResponse: PolicyAdjustmentResponse
  parent: Page
}

const tKey = TKey('pages.adjustment-action-bar')

export const AdjustmentActionBar: FunctionComponent<AdjustmentActionBarProps> = ({
  payment,
  adjustmentResponse,
  parent,
  ...props
}) => {
  const path = useCurrentPath()
  const navigate = useNavigate()

  const modal = useModal()
  const { t } = useWriskTranslation()
  const { apiClient } = usePrincipal()
  const { setPolicy } = usePolicy()

  const { adjustmentId, policyId } = adjustmentResponse

  const onStripePayment = useCheckoutStripe({
    successPath: [path, PolicyAdjustmentResultPath.RESULT].join('/'),
    cancelPath: parent.url,
  })

  const onConfirm = useErrorHandlingAsyncCallback(() =>
    with410ErrorHandling(
      async () => {
        if (payment) {
          await onStripePayment.execute(payment.paymentId)
        } else {
          await apiClient.confirmPolicyAdjustment(adjustmentId)
          setPolicy(await apiClient.getPolicy(policyId))
          navigate(PolicyAdjustmentResultPath.RESULT)
        }
      },
      () =>
        modal.show({
          content: (
            <Box>
              <ModalHeader header={t(tKey('error-gone', 'header'))} />
              <Typo>{t(tKey('error-gone', 'content'))}</Typo>
            </Box>
          ),
        }),
    ),
  )

  const onDismiss = useNavigateCallback(parent.url)

  const actionTKey = payment?.amount ?? 0 > 0 ? TExtends(tKey, 'debit') : tKey

  return (
    <Box width={1} {...props}>
      <ActionBar
        tKey={actionTKey}
        onConfirm={onConfirm.execute}
        onDismiss={onDismiss}
        loading={onConfirm.loading}
      />
    </Box>
  )
}
