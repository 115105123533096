import { ExpandableSection, Flex, LinkAnchor, Typo } from '@wrisk/ui-components'
import { isNil } from 'lodash'
import React, { FunctionComponent, useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { Quote } from '../../../../domain'
import { toQuoteData } from '../../../../hooks/adjustments/proposal/disclosure/data'
import {
  tDefaults,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { QuoteManagementConfig } from '../../../../state/configuration'
import { getFormatter } from '../../../formBuilder'
import { ProposalPath } from '../../proposal/ProposalPath'
import { RenewalPath } from '../RenewalPath'

export interface PolicyManagementSectionProps {
  config: QuoteManagementConfig
  quote: Quote
}

const tKey = TKey('components.policy-management')

export const QuoteManagement: FunctionComponent<PolicyManagementSectionProps> = ({
  quote,
  config,
}) => {
  const navigate = useNavigate()

  const { t } = useWriskTranslation()

  const onUpdateClick = useCallback(
    (e) => {
      e.stopPropagation()
      navigate(
        [
          ProposalPath.DISCLOSURE,
          generatePath(RenewalPath.SECTION, { sectionId: config.id }),
        ].join('/'),
      )
    },
    [config, navigate],
  )

  const toData = toQuoteData(quote)

  const [firstInput] = config.inputs
  const Formatter = getFormatter(firstInput.type)

  const expandedComponents = config.inputs
    .filter(({ adjustment }) => !isNil(toData(adjustment)))
    .map((input) => {
      const Formatter = getFormatter(input.type)

      return (
        <Flex
          key={input.name}
          px={4}
          py={3}
          flexDirection='column'
          alignItems='flex-start'
        >
          <Typo typoSize='xs' color='bodySecondary' mb={1}>
            {t(tDefaults('product.headers', input.name))}
          </Typo>
          <Typo as='pre'>
            <Formatter input={input} value={toData(input.adjustment)} t={t} />
          </Typo>
        </Flex>
      )
    })

  return (
    <ExpandableSection
      p={2}
      variant='raised'
      header={
        <Flex>
          <Typo fontWeight='bold'>
            <Formatter input={firstInput} value={toData(firstInput.adjustment)} t={t} />
          </Typo>
          <Flex>
            <LinkAnchor
              variant='standalone'
              position='relative'
              zIndex={1000}
              typoSize='sm'
              onClick={onUpdateClick}
            >
              {t([tKey('actions.update'), tDefaults('actions.update')])}
            </LinkAnchor>
          </Flex>
        </Flex>
      }
    >
      {expandedComponents}
    </ExpandableSection>
  )
}
