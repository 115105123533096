export enum ClaimStatus {
  ACTIVE = 'ACTIVE',
  SETTLED = 'SETTLED',
  PENDING = 'PENDING',
}

export interface Claim {
  claimType: string
  insuredName?: string
  status: ClaimStatus
  occurredDate: string
  claimReference: string
}
