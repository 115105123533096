import { Box, ModalHeader } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { PromotionTerms } from './PromotionTerms'

export interface PromotionTermsModalProps {
  promoCode: string
}

const tKey = TKey('components.promotion-terms-modal')

export const PromotionTermsModal: FunctionComponent<PromotionTermsModalProps> = (
  props,
) => {
  const { t } = useWriskTranslation()

  const { promoCode } = props

  const subheader = t(tKey(promoCode, 'subheader'), { defaultValue: null })
  const header = t(tKey(promoCode, 'header'))
  return (
    <Box>
      <ModalHeader header={header} subheader={subheader} />
      <PromotionTerms promoCode={promoCode} />
    </Box>
  )
}
