import { ResponsiveStyleValue } from '@styled-system/css'
import styled from 'styled-components'
import {
  color,
  ColorProps,
  compose,
  display,
  DisplayProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from 'styled-system'

import { defaultConfig } from '../../extensions'
import { cursor, CursorProps } from './cursor'
import { textTransform, TextTransformProps } from './textTransform'
import { TypoSizeProps, typoSizes } from './typoSizes'
import { whiteSpace, WhiteSpaceProps } from './whiteSpace'
import { wordBreak, WordBreakProps } from './wordBreak'
import { wordWrap, WordWrapProps } from './wordWrap'

export type TypoProperty = 'default' | 'heading' | 'buttons'

export interface TypoProps
  extends TypoSizeProps,
    TypographyProps,
    ColorProps,
    SpaceProps,
    WhiteSpaceProps,
    TextTransformProps,
    CursorProps,
    DisplayProps,
    WordBreakProps,
    WordWrapProps {
  variant?: ResponsiveStyleValue<TypoProperty>
}

export const Typo = styled.div.withConfig(defaultConfig)<TypoProps>(
  variant({
    scale: 'Typo',
    variants: {
      default: {},
      heading: {
        fontFamily: 'header',
      },
      buttons: {
        fontFamily: 'buttons',
      },
    },
  }),
  typoSizes,
  compose(
    whiteSpace,
    wordWrap,
    wordBreak,
    typography,
    color,
    space,
    textTransform,
    cursor,
    display,
  ),
)

Typo.defaultProps = {
  variant: 'default',
  typoSize: 'base',
}
