import { PageLoadingIndicator } from '@wrisk/ui-components'
import React, { FunctionComponent, useLayoutEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { Proposal } from '../../domain'
import { useApiErrorHandlingAsync } from '../../hooks/auth'
import { useContinueProposalCallback } from '../../hooks/proposal'
import { useRequiredParams } from '../../infrastructure/routing'
import { AppPath } from '../AppPath'
import { usePrincipal } from '../authentication'

const RedirectToProposal: FunctionComponent<{ proposal: Proposal }> = ({ proposal }) => {
  const onContinueProposal = useContinueProposalCallback(proposal)
  useLayoutEffect(onContinueProposal, [onContinueProposal])
  return null
}

export const RetrieveProposalPage = () => {
  const { externalReference } = useRequiredParams<{ externalReference: string }>()

  const { apiClient } = usePrincipal()

  const asyncProposal = useApiErrorHandlingAsync(async () => {
    const [proposal] = await apiClient.getProposals({ externalReference })
    return proposal
  }, [])

  return asyncProposal.loading ? (
    <PageLoadingIndicator />
  ) : asyncProposal.result ? (
    <RedirectToProposal proposal={asyncProposal.result} />
  ) : (
    <Navigate to={AppPath.HOME} />
  )
}
