import css from '@styled-system/css'

export const defaultButtonStyles = css((theme) => ({
  display: 'inline-block',
  fontFamily: 'buttons',
  fontWeight: 'bold',
  borderRadius: 1,
  lineHeight: '1.5em',
  bg: 'transparent',
  color: 'body',
  border: 0,
  cursor: 'pointer',
  position: 'relative',
  textAlign: 'center',
  '.loading': {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    visibility: 'visible',
  },
  '&:focus': {
    outline: 0,
  },
  ...(theme.Button?.base ?? {}),
}))
