import { RadioButton, RadioButtonGrid } from '@wrisk/ui-components'
import { isNil } from 'lodash'
import React, { FunctionComponent, useCallback, useEffect } from 'react'

import {
  tEntries,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { FormInputProps } from '../types'

export interface RadioInputMeta {
  columns?: number
  values: Record<string, string | number>
  defaultValue?: string | number
}

export const RadioFormInput: FunctionComponent<
  FormInputProps<string | number, RadioInputMeta>
> = ({ meta: { columns, values, defaultValue }, name, value, tName, onChange }) => {
  const { t } = useWriskTranslation()

  const onInputChange = useCallback(({ target }) => onChange(target.value), [onChange])

  useEffect(() => {
    if (isNil(value) && !isNil(defaultValue)) {
      onChange(defaultValue)
    }
  }, [value, defaultValue, onChange])

  return (
    <RadioButtonGrid
      width={1}
      columns={columns ?? Math.ceil(Object.keys(values).length / 2)}
    >
      {Object.entries(values).map(([key, option]) => (
        <RadioButton
          key={option}
          name={name}
          value={option}
          onChange={onInputChange}
          checked={(value ?? defaultValue) === option}
          data-testid={option}
        >
          {t(tEntries(tName, key))}
        </RadioButton>
      ))}
    </RadioButtonGrid>
  )
}
