import { Illus } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { useNavigateCallback } from '../../../../../hooks/navigation'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { Page } from '../../../../../infrastructure/routing'
import { useDependencies } from '../../../../../state'
import { ActionBar } from '../../../../organisms/form'
import { FullPage } from '../../../../templates'

const tKey = TKey('pages.adjustment-error')

interface AdjustmentErrorPageProps {
  policyPage: Page
  verificationClear: boolean
}

export const AdjustmentErrorPage: FunctionComponent<AdjustmentErrorPageProps> = ({
  policyPage,
  verificationClear,
}) => {
  const { t } = useWriskTranslation()

  const onDismiss = useNavigateCallback(policyPage.url)

  const { chatClient } = useDependencies()

  const message = !verificationClear
    ? t(tKey('failedVerificationMessage'))
    : t(tKey('defaultMessage'))

  return (
    <FullPage
      pageId='checkout-error-page'
      header={t(tKey('header'))}
      subheader={message}
      upperHeader={<Illus alt='Error' illus='bindError' />}
    >
      <ActionBar
        onConfirm={chatClient.show}
        onDismiss={onDismiss}
        loading={false}
        tKey={tKey}
      />
    </FullPage>
  )
}
