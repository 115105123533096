import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

export const useUpdateEffect = (func: EffectCallback, deps?: DependencyList) => {
  const firstRenderRef = useRef(false)

  useEffect(() => {
    if (firstRenderRef.current) {
      func()
    } else {
      firstRenderRef.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
