import React, { FunctionComponent, PropsWithChildren } from 'react'

import { Flex, FlexProps } from '../layout'

export type SideSectionProps = FlexProps & PropsWithChildren

export const SideSection: FunctionComponent<SideSectionProps> = (props) => (
  <Flex
    flexDirection='column'
    alignItems='flex-start'
    ml={[0, 0, 8, 10, 12]}
    width={['100%', '100%', 'unset']}
    flexGrow={1}
    {...props}
  />
)
