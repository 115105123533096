import { css } from 'styled-components'

import sansRegular from './assets/fonts/mini_sans_serif-regular-web.woff'
import serifBold from './assets/fonts/mini_serif-bold-web.woff'
import serifItalic from './assets/fonts/mini_serif-italic-web.woff'
import serifRegular from './assets/fonts/mini_serif-regular-web.woff'
import UKNumberPlateEot from './assets/fonts/uknumberplate/UKNumberPlate.eot'
import UKNumberPlateWoff2 from './assets/fonts/uknumberplate/UKNumberPlate.woff2'

const fonts = css`
  @font-face {
    font-family: 'Mini Sans';
    src: url(${sansRegular as never}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Mini Sans';
    src: url(${sansRegular as never}) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Mini Serif';
    src: url(${serifBold as never}) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Mini Serif';
    src: url(${serifItalic as never}) format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Mini Serif';
    src: url(${serifRegular as never}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'UKLicencePlate';
    src: url('${UKNumberPlateEot as never}') format('eot'),
      url('${UKNumberPlateEot as never}') format('eot');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'UKLicencePlate';
    src: url('${UKNumberPlateWoff2 as never}') format('woff2'),
      url('${UKNumberPlateWoff2 as never}') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }
`

export default fonts
