import { DateTime, Info, Interval } from 'luxon'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { Box, Flex } from '../atoms'
import { useNotifyingState } from '../extensions'
import { SelectInput } from './SelectInput'

export interface YearMonthInputProps {
  earliestYear: number
  onChange: (value: string | undefined) => void
  value: string | undefined
  monthsAhead: number
}

interface YearMonth {
  year?: number
  month?: number
}

const fromIsoToYearMonth = (value: string | undefined) => {
  if (!value) return {}
  const { year, month } = DateTime.fromISO(value)
  return { year, month }
}

const fromYearMonthToIso = (value: YearMonth) => {
  if (!value.year || !value.month) return undefined
  return DateTime.fromObject(value).toFormat('yyyy-MM')
}

export const YearMonthInput: FunctionComponent<YearMonthInputProps> = ({
  value,
  onChange,
  earliestYear,
  monthsAhead,
}) => {
  const [yearMonth, setYearMonth] = useNotifyingState<YearMonth>(
    fromIsoToYearMonth(value),
    [(it) => onChange(fromYearMonthToIso(it))],
  )

  const { earliest, latest } = useMemo(
    () => ({
      latest: DateTime.now().plus({ months: monthsAhead }),
      earliest: DateTime.fromObject({ year: earliestYear }),
    }),
    [earliestYear, monthsAhead],
  )

  const { months, years } = useMemo(() => {
    const numberOfMonths = yearMonth.year === latest.year ? latest.month : 12

    const years = Interval.fromDateTimes(earliest, latest)
      .splitBy({ year: 1 })
      .reverse()
      .map((it: Interval<true>) => ({
        value: it.start.year,
        text: it.start.year.toString(),
      }))

    const months = Info.months()
      .slice(0, numberOfMonths)
      .map((it, index) => ({
        value: index + 1,
        text: it,
      }))

    return { years, months }
  }, [yearMonth, earliest, latest])

  const onMonthChange = useCallback(
    (value: number | undefined) =>
      setYearMonth({
        ...yearMonth,
        month: value,
      }),
    [setYearMonth, yearMonth],
  )

  const onYearChange = useCallback(
    (value: number | undefined) =>
      setYearMonth({
        year: value,
        month:
          value === latest.year && (yearMonth.month ?? 0) > latest.month
            ? undefined
            : yearMonth.month,
      }),
    [setYearMonth, latest, yearMonth],
  )

  return (
    <Flex width={1} justifyContent='flex-start'>
      <Box width='240px' mr={2}>
        <SelectInput<number>
          name='month'
          values={months}
          value={yearMonth.month}
          placeholder='Month'
          onChange={onMonthChange}
        />
      </Box>
      <Box width='160px'>
        <SelectInput<number>
          name='year'
          values={years}
          value={yearMonth.year}
          placeholder='Year'
          onChange={onYearChange}
        />
      </Box>
    </Flex>
  )
}
