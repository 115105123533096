import React, { FunctionComponent } from 'react'
import { Route, Routes } from 'react-router-dom'

import { Page } from '../../../../../infrastructure/routing'
import { QuoteManagementConfig } from '../../../../../state/configuration'
import { AppPath } from '../../../../AppPath'
import { PolicyAdjustmentResultRouter } from '../results'
import { PolicyAdjustmentPage } from './PolicyAdjustmentPage'
import { PolicyAdjustmentPath } from './PolicyAdjustmentPath'

export interface PolicyAdjustmentRouterProps {
  adjustmentPage: Page
  policyPage: Page
  config: QuoteManagementConfig
}

export const PolicyAdjustmentRouter: FunctionComponent<PolicyAdjustmentRouterProps> = ({
  adjustmentPage,
  policyPage,
  config,
}) => (
  <Routes>
    <Route
      index
      element={<PolicyAdjustmentPage config={config} parent={adjustmentPage} />}
    />
    <Route
      path={PolicyAdjustmentPath.Adjustment + AppPath.WILDCARD}
      element={
        <PolicyAdjustmentResultRouter
          config={config}
          adjustmentPage={adjustmentPage}
          policyPage={policyPage}
        />
      }
    />
  </Routes>
)
