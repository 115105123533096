import { PageLoadingIndicator } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { useAuth } from '../../hooks/auth'
import { useErrorHandlingAsync } from '../../hooks/errors'

export interface RedirectToSignInProps {
  returnTo?: string
}

export const RedirectToSignIn: FunctionComponent<RedirectToSignInProps> = ({
  returnTo,
}) => {
  const auth = useAuth()
  useErrorHandlingAsync(async () => await auth.signIn(returnTo), [])

  return <PageLoadingIndicator />
}
