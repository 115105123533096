import { isAccountActive } from './customerAccount'
import {
  CustomerAccount,
  PaymentMethod,
  PaymentMethodStatus,
  PaymentMethodType,
} from './types'

export const getUsablePaymentMethod = (
  paymentMethods: PaymentMethod[] | undefined,
  type: PaymentMethodType,
) =>
  paymentMethods?.find(
    (paymentMethod) =>
      paymentMethod.type === type &&
      [
        PaymentMethodStatus.ACTIVE,
        PaymentMethodStatus.PENDING_CUSTOMER_APPROVAL,
      ].includes(paymentMethod.status),
  )

export const hasActiveAccountsWithPaymentMethod = (
  customerAccounts: CustomerAccount[],
  type: PaymentMethodType,
): boolean =>
  customerAccounts.some((it) => isAccountActive(it) && it.paymentMethodType === type)

export const hasMissingPaymentMethod = (
  customerAccounts: CustomerAccount[],
  paymentMethods: PaymentMethod[],
  type: PaymentMethodType,
): boolean =>
  hasActiveAccountsWithPaymentMethod(customerAccounts, type) &&
  !getUsablePaymentMethod(paymentMethods, type)
