export enum PaymentMethodStatus {
  PENDING_CUSTOMER_APPROVAL = 'PENDING_CUSTOMER_APPROVAL',
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
}

export enum PaymentMethodType {
  CARD = 'CARD',
  BACS_DEBIT = 'BACS_DEBIT',
}

export interface PaymentMethod {
  mandateId: string
  reference: string
  status: PaymentMethodStatus
  accountNumberEnding: string
  bankName: string
  type: PaymentMethodType
}
