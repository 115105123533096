import {
  Box,
  Flex,
  FlexProps,
  Icon,
  LinkAnchor,
  mdBumps,
  smBumps,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'

import { getName, Profile } from '../../../../../domain'
import {
  TKeyBuilder,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'

export interface EditProfileFlexProps extends FlexProps {
  tKey: TKeyBuilder
  profile: Profile
  onUpdate: (profile: Profile) => void
  onRemove: (profile: Profile) => void
  loading: boolean
}

const EditProfileFlex: FunctionComponent<EditProfileFlexProps> = ({
  profile,
  onUpdate,
  onRemove,
  loading,
  tKey,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const onUpdateCallback = useCallback(() => onUpdate(profile), [onUpdate, profile])
  const onRemoveCallback = useCallback(() => onRemove(profile), [onRemove, profile])

  return (
    <Flex width={1} px={mdBumps} py={3} {...props}>
      <Flex>
        <Icon icon='account' mr={smBumps} />
        <Typo>{getName(profile)}</Typo>
      </Flex>
      {loading ? (
        <Icon icon='loadingDark' />
      ) : (
        <Flex>
          <Box mr={4}>
            <LinkAnchor variant='standalone' onClick={onUpdateCallback} typoSize='sm'>
              {t(tKey('actions.update'))}
            </LinkAnchor>
          </Box>
          <Box>
            <LinkAnchor variant='standalone' onClick={onRemoveCallback} typoSize='sm'>
              {t(tKey('actions.remove'))}
            </LinkAnchor>
          </Box>
        </Flex>
      )}
    </Flex>
  )
}

export default EditProfileFlex
