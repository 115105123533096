import { PageLoadingIndicator } from '@wrisk/ui-components'
import React, { FunctionComponent, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { usePolicyholderAdjustment } from '../../hooks/adjustments/policyholder'
import {
  TExtends,
  TKey,
  useWriskTranslation,
} from '../../infrastructure/internationalisation'
import { Page, useUpButton } from '../../infrastructure/routing'
import { InputConfig } from '../../state/configuration'
import { usePolicyholder } from '../authentication'
import { FormErrors } from '../formBuilder'
import { FullPage } from '../templates'

const tKey = TKey('pages.account-setting')

export const AccountSetting: FunctionComponent<{ input: InputConfig; parent: Page }> = ({
  input,
  parent,
}) => {
  const navigate = useNavigate()

  const { t } = useWriskTranslation()

  const upButton = useUpButton(parent)
  const { policyholder } = usePolicyholder()

  const inputs = useMemo(() => [input], [input])

  const onSubmit = usePolicyholderAdjustment(() => navigate(parent.url))

  return policyholder ? (
    <FullPage header={t(tKey('headers', input.name))} upButton={upButton}>
      <FormErrors
        id={input.name}
        data={policyholder ?? {}}
        options={{ shouldUnregister: true }}
        inputs={inputs}
        onSubmit={onSubmit.execute}
        loading={onSubmit.loading}
        tKey={TExtends(tKey, 'form')}
        t={t}
      />
    </FullPage>
  ) : (
    <PageLoadingIndicator />
  )
}
