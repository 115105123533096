import { BooleanInput } from '@wrisk/ui-components'
import * as React from 'react'
import { FunctionComponent } from 'react'

import { FormInputProps } from '../types'

export const BooleanFormInput: FunctionComponent<FormInputProps<boolean>> = ({
  name,
  value,
  onChange,
}) => <BooleanInput name={name} value={value} onSelect={onChange} />
