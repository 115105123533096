import {
  Box,
  Flex,
  FlexProps,
  Icon,
  IconButton,
  mdBumps,
  ModalHeader,
  PopupMenu,
  PrimaryButton,
  SecondaryButton,
  Typo,
  useModal,
  xsBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback, useState } from 'react'

import { MenuAnchor } from '../../../BasePage/UserNav/MenuAnchor'
import { isPolicy } from '../../../domain'
import { useErrorHandlingAsyncCallback } from '../../../hooks/errors'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { usePrincipal } from '../../authentication'
import { useProposal } from '../../product/proposal/proposalContext'

const tKey = TKey('components.manage-quote-menu')

export const ManageProposalMenu: FunctionComponent<FlexProps> = () => {
  const { proposal } = useProposal()
  const { t } = useWriskTranslation()

  const modal = useModal()

  const { apiClient } = usePrincipal()

  const [menuVisible, setMenuVisibility] = useState(false)

  const onMenuToggleCallback = useCallback(
    () => setMenuVisibility((previous) => !previous),
    [],
  )

  const onDeleteProposalCallback = useErrorHandlingAsyncCallback(async () => {
    await apiClient.deleteProposal(proposal.proposalCode)
    history.go(0)
  })

  const onOpenModalCallback = useCallback(() => {
    setMenuVisibility(false)
    modal.show({
      content: (
        <Box>
          <ModalHeader
            className='modal-header'
            header={t(tKey('header'))}
            subheader={t(tKey('subheader'))}
          />
          <Flex width={1}>
            <PrimaryButton
              width={1}
              mr={2}
              variant='critical'
              loading={onDeleteProposalCallback.loading}
              onClick={onDeleteProposalCallback.execute}
            >
              {t(tKey('actions.delete'))}
            </PrimaryButton>
            <SecondaryButton width={1} onClick={modal.hide}>
              {t(tKey('actions.cancel'))}
            </SecondaryButton>
          </Flex>
        </Box>
      ),
    })
  }, [modal, t, onDeleteProposalCallback])

  return (
    <Flex
      p={xsBumps}
      pt={mdBumps}
      flexDirection='column'
      justifyContent='flex-start'
      alignItems='flex-end'
    >
      {!isPolicy(proposal) && (
        <IconButton mt={-3} onClick={onMenuToggleCallback}>
          <Icon icon='more' size='small' />
        </IconButton>
      )}
      <PopupMenu
        variant='manageQuote'
        visible={menuVisible}
        onDismiss={onMenuToggleCallback}
      >
        <Box width={[1, 1]} py={2}>
          <MenuAnchor py={2} onClick={onOpenModalCallback}>
            <Typo variant='buttons' typoSize='sm' color='textCritical'>
              {t('components.manage-proposal-menu.actions.delete')}
            </Typo>
          </MenuAnchor>
        </Box>
      </PopupMenu>
    </Flex>
  )
}
