import { SearchView } from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import React, { FunctionComponent, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { ApiClient } from '../../../../clients/api'
import { GetDataEntry, SearchDataEntry } from '../../../../domain'
import {
  useApiErrorHandlingAsync,
  useApiErrorHandlingAsyncCallback,
} from '../../../../hooks/auth'
import { TKeyBuilder } from '../../../../infrastructure/internationalisation'
import { usePrincipal } from '../../../authentication'

export interface LookupSearchViewProps {
  tableCode: string
  tName: string
  tKey: TKeyBuilder
  t: TFunction
  minimumLookupChars: number
  onSelect: (getDataEntry: GetDataEntry) => void
}

const searchData = (
  client: ApiClient,
  tableCode: string,
  searchTerm: string,
  minimumLookupChars: number,
) =>
  searchTerm.length >= minimumLookupChars
    ? client.searchData(tableCode, searchTerm)
    : Promise.resolve(undefined)

const boldSearchResult = (text: string): { __html: string } => ({
  __html: text.replace(/\*(.*?)\*/gm, '<strong>$1</strong>'),
})

const renderListItemContent = ({ highlightedName }: SearchDataEntry) => (
  <span dangerouslySetInnerHTML={boldSearchResult(highlightedName ?? '')} />
)

const LookupSearchView: FunctionComponent<LookupSearchViewProps> = ({
  tableCode,
  tName,
  tKey,
  t,
  minimumLookupChars,
  onSelect,
}) => {
  const { apiClient } = usePrincipal()

  const [searchTerm, setSearchTerm] = useState('')
  const { execute: getDataEntryExecute, loading: getDataEntryLoading } =
    useApiErrorHandlingAsyncCallback(async (searchDataEntry: SearchDataEntry) => {
      const getDataEntry = await apiClient.getDataEntry(tableCode, searchDataEntry.code)
      onSelect(getDataEntry)
    })
  const { loading: searchDataLoading, result: searchDataResult } =
    useApiErrorHandlingAsync(
      searchData,
      [apiClient, tableCode, searchTerm, minimumLookupChars],
      {
        setLoading: (state) => ({ ...state, loading: true }),
      },
    )

  const debouncedOnChange = useDebouncedCallback(setSearchTerm, 250)

  return (
    <SearchView
      identifier={tName}
      placeholder={t(tKey(tName, 'placeholder'))}
      notFoundMessage={t(tKey(tName, 'validation.noResults'))}
      onChange={getDataEntryExecute}
      onInputChange={debouncedOnChange}
      loading={searchDataLoading || getDataEntryLoading}
      results={searchDataResult}
      renderListItemContent={renderListItemContent}
    />
  )
}

export default LookupSearchView
