import { createGlobalStyle } from 'styled-components'

import defaults from './defaults'
import normalise from './normalise'

export const GlobalStyle = createGlobalStyle`
  ${(props) => props.theme.fontFaces};
  ${normalise};
  ${defaults};
`
