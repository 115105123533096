import { TFunction } from 'i18next'

import { QuotePremiums } from '../../../hooks/premiums'
import {
  tCovers,
  tFormats,
  TKeyBuilder,
} from '../../../infrastructure/internationalisation'

export const coreLineItems = (
  t: TFunction,
  tKey: TKeyBuilder,
  currentPremiums: QuotePremiums,
  previousPremiums?: QuotePremiums,
) => [
  {
    header: t(tKey('core')),
    currentAmount: t(tFormats('currency.long'), {
      amount: currentPremiums.breakdown.main,
    }),
    previousAmount:
      previousPremiums &&
      t(tFormats('currency.long'), { amount: previousPremiums.breakdown.main }),
  },
]

export const discountLineItems = (
  t: TFunction,
  tKey: TKeyBuilder,
  currentPremiums: QuotePremiums,
  previousPremiums?: QuotePremiums | undefined,
) =>
  currentPremiums.breakdown.discount
    ? [
        {
          header: t(
            tKey(
              currentPremiums.breakdown.main - currentPremiums.breakdown.discount < 0.001
                ? 'discount.full'
                : 'discount.partial',
            ),
          ),
          currentAmount: t(tFormats('currency.long'), {
            amount: -currentPremiums.breakdown.discount,
          }),
          previousAmount:
            previousPremiums &&
            t(tFormats('currency.long'), {
              amount: -previousPremiums.breakdown.discount,
            }),
        },
      ]
    : []

export const optionalLineItems = (
  t: TFunction,
  currentPremiums: QuotePremiums,
  previousPremiums?: QuotePremiums,
) =>
  currentPremiums.optional.map((current) => {
    const previousAmount = previousPremiums?.optional.find(
      (previous) => previous.coverCode === current.coverCode,
    )

    return {
      header: t(tCovers(current.coverCode, 'name')),
      currentAmount: t(tFormats('currency.long'), { amount: current.premium }),
      previousAmount:
        previousAmount &&
        t(tFormats('currency.long'), { amount: previousAmount.premium }),
    }
  })
