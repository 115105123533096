import { chain, uniqBy } from 'lodash'

import { Excess } from './types/excess'

export const isMainExcess = (excess: Excess) => excess.voluntaryExcessApplies

export const hasExcess = (category: string) => (item: Excess) =>
  item.excessCategory === category

export interface ExcessComparison {
  category: string
  previous: number | undefined
  current: number | undefined
}

export const getExcessComparison = (
  previous: Excess[],
  current: Excess[],
): ExcessComparison[] => {
  const excessCategories = chain(previous)
    .concat(current)
    .map('excessCategory')
    .uniq()
    .value()

  return excessCategories.map((it) => ({
    category: it,
    previous: previous.find(hasExcess(it))?.excess,
    current: current.find(hasExcess(it))?.excess,
  }))
}

export const hasUniqueExcesses = (excesses: ExcessComparison[]) =>
  uniqBy(excesses, (it) => it.current).length > 1 ||
  uniqBy(excesses, (it) => it.previous).length > 1

export const hasExcessChanged = (change: ExcessComparison) =>
  change.previous !== change.current
