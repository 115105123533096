import React, { FunctionComponent } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'

import { Page } from '../../../../../infrastructure/routing'
import { ManagementConfig } from '../../../../../state/configuration'
import { PolicyAdjustmentResultPage } from './PolicyAdjustmentResultPage'
import { PolicyAdjustmentResultPath } from './PolicyAdjustmentResultPath'
import { PolicyAdjustmentReviewPage } from './PolicyAdjustmentReviewPage'

export interface PolicyAdjustmentResultRouterProps {
  adjustmentPage: Page
  policyPage: Page
  config?: ManagementConfig
}

export const PolicyAdjustmentResultRouter: FunctionComponent<
  PolicyAdjustmentResultRouterProps
> = ({ adjustmentPage, policyPage, config }) => {
  const { adjustmentId } = useParams<{ adjustmentId: string }>()

  if (!adjustmentId) {
    return <Navigate to={adjustmentPage.url} />
  }

  return (
    <Routes>
      <Route
        index
        element={
          <PolicyAdjustmentReviewPage
            adjustmentId={adjustmentId}
            adjustmentPage={adjustmentPage}
            config={config}
          />
        }
      />
      <Route
        path={PolicyAdjustmentResultPath.RESULT}
        element={
          <PolicyAdjustmentResultPage
            adjustmentId={adjustmentId}
            adjustmentPage={adjustmentPage}
            policyPage={policyPage}
          />
        }
      />
      <Route path='*' element={<Navigate to={adjustmentPage.url} />} />
    </Routes>
  )
}
