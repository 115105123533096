import React, { FunctionComponent, useCallback } from 'react'

export type AnchorProps = Omit<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >,
  'color'
> & {
  disabled?: boolean
}

export const Anchor: FunctionComponent<AnchorProps> = ({
  onClick,
  children,
  href,
  disabled = false,
  ...props
}) => {
  const onInternalClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (onClick) {
        event.preventDefault()

        if (!disabled) {
          onClick(event)
        }
      }
    },
    [onClick, disabled],
  )

  return (
    <a {...props} onClick={onInternalClick} href={href ?? '#'}>
      {children}
    </a>
  )
}
