import { AxiosInstance } from 'axios'

import { PolicyAdjustmentRequest, PolicyAdjustmentResponse } from '../../../domain'
import { with404ErrorHandling, withGenericErrorHandling } from '../errors'

export interface PolicyAdjustmentsClient {
  getPolicyAdjustment: (
    adjustmentId: string,
  ) => Promise<PolicyAdjustmentResponse | undefined>
  createPolicyAdjustment: (
    policyAdjustment: PolicyAdjustmentRequest,
  ) => Promise<PolicyAdjustmentResponse>
  confirmPolicyAdjustment: (adjustmentId: string) => Promise<void>
}

export const createPolicyAdjustmentsClient = (
  getInstance: () => Promise<AxiosInstance>,
): PolicyAdjustmentsClient => ({
  getPolicyAdjustment: (adjustmentId: string) =>
    with404ErrorHandling(() =>
      withGenericErrorHandling(async () => {
        const instance = await getInstance()
        const response = await instance.get<PolicyAdjustmentResponse>(
          `/policyAdjustments/${adjustmentId}`,
        )
        return response.data
      }),
    ),

  createPolicyAdjustment: (policyAdjustment: PolicyAdjustmentRequest) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<PolicyAdjustmentResponse>(
        '/policyAdjustments',
        {
          ...policyAdjustment,
        },
      )
      return { ...response.data, changes: policyAdjustment.changes }
    }),

  confirmPolicyAdjustment: (adjustmentId) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      await instance.post(`/policyAdjustments/${adjustmentId}/commit`, {})
    }),
})
