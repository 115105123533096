import { Checkbox, Flex, FlexAnchor, Grid, mdBumps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback, useLayoutEffect, useState } from 'react'

import { FormInputProps } from '../types'

export type CheckboxListValues = Record<string, boolean>

export interface CheckboxListMeta {
  items: string[]
  defaultValue: boolean
}

const setDefaults = (items: string[], defaultValue: boolean) =>
  items.reduce(
    (acc, item) => ({
      ...acc,
      [item]: defaultValue,
    }),
    {},
  )

export const CheckboxListFormInput: FunctionComponent<
  FormInputProps<CheckboxListValues, CheckboxListMeta>
> = ({ value, meta: { items, defaultValue }, tName, t, onChange, name }) => {
  const [values, setValues] = useState<CheckboxListValues>(
    value ?? setDefaults(items, defaultValue),
  )

  useLayoutEffect(() => {
    if (!value) onChange(values)
  })

  const onCheck = useCallback(
    (id: string) => (checked: boolean) => {
      const newValues = { ...values, [id]: checked }
      setValues(newValues)
      onChange(newValues)
    },
    [values, onChange],
  )

  const checkboxes = items.map((item) => (
    <FlexAnchor
      as='label'
      key={`${name}-${item}`}
      justifyContent='flex-start'
      htmlFor={`${name}-${item}`}
      variant='highlight'
      px={mdBumps}
      py={3}
    >
      <Checkbox
        name={`${name}-${item}`}
        id={`${name}-${item}`}
        value={values[item]}
        onChange={onCheck(item)}
      />
      <Typo pl={2}>{t(`entries.${tName}.${item}`)}</Typo>
    </FlexAnchor>
  ))

  return (
    <Flex width={1} variant='raised' p={2}>
      <Grid width={1} gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}>
        {checkboxes}
      </Grid>
    </Flex>
  )
}
