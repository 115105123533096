import {
  Box,
  Flex,
  FlexProps,
  smBumps,
  TertiaryButton,
  Typo,
  xsBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  CustomerAccount,
  getUsablePaymentMethod,
  hasMissingPaymentMethod,
  PaymentMethod,
  PaymentMethodType,
} from '../../../domain'
import { useSetupMandate } from '../../../hooks/directDebit'
import {
  TKeyBuilder,
  tPaymentMethods,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

export interface PaymentMethodBACSProps extends FlexProps {
  tKey: TKeyBuilder
  customerAccount: CustomerAccount
  paymentMethods: PaymentMethod[]
}

export const PaymentMethodBACS: FunctionComponent<PaymentMethodBACSProps> = ({
  customerAccount,
  paymentMethods,
  tKey,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const onSetupMandate = useSetupMandate()

  const paymentMethod = getUsablePaymentMethod(
    paymentMethods,
    PaymentMethodType.BACS_DEBIT,
  )

  return (
    <Flex px={smBumps} py={xsBumps} {...props}>
      <Box>
        <Typo typoSize='xs' color='bodySecondary'>
          {t(tKey('bacs', 'header'))}
        </Typo>
        <Typo fontWeight='bold'>
          {paymentMethod
            ? t(tPaymentMethods('bacs', 'redacted'), paymentMethod)
            : t(tPaymentMethods('missing'))}
        </Typo>
      </Box>
      {hasMissingPaymentMethod(
        [customerAccount],
        paymentMethods,
        PaymentMethodType.BACS_DEBIT,
      ) && (
        <TertiaryButton
          whiteSpace='nowrap'
          layout='small'
          onClick={onSetupMandate.execute}
          loading={onSetupMandate.loading}
        >
          {t(tKey('bacs', 'actions', 'set-up'))}
        </TertiaryButton>
      )}
    </Flex>
  )
}
