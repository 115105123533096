import React, { FunctionComponent, ReactElement } from 'react'

import { Flex, FlexProps, smBumps, StyledPremium, Typo, xsBumps } from '../atoms'

export interface InvoiceTotalCellProps extends FlexProps {
  header: string | ReactElement
  subheader?: string | ReactElement
  premium: string | ReactElement
}

export const InvoiceTotalCell: FunctionComponent<InvoiceTotalCellProps> = ({
  header,
  subheader,
  premium,
  ...props
}) => (
  <Flex width={1} px={smBumps} py={xsBumps} {...props}>
    <Flex flexDirection='column' alignItems='flex-start' mr={2}>
      <Typo fontWeight='bold'>{header}</Typo>
      {subheader && (
        <Typo color='bodySecondary' typoSize='xs'>
          {subheader}
        </Typo>
      )}
    </Flex>
    <StyledPremium
      variant='inverted'
      typoSize='md'
      fontWeight='bold'
      data-testid='premium-wrapper'
    >
      {premium}
    </StyledPremium>
  </Flex>
)
