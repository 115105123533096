import {
  mdBumps,
  Section,
  SectionContent,
  SectionProps,
  SectionTitle,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  getCurrentPolicyVersion,
  PolicyAdjustmentResponse,
} from '../../../../../../domain'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { ManagementConfig, ManagementType } from '../../../../../../state/configuration'
import { CoverChanges } from '../../../../../organisms/covers/CoverChanges'
import { ExcessChanges } from '../../../../../organisms/excess'
import { PolicyChangesFlex, ProfileChangesFlex } from './components'

export interface PolicyChangesFlexProps extends SectionProps {
  result: PolicyAdjustmentResponse
  config: ManagementConfig
}

const tKey = TKey('components.adjustment-config-changes')

export const AdjustmentConfigChanges: FunctionComponent<PolicyChangesFlexProps> = ({
  result,
  config,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const updatedPolicyVersion = getCurrentPolicyVersion(result.updatedPolicyVersions)

  const change = (() => {
    switch (config.type) {
      case ManagementType.ADDITIONAL_PROFILE:
        return <ProfileChangesFlex config={config} result={result} />
      default:
        return <PolicyChangesFlex config={config} result={result} />
    }
  })()

  return (
    <Section width={1} {...props}>
      <SectionTitle>{t(tKey('header'))}</SectionTitle>
      <SectionContent>
        {change}
        <CoverChanges updatedPolicyVersion={updatedPolicyVersion} mt={mdBumps} />
        <ExcessChanges updatedPolicyVersion={updatedPolicyVersion} mt={mdBumps} />
      </SectionContent>
    </Section>
  )
}
