import { Flex, FlexProps, Heading, mdBumps, Plate, Typo } from '@wrisk/ui-components'
import React from 'react'

import {
  tFormats,
  TKeyBuilder,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'

interface Props extends FlexProps {
  amount?: number
  disabled?: boolean
  text?: string
  tKey: TKeyBuilder
}

export const AddOnHeader: React.FC<Props> = ({
  amount,
  disabled = false,
  text,
  tKey,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const premiumSuffix = t(tKey('premium-suffix'), { defaultValue: null })

  return (
    <Flex p={mdBumps} width={1} {...props}>
      <Heading mr={2} variant='h3' typoSize='md'>
        {text}
      </Heading>
      <Plate>
        {disabled ? (
          <Typo as='span' color='textDisabled' typoSize='sm'>
            {t(tKey('not-available'))}
          </Typo>
        ) : (
          <>
            <Typo as='span' fontWeight='bold' typoSize='base'>
              {t(tFormats('currency.long'), { amount })}
            </Typo>
            {Boolean(premiumSuffix) && (
              <Typo as='span' pl={1} typoSize='xs'>
                {premiumSuffix}
              </Typo>
            )}
          </>
        )}
      </Plate>
    </Flex>
  )
}
