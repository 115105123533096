import { AxiosInstance } from 'axios'

import { CustomerAccount, CustomerPayment } from '../../../domain'
import { withGenericErrorHandling } from '../errors'

export interface CustomerAccountClient {
  createCustomerAccount: (proposalCode: string) => Promise<CustomerAccount>
  getCustomerAccounts: (params?: { policyNumber?: string }) => Promise<CustomerAccount[]>
  checkoutBind: (params: { proposalCode: string }) => Promise<CustomerPayment[]>
  checkoutMta: (params: { adjustmentId: string }) => Promise<CustomerPayment[]>
  updatePaymentDay: (
    accountId: string,
    request: UpdatePaymentDayRequest,
  ) => Promise<CustomerAccount>
}

export interface UpdatePaymentDayRequest {
  policyId: string
  paymentDay: number
}

export const createCustomerAccountClient = (
  getInstance: () => Promise<AxiosInstance>,
): CustomerAccountClient => ({
  getCustomerAccounts: (params) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.get<CustomerAccount[]>(`/customerAccounts`, {
        params,
      })
      return response.data
    }),

  createCustomerAccount: (proposalCode) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<CustomerAccount>(`/customerAccounts`, {
        proposalCode,
      })
      return response.data
    }),

  checkoutBind: (params) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<CustomerPayment[]>(
        `/customerPayments/checkout/bind`,
        params,
      )
      return response.data
    }),

  checkoutMta: (params) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<CustomerPayment[]>(
        `/customerPayments/checkout/mta`,
        params,
      )
      return response.data
    }),

  updatePaymentDay: (accountId, request) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.put<CustomerAccount>(
        `/customerAccounts/${accountId}/paymentDay`,
        request,
      )
      return response.data
    }),
})
