import {
  AnchorProps,
  FooterAnchorProps,
  LinkAnchor,
  LinkAnchorProps,
  TypoSizeProps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, PropsWithChildren } from 'react'

import { useWriskTranslation } from '../../../infrastructure/internationalisation'

interface ExternalLinkProps extends PropsWithChildren {
  type?: string
  href?: string
  text?: string
  as?: React.ComponentType<LinkAnchorProps | FooterAnchorProps>
}

export const ExternalLink: FunctionComponent<
  ExternalLinkProps & AnchorProps & TypoSizeProps
> = ({
  type,
  text,
  href,
  as: LinkComponent = LinkAnchor,
  children,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  ref,
  ...props
}) => {
  const { t } = useWriskTranslation()

  return (
    <LinkComponent
      fontFamily='body'
      fontWeight='normal'
      color='inherit'
      target='_blank'
      rel='noopener noreferrer'
      href={href ?? t<string>(`links.${type}`, { defaultValue: '#' })}
      {...props}
    >
      {children ?? text}
    </LinkComponent>
  )
}
