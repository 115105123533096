import { ExpandableSection, Flex, smBumps, Typo } from '@wrisk/ui-components'
import { isNil } from 'lodash'
import React, { FunctionComponent } from 'react'

import { Quote } from '../../../domain'
import { toQuoteData } from '../../../hooks/adjustments/proposal/disclosure/data'
import {
  tDefaults,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { QuoteManagementConfig } from '../../../state/configuration'
import { getFormatter } from '../../formBuilder'

export const QuoteInformation: FunctionComponent<{
  config: QuoteManagementConfig
  quote: Quote
}> = ({ config, quote }) => {
  const { t } = useWriskTranslation()

  const [firstInput] = config.inputs

  const Formatter = getFormatter(firstInput.type)
  const toData = toQuoteData(quote)

  const expandedComponents = config.inputs
    .filter(({ adjustment }) => !isNil(toData(adjustment)))
    .map((input) => {
      const Formatter = getFormatter(input.type)

      return (
        <Flex
          key={input.name}
          px={smBumps}
          py={2}
          flexDirection='column'
          alignItems='flex-start'
        >
          <Typo typoSize='xs' color='bodySecondary' mb={1}>
            {t(tDefaults('product.headers', input.name))}
          </Typo>
          <Typo as='pre'>
            <Formatter input={input} value={toData(input.adjustment)} t={t} />
          </Typo>
        </Flex>
      )
    })

  return (
    <ExpandableSection
      reversed
      header={
        <Typo fontWeight='bold'>
          <Formatter input={firstInput} value={toData(firstInput.adjustment)} t={t} />
        </Typo>
      }
    >
      {expandedComponents}
    </ExpandableSection>
  )
}
