import { Input } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { FormInputProps } from '../types'

export const HiddenFormInput: FunctionComponent<FormInputProps<string>> = ({
  name,
  value,
}) => {
  return <Input name={name} value={value} type='hidden' />
}
