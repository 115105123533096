import { isEmpty, isNil } from 'lodash'
import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'

import { Policy, Proposal } from '../../domain'
import { useApiErrorHandlingAsyncCallback } from '../../hooks/auth'
import { createRepository } from '../../infrastructure/storage'
import { getProposalTrackingNames, useConfig } from '../../state/configuration'
import { usePrincipal } from '../authentication'

interface ProposalTracking {
  trackImpression: (proposal: Proposal | Policy | undefined) => void
}

const ProposalTrackingContext = createContext<ProposalTracking | undefined>(undefined)

const ProposalTrackingProvider = ProposalTrackingContext.Provider

const proposalTrackingRepository = createRepository<Record<string, string>>(
  'proposalTracking',
  'session',
)

export const ProposalTrackingManager: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const location = useLocation()
  const trackingNames = useConfig(getProposalTrackingNames)

  const { apiClient } = usePrincipal()

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    const tracking = trackingNames.reduce(
      (acc, name) =>
        params.get(name)
          ? {
              ...acc,
              [name]: params.get(name),
            }
          : acc,
      {},
    )

    if (Object.keys(tracking).length) {
      proposalTrackingRepository.save(tracking)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [proposalsTracked, setProposalsTracked] = useState<string[]>([])

  const trackProposal = useApiErrorHandlingAsyncCallback(
    async (proposalOrPolicy: Proposal | Policy) => {
      const values = proposalTrackingRepository.get()
      if (isNil(values)) return
      if (isEmpty(values)) return
      if (!proposalOrPolicy) return
      if (proposalsTracked.includes(proposalOrPolicy.proposalCode)) return

      setProposalsTracked((tracked) => [...tracked, proposalOrPolicy.proposalCode])
      await apiClient.postImpression({
        type: 'proposal',
        values: values,
        tags: {
          proposalCode: proposalOrPolicy.proposalCode,
        },
      })
    },
  )

  return (
    <ProposalTrackingProvider value={{ trackImpression: trackProposal.execute }}>
      {children}
    </ProposalTrackingProvider>
  )
}

export const useProposalTracking = (): ProposalTracking => {
  const context = useContext(ProposalTrackingContext)

  if (!context) {
    throw new Error('Cannot use TrackingContext when undefined.')
  }

  return context
}
