import React, { forwardRef, useImperativeHandle, useRef } from 'react'

export type InputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & { 'data-testid'?: string; 'data-1p-ignore'?: boolean },
  'width' | 'ref'
>

export const Input = forwardRef<{ focus: () => void }, InputProps>((props, ref) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useImperativeHandle(ref, () => ({
    focus: () => inputRef.current?.focus(),
  }))

  return (
    <input
      {...props}
      autoComplete={props.autoComplete || 'off'}
      autoFocus={props.autoFocus || false}
      ref={inputRef}
    />
  )
})

Input.displayName = 'Input'
