import React, { FunctionComponent, PropsWithChildren } from 'react'
import { useLocation } from 'react-router-dom'

import { usePrincipal } from './PrincipalProvider'
import { RedirectToSignIn } from './RedirectToSignIn'

export interface ProtectedRoutesProps extends PropsWithChildren {
  preservePath?: boolean
}

export const ProtectedRoutes: FunctionComponent<ProtectedRoutesProps> = ({
  children,
  preservePath = false,
}) => {
  const { isAuthenticated } = usePrincipal()
  const { pathname } = useLocation()

  const returnTo = preservePath ? pathname : undefined

  return isAuthenticated ? <>{children}</> : <RedirectToSignIn returnTo={returnTo} />
}
