import { FlexProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { getCurrentQuote } from '../../../domain'
import { useEditProposalCallback } from '../../../hooks/proposal'
import { toDateTime } from '../../../util/date'
import { useProposal } from '../../product/proposal/proposalContext'
import { QuoteDetailsView } from '../quote'

export const ProposalDetailsView: FunctionComponent<FlexProps> = (props) => {
  const { proposal } = useProposal()

  const quote = getCurrentQuote(proposal)
  const onEdit = useEditProposalCallback(proposal)

  const startAt = toDateTime(proposal.startAt)
  const endAt = proposal.monthlyBilling ? undefined : toDateTime(proposal.endAt)

  return (
    <QuoteDetailsView
      onEdit={onEdit}
      quote={quote}
      startAt={startAt}
      endAt={endAt}
      {...props}
    />
  )
}
