import { InputMessage, useLicencePlate } from '@wrisk/ui-components'
import React, { FunctionComponent, ReactNode } from 'react'

import { Specification } from '../../../../domain'
import { useApiErrorHandlingAsyncCallback } from '../../../../hooks/auth'
import { usePrincipal } from '../../../authentication'
import { VehicleLookup } from './VehicleLookup'

interface Props {
  value?: Specification
  onChange?: (vrn: string | undefined) => void
  onLookupStart?: () => void
  onLookupComplete: (value?: Specification, vrn?: string) => void
  error: ReactNode
}

export const VehicleLookupWrapper: FunctionComponent<Props> = ({
  value,
  onChange,
  onLookupStart,
  onLookupComplete,
  error,
}) => {
  const [licencePlate, licencePlateValid, setLicencePlate] = useLicencePlate(
    value?.vrn ?? undefined,
    (d) => onChange?.(d),
  )

  const { apiClient } = usePrincipal()

  const { result, loading, execute } = useApiErrorHandlingAsyncCallback(async () => {
    onLookupStart?.()
    const vehicle = await apiClient.lookupVehicle(licencePlate)
    onLookupComplete(vehicle ?? undefined, licencePlate)
    return vehicle
  })

  return (
    <>
      <VehicleLookup
        disabled={!licencePlateValid}
        loading={loading}
        value={value?.vrn ?? undefined}
        onSearchExecute={execute}
        onChange={setLicencePlate}
      />
      {result === null && <InputMessage>{error}</InputMessage>}
    </>
  )
}
