import { Box, BoxProps, lgBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { CustomerAccount, Policy, PolicyStatus } from '../../domain'
import { ProductConfig, Scheme } from '../../state/configuration'
import { PolicyProvider } from '../product/policy/policyContext'
import { ProductContext } from '../product/productContext'
import { getPolicyCard } from './cards'

const policyStatusOrder = Object.values(PolicyStatus)

export interface PolicyInformation {
  policy: Policy
  scheme: Scheme
  product: ProductConfig
  customerAccount: CustomerAccount
}

export interface PoliciesViewProps extends BoxProps {
  policyInformation: PolicyInformation[]
}

const sortPolicies = (left: PolicyInformation, right: PolicyInformation) => {
  const statusComparison =
    policyStatusOrder.indexOf(left.policy.policyDetail.policyStatus) -
    policyStatusOrder.indexOf(right.policy.policyDetail.policyStatus)

  return statusComparison
    ? statusComparison
    : -left.policy.policyDetail.inceptedAt.localeCompare(
        right.policy.policyDetail.inceptedAt,
      )
}

export const isPolicyInformation = (
  info: Partial<PolicyInformation>,
): info is PolicyInformation =>
  Boolean(info.policy && info.product && info.scheme && info.customerAccount)

export const getPolicyInformation =
  (
    schemes: Scheme[],
    products: Record<string, ProductConfig>,
    customerAccounts: CustomerAccount[],
  ) =>
  (policies: Policy[]): PolicyInformation[] =>
    policies
      .map((policy) => ({
        policy,
        scheme: schemes.find((it) => policy.schemeCode === it.schemeCode),
        product: products[policy.schemeCode],
        customerAccount: customerAccounts.find(
          (acc) => acc.accountCode === policy.policyNumber,
        ),
      }))
      .filter(isPolicyInformation)

export const PoliciesView: FunctionComponent<PoliciesViewProps> = ({
  policyInformation,
  ...props
}) => {
  const sections = policyInformation
    .sort(sortPolicies)
    .map(({ policy, product, scheme, customerAccount }) => {
      const PolicyCard = getPolicyCard(policy)

      return (
        <ProductContext.Provider value={{ scheme, product }} key={policy.policyId}>
          <PolicyProvider policy={policy}>
            <PolicyCard customerAccount={customerAccount} mb={lgBumps} />
          </PolicyProvider>
        </ProductContext.Provider>
      )
    })

  return <Box {...props}>{sections}</Box>
}
