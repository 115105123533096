export interface CustomerCheck {
  isClear: boolean
  checks: CustomerCheckResults
}

export type CustomerCheckResults = Record<string, CustomerVerificationCheck>

export interface CustomerVerificationCheck {
  result: CustomerCheckResultType
  performedAt: string
}

export enum CustomerCheckResultType {
  CONSIDER = 'CONSIDER',
}
