import { DateTime, Info, Interval } from 'luxon'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { Box, Flex } from '../atoms'
import { useNotifyingState } from '../extensions'
import { SelectInput } from './SelectInput'

export interface YearMonthInputProps {
  earliestYear: number
  latestYear: number | undefined
  onChange: (value: string | null) => void
  value: string | null
}

interface YearMonthDay {
  year?: number
  month?: number
  day?: number
}

const fromIsoToYearMonthDay = (value: string | null) => {
  if (!value) return {}
  const { year, month, day } = DateTime.fromISO(value)
  return { year, month, day }
}

const fromYearMonthDayToIso = (value: YearMonthDay) => {
  if (!value.year || !value.month || !value.day) return null
  return DateTime.fromObject(value).toFormat('yyyy-MM-dd')
}

export const YearMonthDayInput: FunctionComponent<YearMonthInputProps> = ({
  value,
  onChange,
  earliestYear,
  latestYear,
}) => {
  const [yearMonthDay, setYearMonthDay] = useNotifyingState<YearMonthDay>(
    fromIsoToYearMonthDay(value),
    [(it) => onChange(fromYearMonthDayToIso(it))],
  )

  // TODO: This seems to be broken when in BST without setting month
  //  because it sets it to 2023-01-01 at midnight, which is 2022-12-31T23:00:00.000+01:00
  const { earliest, latest } = useMemo(
    () => ({
      latest: DateTime.fromObject({ year: latestYear, month: 7 }),
      earliest: DateTime.fromObject({ year: earliestYear }),
    }),
    [earliestYear, latestYear],
  )

  const { days, months, years } = useMemo(() => {
    const days = Array.from({ length: 31 }, (_, i) => i + 1).map((it, index) => ({
      value: index + 1,
      text: String(it),
    }))

    const months = Info.months().map((it, index) => ({
      value: index + 1,
      text: it,
    }))

    const years = Interval.fromDateTimes(earliest, latest)
      .splitBy({ year: 1 })
      .reverse()
      .map((it: Interval<true>) => ({
        value: it.start.year,
        text: it.start.year.toString(),
      }))

    return { days, months, years }
  }, [earliest, latest])

  const onDayChange = useCallback(
    (value: number | undefined) =>
      setYearMonthDay({
        ...yearMonthDay,
        day: value,
      }),
    [setYearMonthDay, yearMonthDay],
  )

  const onMonthChange = useCallback(
    (value: number | undefined) =>
      setYearMonthDay({
        ...yearMonthDay,
        month: value,
      }),
    [setYearMonthDay, yearMonthDay],
  )

  const onYearChange = useCallback(
    (value: number | undefined) =>
      setYearMonthDay({
        ...yearMonthDay,
        year: value,
      }),
    [setYearMonthDay, yearMonthDay],
  )

  return (
    <Flex width={1} justifyContent='flex-start'>
      <Box width='160px' mr={2}>
        <SelectInput<number>
          values={days}
          value={yearMonthDay.day}
          placeholder='Day'
          onChange={onDayChange}
        />
      </Box>
      <Box width='240px' mr={2}>
        <SelectInput<number>
          values={months}
          value={yearMonthDay.month}
          placeholder='Month'
          onChange={onMonthChange}
        />
      </Box>
      <Box width='160px'>
        <SelectInput<number>
          values={years}
          value={yearMonthDay.year}
          placeholder='Year'
          onChange={onYearChange}
        />
      </Box>
    </Flex>
  )
}
