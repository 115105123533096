import React, { FunctionComponent } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Page } from '../../../../infrastructure/routing'
import { useProduct } from '../../productContext'
import { UploadConfirmation } from './UploadConfirmation'
import { UploadPage } from './UploadPage'

export type UploadRouterProps = { parent: Page }

export const UploadRouter: FunctionComponent<UploadRouterProps> = ({ parent }) => {
  const {
    product: { uploads },
  } = useProduct()

  return (
    <Routes>
      {Object.entries(uploads ?? {})?.map(([type, config]) => (
        <Route
          key={`upload-${type}`}
          path={type}
          element={<UploadPage type={type} config={config} parent={parent} />}
        />
      ))}
      <Route
        key='upload-confirmation'
        path='confirmation'
        element={<UploadConfirmation parent={parent} />}
      />
      <Route path='*' element={<Navigate to={parent.url} />} />
    </Routes>
  )
}
