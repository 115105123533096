import React, { FunctionComponent } from 'react'

import { Data, Policy, Profile } from '../../../../../domain'
import { useUpdateAdditionalProfilePolicyAdjustment } from '../../../../../hooks/adjustments/policy'
import { AdditionalProfileManagementConfig } from '../../../../../state/configuration'
import { AdjustmentModal } from '../admin'

export interface UpdateAdditionalProfileAdjustmentModalProps {
  updatedData: Data
  policy: Policy
  config: AdditionalProfileManagementConfig
  onSuccess: (response: unknown) => void
  onCancel: () => void
  profile: Profile
}

export const UpdateAdditionalProfileAdjustmentModal: FunctionComponent<
  UpdateAdditionalProfileAdjustmentModalProps
> = ({ updatedData, policy, config, onSuccess, onCancel, profile }) => {
  const onSubmitAdjustment = useUpdateAdditionalProfilePolicyAdjustment(
    config.inputs,
    profile,
    onSuccess,
  )

  return (
    <AdjustmentModal
      updatedData={updatedData}
      policy={policy}
      onCancel={onCancel}
      onSubmitAdjustment={onSubmitAdjustment}
    />
  )
}
