import {
  Box,
  Flex,
  FlexProps,
  LinkAnchor,
  mdBumps,
  Typo,
  xsBumps,
} from '@wrisk/ui-components'
import { TFunctionDetailedResult } from 'i18next'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import { Trans } from 'react-i18next'

import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'

export interface PromotionTermsProps extends FlexProps {
  promoCode: string
}

const tKey = TKey('components.promotion-terms')

interface TermAnchorProps extends PropsWithChildren {
  href?: string
}

const TermAnchor: FunctionComponent<TermAnchorProps> = ({ href, children }) => {
  if (!href) throw new Error(`No href supplied for "${children}"`)

  return (
    <LinkAnchor href={href} target='_blank'>
      {children}
    </LinkAnchor>
  )
}

export const PromotionTerms: FunctionComponent<PromotionTermsProps> = ({
  promoCode,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const termKeys = t(tKey(promoCode), {
    returnObjects: true,
    returnDetails: true,
    defaultValue: { res: { res: [] } },
  }) as TFunctionDetailedResult<TFunctionDetailedResult[]>

  const terms = termKeys.res.map(({ exactUsedKey }) => {
    return (
      <Box
        key={exactUsedKey}
        width={1}
        as='li'
        pb={xsBumps}
        style={{ listStyleType: 'disc' }}
      >
        <Typo>
          <Trans
            t={t}
            i18nKey={exactUsedKey}
            components={{
              TermAnchor: <TermAnchor />,
            }}
          />
        </Typo>
      </Box>
    )
  })

  return (
    <Flex pl={mdBumps} flexDirection='column' as='ul' {...props}>
      {terms}
    </Flex>
  )
}
