import { PageLoadingIndicator } from '@wrisk/ui-components'
import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useErrorHandlingAsync } from '../../../hooks/errors'
import { usePrincipal } from '../../authentication'

export const DirectDebitCallbackWrapper: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [params, setSearchParams] = useSearchParams()

  const { apiClient } = usePrincipal()

  const redirectFlowId = useMemo(() => {
    return params.get('redirect_flow_id')
  }, [params])

  useErrorHandlingAsync(async () => {
    if (redirectFlowId) {
      await apiClient.createPaymentMethod(redirectFlowId)
      setSearchParams((a) => {
        a.delete('redirect_flow_id')
        return a
      })
    }
  }, [redirectFlowId])

  return redirectFlowId ? <PageLoadingIndicator /> : <>{children}</>
}
