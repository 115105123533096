import { Header, HeaderTitle, pageEntranceSpringValues } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { animated, useSpring } from 'react-spring'

import { Proposal } from '../../../../../../domain'
import {
  useProposalAdjustment,
  useProposalFormData,
} from '../../../../../../hooks/adjustments/proposal'
import {
  TExtends,
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { MainDisclosureConfig } from '../../../../../../state/configuration'
import { FormErrors } from '../../../../../formBuilder'

export interface DisclosureFormProps {
  section: MainDisclosureConfig
  isLastSection: boolean
  onComplete: (proposal: Proposal) => void
}

const tKey = TKey('pages.disclosure')

export const DisclosureSection: FunctionComponent<DisclosureFormProps> = ({
  section,
  isLastSection,
  onComplete,
}) => {
  const { t } = useWriskTranslation()
  const spring = useSpring(pageEntranceSpringValues)

  const data = useProposalFormData(section.inputs)
  const onSubmitAdjustment = useProposalAdjustment(
    section.inputs,
    isLastSection,
    onComplete,
  )

  return (
    <animated.div style={spring}>
      <Header>
        <HeaderTitle>{t(tKey('headers', section.id))}</HeaderTitle>
      </Header>
      <FormErrors
        id={section.id}
        key={section.id}
        footerText={section.footerText}
        data={data}
        inputs={section.inputs}
        onSubmit={onSubmitAdjustment.execute}
        loading={onSubmitAdjustment.loading}
        tKey={TExtends(tKey, 'form')}
        t={t}
      />
    </animated.div>
  )
}
