import { Flex, FlexProps, mdBumps, smBumps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { ExcessComparison } from '../../../../domain'
import {
  tExcesses,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { ExcessRow } from '../ExcessRow'

const tKey = TKey('components.main-excess')

export interface MainExcessBreakdownProps extends FlexProps {
  excessComparisons: ExcessComparison[]
}

export const MainExcessBreakdown: FunctionComponent<MainExcessBreakdownProps> = ({
  excessComparisons,
  ...props
}) => {
  const { t } = useWriskTranslation()

  return (
    <Flex
      variant='raised'
      flexDirection='column'
      alignItems='flex-start'
      width={1}
      rowGap={smBumps}
      p={mdBumps}
      {...props}
    >
      <Typo typoSize='xs'>{t(tKey('header'))}</Typo>
      {excessComparisons.map((it, index) => (
        <ExcessRow
          key={`${it.category}-${index}`}
          header={t(tExcesses(it.category, 'name'))}
          currentExcess={it.current}
          previousExcess={it.previous}
        />
      ))}
    </Flex>
  )
}
