import { Box, Flex, Heading, InvoiceTotalCell, mdBumps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { CustomerStatementLineItem, CustomerStatementSummary } from '../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { toDateTime } from '../../../util/date'
import { IPT } from '../product'
import { InvoiceItems } from './InvoiceItems'
import { StatementStatusIcon } from './StatementStatusIcon'
import { SubTotalSection } from './SubTotalSection'

interface InvoiceGroupProps {
  lineItems: CustomerStatementLineItem[]
  statementSummary: CustomerStatementSummary
}

const tKey = TKey('components.invoice-group')

export const InvoiceGroup: FunctionComponent<InvoiceGroupProps> = ({
  lineItems,
  statementSummary,
}) => {
  const { t } = useWriskTranslation()
  const { statementBalance, createdAt, status } = statementSummary

  return (
    <Box variant='raised'>
      <Box p={mdBumps} borderBottomWidth={1}>
        <Flex mb={2}>
          <Heading as='h2' variant='h2' typoSize='xl'>
            {t(tKey('header'))}
          </Heading>
          <StatementStatusIcon status={status} iconSize='small' p={2} />
        </Flex>
        <Typo typoSize='sm'>
          {t(tFormats('date.medium'), { value: toDateTime(createdAt) })}
        </Typo>
      </Box>

      <InvoiceItems lineItems={lineItems} />

      <SubTotalSection summary={statementSummary} lineItems={lineItems} />

      <InvoiceTotalCell
        header={t<string>(tKey('total'))}
        subheader={<IPT />}
        premium={t<string>(tFormats('currency.long'), {
          amount: statementBalance / 100,
        })}
        p={mdBumps}
      />
    </Box>
  )
}
