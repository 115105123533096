import { Box, BoxProps, Flex, mdBumps, smBumps, Typo } from '@wrisk/ui-components'
import { partition } from 'lodash'
import React, { FunctionComponent } from 'react'

import {
  getCoreAsset,
  getEndorsementDifference,
  getExcessComparison,
  hasExcessChanged,
  hasUniqueExcesses,
  isExcessEndorsement,
  isMainExcess,
  PolicyVersion,
} from '../../../domain'
import {
  tExcesses,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { usePolicy } from '../../product/policy/policyContext'
import { useProduct } from '../../product/productContext'
import { Endorsements } from '../endorsements'
import { ExcessRow } from './ExcessRow'

export interface ExcessChangesProps extends BoxProps {
  updatedPolicyVersion: PolicyVersion
}

const tKey = TKey('components.excess-changes')

export const ExcessChanges: FunctionComponent<ExcessChangesProps> = ({
  updatedPolicyVersion: { quote: updatedQuote },
  ...props
}) => {
  const { t } = useWriskTranslation()

  const {
    policy: {
      currentPolicyVersion: { quote: currentQuote },
    },
  } = usePolicy()
  const { product } = useProduct()

  const [updatedMain, updatedOther] = partition(
    getCoreAsset(updatedQuote).mandatoryExcessLines,
    isMainExcess,
  )

  const [currentMain, currentOther] = partition(
    getCoreAsset(currentQuote).mandatoryExcessLines,
    isMainExcess,
  )

  const mainComparisons = getExcessComparison(currentMain, updatedMain)
  const otherComparisons = getExcessComparison(currentOther, updatedOther)

  const hasBreakdown = hasUniqueExcesses(mainComparisons)

  const mainChanges = mainComparisons.filter(hasExcessChanged)
  const otherChanges = otherComparisons.filter(hasExcessChanged)

  const endorsements = getEndorsementDifference(
    updatedQuote.endorsements.filter(isExcessEndorsement(product)),
    currentQuote.endorsements.filter(isExcessEndorsement(product)),
  )

  return mainChanges.length || otherChanges.length || endorsements.length ? (
    <Box variant='raised' {...props}>
      <Box p={mdBumps}>
        <Typo fontWeight='bold'>{t(tKey('header'))}</Typo>
      </Box>
      {Boolean(mainChanges.length) && (
        <Flex
          p={mdBumps}
          flexDirection='column'
          alignItems='flex-start'
          rowGap={smBumps}
          borderTopWidth={1}
        >
          {hasBreakdown ? (
            <>
              <Typo typoSize='sm' color='bodySecondary'>
                {t(tKey('sections', 'compulsory'))}
              </Typo>
              {mainChanges.map((it) => (
                <ExcessRow
                  key={it.category}
                  header={t(tExcesses(it.category, 'name'))}
                  currentExcess={it.current}
                  previousExcess={it.previous}
                />
              ))}
            </>
          ) : (
            <ExcessRow
              header={t(tKey('sections', 'compulsory'))}
              currentExcess={mainChanges[0]?.current}
              previousExcess={mainChanges[0]?.previous}
            />
          )}
        </Flex>
      )}
      {Boolean(otherChanges.length) && (
        <Flex
          p={mdBumps}
          flexDirection='column'
          alignItems='flex-start'
          rowGap={smBumps}
          borderTopWidth={1}
        >
          <Typo typoSize='sm' color='bodySecondary'>
            {t(tKey('sections', 'other'))}
          </Typo>
          {otherChanges.map((it) => (
            <ExcessRow
              key={it.category}
              header={t(tExcesses(it.category, 'name'))}
              currentExcess={it.current}
              previousExcess={it.previous}
            />
          ))}
        </Flex>
      )}
      {Boolean(endorsements.length) && (
        <Endorsements
          quote={updatedQuote}
          endorsements={endorsements}
          p={mdBumps}
          borderTopWidth={1}
        />
      )}
    </Box>
  ) : null
}
