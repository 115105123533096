import { BoxProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { Quote } from '../../../domain'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { ProductDocumentsSection } from './ProductDocumentsSection'

export interface ProposalDocumentsSectionProps extends BoxProps {
  quote: Quote
}

const tKey = TKey('components.product-documents-titled')

export const ProposalDocumentsSection: FunctionComponent<
  ProposalDocumentsSectionProps
> = ({ quote, ...props }) => {
  const { t } = useWriskTranslation()

  const subheader = t(tKey('subheader'), { defaultValue: null }) && (
    <Trans i18nKey={tKey('subheader')} t={t} />
  )

  return (
    <ProductDocumentsSection
      header={t<string>(tKey('header'))}
      subheader={subheader}
      quote={quote}
      {...props}
    />
  )
}
