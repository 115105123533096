import { SectionProps, useModal } from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'

import { getCurrentQuote } from '../../../domain'
import { useProposalExcessAdjustment } from '../../../hooks/adjustments/proposal'
import { useProduct } from '../../product/productContext'
import { useProposal } from '../../product/proposal/proposalContext'
import { ExcessSection, VoluntaryExcessModal } from '../excess'

export const ProposalExcessSection: FunctionComponent<SectionProps> = (props) => {
  const modal = useModal()

  const { proposal } = useProposal()
  const { product } = useProduct()

  const quote = getCurrentQuote(proposal)

  const onExcessChange = useCallback(() => {
    modal.show({
      content: <ProposalVoluntaryExcessModal onComplete={modal.hide} />,
    })
  }, [modal])

  return (
    <ExcessSection
      config={product.excess}
      currentQuote={quote}
      onChange={onExcessChange}
      {...props}
    />
  )
}
export const ProposalVoluntaryExcessModal: FunctionComponent<{
  onComplete: () => void
}> = ({ onComplete }) => {
  const { loading, execute } = useProposalExcessAdjustment(onComplete)

  const { proposal } = useProposal()

  const quote = getCurrentQuote(proposal)

  return <VoluntaryExcessModal loading={loading} onChange={execute} quote={quote} />
}
