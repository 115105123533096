import { FullPageTemplate } from '@wrisk/ui-components'
import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react'

import { Footer } from './Footer'

export interface FullPageProps extends PropsWithChildren {
  pageId?: string
  header: ReactNode
  subheader?: ReactNode
  upperHeader?: ReactNode
  helpLinkText?: ReactNode
  helpLinkUrl?: string
  upButton?: {
    to: string
    text: string
  }
}

export const FullPage: FunctionComponent<FullPageProps> = ({ ...props }) => (
  <FullPageTemplate {...props} footer={<Footer />} />
)
