import { SectionProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { CustomerPayment } from '../../../../domain'
import { usePolicy } from '../../../product/policy/policyContext'
import { DeferredPremiumSection } from './components/DeferredPremiumSection'
import { PaymentPremiumSection } from './components/PaymentPremiumSection'

export interface ProrataPremiumSectionProps extends SectionProps {
  payment: CustomerPayment | undefined
  prorataPremium: number
}

const NULL_PAYMENT: CustomerPayment = {
  paymentId: '',
  amount: 0,
}

export const ProrataPremiumSection: FunctionComponent<ProrataPremiumSectionProps> = ({
  payment,
  prorataPremium,
  ...props
}) => {
  const { policy } = usePolicy()

  return policy.policyDetail.monthlyBilling ? (
    <DeferredPremiumSection prorataPremium={prorataPremium} {...props} />
  ) : (
    <PaymentPremiumSection
      payment={payment ?? NULL_PAYMENT}
      prorataPremium={prorataPremium}
      {...props}
    />
  )
}
