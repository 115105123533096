import React, { FunctionComponent, useMemo } from 'react'
import { generatePath, Navigate, Route, Routes } from 'react-router-dom'

import { Page, useCurrentPath } from '../../../../infrastructure/routing'
import { ManagementType } from '../../../../state/configuration'
import { AppPath } from '../../../AppPath'
import { useProduct } from '../../productContext'
import {
  AdditionalProfileAdjustmentRouter,
  PolicyAdjustmentPath,
  PolicyAdjustmentRouter,
} from '../adjustments'
import { ManagementPage } from './ManagementPage'

export const PolicyManagementRouter: FunctionComponent<{ parent: Page }> = ({
  parent,
}) => {
  const {
    product: { management },
  } = useProduct()
  const currentPath = useCurrentPath()

  const managementPage = useMemo(
    () => ({ url: currentPath, id: 'management' }),
    [currentPath],
  )

  const routes = management.map((it) => {
    const path = generatePath(PolicyAdjustmentPath.Section, { sectionId: it.id })

    const element = (() => {
      switch (it.type) {
        case ManagementType.ADDITIONAL_PROFILE:
          return (
            <AdditionalProfileAdjustmentRouter
              config={it}
              adjustmentPage={managementPage}
              policyPage={parent}
            />
          )
        default:
          return (
            <PolicyAdjustmentRouter
              config={it}
              adjustmentPage={managementPage}
              policyPage={parent}
            />
          )
      }
    })()

    return <Route key={it.id} path={path + AppPath.WILDCARD} element={element} />
  })

  return (
    <Routes>
      <Route index element={<ManagementPage parent={parent} />} />
      <Route path='*' element={<Navigate to='..' />} />
      {routes}
    </Routes>
  )
}
