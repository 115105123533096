import { TabView } from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import React, { FunctionComponent, useCallback, useState } from 'react'
import { Trans } from 'react-i18next'

import { Specification } from '../../../../domain'
import { TKeyBuilder } from '../../../../infrastructure/internationalisation'
import { VehicleLookupWrapper } from '../VehicleLookup'
import { SelectModel } from '../VehicleLookup/SelectModel'
import { VehicleDropdowns } from './DropdownsTab/VehicleDropdowns'
import { VehicleTabsConfig } from './types'

export interface VehicleTabsProps {
  t: TFunction
  tKey: TKeyBuilder
  tName: string
  initialTab: number
  value?: Specification
  config?: VehicleTabsConfig
  onVehicleSelected: (
    specification?: Specification,
    foundByLookup?: boolean,
    vrn?: string,
  ) => void
}

export const VehicleTabs: FunctionComponent<VehicleTabsProps> = ({
  t,
  tKey,
  tName,
  initialTab,
  value,
  config,
  onVehicleSelected,
}) => {
  const [tab, setTab] = useState(initialTab)

  const onTabChange = useCallback(
    (index: number) => {
      onVehicleSelected(undefined, undefined, undefined)
      setTab(index)
    },
    [onVehicleSelected],
  )

  const onVehicleSelectByLookup = useCallback(
    (specification?: Specification) => {
      onVehicleSelected(specification, true, specification?.vrn ?? undefined)
    },
    [onVehicleSelected],
  )

  const onVehicleSelectByDropdowns = useCallback(
    (specification?: Specification) => {
      onVehicleSelected(specification, false, undefined)
    },
    [onVehicleSelected],
  )

  const onNotMyVehicleClick = useCallback(() => {
    onTabChange(1)
  }, [onTabChange])

  const onVehicleSelectDataChange = useCallback(
    (specification?: Specification) => {
      if (!specification) {
        onVehicleSelected(undefined, undefined, undefined)
      }
    },
    [onVehicleSelected],
  )

  const error = (
    <Trans
      t={t}
      i18nKey={tKey(tName, 'validation', 'notFoundTabs')}
      components={{
        cta: <SelectModel onClick={onNotMyVehicleClick} />,
      }}
    />
  )

  const tabItems = [
    {
      header: t(tKey(tName, 'tabHeaders.searchReg')),
      component: () => (
        <VehicleLookupWrapper
          value={value}
          onLookupComplete={onVehicleSelectByLookup}
          error={error}
        />
      ),
    },
    {
      header: t(tKey(tName, 'tabHeaders.selectVehicle')),
      component: () => (
        <VehicleDropdowns
          config={config}
          value={value}
          onVehicleDataChange={onVehicleSelectDataChange}
          onConfirmButtonClick={onVehicleSelectByDropdowns}
        />
      ),
    },
  ]

  return (
    <TabView
      width={1}
      key={tab}
      items={tabItems}
      tabIndex={tab}
      onTabChange={onTabChange}
    />
  )
}
