// eslint-disable-next-line import/no-unresolved
import { Property } from 'csstype'
import { ResponsiveValue, system } from 'styled-system'

export interface WordBreakProps {
  wordBreak?: ResponsiveValue<Property.WordBreak>
}

export const wordBreak = system({
  wordBreak: true,
})
