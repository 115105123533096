import {
  Box,
  InputWrapper,
  PrimaryButton,
  smBumps,
  TextInput,
  Typo,
} from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import React, {
  ChangeEvent,
  FunctionComponent,
  HTMLInputTypeAttribute,
  useCallback,
  useState,
} from 'react'

import { with404ErrorHandling } from '../../../../clients/api'
import { GetDataEntry } from '../../../../domain'
import { useApiErrorHandlingAsyncCallback } from '../../../../hooks/auth'
import { TKeyBuilder } from '../../../../infrastructure/internationalisation'
import { usePrincipal } from '../../../authentication'

interface DataEntrySearchViewProps {
  name: string
  value: GetDataEntry | undefined

  tKey: TKeyBuilder
  t: TFunction

  onChange: (entry: GetDataEntry) => void
  tableCode: string

  placeholder?: string
  characterLimit?: number
  type?: HTMLInputTypeAttribute
  regExp?: string
}

export const DataEntrySearchView: FunctionComponent<DataEntrySearchViewProps> = ({
  tableCode,
  tKey,
  t,
  name,
  value,
  onChange,
  placeholder,
  characterLimit,
  regExp,
  type,
}) => {
  const { apiClient } = usePrincipal()

  const [textValue, setTextValue] = useState(value?.code ?? '')
  const [valid, setValid] = useState(Boolean(value))

  const onSubmit = useApiErrorHandlingAsyncCallback(async () => {
    const entry = await with404ErrorHandling(() =>
      apiClient.getDataEntry(tableCode, textValue),
    )
    if (entry) {
      onChange(entry)
    }
  })

  const onInputChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const value = target.value.toUpperCase()

      onSubmit.reset()
      setValid(!regExp || new RegExp(regExp).test(value))

      if (!characterLimit || value.length <= characterLimit) {
        setTextValue(value)
      }
    },
    [characterLimit, onSubmit, regExp],
  )

  const errorText = Boolean(onSubmit.status === 'success' && !onSubmit.result) && (
    <Box mt={smBumps}>
      <Typo color='textCritical'>{t(tKey(name, 'validation.noResults'))}</Typo>
    </Box>
  )

  const onInputKeyPress = useCallback(
    async (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        await onSubmit.execute()
      }
    },
    [onSubmit],
  )

  return (
    <Box>
      <InputWrapper>
        <TextInput
          width={1}
          type={type ?? 'text'}
          variant='ghost'
          value={textValue}
          onChange={onInputChange}
          placeholder={placeholder}
          onKeyPress={onInputKeyPress}
          maxLength={characterLimit}
        />
        <PrimaryButton
          minWidth='8rem'
          layout='small'
          loading={onSubmit.loading}
          onClick={onSubmit.execute}
          disabled={!valid}
          data-testid='search'
          whiteSpace='nowrap'
        >
          {t(tKey(name, 'actions.check'))}
        </PrimaryButton>
      </InputWrapper>
      {errorText}
    </Box>
  )
}
