import { AxiosInstance } from 'axios'

import { Claim } from '../../../domain'
import { withGenericErrorHandling } from '../errors'

export interface ClaimsClient {
  getClaims: (policyNumber: string) => Promise<Claim[]>
}

export const createClaimsClient = (
  getInstance: () => Promise<AxiosInstance>,
): ClaimsClient => ({
  getClaims: (policyNumber: string) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.get<Claim[]>('/claims', {
        params: { policyNumber },
      })
      return response.data
    }),
})
