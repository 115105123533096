import {
  BoxProps,
  Flex,
  mdBumps,
  Notification,
  Typo,
  xsBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, PropsWithChildren } from 'react'

import { CustomerStatementStatus, CustomerStatementSummary } from '../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { toDateTime } from '../../../util/date'

export interface PaymentPendingInfoProps extends BoxProps {
  summary: CustomerStatementSummary
  isHighlighted?: boolean
}

interface InfoComponentProps extends PropsWithChildren {
  isHighlighted?: boolean
}

const InfoComponent: FunctionComponent<InfoComponentProps> = ({
  isHighlighted,
  children,
}) =>
  isHighlighted ? (
    <Notification layout='small'>{children}</Notification>
  ) : (
    <Flex width={1} px={mdBumps} py={xsBumps}>
      {children}
    </Flex>
  )

const tKey = TKey('components.payment-pending-info')

export const PaymentPendingInfo: FunctionComponent<PaymentPendingInfoProps> = ({
  summary: { amountOutstanding, status, paymentDueAt, final },
  isHighlighted,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const isPendingStatusWithOutstandingBalance =
    status === CustomerStatementStatus.PENDING && amountOutstanding > 0

  const notification = isPendingStatusWithOutstandingBalance
    ? final
      ? t(tKey('pending-final-payment'))
      : t(tKey('pending-payment'), {
          paymentDueAt: t(tFormats('date.medium'), {
            value: toDateTime(paymentDueAt),
          }),
        })
    : final && amountOutstanding < 0
    ? t(tKey('pending-refund'), {
        amount: t(tFormats('currency.long'), {
          amount: -amountOutstanding / 100,
        }),
      })
    : null

  if (!notification) return null

  return (
    <InfoComponent isHighlighted={isHighlighted} {...props}>
      <Typo typoSize='sm'>{notification}</Typo>
    </InfoComponent>
  )
}
