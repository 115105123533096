import { Box, ModalHeader, SectionProps, useModal } from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'

import { getCurrentQuote, PriceOption } from '../../../domain'
import {
  useProposalAdjustment,
  useProposalFormData,
} from '../../../hooks/adjustments/proposal'
import {
  TExtends,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { AdjustmentInputConfig, getCoreAssetType } from '../../../state/configuration'
import { FormDisabled } from '../../formBuilder'
import { useProduct } from '../../product/productContext'
import { useProposal } from '../../product/proposal/proposalContext'
import { CoverSection } from '../covers'

export const ProposalCoverSection: FunctionComponent<SectionProps> = (props) => {
  const { proposal } = useProposal()
  const { product } = useProduct()

  const { covers } = getCoreAssetType(product)

  const modal = useModal()

  const quote = getCurrentQuote(proposal)

  const onChange = useCallback(
    (cover: PriceOption) => {
      const { inputs } = covers.optional?.find((it) => it.code === cover.coverCode) ?? {}

      if (!inputs) {
        throw new Error(`No inputs found for optional cover ${cover.coverCode}`)
      }

      modal.show({
        content: (
          <ProposalEditOptionalCoverModal inputs={inputs} onComplete={modal.hide} />
        ),
      })
    },
    [covers, modal],
  )

  return <CoverSection quote={quote} onChange={onChange} {...props} />
}

const tKey = TKey('components.edit-optional-cover-modal')

interface EditOptionalCoverModalProps {
  inputs: AdjustmentInputConfig[]
  onComplete: () => void
}

const ProposalEditOptionalCoverModal: FunctionComponent<EditOptionalCoverModalProps> = ({
  inputs,
  onComplete,
}) => {
  const { t } = useWriskTranslation()

  const data = useProposalFormData(inputs)
  const onSubmitAdjustment = useProposalAdjustment(inputs, true, onComplete)

  return (
    <Box>
      <ModalHeader header={t(tKey('header'))} />
      <FormDisabled
        id='editOptionalCover'
        data={data}
        inputs={inputs}
        onSubmit={onSubmitAdjustment.execute}
        loading={onSubmitAdjustment.loading}
        tKey={TExtends(tKey, 'inputs')}
        options={{ shouldUnregister: true }}
        t={t}
      />
    </Box>
  )
}
