import {
  Box,
  Flex,
  FlexAnchor,
  FlexProps,
  LinkAnchor,
  mdBumps,
  PrimaryButton,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  tDefaults,
  TKeyBuilder,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

export interface ActionBarProps extends FlexProps {
  onConfirm?: () => void
  onDismiss?: () => void
  tKey: TKeyBuilder
  loading?: boolean
  disabled?: boolean
  destructive?: boolean
}

export const ActionBar: FunctionComponent<ActionBarProps> = ({
  onDismiss,
  onConfirm,
  tKey,
  loading = false,
  disabled = false,
  destructive = false,
  ...props
}) => {
  const { t } = useWriskTranslation()

  return (
    <Flex width={1} {...props}>
      <PrimaryButton
        type={onConfirm ? 'button' : 'submit'}
        variant={destructive ? 'critical' : 'default'}
        layout='fixed'
        onClick={onConfirm}
        loading={loading}
        disabled={disabled}
        data-testid='button-confirm'
      >
        {t([tKey('actions.confirm'), tDefaults('actions.confirm')])}
      </PrimaryButton>

      {onDismiss && (
        <Box ml={mdBumps}>
          <LinkAnchor
            as={FlexAnchor}
            variant='standalone'
            typoSize='sm'
            onClick={onDismiss}
          >
            {t([tKey('actions.discard'), tDefaults('actions.discard')])}
          </LinkAnchor>
        </Box>
      )}
    </Flex>
  )
}
