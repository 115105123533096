import { Box, BoxProps, Flex, mdBumps, smBumps, Typo } from '@wrisk/ui-components'
import { negate } from 'lodash'
import React, { FunctionComponent } from 'react'

import { getCoreAsset, isMainExcess, Quote } from '../../../domain'
import {
  tExcesses,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { allEqual } from '../../../util/array'
import { ExcessEndorsements } from './ExcessEndorsements'
import { ExcessRow } from './ExcessRow'

export interface ExcessSummaryProps extends BoxProps {
  quote: Quote
}

const tKey = TKey('components.excess-summary')

export const ExcessSummary: FunctionComponent<ExcessSummaryProps> = ({
  quote,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const { mandatoryExcessLines, voluntaryExcess } = getCoreAsset(quote)

  const mainExcessValues = mandatoryExcessLines
    .filter(isMainExcess)
    .map((it) => it.excess)

  const isRolledUp = allEqual(mainExcessValues)

  const mainExcessSection = isRolledUp ? (
    <ExcessRow
      currentExcess={mainExcessValues[0]}
      header={t(tKey('headings.compulsory'))}
      typoSize='sm'
      px={smBumps}
    />
  ) : (
    <>
      <Box px={mdBumps} py={2}>
        <Typo typoSize='sm'>{t(tKey('headings.compulsory'))}</Typo>
      </Box>
      {mandatoryExcessLines
        .filter(isMainExcess)
        .filter((it) => it.excess > 0)
        .map((it) => (
          <ExcessRow
            key={it.excessCategory}
            currentExcess={it.excess}
            header={t(tExcesses(it.excessCategory, 'name'))}
            typoSize='sm'
            px={smBumps}
          />
        ))}
    </>
  )

  const voluntaryExcessSection = (
    <ExcessRow
      currentExcess={voluntaryExcess}
      header={t(tKey('headings.voluntary'))}
      typoSize='sm'
      px={smBumps}
    />
  )

  const otherExcessSection = mandatoryExcessLines
    .filter(negate(isMainExcess))
    .map((it) => (
      <ExcessRow
        key={it.excessCategory}
        currentExcess={it.excess}
        header={t(tExcesses(it.excessCategory, 'name'))}
        typoSize='sm'
        px={smBumps}
      />
    ))

  const endorsementsSection = <ExcessEndorsements quote={quote} />

  return (
    <Flex
      width={1}
      flexDirection='column'
      justifyContent='flex-start'
      rowGap={smBumps}
      py={smBumps}
      {...props}
    >
      {mainExcessSection}
      {voluntaryExcessSection}
      {otherExcessSection}
      {endorsementsSection}
    </Flex>
  )
}
