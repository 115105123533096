import {
  Flex,
  MainSection,
  mdBumps,
  pageEntranceSpringValues,
  PageLoadingIndicator,
  Section,
  SectionContent,
  SectionTitle,
  SideSection,
  Typo,
  WideContainer,
  xlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { animated, useSpring } from 'react-spring'

import { useApiErrorHandlingAsync } from '../../../../../../hooks/auth'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { usePrincipal } from '../../../../../authentication'
import { ClaimCard } from '../../../../../organisms/claims'
import { usePolicy } from '../../../policyContext'
import { ClaimsSidebar } from '../components/ClaimsSidebar'

const tKey = TKey('pages.claims-tab')

export const ClaimsTab: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  const spring = useSpring(pageEntranceSpringValues)
  const { apiClient } = usePrincipal()
  const {
    policy: { policyNumber },
  } = usePolicy()

  const { loading, result: claims } = useApiErrorHandlingAsync(apiClient.getClaims, [
    policyNumber,
  ])

  if (loading || !claims) {
    return <PageLoadingIndicator />
  }

  return (
    <animated.div style={spring}>
      <WideContainer variant='sidebar'>
        <SideSection>
          <ClaimsSidebar />
        </SideSection>
        <MainSection>
          <Section width={1} mb={xlBumps}>
            <SectionTitle>{t(tKey('header'))}</SectionTitle>
            <SectionContent>
              {claims.length ? (
                claims.map((it, i) => <ClaimCard claim={it} key={i} mt={i && mdBumps} />)
              ) : (
                <Flex variant='raised' p={mdBumps}>
                  <Typo>{t(tKey('no-claims'))}</Typo>
                </Flex>
              )}
            </SectionContent>
          </Section>
        </MainSection>
      </WideContainer>
    </animated.div>
  )
}
