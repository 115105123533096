import css from '@styled-system/css'
import styled from 'styled-components'
import { compose, layout, LayoutProps, space, SpaceProps, variant } from 'styled-system'

export type LogoNavProperty = 'default'

export interface LogoNavProps extends LayoutProps, SpaceProps {
  variant?: LogoNavProperty
}

export const LogoNav = styled.div<LogoNavProps>(
  css({
    display: 'flex',
    alignItems: 'center',
    minHeight: '50%',
    lineHeight: 0,
  }),
  variant({
    scale: 'LogoNav',
    variants: {
      default: {},
    },
  }),
  compose(layout, space),
)

LogoNav.defaultProps = {
  variant: 'default',
}
