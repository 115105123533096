import { isNil } from 'lodash'
import React, { ChangeEvent, FunctionComponent, useCallback } from 'react'

import { Box, BoxProps, TextInput } from '../atoms'
import { useNotifyingState } from '../extensions'
import { InputMessage } from './InputMessage'

export const parser = (value: string): number | undefined => {
  const sanitisedValue = value.replace(/[^0-9]/g, '')
  if (sanitisedValue.length === 0) {
    return undefined
  }
  const num = Number(sanitisedValue)
  return Number.isInteger(num) ? num : undefined
}

export interface NumberInputProps extends BoxProps {
  inputErrorMessage: string
  inputPlaceholder: string
  onChange: (value: number | undefined) => void
  shouldSetValue: (value: number | undefined) => boolean
  shouldShowError: (value: number | undefined) => boolean
  formatter: (value: number) => string
  initialValue?: number
}

export const NumberInput: FunctionComponent<NumberInputProps> = (props) => {
  const {
    inputErrorMessage,
    initialValue,
    inputPlaceholder,
    shouldShowError,
    shouldSetValue,
    formatter,
    onChange,
    ...boxProps
  } = props

  const [value, setValue] = useNotifyingState(initialValue, [onChange])

  const onValueChanged = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const valueAsNumber = parser(target.value)
      if (shouldSetValue(valueAsNumber)) {
        setValue(valueAsNumber)
      }
    },
    [setValue, shouldSetValue],
  )

  const format = (num: number | undefined) => (isNil(num) ? '' : formatter(num))

  return (
    <Box width='140px' {...boxProps}>
      <TextInput
        type='tel'
        id='number'
        name='number'
        placeholder={inputPlaceholder}
        value={format(value)}
        onChange={onValueChanged}
      />
      {shouldShowError(value) && <InputMessage>{inputErrorMessage}</InputMessage>}
    </Box>
  )
}
