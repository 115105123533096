import React, { FunctionComponent, ReactNode } from 'react'

import { getPolicyDocuments, PolicyVersion } from '../../../../domain'
import { useConfig } from '../../../../state/configuration'
import { useProduct } from '../../../product/productContext'
import { ProductDocumentsSection } from '../ProductDocumentsSection'
import { PolicyDocumentLink } from './PolicyDocumentLink'

export interface PolicyDocumentsSectionProps {
  policyVersion: PolicyVersion
  title?: string
  subtitle?: ReactNode
}

export const PolicyDocumentsSection: FunctionComponent<PolicyDocumentsSectionProps> = ({
  policyVersion: { policyRefNumber, quote },
  title,
  subtitle,
}) => {
  const {
    scheme: { schemeCode },
  } = useProduct()

  const policyDocumentsConfig = useConfig(getPolicyDocuments(schemeCode, quote))

  const policyDocuments = policyDocumentsConfig.map((document) => (
    <PolicyDocumentLink
      key={document.id}
      policyRefNumber={policyRefNumber}
      {...document}
    />
  ))

  return (
    <ProductDocumentsSection
      quote={quote}
      additionalDocuments={policyDocuments}
      header={title}
      subheader={subtitle}
    />
  )
}
