import React, { FunctionComponent } from 'react'

import { Box, BoxProps, Icon, NavAnchor } from '../atoms'
import { AccountInitials } from './AccountInitials'

export interface AccountNavBoxProps extends BoxProps {
  user: {
    firstName?: string
    lastName?: string
  }
  onClick: () => void
  active: boolean
}

export const AccountNavBox: FunctionComponent<AccountNavBoxProps> = ({
  user,
  onClick,
  active,
}) => {
  return (
    <NavAnchor id='accountNavAction' onClick={onClick}>
      <Box mr={1}>
        <AccountInitials firstName={user.firstName} lastName={user.lastName} />
      </Box>
      <Icon
        icon='chevronDown'
        size='tiny'
        rotate={active ? 180 : 0}
        transition='default'
      />
    </NavAnchor>
  )
}
