import { LicencePlateInput, PrimaryButton } from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'

import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'

interface VehicleLookupProps {
  disabled?: boolean
  value?: string
  loading: boolean
  onChange: (licencePlate: string) => void
  onSearchExecute: () => void
}

const tKey = TKey('components.vehicle-lookup')

export const VehicleLookup: FunctionComponent<VehicleLookupProps> = ({
  disabled,
  loading,
  value,
  onChange,
  onSearchExecute,
}) => {
  const { t } = useWriskTranslation()

  const handleSearchExecute = useCallback(() => {
    if (!disabled) onSearchExecute()
  }, [disabled, onSearchExecute])

  return (
    <LicencePlateInput
      name='vehicleRegistration'
      initialValue={value}
      placeholder={t<string>(tKey('placeholder'))}
      onChange={onChange}
      onEnterPress={handleSearchExecute}
    >
      <PrimaryButton
        data-testid='vehicle-search'
        disabled={disabled}
        layout='small'
        loading={loading}
        whiteSpace='nowrap'
        onClick={handleSearchExecute}
      >
        {t(tKey('actions.search'))}
      </PrimaryButton>
    </LicencePlateInput>
  )
}
