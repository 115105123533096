import { useMemo } from 'react'

import { usePrincipal } from '../../../../application/authentication'
import { usePolicy } from '../../../../application/product/policy/policyContext'
import {
  Data,
  PolicyAdjustmentResponse,
  PolicyChangeType,
  Profile,
} from '../../../../domain'
import { AdjustmentInputConfig } from '../../../../state/configuration'
import { useApiErrorHandlingAsyncCallback } from '../../../auth'
import { useErrorHandlingAsyncCallback } from '../../../errors'
import { hasChanges } from '../helpers'
import { toAdditionalProfilePolicyAdjustment } from './adjustments'
import { toAdditionalProfilePolicyData } from './data'

export const useAdditionalProfileFormData = (
  inputs: AdjustmentInputConfig[],
  profile: Profile | undefined,
) => {
  return useMemo(() => {
    const toData = toAdditionalProfilePolicyData(profile)

    return inputs.reduce(
      (data, input) => ({
        ...data,
        [input.name]: toData(input.adjustment),
      }),
      {},
    )
  }, [inputs, profile])
}

export const useSubmitRemoveProfilePolicyAdjustment = (
  onSubmitted?: (response: PolicyAdjustmentResponse) => void,
) => {
  const { policy } = usePolicy()
  const { apiClient } = usePrincipal()

  return useErrorHandlingAsyncCallback(async (profile: Profile, effectiveAt?: string) => {
    const response = await apiClient.createPolicyAdjustment({
      policyId: policy.policyId,
      adjustmentType: 'MTA',
      changes: [
        {
          changeType: PolicyChangeType.REMOVE_PROFILE,
          profileCode: profile.profileCode,
        },
      ],
      ...(effectiveAt && { effectiveAt }),
    })

    onSubmitted?.(response)
  })
}

export const useUpdateAdditionalProfilePolicyAdjustment = (
  inputs: AdjustmentInputConfig[],
  profile: Profile,
  onSubmitted?: (response?: PolicyAdjustmentResponse) => void,
) => {
  const { apiClient } = usePrincipal()
  const { policy } = usePolicy()

  const existingData = useAdditionalProfileFormData(inputs, profile)
  const profilePolicyAdjustment = toAdditionalProfilePolicyAdjustment(
    inputs,
    policy,
    profile,
  )

  return useApiErrorHandlingAsyncCallback(async (data: Data, effectiveAt?: string) => {
    const policyAdjustment = profilePolicyAdjustment(existingData, data, effectiveAt)

    if (hasChanges(policyAdjustment)) {
      const response = await apiClient.createPolicyAdjustment(policyAdjustment)
      onSubmitted?.(response)
    } else {
      onSubmitted?.()
    }
  })
}
