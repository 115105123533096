import { default as React, FunctionComponent } from 'react'

import { toVehicleDescription } from '../../../domain'
import { tDefaults } from '../../../infrastructure/internationalisation'
import { VehicleInputProps } from '../inputs'
import { Formatter } from '../types'

export const VehicleFormatter: FunctionComponent<Formatter<VehicleInputProps>> = ({
  value,
  t,
}) =>
  value?.specification ? (
    <>{toVehicleDescription(value.specification)}</>
  ) : (
    <>{t(tDefaults('inputs.no-answer'))}</>
  )
