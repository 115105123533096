import { Grid } from '@wrisk/ui-components'
import React from 'react'

import { IncludedCoverItemWithInfo } from './IncludedCoverItemWithInfo'

interface IncludedCoverItemsWithInfoProps {
  includedCovers: string[]
}

export const IncludedCoverItemsWithInfo: React.FC<IncludedCoverItemsWithInfoProps> = ({
  includedCovers,
}) => {
  const includedCoverItemsWithInfo = includedCovers.map((cover) => (
    <IncludedCoverItemWithInfo key={cover} width={1} cover={cover} />
  ))

  return (
    <Grid
      width={1}
      p={2}
      gridTemplateColumns='1fr'
      gridTemplateRows='auto'
      alignItems='stretch'
    >
      {includedCoverItemsWithInfo}
    </Grid>
  )
}
