import { FlexProps } from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { Profile, Quote } from '../../../../domain'
import { useRemoveAdditionalProfileProposalAdjustment } from '../../../../hooks/adjustments/proposal'
import { AdditionalProfileManagementConfig } from '../../../../state/configuration'
import { AdditionalProfileView } from '../../../organisms/quote'
import { ProposalPath } from '../../proposal/ProposalPath'
import { AdditionalProfilePath } from '../RenewalAdditionalProfileRouter'
import { RenewalPath } from '../RenewalPath'

export interface PolicyManagementSectionProps extends FlexProps {
  config: AdditionalProfileManagementConfig
  quote: Quote
}

export const AdditionalProfileManagement: FunctionComponent<
  PolicyManagementSectionProps
> = ({ quote, config, ...props }) => {
  const navigate = useNavigate()

  const onUpdateCallback = useCallback(
    (profile: Profile) => {
      navigate(
        [
          ProposalPath.DISCLOSURE,
          generatePath(RenewalPath.SECTION, { sectionId: config.id }),
          generatePath(AdditionalProfilePath.Profile, profile),
        ].join('/'),
      )
    },
    [config, navigate],
  )
  const onRemoveCallback = useRemoveAdditionalProfileProposalAdjustment(true)
  const onAddCallback = useCallback(() => {
    navigate(
      [
        ProposalPath.DISCLOSURE,
        generatePath(RenewalPath.SECTION, { sectionId: config.id }),
        generatePath(AdditionalProfilePath.NewProfile),
      ].join('/'),
    )
  }, [config, navigate])

  return (
    <AdditionalProfileView
      insuredProfiles={quote.insuredProfiles}
      onAdd={onAddCallback}
      onRemove={onRemoveCallback.execute}
      onUpdate={onUpdateCallback}
      loading={onRemoveCallback.loading}
      maxAdditionalProfiles={4}
      {...props}
    />
  )
}
