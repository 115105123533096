import { Illus } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { getCurrentPolicyVersion, PolicyAdjustmentResponse } from '../../../../../domain'
import { useNavigateCallback } from '../../../../../hooks/navigation'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { Page } from '../../../../../infrastructure/routing'
import { isISOToday, toFormattedDateWithDay } from '../../../../../util/date'
import { usePrincipal } from '../../../../authentication'
import { Feedback } from '../../../../organisms/feedback/Feedback'
import { ActionBar } from '../../../../organisms/form'
import { FullPage } from '../../../../templates'
import { usePolicy } from '../../policyContext'

const tKey = TKey('pages.adjustment-success')

interface AdjustmentSuccessPageProps {
  adjustmentPage: Page
  policyPage: Page
  policyAdjustmentResponse: PolicyAdjustmentResponse
}

export const AdjustmentSuccessPage: FunctionComponent<AdjustmentSuccessPageProps> = ({
  adjustmentPage,
  policyPage,
  policyAdjustmentResponse,
}) => {
  const { t } = useWriskTranslation()
  const { isAdmin } = usePrincipal()
  const {
    policy: { policyId },
  } = usePolicy()

  const policyVersion = getCurrentPolicyVersion(
    policyAdjustmentResponse.updatedPolicyVersions,
  )

  const startedAt = policyVersion.startedAt
  const subheaderPath =
    startedAt && !isISOToday(policyVersion.startedAt) ? 'deferred' : 'immediately'
  const subheader = t(tKey('subheader', subheaderPath), {
    effectiveAt: toFormattedDateWithDay(startedAt ?? ''),
  })

  const onConfirm = useNavigateCallback(policyPage.url)
  const onDismiss = useNavigateCallback(adjustmentPage.url)

  return (
    <FullPage
      pageId='adjustment-success-page'
      header={t(tKey('header'))}
      subheader={subheader}
      upperHeader={<Illus alt='Success' illus='bindSuccess' />}
    >
      <ActionBar
        onConfirm={onConfirm}
        onDismiss={onDismiss}
        loading={false}
        tKey={tKey}
      />
      {!isAdmin && <Feedback type='mta' tags={{ policyId }} />}
    </FullPage>
  )
}
