import React, { FunctionComponent } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { AppPath } from '../../../AppPath'
import { DisclosureRouter, ProposalReferralPage } from '../disclosure'
import { ProposalPath } from '../ProposalPath'

export const ProposalReferredRoutes: FunctionComponent = () => (
  <Routes>
    <Route key='referral' index element={<ProposalReferralPage />} />
    <Route
      key='disclosure'
      path={ProposalPath.DISCLOSURE + AppPath.WILDCARD}
      element={<DisclosureRouter />}
    />
    <Route path='*' element={<Navigate to={AppPath.HOME} />} />
  </Routes>
)
