import { FlexProps, useModal } from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { getFirstInceptedDate, getPolicyToEndAt, getPolicyVersion } from '../../../domain'
import { useIsMtaAllowed } from '../../../hooks/adjustments/policy/renewal'
import { usePolicy } from '../../product/policy/policyContext'
import { PolicyPath } from '../../product/policy/PolicyPath'
import { InRenewalMtaBlockedModal } from '../InRenewalMtaBlockedModal'
import { QuoteDetailsView } from '../quote'

export const PolicyDetailsView: FunctionComponent<FlexProps> = (props) => {
  const navigate = useNavigate()

  const { policy } = usePolicy()
  const { quote } = getPolicyVersion(policy)

  const modal = useModal()
  const isMtaAllowed = useIsMtaAllowed()

  const onEdit = useCallback(() => {
    isMtaAllowed
      ? navigate(PolicyPath.MANAGEMENT)
      : modal.show({
          content: <InRenewalMtaBlockedModal />,
        })
  }, [isMtaAllowed, modal, navigate])

  const startAt = getFirstInceptedDate(policy)
  const endAt = policy.policyDetail.monthlyBilling ? undefined : getPolicyToEndAt(policy)

  return (
    <QuoteDetailsView
      onEdit={onEdit}
      quote={quote}
      startAt={startAt}
      endAt={endAt}
      {...props}
    />
  )
}
