import React, { FunctionComponent } from 'react'

import { Box, Flex, xsBumps } from '../atoms'
import { OptionTile } from './OptionTile'

export interface BooleanInputProps {
  name: string
  value: boolean | undefined
  onSelect: (value: boolean) => void
}

export const BooleanInput: FunctionComponent<BooleanInputProps> = ({
  name,
  value,
  onSelect,
}) => (
  <Flex flexDirection='row' width={[1, 1, 3 / 5]}>
    <Box width={1} mr={xsBumps}>
      <OptionTile
        name={name}
        value={true}
        text='Yes'
        onSelect={onSelect}
        checked={value === true}
      />
    </Box>
    <Box width={1}>
      <OptionTile
        name={name}
        value={false}
        text='No'
        onSelect={onSelect}
        checked={value === false}
      />
    </Box>
  </Flex>
)
