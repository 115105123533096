import css from '@styled-system/css'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'
import {
  BackgroundColorProps,
  BorderProps,
  borders,
  color,
  compose,
  display,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from 'styled-system'

import { defaultConfig } from '../../extensions'
import { gap, GapProps } from '../containers/gap'
import { containerStyle, ContainerStyleProps } from './containerStyle'
import { displayOn, DisplayOnProps } from './displayOn'

export type FlexProps = FlexboxProps &
  LayoutProps &
  SpaceProps &
  BorderProps &
  BackgroundColorProps &
  PositionProps &
  ContainerStyleProps &
  DisplayOnProps &
  GapProps &
  PropsWithChildren

export const Flex = styled.div.withConfig(defaultConfig)<FlexProps>(
  css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderColor: 'divider',
    borderStyle: 'solid',
    borderWidth: 0,
  }),
  containerStyle,
  displayOn,
  compose(flexbox, display, layout, space, borders, color, position, gap),
)

Flex.defaultProps = {
  variant: 'default',
  displayOn: 'default',
}
