import React, { FunctionComponent } from 'react'

import { AccountIcon, Typo } from '../atoms'

export const AccountInitials: FunctionComponent<{
  firstName?: string
  lastName?: string
}> = ({ firstName, lastName }) => {
  const first = firstName ?? '?'
  const last = lastName ?? '?'

  return (
    <AccountIcon>
      <Typo
        lineHeight={1}
        fontFamily='input'
        variant='heading'
        color='background'
        typoSize='xs'
        data-testid='account-initial'
      >
        {first.charAt(0).toUpperCase()}
        {last.charAt(0).toUpperCase()}
      </Typo>
    </AccountIcon>
  )
}
