import styled from 'styled-components'

export const GhostSelect = styled.select((props) => ({
  border: 'none',
  appearance: 'none',
  '-webkit-appearance': 'none',
  cursor: 'pointer',
  width: '100%',

  background: `url(${props.theme.icons.chevronDown.url}) no-repeat right center`,
  backgroundSize: '16px',

  padding: '1.25rem 0',

  '&:focus': {
    outline: '0',
  },
  '&[disabled] &:disabled': {
    opacity: '0.4',
    cursor: 'not-allowed',
  },
  '&:not(:focus):invalid': {
    color: '${(props) => props.theme.colors.placeholder}',
  },
}))
