import React, { FunctionComponent } from 'react'

import { tEntries } from '../../../infrastructure/internationalisation'
import { Formatter } from '../types'

export const CascadingDropdownFormatter: FunctionComponent<Formatter<string>> = ({
  value,
  input: { name },
  t,
}) => <>{value ? t(tEntries(name, value)) : t('defaults.inputs.no-answer')}</>
