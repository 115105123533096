// eslint-disable-next-line import/no-unresolved
import { Property } from 'csstype'
import { ResponsiveValue, system } from 'styled-system'

export interface WordWrapProps {
  wordWrap?: ResponsiveValue<Property.WordWrap>
}

export const wordWrap = system({
  wordWrap: true,
})
