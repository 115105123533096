import { useModal } from '@wrisk/ui-components'
import React, { FunctionComponent, useEffect, useState } from 'react'

import { useProduct } from '../../product/productContext'
import { FeedbackModal } from './FeedbackModal'

interface FeedbackProps {
  type: string
  tags: Record<string, string>
}

export const Feedback: FunctionComponent<FeedbackProps> = ({ type, tags }) => {
  const {
    product: { feedback },
  } = useProduct()

  const modal = useModal()
  const [modalSeen, setModalSeen] = useState(false)

  useEffect(() => {
    if (feedback?.[type] && !modalSeen) {
      const timer = setTimeout(() => {
        modal.show({
          content: <FeedbackModal config={feedback[type]} tags={tags} />,
          variant: 'feedback',
        })
        setModalSeen(true)
      }, 2000)

      return () => clearTimeout(timer)
    }
  }, [feedback, modal, modalSeen, tags, type])

  return null
}
