import {
  Flex,
  mdBumps,
  Section,
  SectionContent,
  SectionProps,
  SectionTitle,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { PolicyAdjustmentResponse } from '../../../../../../domain'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { CoverChanges } from '../../../../../organisms/covers/CoverChanges'
import { ExcessChanges } from '../../../../../organisms/excess'
import { EffectiveAt } from '../AdjustmentConfigChanges/components/EffectiveAt'
import { PolicyAdjustmentChangesItem } from './components/ChangesItem'

export interface AdjustmentResponseChangeProps extends SectionProps {
  result: PolicyAdjustmentResponse
}

const tKey = TKey('components.adjustment-response-changes')

export const AdjustmentResponseChanges: FunctionComponent<
  AdjustmentResponseChangeProps
> = ({ result, ...props }) => {
  const { t } = useWriskTranslation()

  const { updatedPolicyVersions } = result
  const [updatedPolicyVersion] = updatedPolicyVersions
  return (
    <Section width={1} {...props}>
      <SectionTitle>{t(tKey('header'))}</SectionTitle>
      <SectionContent>
        <Flex
          alignItems='stretch'
          flexDirection='column'
          pt={2}
          variant='raised'
          width={1}
        >
          {result.changes.map((change, index) => (
            <PolicyAdjustmentChangesItem
              key={index}
              change={change}
              updatedPolicyVersion={updatedPolicyVersion}
            />
          ))}
          <EffectiveAt mt={2} effectiveAt={updatedPolicyVersion.startedAt} />
        </Flex>
        <CoverChanges updatedPolicyVersion={updatedPolicyVersion} mt={mdBumps} />
        <ExcessChanges updatedPolicyVersion={updatedPolicyVersion} mt={mdBumps} />
      </SectionContent>
    </Section>
  )
}
