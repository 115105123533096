import {
  Box,
  Flex,
  Form,
  Heading,
  mdBumps,
  PrimaryButton,
  TextInput,
  Typo,
  xlBumps,
} from '@wrisk/ui-components'
import React, { ChangeEvent, FunctionComponent, useCallback, useState } from 'react'

import { useImpersonate } from './impersonateContext'

export const AdminImpersonate: FunctionComponent = () => {
  const { setImpersonate } = useImpersonate()

  const [value, setValue] = useState<string>('')

  const onChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => setValue(target.value),
    [],
  )
  const onSubmit = useCallback(() => setImpersonate(value), [setImpersonate, value])

  return (
    <Flex width='100vw' height='100vh' justifyContent='center' flexDirection='column'>
      <Box p={xlBumps} maxWidth='600px' width='80%'>
        <Heading typoSize='lg' pb={mdBumps}>
          Impersonate
        </Heading>
        <Typo typoSize='md' mb={mdBumps}>
          Please enter an id to impersonate:
        </Typo>
        <Form formId='impersonate' onSubmit={onSubmit}>
          <TextInput
            width={1}
            value={value}
            onChange={onChange}
            placeholder='email|5f682cp2d950a872a4a9V126'
            mb={mdBumps}
          />
          <PrimaryButton type='submit'>Impersonate</PrimaryButton>
        </Form>
      </Box>
    </Flex>
  )
}
