import { AxiosProgressEvent } from 'axios'
import { round } from 'lodash'

export type UpdateProgressPayload = { file: File; percentage: number }
export type RejectFiles = { name: string; error: string }

export type FileUploadActions =
  | { type: 'ADD_FILES'; payload: File[] }
  | { type: 'ADDED_FILES'; payload?: never }
  | { type: 'REMOVE_FILE'; payload: File }
  | { type: 'REJECT_FILES'; payload: RejectFiles[] }
  | { type: 'CLEAR_REJECTIONS'; payload?: never }
  | { type: 'UPDATE_PROGRESS'; payload: UpdateProgressPayload }
  | { type: 'EXCEPTION'; payload: File[] }

export const hasFile = (files: File[]) => (file: File) =>
  !files.some((it) => it.name === file.name)

export const getUploadPercentage = (progress: AxiosProgressEvent) =>
  round(progress.total ? progress.loaded / progress.total : 0, 2)
