import { variant } from 'styled-system'

export type DisplayOnProperty = 'default' | 'desktop' | 'mobile'

export interface DisplayOnProps {
  displayOn?: DisplayOnProperty
}

export const displayOn = variant({
  prop: 'displayOn',
  variants: {
    default: {},
    desktop: {
      display: ['none', 'none', 'block'],
    },
    mobile: {
      display: ['flex', 'flex', 'none'],
    },
  },
})
