import {
  isGreaterThanOrEqual,
  isLessThanOrEqual,
  NumberInput,
  validateAll,
} from '@wrisk/ui-components'
import { isNil } from 'lodash'
import React, { FunctionComponent, useCallback } from 'react'

import { tFormats } from '../../../infrastructure/internationalisation'
import { FormInputProps } from '../types'

interface NumberTextFormInputMeta {
  formatParams?: Record<string, unknown>
}

const shouldShowError = () => false

export const NumberTextFormInput: FunctionComponent<
  FormInputProps<number, NumberTextFormInputMeta>
> = ({ value, onChange, meta, validation, tKey, tName, t }) => {
  const validator = validateAll(
    isLessThanOrEqual(validation?.lessThanOrEqual),
    isGreaterThanOrEqual(validation?.greaterThanOrEqual),
  )

  const formatter = useCallback(
    (amount: number) =>
      t(tFormats('number.standard'), {
        amount,
        ...meta?.formatParams,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  const shouldSetValue = (num: number | undefined) => isNil(num) || validator(num)

  return (
    <NumberInput
      inputErrorMessage={''}
      initialValue={value}
      onChange={onChange}
      inputPlaceholder={t(tKey(tName, 'placeholder'))}
      shouldSetValue={shouldSetValue}
      shouldShowError={shouldShowError}
      formatter={formatter}
    />
  )
}
