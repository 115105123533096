import React, {
  FormEvent,
  FunctionComponent,
  PropsWithChildren,
  useCallback,
} from 'react'

export interface FormProps extends PropsWithChildren {
  formId: string
  onSubmit?: (event: FormEvent) => void
  className?: string
  action?: string
  method?: string
}

export const Form: FunctionComponent<FormProps> = ({
  formId,
  onSubmit,
  children,
  className,
}) => {
  const onFormSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      onSubmit?.(event)
    },
    [onSubmit],
  )

  return (
    <form
      style={{ width: '100%' }}
      className={className}
      id={formId}
      autoComplete='off'
      onSubmit={onSubmit && onFormSubmit}
    >
      {children}
    </form>
  )
}
