import { SectionProps } from '@wrisk/ui-components'
import React from 'react'

import { isProductEndorsement, Quote } from '../../../domain'
import { useProduct } from '../../product/productContext'
import { Endorsements } from '../endorsements'

interface CoverEndorsementsProps extends SectionProps {
  quote: Quote
}

export const CoverEndorsements: React.FC<CoverEndorsementsProps> = ({
  quote,
  ...props
}) => {
  const { product } = useProduct()

  const endorsements = quote.endorsements.filter(isProductEndorsement(product))

  return <Endorsements quote={quote} endorsements={endorsements} {...props} />
}
