import { Flex, FlexProps, Typo, TypoSizeProps, xsBumps } from '@wrisk/ui-components'
import { isNil } from 'lodash'
import React, { FunctionComponent } from 'react'

import {
  tFormats,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

export interface ExcessRowProps extends FlexProps, TypoSizeProps {
  currentExcess?: number
  previousExcess?: number
  header: string
}

export const ExcessRow: FunctionComponent<ExcessRowProps> = ({
  currentExcess,
  previousExcess,
  header,
  typoSize,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const hasExcessChanged = !isNil(previousExcess) && previousExcess !== currentExcess

  return (
    <Flex columnGap={2} width={1} {...props}>
      <Typo typoSize={typoSize}>{header}</Typo>
      <Flex columnGap={xsBumps}>
        {hasExcessChanged && (
          <Typo typoSize={typoSize} color='inactive'>
            <s>{t(tFormats('currency.short'), { amount: previousExcess ?? 0 })}</s>
          </Typo>
        )}
        <Typo typoSize={typoSize}>
          {t(tFormats('currency.short'), { amount: currentExcess ?? 0 })}
        </Typo>
      </Flex>
    </Flex>
  )
}
