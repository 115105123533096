export const xxlBumps = [10, 10, 11, 12, 14] // ['48px, 48px, 56px, 64px, 80px']
export const xlBumps = [8, 8, 9, 10, 12] // ['32px, 32px, 40px, 48px, 64px']
export const lgBumps = [7, 7, 7, 9, 10] // ['28px, 28px, 28px, 40px, 48px']
export const mdBumps = [5, 5, 5, 6, 6] // ['20px, 20px, 20px, 24px, 24px']
export const smBumps = [3, 3, 3, 4, 4] // ['12px, 12px, 12px, 16px, 16px']
export const xsBumps = [2, 2, 2, 3, 3] // ['8px, 8px, 8px, 12px, 12px']
export const xxsBumps = [1, 1, 1, 2, 2] // ['4px, 4px, 4px, 8px, 8px']

// All space values
// 0  : 0px : 0rem
// 1  : 4px : 0.25rem
// 2  : 8px : 0.5rem
// 3  : 12px : 0.75rem
// 4  : 16px : 1rem
// 5  : 20px : 1.25rem
// 6  : 24px : 1.5rem
// 7  : 28px : 1.75rem
// 8  : 32px : 2rem
// 9  : 40px : 2.5rem
// 10 : 48px : 3rem
// 11 : 56px : 3.5rem
// 12 : 64px : 4rem
// 13 : 72px : 4.5rem
// 14 : 80px : 5rem
