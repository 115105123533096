import {
  Box,
  Flex,
  mdBumps,
  PaymentItemCell,
  PaymentTotalCell,
  smBumps,
  Status,
  Typo,
  xsBumps,
} from '@wrisk/ui-components'
import { negate } from 'lodash'
import React, { FunctionComponent } from 'react'

import {
  CustomerStatementLineItem,
  CustomerStatementStatus,
  CustomerStatementSummary,
  isJournalLineItem,
  isPendingLineItem,
  LineItemStatus,
} from '../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { toDateTime } from '../../../util/date'
import { PaymentPendingInfo } from './PaymentPendingInfo'

type PaymentGroupProps = {
  transactions: CustomerStatementLineItem[]
  statementSummary: CustomerStatementSummary
}

const tKey = TKey('components.payment-group')

export const PaymentGroup: FunctionComponent<PaymentGroupProps> = ({
  transactions,
  statementSummary,
}) => {
  const { t } = useWriskTranslation()

  const { amountOutstanding, carriedForwardBalance, status } = statementSummary

  const nonPendingPayments = transactions.filter(negate(isPendingLineItem))

  const carriedForwardBalanceItem =
    carriedForwardBalance !== 0 ? (
      <Flex width={1} px={mdBumps} py={xsBumps}>
        <Typo typoSize='sm'>
          {t<string>(
            tKey(
              'carried-forward-balance',
              carriedForwardBalance > 0 ? 'credit' : 'debit',
            ),
          )}
        </Typo>
        <Typo typoSize='sm'>
          {t<string>(tFormats('currency.long'), {
            amount: carriedForwardBalance / 100,
          })}
        </Typo>
      </Flex>
    ) : null

  const isPendingStatusWithOutstandingBalance =
    status === CustomerStatementStatus.PENDING && amountOutstanding > 0

  const showPayments =
    Boolean(nonPendingPayments.length) ||
    Boolean(carriedForwardBalanceItem) ||
    Boolean(isPendingStatusWithOutstandingBalance)

  return showPayments ? (
    <Box variant='raised' mt={mdBumps}>
      <Box px={mdBumps} py={smBumps} borderBottomWidth={1}>
        <Typo fontWeight='bold'>{t(tKey('header'))}</Typo>
      </Box>

      <Box borderBottomWidth={1} py={2}>
        {nonPendingPayments.map((payment, index) => {
          const prefix = t(tKey('prefixes', payment.type), {
            defaultValue: null,
          })

          const description = isJournalLineItem(payment)
            ? t(tKey('journal-descriptions', payment.type))
            : [
                prefix,
                payment.paymentMethodDescription ??
                  t(tKey('default-descriptions', payment.subType)),
              ]
                .filter(Boolean)
                .join(': ')

          const amount =
            payment.status === LineItemStatus.FAILED ? (
              <Status typoSize='xs' variant='critical'>
                {t(tKey('failed-payment.status'))}
              </Status>
            ) : (
              t(tFormats('currency.long'), {
                amount: payment.amount / 100,
              })
            )

          return (
            <PaymentItemCell
              key={index}
              date={t<string>(tFormats('date.medium'), {
                value: toDateTime(payment.effectiveAt),
              })}
              description={description}
              amount={amount}
            />
          )
        })}
        {carriedForwardBalanceItem}
        <PaymentPendingInfo summary={statementSummary} px={mdBumps} py={xsBumps} />
      </Box>

      <PaymentTotalCell
        header={t<string>(tKey('total'))}
        premium={t<string>(tFormats('currency.long'), {
          amount: amountOutstanding / 100,
        })}
      />
    </Box>
  ) : null
}
