import React, { FunctionComponent, useMemo } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { getCurrentQuote, isAddOn } from '../../../../domain'
import { useOptionalCovers } from '../../../../hooks/covers'
import { useCurrentPath } from '../../../../infrastructure/routing'
import { AppPath } from '../../../AppPath'
import { usePolicyholder } from '../../../authentication'
import { useProduct } from '../../productContext'
import { CheckoutRouter } from '../checkout'
import { ProposalConfigurationPage, ProposalSummaryPage } from '../configuration'
import { ProposalAddOnsPage } from '../covers'
import { AccountSetupPage, DisclosureRouter, ProposalUpgradePage } from '../disclosure'
import { useProposal } from '../proposalContext'
import { ProposalPath } from '../ProposalPath'

export const ProposalQuoteRoutes: FunctionComponent = () => {
  const currentPath = useCurrentPath()

  const { policyholder } = usePolicyholder()
  const { proposal } = useProposal()
  const { product } = useProduct()

  const quote = getCurrentQuote(proposal)

  const optionalCovers = useOptionalCovers(quote, product).filter(isAddOn(product))

  const quotePage = useMemo(() => ({ url: currentPath, id: 'quote' }), [currentPath])
  const addOnsPage = useMemo(
    () => ({ url: [currentPath, ProposalPath.ADD_ONS].join('/'), id: 'add-ons' }),
    [currentPath],
  )

  return (
    <Routes>
      <Route key='quote' index element={<ProposalConfigurationPage />} />

      <Route
        key='add-ons'
        path={ProposalPath.ADD_ONS}
        element={
          <ProposalAddOnsPage
            destination={
              policyholder?.accountRegistered
                ? ProposalPath.SUMMARY
                : ProposalPath.ACCOUNT_SETUP
            }
            parent={quotePage}
          />
        }
      />

      <Route
        key='account-setup'
        path={ProposalPath.ACCOUNT_SETUP}
        element={
          <AccountSetupPage parent={optionalCovers.length ? addOnsPage : quotePage} />
        }
      />

      <Route
        key='disclosure'
        path={ProposalPath.DISCLOSURE + AppPath.WILDCARD}
        element={<DisclosureRouter />}
      />
      <Route
        key='upgrade'
        path={ProposalPath.UPGRADE}
        element={<ProposalUpgradePage parent={quotePage} />}
      />

      <Route
        key='summary'
        path={ProposalPath.SUMMARY}
        element={<ProposalSummaryPage />}
      />
      <Route
        key='checkout'
        path={ProposalPath.CHECKOUT + AppPath.WILDCARD}
        element={<CheckoutRouter />}
      />

      <Route path='*' element={<Navigate to={AppPath.HOME} />} />
    </Routes>
  )
}
