import { Auth0Client, Auth0ClientOptions, createAuth0Client } from '@auth0/auth0-spa-js'

import {
  isAuthenticationLoginRequiredError,
  isAuthenticationUnauthorizedError,
  isRefreshTokenMissing,
} from './errors'
import { User } from './user'

interface UserClaims {
  sub: string
  email: string
  given_name: string
  family_name: string
  picture: string
  ['https://wrisk.co/ph_code']: string
  ['https://wrisk.co/roles']?: string[]
}

const toUser = (claims: UserClaims): User => ({
  userId: claims['https://wrisk.co/ph_code'],
  picture: claims.picture,
  roles: claims['https://wrisk.co/roles'] ?? [],
  permissions: claims['https://wrisk.co/permissions'] ?? [],
  username: claims.email,
  firstName: claims.given_name,
  lastName: claims.family_name,
})

export interface SignInCallbackState {
  returnTo: string
}

export interface AuthClient {
  authDomain: string
  signIn: (callbackState: SignInCallbackState, replace?: boolean) => Promise<void>
  handleSignInCallback: () => Promise<{ user: User; appState: SignInCallbackState }>
  signInSilently: () => Promise<User | undefined>
  signOut: () => Promise<void>
  getTokenSilently: () => Promise<string>
}

export const createAuthClient = (config: Auth0ClientOptions): AuthClient => {
  return {
    authDomain: config.domain,

    signIn: async (appState) => {
      await initAuth0Client(config)
      await auth0Client.loginWithRedirect({ appState })
    },

    handleSignInCallback: async () => {
      await initAuth0Client(config)
      const redirectLoginResult = await auth0Client.handleRedirectCallback()
      const appState = redirectLoginResult.appState as SignInCallbackState
      const userClaims = (await auth0Client.getUser()) as UserClaims
      const user = toUser(userClaims)

      return { user, appState }
    },

    signInSilently: async () => {
      await initAuth0Client(config)
      try {
        await auth0Client.getTokenSilently()
      } catch (e) {
        if (
          isAuthenticationLoginRequiredError(e) ||
          isAuthenticationUnauthorizedError(e) ||
          isRefreshTokenMissing(e)
        ) {
          return undefined
        }
        throw e
      }

      const userClaims = (await auth0Client.getUser()) as UserClaims
      return toUser(userClaims)
    },

    signOut: async () => {
      await initAuth0Client(config)
      await auth0Client?.logout({
        clientId: config.clientId,
        logoutParams: {
          returnTo: window.location.origin,
        },
      })
    },

    getTokenSilently: async () => {
      await initAuth0Client(config)
      try {
        return await auth0Client.getTokenSilently()
      } catch (e) {
        if (isRefreshTokenMissing(e)) {
          await auth0Client.loginWithRedirect({
            appState: window.location.pathname + window.location.search,
          })
          return ''
        } else throw e
      }
    },
  }
}

let auth0Client: Auth0Client

const initAuth0Client = async (config: Auth0ClientOptions) => {
  if (auth0Client === undefined) {
    auth0Client = await createAuth0Client({
      ...config,
      authorizationParams: {
        ...config.authorizationParams,
        redirect_uri: `${window.location.origin}/callback`,
      },
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
    })
  }
}
