export enum PolicyPath {
  POLICY = 'policy/:policyId',
  ADD_ONS = 'add-ons',
  MANAGEMENT = 'management',
  EXCESS = 'excess',
  CANCEL = 'cancel',
  CANCEL_CONFIRMATION = 'cancellation-confirmation',
  UPLOADS = 'uploads',
  CLAIMS = 'claims',
  DOCUMENTS = 'documents',
  BILLING = 'billing',
  DETAILS = 'details',
}

export interface PolicyParams extends Record<string, string> {
  policyId: string
}
