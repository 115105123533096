import 'core-js/stable'
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { DefaultTheme } from 'styled-components'

import { App } from './App'
import { TranslationResource } from './infrastructure/internationalisation'

export const renderApp = (
  elementId: string,
  theme: DefaultTheme,
  translations: TranslationResource,
) => {
  const container = document.getElementById(elementId)

  if (!container) throw new Error('Could not find container element')

  const root = createRoot(container)
  root.render(<App translations={translations} theme={theme} />)
}
