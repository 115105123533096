import React, { FunctionComponent } from 'react'

import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { FullPage } from '../../../templates'

const tKey = TKey('pages.bind-disabled')

export const BindDisabled: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  return (
    <FullPage
      pageId='bind-disabled'
      header={t(tKey('header'))}
      subheader={t(tKey('subheader'))}
    />
  )
}
