import { Flex, FlexProps, OptionTile } from '@wrisk/ui-components'
import React from 'react'

/**
 * Renders "Yes / No" buttons  for Single add-on
 */
interface Props extends FlexProps {
  prefixForIds: string
  selected?: boolean
  titleNo: string
  titleYes: string
  onSelect: (value: boolean) => void
}

export const AddOnButtons: React.FC<Props> = ({
  prefixForIds,
  selected,
  titleNo,
  titleYes,
  onSelect,
  ...restOfProps
}) => {
  return (
    <Flex flexDirection={['column', 'row']} {...restOfProps}>
      <OptionTile
        mb={[2, 0]}
        mr={[0, 2]}
        name={prefixForIds + '-yes'}
        checked={selected === true}
        value={true}
        text={titleYes}
        onSelect={onSelect}
        data-testid='option-yes'
      />
      <OptionTile
        name={prefixForIds + '-no'}
        checked={selected === false}
        value={false}
        text={titleNo}
        onSelect={onSelect}
        data-testid='option-no'
      />
    </Flex>
  )
}
