import { useEffect, useRef } from 'react'

export const usePortal = (id = 'app') => {
  const rootElemRef = useRef(document.createElement('div'))

  useEffect(() => {
    const parentElem = document.getElementById(id)
    parentElem?.appendChild(rootElemRef.current)
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      rootElemRef.current.remove()
    }
  }, [id])

  return rootElemRef.current
}
