import { Box, mdBumps, Typo, xxsBumps } from '@wrisk/ui-components'
import { negate, partition } from 'lodash'
import React, { FunctionComponent } from 'react'

import {
  CustomerStatementLineItem,
  isJournalLineItem,
  isMtaLineItem,
} from '../../../domain'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { InvoiceItem } from './InvoiceItem'

interface InvoiceItemProps {
  lineItems: CustomerStatementLineItem[]
}

const tKey = TKey('components.invoice-items')

export const InvoiceItems: FunctionComponent<InvoiceItemProps> = ({ lineItems }) => {
  const { t } = useWriskTranslation()

  const otherInvoices = lineItems.filter(negate(isJournalLineItem))
  const [mtaInvoices, mainInvoices] = partition(otherInvoices, isMtaLineItem)

  return (
    <>
      {Boolean(mainInvoices.length) && (
        <Box borderBottomWidth={1} py={2}>
          {mainInvoices.map((item, index) => (
            <InvoiceItem item={item} key={index} />
          ))}
        </Box>
      )}

      {Boolean(mtaInvoices.length) && (
        <Box borderBottomWidth={1} py={2}>
          <Typo typoSize='xs' color='bodySecondary' px={mdBumps} py={xxsBumps}>
            {t(tKey('mta'))}
          </Typo>
          {mtaInvoices.map((item, index) => (
            <InvoiceItem item={item} key={index} />
          ))}
        </Box>
      )}
    </>
  )
}
