import { ExpandableSection, Flex, smBumps, Typo } from '@wrisk/ui-components'
import { isNil } from 'lodash'
import React, { FunctionComponent } from 'react'

import { getAdditionalProfiles, InsuredProfile, Quote } from '../../../domain'
import { toAdditionalProfileProposalData } from '../../../hooks/adjustments/proposal/additionalProfile/data'
import {
  tDefaults,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { AdditionalProfileManagementConfig } from '../../../state/configuration'
import { getFormatter } from '../../formBuilder'

const AdditionalProfileInformation: FunctionComponent<{
  summary: AdditionalProfileManagementConfig
  profile: InsuredProfile
}> = ({ summary, profile }) => {
  const { t } = useWriskTranslation()

  const [firstInput] = summary.inputs

  const toData = toAdditionalProfileProposalData(profile)
  const Formatter = getFormatter(firstInput.type)

  const expandedComponents = summary.inputs
    .filter(({ adjustment }) => !isNil(toData(adjustment)))
    .map((input) => {
      const Formatter = getFormatter(input.type)

      return (
        <Flex
          key={input.name}
          px={smBumps}
          py={2}
          flexDirection='column'
          alignItems='flex-start'
        >
          <Typo typoSize='xs' color='bodySecondary' mb={1}>
            {t(tDefaults('product.headers', input.name))}
          </Typo>
          <Typo as='pre'>
            <Formatter input={input} value={toData(input.adjustment)} t={t} />
          </Typo>
        </Flex>
      )
    })

  return (
    <ExpandableSection
      reversed
      header={
        <Typo fontWeight='bold'>
          <Formatter input={firstInput} value={toData(firstInput.adjustment)} t={t} />
        </Typo>
      }
    >
      {expandedComponents}
    </ExpandableSection>
  )
}

export const AdditionalProfilesInformation: FunctionComponent<{
  config: AdditionalProfileManagementConfig
  quote: Quote
}> = ({ config, quote }) => {
  const additionalProfiles = getAdditionalProfiles(quote)

  const sections = additionalProfiles.map((it) => (
    <AdditionalProfileInformation key={it.profileCode} summary={config} profile={it} />
  ))

  return <>{sections}</>
}
