import React, {
  ChangeEvent,
  FunctionComponent,
  PropsWithChildren,
  useCallback,
} from 'react'
import styled from 'styled-components'

import { Box, FlexAnchor, mdBumps, Radio, smBumps, Typo } from '../atoms'

export interface SelectItemProps extends PropsWithChildren {
  name: string
  id: string
  checked: boolean
  onChange: (id: string) => void
  className?: string
}

const Component: FunctionComponent<SelectItemProps> = (props) => {
  const { name, id, checked, onChange, children, className } = props

  const onInputChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      onChange(target.value)
    },
    [onChange],
  )

  const uniqueId = name + id

  return (
    <FlexAnchor
      as='label'
      className={className}
      variant='inset'
      px={mdBumps}
      py={3}
      width={1}
      justifyContent='flex-start'
    >
      <Box mr={smBumps}>
        <Radio
          name={name}
          value={id}
          data-test={id}
          id={uniqueId}
          onChange={onInputChange}
          checked={checked}
        />
      </Box>
      <Typo fontFamily='input' as='label' htmlFor={uniqueId}>
        {children}
      </Typo>
    </FlexAnchor>
  )
}

export const SelectItem = styled(Component)({
  label: {
    cursor: 'pointer',
  },
})
