import { Box, ModalHeader, SectionProps, useModal } from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import {
  Data,
  getPolicyVersion,
  PolicyAdjustmentResponse,
  PriceOption,
} from '../../../domain'
import { usePolicyAdjustment, usePolicyFormData } from '../../../hooks/adjustments/policy'
import {
  TExtends,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { AdjustmentInputConfig, getCoreAssetType } from '../../../state/configuration'
import { usePrincipal } from '../../authentication'
import { FormDisabled } from '../../formBuilder'
import { AdjustmentModal } from '../../product/policy/adjustments/admin'
import { AddOnPath } from '../../product/policy/covers/AddOnPath'
import { usePolicy } from '../../product/policy/policyContext'
import { PolicyPath } from '../../product/policy/PolicyPath'
import { useProduct } from '../../product/productContext'
import { CoverSection } from '../covers'

export const PolicyCoverSection: FunctionComponent<SectionProps> = (props) => {
  const { policy } = usePolicy()
  const { product } = useProduct()

  const { covers } = getCoreAssetType(product)

  const { isAdmin } = usePrincipal()

  const modal = useModal()

  const { quote } = getPolicyVersion(policy)

  const onChange = useCallback(
    (cover: PriceOption) => {
      const { inputs } = covers.optional?.find((it) => it.code === cover.coverCode) ?? {}

      if (!inputs) {
        throw new Error(`No inputs found for optional cover ${cover.coverCode}`)
      }

      modal.show({
        content: <PolicyEditOptionalCoverModal inputs={inputs} onComplete={modal.hide} />,
      })
    },
    [covers, modal],
  )

  return (
    <CoverSection quote={quote} onChange={isAdmin ? onChange : undefined} {...props} />
  )
}

const tKey = TKey('components.edit-optional-cover-modal')

interface EditOptionalCoverModalProps {
  inputs: AdjustmentInputConfig[]
  onComplete: () => void
}

const PolicyEditOptionalCoverModal: FunctionComponent<EditOptionalCoverModalProps> = ({
  inputs,
  onComplete,
}) => {
  const { t } = useWriskTranslation()
  const navigate = useNavigate()
  const modal = useModal()

  const { policy } = usePolicy()
  const { isAdmin } = usePrincipal()

  const data = usePolicyFormData(inputs)

  const onAdjustmentSubmitted = useCallback(
    async (response: PolicyAdjustmentResponse | undefined) => {
      if (response) {
        navigate(
          generatePath([PolicyPath.ADD_ONS, AddOnPath.REVIEW].join('/'), {
            adjustmentId: response.adjustmentId,
          }),
        )
      }

      onComplete()
    },
    [navigate, onComplete],
  )

  const onSubmitAdjustment = usePolicyAdjustment(inputs, onAdjustmentSubmitted)

  const onSubmit = useCallback(
    (updatedData: Data) => {
      if (isAdmin) {
        modal.show({
          content: (
            <AdjustmentModal
              updatedData={updatedData}
              policy={policy}
              onCancel={modal.hide}
              onSubmitAdjustment={onSubmitAdjustment}
            />
          ),
        })
      } else {
        void onSubmitAdjustment.execute(updatedData)
      }
    },
    [isAdmin, modal, onSubmitAdjustment, policy],
  )

  return (
    <Box>
      <ModalHeader header={t(tKey('header'))} />
      <FormDisabled
        id='editOptionalCover'
        data={data}
        inputs={inputs}
        onSubmit={onSubmit}
        loading={onSubmitAdjustment.loading}
        tKey={TExtends(tKey, 'inputs')}
        options={{ shouldUnregister: true }}
        t={t}
      />
    </Box>
  )
}
