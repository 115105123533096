import {
  Box,
  Flex,
  FlexProps,
  smBumps,
  TertiaryButton,
  Typo,
  useModal,
  xsBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'

import { CustomerAccount, isAccountActive } from '../../../domain'
import {
  tFormats,
  TKeyBuilder,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { getFeatureToggles, useConfig } from '../../../state/configuration'
import { PaymentDayModal } from './PaymentDayModal'

export interface PaymentMethodCardProps extends FlexProps {
  tKey: TKeyBuilder
  customerAccount: CustomerAccount
  onUpdate: (customerAccount: CustomerAccount) => void
  loading: boolean
}

export const BillingDay: FunctionComponent<PaymentMethodCardProps> = ({
  customerAccount,
  onUpdate,
  loading,
  tKey,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const modal = useModal()

  const { allowModifyBillingDay } = useConfig(getFeatureToggles)

  const onUpdateBillingDay = useCallback(
    () =>
      customerAccount &&
      modal.show({
        content: (
          <PaymentDayModal
            onClose={modal.hide}
            customerAccount={customerAccount}
            onUpdate={onUpdate}
          />
        ),
      }),
    [customerAccount, modal, onUpdate],
  )

  return (
    <Flex px={smBumps} py={xsBumps} {...props}>
      <Box>
        <Typo typoSize='xs' color='bodySecondary'>
          {t(tKey('billing', 'header'))}
        </Typo>
        <Typo fontWeight='bold'>
          {t(tKey('billing', 'content'), {
            day: t(tFormats('number', 'ordinal'), {
              count: customerAccount.paymentDay,
              ordinal: true,
            }),
          })}
        </Typo>
      </Box>
      {allowModifyBillingDay && isAccountActive(customerAccount) && (
        <TertiaryButton layout='small' onClick={onUpdateBillingDay} loading={loading}>
          {t(tKey('billing', 'actions', 'edit'))}
        </TertiaryButton>
      )}
    </Flex>
  )
}
