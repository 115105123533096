import {
  Checkbox,
  Flex,
  FlexAnchor,
  mdBumps,
  PrimaryButton,
  Typo,
  xlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback, useState } from 'react'
import { Trans } from 'react-i18next'

import {
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { ProductConfig } from '../../../../../../state/configuration'
import { useLinks } from '../../../../../organisms/links/hooks'

const tKey = TKey('components.start-checkout')

export const StartCheckoutSection: FunctionComponent<{
  product: ProductConfig
  onConfirm: () => void
  loading: boolean
}> = ({ product, onConfirm, loading }) => {
  const { t } = useWriskTranslation()
  const links = useLinks()

  const [checkoutChecklist, setCheckoutChecklist] = useState<Record<string, boolean>>(
    product.checkoutChecklist?.reduce((acc, it) => ({ ...acc, [it]: false }), {}) ?? {},
  )

  const onChange = useCallback(
    (id: string) => (checked: boolean) =>
      setCheckoutChecklist((current) => ({ ...current, [id]: checked })),
    [setCheckoutChecklist],
  )

  const confirmSections = Object.entries(checkoutChecklist).map(([id, checked]) => (
    <FlexAnchor
      key={id}
      as='label'
      htmlFor={id}
      backgroundColor='chalk'
      justifyContent='flex-start'
      width={1}
      variant='inset'
      px={mdBumps}
      py={3}
    >
      <Checkbox id={id} mr={4} value={checked} onChange={onChange(id)} />
      <Typo>
        <Trans t={t} i18nKey={tKey('agreements', id)} components={links} />
      </Typo>
    </FlexAnchor>
  ))

  return (
    <>
      {confirmSections.length > 0 && (
        <Flex
          width={1}
          py={2}
          mb={xlBumps}
          flexWrap='wrap'
          variant='raised'
          flexDirection='column'
        >
          {confirmSections}
        </Flex>
      )}

      <PrimaryButton
        data-testid='button-continue'
        layout='fixed'
        onClick={onConfirm}
        loading={loading}
        disabled={!Object.values(checkoutChecklist).every(Boolean)}
      >
        {t(tKey('actions.continue'))}
      </PrimaryButton>
    </>
  )
}
