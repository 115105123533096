import { useMemo } from 'react'

import {
  AccrualType,
  getPremiumByAccrualType,
  inForce,
  isCoverActive,
  PriceOption,
  Quote,
} from '../domain'
import { ProductConfig } from '../state/configuration'
import { useDiscountCovers, useMainCovers, useOptionalCovers } from './covers'

export interface QuotePremiums {
  breakdown: {
    main: number
    discount: number
  }
  totals: {
    gross: number
    discounted: number
  }
  optional: PriceOption[]
}

export const useQuotePremiums = (
  quote: Quote | undefined,
  product: ProductConfig,
): QuotePremiums => {
  const allMainCovers = useMainCovers(quote)
  const allOptionalCovers = useOptionalCovers(quote, product)
  const allDiscountCovers = useDiscountCovers(quote)

  return useMemo(() => {
    const mainCovers = allMainCovers.filter(isCoverActive)
    const optionalCovers = allOptionalCovers.filter(isCoverActive)
    const discountCovers = allDiscountCovers.filter(inForce)

    const mainPremium = getPremiumByAccrualType(mainCovers, AccrualType.MONTHLY)
    const optionalPremium = getPremiumByAccrualType(optionalCovers, AccrualType.MONTHLY)
    const discountPremium = getPremiumByAccrualType(discountCovers, AccrualType.MONTHLY)

    const grossTotal = mainPremium + optionalPremium
    const discountedTotal = grossTotal - discountPremium

    return {
      breakdown: {
        main: mainPremium,
        discount: discountPremium,
      },
      totals: {
        gross: grossTotal,
        discounted: discountedTotal,
      },
      optional: optionalCovers,
    }
  }, [allMainCovers, allOptionalCovers, allDiscountCovers])
}

export const hasDiscount = (amounts: QuotePremiums['breakdown']) => amounts.discount > 0
