import { Grid } from '@wrisk/ui-components'
import React from 'react'

import { getExposuresContentBaseUrl, useConfig } from '../../../state/configuration'
import { useProduct } from '../../product/productContext'
import { IncludedCoverItem } from './IncludedCoverItem'

interface IncludedCoverItemsProps {
  includedCovers: string[]
}

export const IncludedCoverItems: React.FC<IncludedCoverItemsProps> = ({
  includedCovers,
}) => {
  const { scheme } = useProduct()

  const contentBaseUrl = useConfig(getExposuresContentBaseUrl(scheme.schemeCode))

  const includedCoverItems = includedCovers.map((cover) => (
    <IncludedCoverItem
      key={cover}
      width={1}
      cover={cover}
      contentBaseUrl={contentBaseUrl}
    />
  ))

  return (
    <Grid
      width={1}
      p={2}
      gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}
      gridTemplateRows='auto'
      alignItems='stretch'
    >
      {includedCoverItems}
    </Grid>
  )
}
