import { Specification } from '../../../../domain'

export interface VehicleInputProps {
  specification?: Specification
  vrn?: string
  regLookup?: boolean
}

export const NULL_VEHICLE: VehicleInputProps = {
  specification: undefined,
  vrn: undefined,
  regLookup: undefined,
}
