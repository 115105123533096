import {
  Box,
  Flex,
  Heading,
  HelpLinkIFrame,
  LinkAnchor,
  mdBumps,
  PrimaryButton,
  smBumps,
  Status,
  Typo,
  useModal,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import {
  AccrualType,
  getPremiumByAccrualType,
  InsuredAsset,
  PolicyChangeType,
  PriceOption,
} from '../../../../../../../domain'
import { useIsMtaAllowed } from '../../../../../../../hooks/adjustments/policy/renewal'
import { useErrorHandlingAsyncCallback } from '../../../../../../../hooks/errors'
import {
  tCovers,
  TKey,
  useWriskTranslation,
} from '../../../../../../../infrastructure/internationalisation'
import {
  getOptionalCoverLink,
  getOptionalCoversContentBaseUrl,
  useConfig,
} from '../../../../../../../state/configuration'
import { usePrincipal } from '../../../../../../authentication'
import { InRenewalMtaBlockedModal } from '../../../../../../organisms/InRenewalMtaBlockedModal'
import { useProduct } from '../../../../../productContext'
import { ProposalPath } from '../../../../../proposal/ProposalPath'
import { AddOnPath } from '../../../../covers/AddOnPath'
import { usePolicy } from '../../../../policyContext'

export interface AddOnCardProps {
  asset: InsuredAsset
  cover: PriceOption
}

const tKey = TKey(`components.add-on-card`)

export const AddOnCard: FunctionComponent<AddOnCardProps> = ({ asset, cover }) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()

  const { scheme } = useProduct()
  const { apiClient } = usePrincipal()
  const { policy } = usePolicy()
  const modal = useModal()
  const isMtaAllowed = useIsMtaAllowed()

  const amount = getPremiumByAccrualType([cover], AccrualType.MONTHLY)

  const contentBaseUrl = useConfig(getOptionalCoversContentBaseUrl(scheme.schemeCode))
  const link = getOptionalCoverLink(contentBaseUrl, cover.insurer, cover.coverCode)

  const onLinkMoreClick = useCallback(() => {
    modal.show({
      content: <HelpLinkIFrame src={link} />,
      variant: 'iframe',
    })
  }, [modal, link])

  const onButtonAddClick = useErrorHandlingAsyncCallback(async () => {
    if (isMtaAllowed) {
      const { adjustmentId } = await apiClient.createPolicyAdjustment({
        policyId: policy.policyId,
        adjustmentType: 'MTA',
        changes: [
          {
            changeType: PolicyChangeType.ADD_COVER,
            assetCode: asset.assetCode,
            coverCode: cover.coverCode,
          },
        ],
      })

      navigate({
        pathname: generatePath([ProposalPath.ADD_ONS, AddOnPath.REVIEW].join('/'), {
          adjustmentId,
        }),
      })
    } else {
      void modal.show({
        content: <InRenewalMtaBlockedModal />,
      })
    }
  })

  return (
    <Flex
      alignItems='flex-start'
      flexDirection='column'
      mb={smBumps}
      p={mdBumps}
      variant='raised'
      width={1}
    >
      <Status mb={smBumps} variant='info' typoSize='xs'>
        {t(tKey('status'))}
      </Status>
      <Heading mb={smBumps} typoSize='md' variant='h3'>
        {t(tCovers(cover.coverCode, 'name'))}
      </Heading>
      <Box mb={mdBumps} width={1}>
        <Typo mb={smBumps}>{t(tCovers(cover.coverCode, 'description'))}</Typo>
        <LinkAnchor variant='standalone' typoSize='sm' onClick={onLinkMoreClick}>
          {t(tKey('actions.learn-more'))}
        </LinkAnchor>
      </Box>

      <PrimaryButton
        onClick={onButtonAddClick.execute}
        loading={onButtonAddClick.loading}
        data-testid='add-on-button'
      >
        {t(tKey('actions.add'), { amount })}
      </PrimaryButton>
    </Flex>
  )
}
