import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Page } from '../../../../infrastructure/routing'
import { AppPath } from '../../../AppPath'
import { PolicyAdjustmentResultRouter } from '../adjustments'
import { AddOnPath } from './AddOnPath'
import { PolicyAddOnPage } from './PolicyAddOnPage'

export const AddOnRouter: React.FC<{ parent: Page }> = ({ parent }) => (
  <Routes>
    <Route
      key='add-ons'
      index
      element={<PolicyAddOnPage destination={AddOnPath.REVIEW} parent={parent} />}
    />

    <Route
      key='add-ons-review'
      path={AddOnPath.REVIEW + AppPath.WILDCARD}
      element={
        <PolicyAdjustmentResultRouter adjustmentPage={parent} policyPage={parent} />
      }
    />

    <Route path='*' element={<Navigate to={parent.url} />} />
  </Routes>
)
