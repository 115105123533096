import css from '@styled-system/css'
import styled from 'styled-components'
import {
  border,
  BorderProps,
  compose,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant,
} from 'styled-system'

import { transition, TransitionProps } from '../transition'

export type SelectResultsProperty = 'default'

export interface SelectResultsProps
  extends LayoutProps,
    SpaceProps,
    BorderProps,
    TransitionProps {
  variant?: SelectResultsProperty
}

export const SelectResults = styled.ul<SelectResultsProps>(
  css({
    position: 'relative',
    width: '100%',
    overflowY: 'auto',
    borderRadius: 2,
    maxHeight: '14rem',
    zIndex: 150,
    fontFamily: 'input',
    backgroundColor: 'chalk',
    boxShadow: 'default',
    p: 2,
  }),
  variant({
    scale: 'SelectResults',
    variants: {
      default: {},
    },
  }),
  compose(layout, space, border, transition),
)

SelectResults.defaultProps = {
  variant: 'default',
  role: 'listbox',
}
