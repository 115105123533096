import { system } from 'styled-system'

export type TextDecorationProperty = 'none' | 'underline'

export interface TextDecorationProps {
  textDecoration?: TextDecorationProperty
}

export const textDecoration = system({
  textDecoration: {
    property: 'textDecoration',
  },
})
