import React, { FunctionComponent, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  getCurrentQuote,
  getInsuredProfileStrict,
  Profile,
  Proposal,
} from '../../../domain'
import {
  useAdditionalProfileFormData,
  useAdditionalProfileProposalAdjustment,
} from '../../../hooks/adjustments/proposal'
import {
  TExtends,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { Page, useRequiredParams, useUpButton } from '../../../infrastructure/routing'
import { AdditionalProfileManagementConfig } from '../../../state/configuration'
import { FormErrors } from '../../formBuilder'
import { FullPage } from '../../templates'
import { useProposal } from '../proposal/proposalContext'

export interface RenewalAdditionalProfilePageProps {
  config: AdditionalProfileManagementConfig
  parent: Page
}

const tKey = TKey('pages.additional-profile')

const hasReadOnly = (profileCode: string | undefined, proposal: Proposal) =>
  profileCode &&
  proposal.quote.insuredProfiles.find((it) => it.profileCode === profileCode)

const Content: FunctionComponent<
  RenewalAdditionalProfilePageProps & { profile: Profile }
> = ({ config, parent, profile }) => {
  const { t } = useWriskTranslation()

  const navigate = useNavigate()
  const upButton = useUpButton(parent)
  const { proposal } = useProposal()

  const data = useAdditionalProfileFormData(config.inputs, profile)
  const options = useMemo(
    () =>
      hasReadOnly(profile.profileCode, proposal) ? undefined : { disableReadOnly: true },
    [profile, proposal],
  )
  const onSubmitAdjustment = useAdditionalProfileProposalAdjustment(
    config.inputs,
    true,
    profile,
    () => {
      navigate(parent.url)
    },
  )

  return (
    <FullPage upButton={upButton} header={t(tKey('header'))}>
      <FormErrors
        id='additional-profile'
        data={data}
        inputs={config.inputs}
        options={options}
        onSubmit={onSubmitAdjustment.execute}
        loading={onSubmitAdjustment.loading}
        tKey={TExtends(tKey, 'form')}
        t={t}
      />
    </FullPage>
  )
}

export const RenewalAdditionalProfilePage: FunctionComponent<
  RenewalAdditionalProfilePageProps
> = ({ config, parent }) => {
  const { profileCode } = useRequiredParams<{ profileCode: string }>()

  const { proposal } = useProposal()

  const quote = getCurrentQuote(proposal)
  const profile = getInsuredProfileStrict(quote, profileCode)

  return <Content profile={profile} config={config} parent={parent} />
}

export const RenewalAdditionalProfileCreatePage: FunctionComponent<
  RenewalAdditionalProfilePageProps
> = ({ config, parent }) => {
  return (
    <Content profile={{ profileCode: 'p1', data: {} }} config={config} parent={parent} />
  )
}
