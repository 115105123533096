// eslint-disable-next-line import/no-unresolved
import { GenericError } from '@auth0/auth0-spa-js/dist/typings/errors'

export const isAuthenticationError = (error: unknown): error is GenericError =>
  Boolean(error) &&
  (error as GenericError).error !== undefined &&
  (error as GenericError).error_description !== undefined

export const isAuthenticationLoginRequiredError = (
  error: unknown,
): error is GenericError =>
  isAuthenticationError(error) && error.error === 'login_required'

export const isAuthenticationUnauthorizedError = (
  error: unknown,
): error is GenericError => isAuthenticationError(error) && error.error === 'unauthorized'

export const isRefreshTokenMissing = (error: unknown): error is GenericError =>
  isAuthenticationError(error) &&
  (error.error === 'missing_refresh_token' || error.error === 'invalid_grant')
