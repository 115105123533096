import { AxiosInstance, AxiosProgressEvent } from 'axios'

import { Document, Policy, UploadCommit } from '../../../domain'
import { withGenericErrorHandling } from '../errors'

export interface PoliciesClient {
  getPolicy: (policyId: string) => Promise<Policy>
  getPolicies: (params?: { schemeCode?: string }) => Promise<Policy[]>
  updateBillingDay: (policyId: string, billingDay: number) => Promise<Policy>
  updateRenewalStop: (policyId: string, renewalStoppedReason: string) => Promise<Policy>

  getPolicyDocument: (policyRefNumber: string, template: string) => Promise<Document>
  stageUpload: (
    policyId: string,
    file: File,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  ) => Promise<void>
  commitUpload: (policyId: string, commit: UploadCommit) => Promise<void>

  clearPolicyRenewalStop: (policyId: string) => Promise<Policy>
  setPolicyRenewalStop: (policyId: string, renewalStopReason: string) => Promise<Policy>
}

export const createPoliciesClient = (
  getInstance: () => Promise<AxiosInstance>,
): PoliciesClient => ({
  getPolicy: (policyId) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.get<Policy>(`/policies/${policyId}`)
      return response.data
    }),
  getPolicies: (params: { schemeCode?: string } = {}) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.get<Policy[]>(`/policies`, {
        params: { ...params },
      })
      return response.data
    }),
  updateBillingDay: (policyId, billingDay) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<Policy>(`/policies/${policyId}/billingDay`, {
        billingDay,
      })
      return response.data
    }),
  updateRenewalStop: (policyId, renewalStoppedReason) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<Policy>(`/policies/${policyId}/renewalStop`, {
        renewalStoppedReason,
      })
      return response.data
    }),
  getPolicyDocument: (policyRefNumber, template) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.get<Document>(
        `/documents/policy/${policyRefNumber}/${template}`,
      )
      return response.data
    }),
  stageUpload: (policyId, file, onUploadProgress) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()

      const form = new FormData()
      form.append('file', file)

      await instance.post(`/documents/upload/stage/${policyId}`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      })
    }),
  commitUpload: (policyId, upload) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()

      await instance.post(`/documents/upload/commit/${policyId}`, upload)
    }),
  clearPolicyRenewalStop: (policyId) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.delete<Policy>(`/policies/${policyId}/renewalStop`)
      return response.data
    }),
  setPolicyRenewalStop: (policyId, renewalStopReason) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.put<Policy>(`/policies/${policyId}/renewalStop`, {
        renewalStopReason,
      })
      return response.data
    }),
})
