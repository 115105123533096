import {
  Box,
  Flex,
  Heading,
  InvoiceTotalCell,
  mdBumps,
  Status,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { CustomerStatement } from '../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { toDateTime } from '../../../util/date'
import { IPT } from '../product'
import { InvoiceItems } from './InvoiceItems'
import { SubTotalSection } from './SubTotalSection'

export interface NextStatementModalProps {
  hide: () => void
  statement: CustomerStatement
}

const tKey = TKey('components.next-statement-modal')

export const NextStatementModal: FunctionComponent<NextStatementModalProps> = ({
  statement: { statementSummary, lineItems },
}) => {
  const { t } = useWriskTranslation()
  const { statementBalance, createdAt } = statementSummary

  return (
    <Box variant='raised'>
      <Box p={mdBumps} borderBottomWidth={1}>
        <Flex mb={2}>
          <Heading as='h2' variant='h2' typoSize='xl'>
            {t(tKey('header'))}
          </Heading>
          <Status typoSize='xs' variant='default'>
            {t(tKey('status'))}
          </Status>
        </Flex>
        <Typo typoSize='sm'>
          {t(tKey('subheader'), {
            createdAt: t(tFormats('date.medium'), {
              value: toDateTime(createdAt),
            }),
          })}
        </Typo>
      </Box>

      <InvoiceItems lineItems={lineItems} />

      <SubTotalSection summary={statementSummary} lineItems={lineItems} />

      <InvoiceTotalCell
        header={t<string>(tKey('total'))}
        subheader={<IPT />}
        premium={t<string>(tFormats('currency.long'), {
          amount: statementBalance / 100,
        })}
        p={mdBumps}
      />
    </Box>
  )
}
