import React, { FunctionComponent } from 'react'

import { Policy, Profile } from '../../../../../domain'
import { useSubmitRemoveProfilePolicyAdjustment } from '../../../../../hooks/adjustments/policy'
import { AdjustmentModal } from '../admin'

export interface RemoveAdditionalProfileAdjustmentModalProps {
  updatedData: Profile
  policy: Policy
  onSuccess: (response: unknown) => void
  onCancel: () => void
}

export const RemoveAdditionalProfileAdjustmentModal: FunctionComponent<
  RemoveAdditionalProfileAdjustmentModalProps
> = ({ updatedData, policy, onSuccess, onCancel }) => {
  const onSubmitAdjustment = useSubmitRemoveProfilePolicyAdjustment(onSuccess)

  return (
    <AdjustmentModal
      updatedData={updatedData}
      policy={policy}
      onCancel={onCancel}
      onSubmitAdjustment={onSubmitAdjustment}
    />
  )
}
