import { Box, Flex, Typo, xxlBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import {
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { Chat } from '../../../../organisms/Chat'
import { ExternalLink } from '../../../../organisms/links'
import { FullPage } from '../../../../templates'

const tKey = TKey('pages.invalid-adjustment')

export const InvalidPolicyAdjustmentPage: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  return (
    <FullPage pageId='invalid-state-adjustment-page' header={t(tKey('header'))}>
      <Box mb={xxlBumps}>
        <Typo as='pre'>
          <Trans
            t={t}
            i18nKey={tKey('subheader')}
            components={{
              ExternalLink: <ExternalLink />,
            }}
          />
        </Typo>
      </Box>
      <Flex width={1}>
        <Trans
          t={t}
          i18nKey={tKey('contact-us')}
          components={{
            Chat: <Chat />,
          }}
        />
      </Flex>
    </FullPage>
  )
}
