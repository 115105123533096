import { Box, ModalHeader, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { useNavigateCallback } from '../../../../../../hooks/navigation'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../../infrastructure/internationalisation'
import { AppPath } from '../../../../../AppPath'
import { ActionBar } from '../../../../../organisms/form'

const tKey = TKey('components.outstanding-balance-modal')

export const OutstandingBalanceModal: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  const onViewDashboardCallback = useNavigateCallback(AppPath.HOME)

  return (
    <Box>
      <ModalHeader header={t(tKey('header'))} />
      <Typo>{t(tKey('content'))}</Typo>
      <ActionBar loading={false} onConfirm={onViewDashboardCallback} tKey={tKey} mt={6} />
    </Box>
  )
}
