import {
  Box,
  Flex,
  mdBumps,
  RadioButton,
  RadioButtonGrid,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'

import { TKey } from '../../../infrastructure/internationalisation'
import { FormInputProps } from '../types'

const selectListTKey = TKey('components.boolean-list-form')

export const BooleanListFormInput: FunctionComponent<
  FormInputProps<Record<string, boolean | undefined>, string[]>
> = ({ value, onChange, meta, tKey, tName, t }) => {
  const selectList = {
    [t(selectListTKey('yes'))]: true,
    [t(selectListTKey('no'))]: false,
  }

  const onInputChange = useCallback(
    (id: string) =>
      ({ target }) =>
        onChange({
          ...value,
          [id]: Boolean(target.value),
        }),
    [onChange, value],
  )

  const inputList = meta.map((it) => (
    <Flex width={1} key={it} px={mdBumps} py={3}>
      <Box mr={2}>
        <Typo fontFamily='input'>
          {t([tKey(tName, 'options', it), `entries.${tName}.${it}`])}
        </Typo>
      </Box>

      <RadioButtonGrid data-testid={it} columns={2} minWidth='6.5rem'>
        {Object.entries(selectList).map(([text, option], index) => (
          <RadioButton
            type='nested'
            layout='small'
            name={it}
            key={index}
            value={option ? 1 : 0}
            onChange={onInputChange(it)}
            data-testid={option}
            checked={value?.[it] === option}
          >
            {text}
          </RadioButton>
        ))}
      </RadioButtonGrid>
    </Flex>
  ))

  return (
    <Flex width={1} variant='raised' flexDirection='column' py={2}>
      {inputList}
    </Flex>
  )
}
