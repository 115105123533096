import { isNil } from 'lodash'
import React, { FunctionComponent, useCallback } from 'react'

import { Specification } from '../../../../domain'
import { VehicleCard, VehicleTabs, VehicleTabsConfig } from '../../../organisms/form'
import { FormInputProps } from '../../types'
import { NULL_VEHICLE, VehicleInputProps } from './types'

export const VehicleTabsFormInput: FunctionComponent<
  FormInputProps<VehicleInputProps, VehicleTabsConfig>
> = ({ t, tKey, tName, value, onChange, meta }) => {
  const onChangeButtonClick = useCallback(() => {
    onChange(NULL_VEHICLE)
  }, [onChange])

  const onVehicleSelected = useCallback(
    (specification?: Specification, foundByLookup = false, vrn?: string | undefined) => {
      if (specification) {
        onChange({
          specification: specification,
          vrn: vrn,
          regLookup: foundByLookup,
        })
      } else {
        onChange(NULL_VEHICLE)
      }
    },
    [onChange],
  )

  const initialTab = isNil(value?.regLookup) || value?.regLookup ? 0 : 1

  return value?.specification ? (
    <VehicleCard
      showReg={meta?.showReg}
      specification={value?.specification}
      onChangeButtonClick={onChangeButtonClick}
    />
  ) : (
    <VehicleTabs
      t={t}
      tKey={tKey}
      tName={tName}
      config={meta}
      initialTab={initialTab}
      value={value?.specification}
      onVehicleSelected={onVehicleSelected}
    />
  )
}
