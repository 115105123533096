export const hasFormAttribute = () => {
  const form = document.createElement('form')
  const input = document.createElement('input')
  const div = document.createElement('div')
  const id = `form_test${new Date().getTime()}`

  form.id = id

  // IE6/7 confuses the form idl attribute and the form content attribute, so we use document.createAttribute
  try {
    input.setAttribute('form', id)
  } catch (e) {
    // TODO: do we need airbrakeError() or some other notification here?
    if (document.createAttribute) {
      const attr = document.createAttribute('form')
      attr.nodeValue = id
      input.setAttributeNode(attr)
    }
  }

  div.appendChild(form)
  div.appendChild(input)

  document.body.appendChild(div)

  const bool = form.elements && form.elements.length === 1 && input.form === form

  div.parentNode && div.parentNode.removeChild(div)
  return bool
}
