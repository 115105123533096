import { Anchor } from '@wrisk/ui-components'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import styled from 'styled-components'

const TemplateContainer = styled.div({
  display: 'inline',
  whiteSpace: 'nowrap',
  textDecoration: 'underline',
})

export interface VehicleNotFoundProps extends PropsWithChildren {
  onClick: (data?: unknown) => void
}

export const SelectModel: FunctionComponent<VehicleNotFoundProps> = ({
  onClick,
  children,
}) => (
  <TemplateContainer>
    <Anchor onClick={onClick}>{children}</Anchor>
  </TemplateContainer>
)
