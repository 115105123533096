import { KeyPrefix, Namespace } from 'i18next'
import { useTranslation, UseTranslationOptions } from 'react-i18next'

import { useScheme } from '../../application/product/productContext'

export const useWriskTranslation = <
  N extends Namespace,
  TKPrefix extends KeyPrefix<N> = undefined,
>(
  options?: UseTranslationOptions<TKPrefix>,
) => {
  const scheme = useScheme()
  return useProductTranslation(scheme?.schemeCode, options)
}

export const useProductTranslation = <
  N extends Namespace,
  TKPrefix extends KeyPrefix<N> = undefined,
>(
  schemeCode: string | undefined,
  options?: UseTranslationOptions<TKPrefix>,
) => useTranslation(schemeCode, options)
