import { useMemo } from 'react'

import { TKey, useWriskTranslation } from '../internationalisation'

export interface Page {
  url: string
  id: string
}

const tBack = TKey('back')

export const useUpButton = (parent?: Page) => {
  const { t } = useWriskTranslation()
  return useMemo(
    () => (parent ? { to: parent.url, text: t(tBack(parent.id)) } : undefined),
    [parent, t],
  )
}
