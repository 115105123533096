import { PaymentMethodType } from './paymentMethod'

export interface CustomerAccount {
  accountId: string
  accountStatus: AccountStatus
  paymentMethodType?: PaymentMethodType
  accountBalance: number
  paymentDay: number
  accountCode?: string
  outstandingAmount: number
}

export enum AccountStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export interface CustomerPayment {
  paymentId: string
  amount: number
}
