import {
  Box,
  BoxProps,
  Flex,
  smBumps,
  TertiaryButton,
  Typo,
  xsBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  CustomerAccount,
  getUsablePaymentMethod,
  hasActiveAccountsWithPaymentMethod,
  PaymentMethod,
  PaymentMethodType,
} from '../../../domain'
import { useSetupStripe } from '../../../hooks/stripe'
import {
  TKeyBuilder,
  tPaymentMethods,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

export interface PaymentMethodCardProps extends BoxProps {
  tKey: TKeyBuilder
  customerAccount: CustomerAccount
  paymentMethods: PaymentMethod[]
}

export const PaymentMethodCard: FunctionComponent<PaymentMethodCardProps> = ({
  customerAccount,
  paymentMethods,
  tKey,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const paymentMethod = getUsablePaymentMethod(paymentMethods, PaymentMethodType.CARD)

  const onSetupStripe = useSetupStripe({
    successPath: location.pathname,
    cancelPath: location.pathname,
    clientReference: paymentMethod?.mandateId,
  })

  return (
    <Flex px={smBumps} py={xsBumps} {...props}>
      <Box>
        <Typo typoSize='xs' color='bodySecondary'>
          {t(tKey('card', 'header'))}
        </Typo>
        <Typo fontWeight='bold' textTransform='capitalize'>
          {paymentMethod
            ? t(tPaymentMethods('card', 'redacted'), paymentMethod)
            : t(tPaymentMethods('missing'))}
        </Typo>
      </Box>
      {hasActiveAccountsWithPaymentMethod([customerAccount], PaymentMethodType.CARD) && (
        <TertiaryButton
          whiteSpace='nowrap'
          layout='small'
          onClick={onSetupStripe.execute}
          loading={onSetupStripe.loading}
        >
          {paymentMethod
            ? t(tKey('card', 'actions', 'edit'))
            : t(tKey('card', 'actions', 'set-up'))}
        </TertiaryButton>
      )}
    </Flex>
  )
}
