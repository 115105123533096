import {
  FlexAnchor,
  FlexAnchorProps,
  HelpLinkIFrame,
  Icon,
  IconProperty,
  smBumps,
  Typo,
  useModal,
} from '@wrisk/ui-components'
import React, { useCallback } from 'react'

import {
  tCovers,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

interface IncludedCoverItemProps extends Omit<FlexAnchorProps, 'ref'> {
  cover: string
  iconSize?: IconProperty
  contentBaseUrl: string
}

export const IncludedCoverItem: React.FC<IncludedCoverItemProps> = ({
  cover,
  contentBaseUrl,
  typoSize,
  iconSize,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const modal = useModal()

  const onClick = useCallback(() => {
    modal.show({
      content: <HelpLinkIFrame src={`${contentBaseUrl}/${cover}.html`} />,
      variant: 'iframe',
    })
  }, [contentBaseUrl, cover, modal])

  return (
    <FlexAnchor
      variant='highlight'
      justifyContent='flex-start'
      py={3}
      px={smBumps}
      onClick={onClick}
      {...props}
    >
      <Icon icon='tick' mr={smBumps} size={iconSize} />
      <Typo wordWrap='break-word' typoSize={typoSize}>
        {t(tCovers(cover, 'name'))}
      </Typo>
    </FlexAnchor>
  )
}
