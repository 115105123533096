import css from '@styled-system/css'
import React, { FunctionComponent, ReactNode, useCallback } from 'react'
import styled from 'styled-components'
import { compose } from 'styled-system'

import { LinkAnchor, TypoSizeProps, typoSizes } from '../atoms'
import { HelpLinkIFrame } from './HelpLinkIFrame'
import { useModal } from './Modal'

export interface HelpLinkProps extends TypoSizeProps {
  linkText: ReactNode
  link: string
  isOpenModal?: boolean
  className?: string
}

const Component: FunctionComponent<HelpLinkProps> = ({
  link,
  linkText,
  className,
  isOpenModal = true,
  ...props
}) => {
  const modal = useModal()

  const openModal = useCallback(
    () =>
      modal.show({
        content: <HelpLinkIFrame src={link} />,
        variant: 'iframe',
      }),
    [link, modal],
  )

  const helpLink = isOpenModal ? (
    <LinkAnchor
      variant='standalone'
      target='_blank'
      className={className}
      href={link}
      onClick={openModal}
      {...props}
    >
      {linkText}
    </LinkAnchor>
  ) : (
    <LinkAnchor
      variant='standalone'
      target='_blank'
      className={className}
      href={link}
      {...props}
    >
      {linkText}
    </LinkAnchor>
  )

  return <>{helpLink}</>
}

export const HelpLink = styled(Component)<HelpLinkProps>(
  css({
    color: 'hyperlink',
    fontFamily: 'buttons',
    fontWeight: 'bold',
  }),
  compose(typoSizes),
)

HelpLink.defaultProps = {
  typoSize: 'sm',
}
