import { LicencePlate } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { tDefaults } from '../../../infrastructure/internationalisation'
import { Formatter } from '../types'

export const LicencePlateFormatter: FunctionComponent<Formatter<string>> = ({
  value,
  t,
}) => {
  return value ? (
    <LicencePlate variant='subtle' licencePlate={value} />
  ) : (
    <>{t(tDefaults('inputs.no-answer'))}</>
  )
}
