import React, { FunctionComponent, ReactNode } from 'react'

import { Box, BoxProps, Flex, smBumps, Typo, xsBumps } from '../atoms'

export interface PremiumLineItem {
  header: ReactNode
  currentAmount: string
  previousAmount?: string
}

export interface PremiumBreakdownProps extends BoxProps {
  lineItems: PremiumLineItem[]
  ipt: ReactNode
}

export const PremiumBreakdown: FunctionComponent<PremiumBreakdownProps> = ({
  lineItems,
  ipt,
  ...props
}) => {
  return (
    <Box {...props}>
      {lineItems.map(({ header, previousAmount, currentAmount }, i) => (
        <Flex width={1} key={i} my={smBumps}>
          <Typo typoSize='sm'>{header}</Typo>
          <Flex>
            {previousAmount && previousAmount !== currentAmount && (
              <Typo mr={xsBumps} color='inactive' typoSize='sm'>
                <s>{previousAmount}</s>
              </Typo>
            )}
            <Typo typoSize='sm'>{currentAmount}</Typo>
          </Flex>
        </Flex>
      ))}
      <Box width={1} borderTopWidth={1} py={smBumps}>
        <Typo typoSize='xs' color='bodySecondary'>
          {ipt}
        </Typo>
      </Box>
    </Box>
  )
}
