import React, { FunctionComponent, ReactNode, useCallback, useState } from 'react'

import { Box, BoxProps, FlexAnchor, Icon, smBumps, xsBumps } from '../atoms'

export interface ExpandableSectionProps extends BoxProps {
  header: ReactNode
  open?: boolean
  reversed?: boolean
}

export const ExpandableSection: FunctionComponent<ExpandableSectionProps> = ({
  open = false,
  reversed = false,
  header,
  children,
  ...props
}) => {
  const [opened, setOpen] = useState(open)
  const onExpandClick = useCallback(() => setOpen((prev) => !prev), [])

  return (
    <Box {...props}>
      <FlexAnchor
        transition='default'
        flexDirection={reversed ? 'row-reverse' : 'row'}
        as='div'
        aria-expanded={opened}
        width={1}
        justifyContent='stretch'
        p={smBumps}
        variant='highlight'
        onClick={onExpandClick}
      >
        <Icon
          icon='chevronDown'
          size='tiny'
          mr={reversed ? 0 : xsBumps}
          ml={reversed ? xsBumps : 0}
          rotate={opened ? 180 : 0}
          transition='default'
        />
        <Box width={1}>{header}</Box>
      </FlexAnchor>
      {opened && <Box width={1}>{children}</Box>}
    </Box>
  )
}
