import { Box, ModalHeader } from '@wrisk/ui-components'
import React, { FunctionComponent, useState } from 'react'

import { useApiErrorHandlingAsyncCallback } from '../../../hooks/auth'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { FeedbackConfig } from '../../../state/configuration'
import { usePrincipal } from '../../authentication'
import { FeedbackForm } from './FeedbackForm'
import { FeedbackModalConfirmation } from './FeedbackModalConfirmation'
import { FeedbackModalTrustPilot } from './FeedbackModalTrustPilot'

const tKey = TKey('components.feedback-modal')

export interface FeedbackFormData {
  rating: number
  comment: string
}

interface FeedbackModalProps {
  config: FeedbackConfig
  tags: Record<string, string>
}

export const FeedbackModal: FunctionComponent<FeedbackModalProps> = ({
  config,
  tags,
}) => {
  const { t } = useWriskTranslation()
  const { apiClient } = usePrincipal()

  const [view, setView] = useState('default')

  const onSubmit = useApiErrorHandlingAsyncCallback(async (data: FeedbackFormData) => {
    const action = config.actions[data.rating]

    void apiClient.postInsight({
      type: config.type,
      rating: data.rating,
      comment: data.comment,
      tags,
    })

    setView(action)
  })

  return view === 'confirmation' ? (
    <FeedbackModalConfirmation />
  ) : view === 'trustPilot' ? (
    <FeedbackModalTrustPilot />
  ) : (
    <Box>
      <ModalHeader header={t(tKey('header'))} />
      <FeedbackForm type={config.type} onSubmit={onSubmit} />
    </Box>
  )
}
