import { SectionProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { isExcessEndorsement, Quote } from '../../../domain'
import { useProduct } from '../../product/productContext'
import { Endorsements } from '../endorsements'

export interface ExcessEndorsementsProps extends SectionProps {
  quote: Quote
}

export const ExcessEndorsements: FunctionComponent<ExcessEndorsementsProps> = ({
  quote,
  ...props
}) => {
  const { product } = useProduct()

  const endorsements = quote.endorsements.filter(isExcessEndorsement(product))

  return <Endorsements quote={quote} endorsements={endorsements} {...props} />
}
