import css from '@styled-system/css'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'
import {
  BackgroundColorProps,
  BorderProps,
  borders,
  color,
  compose,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from 'styled-system'

import { transition, TransitionProps } from '../../transition'
import { containerStyle, ContainerStyleProps } from './containerStyle'

export interface BoxProps
  extends LayoutProps,
    SpaceProps,
    BorderProps,
    BackgroundColorProps,
    ContainerStyleProps,
    TransitionProps,
    PositionProps,
    PropsWithChildren {
  id?: string
}

export const Box = styled.div<BoxProps>(
  css({
    // scrollbarGutter: 'stable',
    borderColor: 'divider',
    borderStyle: 'solid',
    borderWidth: 0,
  }),
  containerStyle,
  compose(layout, position, space, borders, color, transition),
)
