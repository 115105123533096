import { Box, ModalHeader } from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'

import {
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { ActionBar } from '../../../../organisms/form'
import { AdjustmentModalProps } from './types'

const tKey = TKey('components.deferred-adjustment-modal')

export const DeferredAdjustmentModal: FunctionComponent<AdjustmentModalProps> = ({
  updatedData,
  onCancel,
  onSubmitAdjustment,
}) => {
  const { t } = useWriskTranslation()

  const onSubmit = useCallback(
    () => void onSubmitAdjustment.execute(updatedData),
    [onSubmitAdjustment, updatedData],
  )

  return (
    <Box>
      <ModalHeader header={t(tKey('header'))} subheader={t(tKey('subheader'))} />
      <ActionBar
        onDismiss={onCancel}
        loading={onSubmitAdjustment.loading}
        onConfirm={onSubmit}
        tKey={tKey}
      />
    </Box>
  )
}
