import { Box, Flex, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

interface Props {
  name: string
  value: string | undefined | null
}

export const AttributeRow: FunctionComponent<Props> = ({ name, value }) =>
  value ? (
    <Flex width={1} mt={2}>
      <Box width={[1 / 2, 1 / 3]}>
        <Typo typoSize='sm' color='bodySecondary'>
          {name}
        </Typo>
      </Box>
      <Box width={[1 / 2, 2 / 3]}>
        <Typo typoSize='sm'>{value}</Typo>
      </Box>
    </Flex>
  ) : null
