import { logger } from '../../infrastructure/logging'
import { ChatClient } from './types'

export const twilioChat: ChatClient = {
  show: () => {
    try {
      window.Twilio?.expandWebChat()
    } catch (e) {
      logger.error('There was a problem showing Twilio Chat', e)
    }
  },
  register: ({ provider, config, locale }) => {
    if (config?.chatServer) {
      if (!window.Twilio) logger.error(`Twilio could not be loaded`)

      try {
        window.Twilio?.initLogger('info')
        window.Twilio?.initWebchat({
          serverUrl: config.chatServer,
          brandName: provider,
          lng: locale,
        })
      } catch (e) {
        logger.error('There was a problem initialising Twilio Chat', e)
      }
    }
  },
  shutDown: () => {
    try {
      window.Twilio?.removeChatUser()
    } catch (e) {
      logger.error('There was a problem shutting down Twilio Chat', e)
    }
  },
  updateUser: (user) => {
    try {
      window.Twilio?.updateChatUser({
        userId: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        emailAddress: user.username,
      })
    } catch (e) {
      logger.error('There was a problem updating user in Twilio Chat', e)
    }
  },
}
