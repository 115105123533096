import React, { FunctionComponent, ReactNode } from 'react'

import { Box, Flex, FlexProps, StyledPremium, Typo, xsBumps } from '../atoms'

export interface PremiumSummaryProps extends FlexProps {
  header: ReactNode
  subheader?: ReactNode
  currentAmount: string
  previousAmount?: string
}

export const PremiumSummary: FunctionComponent<PremiumSummaryProps> = ({
  header,
  subheader,
  currentAmount,
  previousAmount,
  ...props
}) => (
  <Flex {...props}>
    <Box mr={2}>
      <Typo fontWeight='bold'>{header}</Typo>
      {subheader && (
        <Typo typoSize='xs' mt={1}>
          {subheader}
        </Typo>
      )}
    </Box>
    <Flex>
      {previousAmount && currentAmount !== previousAmount && (
        <Typo mr={xsBumps} color='inactive'>
          <s>{previousAmount}</s>
        </Typo>
      )}
      <StyledPremium
        variant='inverted'
        typoSize='md'
        fontWeight='bold'
        data-testid='styled-premium'
      >
        {currentAmount}
      </StyledPremium>
    </Flex>
  </Flex>
)
