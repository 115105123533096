import { useModal } from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { Data, PolicyAdjustmentResponse } from '../../../../../domain'
import {
  usePolicyAdjustment,
  usePolicyFormData,
} from '../../../../../hooks/adjustments/policy'
import {
  TExtends,
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { Page, useUpButton } from '../../../../../infrastructure/routing'
import { QuoteManagementConfig } from '../../../../../state/configuration'
import { usePrincipal } from '../../../../authentication'
import { FormErrors } from '../../../../formBuilder'
import { FullPage } from '../../../../templates'
import { usePolicy } from '../../policyContext'
import { PolicyAdjustmentModal } from './PolicyAdjustmentModal'
import { PolicyAdjustmentPath } from './PolicyAdjustmentPath'

const tKey = TKey('pages.disclosure')

export interface PolicyAdjustmentPageProps {
  parent: Page
  config: QuoteManagementConfig
}

export const PolicyAdjustmentPage: FunctionComponent<PolicyAdjustmentPageProps> = ({
  config,
  parent,
}) => {
  const { t } = useWriskTranslation()
  const { policy } = usePolicy()
  const { isAdmin } = usePrincipal()
  const modal = useModal()

  const data = usePolicyFormData(config.inputs)
  const navigate = useNavigate()

  const upButton = useUpButton(parent)

  const onSuccess = useCallback(
    (response: PolicyAdjustmentResponse | undefined) => {
      modal.hide()

      response
        ? navigate(
            generatePath(PolicyAdjustmentPath.Adjustment, {
              adjustmentId: response.adjustmentId,
            }),
          )
        : navigate(parent.url)
    },
    [modal, navigate, parent],
  )

  const onSubmitAdjustment = usePolicyAdjustment(config.inputs, onSuccess)

  const onSubmit = useCallback(
    (updatedData: Data) => {
      if (isAdmin) {
        modal.show({
          content: (
            <PolicyAdjustmentModal
              updatedData={updatedData}
              policy={policy}
              config={config}
              onSuccess={onSuccess}
              onCancel={modal.hide}
            />
          ),
        })
      } else {
        void onSubmitAdjustment.execute(updatedData)
      }
    },
    [config, isAdmin, modal, onSubmitAdjustment, onSuccess, policy],
  )

  return (
    <FullPage
      pageId='adjustment-section'
      header={t(tKey('headers', config.id))}
      upButton={upButton}
    >
      <FormErrors
        id='adjustmentSection'
        key='adjustmentSection'
        data={data}
        inputs={config.inputs}
        onSubmit={onSubmit}
        loading={onSubmitAdjustment.loading}
        tKey={TExtends(tKey, 'form')}
        t={t}
      />
    </FullPage>
  )
}
