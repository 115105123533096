import {
  Box,
  Heading,
  Image,
  lgBumps,
  LinkAnchor,
  PromotionBannerContainer,
  PromotionBannerContainerProps,
  useModal,
  WriskTheme,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'
import { useTheme } from 'styled-components'
import { SpaceProps } from 'styled-system'

import { Quote } from '../../../domain'
import {
  TKeyBuilder,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { PromotionTermsModal } from './PromotionTermsModal'

interface ContentProps extends SpaceProps {
  promoCode: string
  tKey: TKeyBuilder
}

const Content: FunctionComponent<ContentProps> = ({ tKey, promoCode, ...props }) => {
  const { t } = useWriskTranslation()

  const theme = useTheme() as WriskTheme
  const modal = useModal()

  const onOfferTermsClick = useCallback(() => {
    if (promoCode) {
      modal.show({
        content: <PromotionTermsModal promoCode={promoCode} />,
      })
    }
  }, [modal, promoCode])

  const background = theme.promoImages?.[promoCode]?.background
  const foreground = theme.promoImages?.[promoCode]?.foreground

  return (
    <PromotionBannerContainer
      backgroundImage={background}
      className={promoCode}
      {...props}
    >
      <Box width={1}>
        <Heading variant='h3' as='h3' typoSize='xl' mb={lgBumps}>
          {t(tKey(promoCode, 'heading'))}
        </Heading>
        <LinkAnchor
          typoSize='sm'
          lineHeight={0}
          variant='standalone'
          borderColor='foregroundPromoBanner'
          color='foregroundPromoBanner'
          hoverColor='foregroundPromoBanner'
          onClick={onOfferTermsClick}
        >
          {t(tKey('actions', 'offerTerms'))}
        </LinkAnchor>
      </Box>
      {foreground && <Image src={foreground} />}
    </PromotionBannerContainer>
  )
}

export interface PromotionBannerProps extends PromotionBannerContainerProps {
  tKey: TKeyBuilder
  quote: Quote
}

export const PromotionBanner: FunctionComponent<PromotionBannerProps> = ({
  quote: { promoCode },
  tKey,
  ...props
}) => (promoCode ? <Content tKey={tKey} promoCode={promoCode} {...props} /> : null)
