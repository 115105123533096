import React, { FunctionComponent } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Page } from '../../../../infrastructure/routing'
import { AppPath } from '../../../AppPath'
import { PolicyAdjustmentResultRouter } from '../adjustments'
import { ExcessPath } from './ExcessPath'

export const ExcessRouter: FunctionComponent<{ parent: Page }> = ({ parent }) => (
  <Routes>
    <Route
      key='review'
      path={ExcessPath.REVIEW + AppPath.WILDCARD}
      element={
        <PolicyAdjustmentResultRouter adjustmentPage={parent} policyPage={parent} />
      }
    />
    <Route path='*' element={<Navigate to={parent.url} />} />
  </Routes>
)
