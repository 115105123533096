import { xxlBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import {
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { Page, useUpButton } from '../../../../../infrastructure/routing'
import { ManagementType } from '../../../../../state/configuration'
import { FullPage } from '../../../../templates'
import { useProductConditions } from '../../../conditions'
import { useProduct } from '../../../productContext'
import { AdditionalProfileSection, PolicyAdjustmentSection } from './components'

const tKey = TKey('pages.adjustment-management')

export interface ManagementPageProps {
  parent: Page
}

export const ManagementPage: FunctionComponent<ManagementPageProps> = ({ parent }) => {
  const { t } = useWriskTranslation()

  const {
    product: { management },
  } = useProduct()

  const conditions = useProductConditions()
  const upButton = useUpButton(parent)

  return (
    <FullPage pageId='management-page' header={t(tKey('header'))} upButton={upButton}>
      {management
        .filter((section) => section.conditions?.every(conditions) ?? true)
        .map((it, index) => {
          const mb = management.length - 1 === index ? undefined : xxlBumps

          switch (it.type) {
            case ManagementType.ADDITIONAL_PROFILE:
              return (
                <AdditionalProfileSection key={it.id} config={it} tKey={tKey} mb={mb} />
              )
            default:
              return (
                <PolicyAdjustmentSection key={it.id} config={it} tKey={tKey} mb={mb} />
              )
          }
        })}
    </FullPage>
  )
}
