import { FunctionComponent } from 'react'

import {
  AddressFormatter,
  BooleanFormatter,
  BooleanListFormatter,
  CascadingDropdownFormatter,
  CheckboxListFormatter,
  DateFormatter,
  LicencePlateFormatter,
  NameFormatter,
  NumberTextFormatter,
  QuestionLoopFormatter,
  RadioFormatter,
  SingleSelectFormatter,
  StringFormatter,
  VehicleFormatter,
  YearMonthFormatter,
} from './formatters'
import {
  AddressFormInput,
  BooleanFormInput,
  BooleanListFormInput,
  CascadingFormInput,
  CheckboxFormInput,
  CheckboxListFormInput,
  CheckboxPromotionFormInput,
  DataEntryInput,
  DateFormInput,
  DatePickerFormInput,
  DropDownSelectFormInput,
  EmailFormInput,
  LookupFormInput,
  NameFormInput,
  NumberTextFormInput,
  PhoneNumberFormInput,
  RadioFormInput,
  SingleSelectFormInput,
  TextFormInput,
  VehicleLookupFormInput,
  VehicleRegistrationFormInput,
  VehicleTabsFormInput,
  YearMonthFormInput,
} from './inputs'
import { HiddenFormInput } from './inputs/HiddenFormInput'
import { Formatter, FormInputProps } from './types'

const inputMap: Record<string, FunctionComponent<FormInputProps>> = {
  boolean: BooleanFormInput,
  booleanList: BooleanListFormInput,
  name: NameFormInput,
  date: DateFormInput,
  lookup: LookupFormInput,
  dataEntry: DataEntryInput,
  dropDownSelect: DropDownSelectFormInput,
  cascading: CascadingFormInput,
  address: AddressFormInput,
  checkbox: CheckboxFormInput,
  checkboxPromotion: CheckboxPromotionFormInput,
  checkboxList: CheckboxListFormInput,
  text: TextFormInput,
  radio: RadioFormInput,
  numberText: NumberTextFormInput,
  phoneNumber: PhoneNumberFormInput,
  singleSelect: SingleSelectFormInput,
  yearMonth: YearMonthFormInput,
  vehicleTabs: VehicleTabsFormInput,
  vehicleLookup: VehicleLookupFormInput,
  vehicleRegistration: VehicleRegistrationFormInput,
  datePicker: DatePickerFormInput,
  email: EmailFormInput,
  hidden: HiddenFormInput,
}

export const getInput = (
  type: keyof typeof inputMap,
): FunctionComponent<FormInputProps> => inputMap[type]

const formatterMap: Record<string, FunctionComponent<Formatter>> = {
  boolean: BooleanFormatter,
  booleanList: BooleanListFormatter,
  name: NameFormatter,
  date: DateFormatter,
  lookup: StringFormatter,
  dataEntry: StringFormatter,
  dropDownSelect: StringFormatter,
  cascading: CascadingDropdownFormatter,
  address: AddressFormatter,
  checkboxList: CheckboxListFormatter,
  text: StringFormatter,
  radio: RadioFormatter,
  numberText: NumberTextFormatter,
  phoneNumber: StringFormatter,
  singleSelect: SingleSelectFormatter,
  yearMonth: YearMonthFormatter,
  vehicleTabs: VehicleFormatter,
  vehicleLookup: VehicleFormatter,
  vehicleRegistration: LicencePlateFormatter,
  datePicker: DateFormatter,
  email: StringFormatter,
  questionLoop: QuestionLoopFormatter,
}

export const getFormatter = (
  type: keyof typeof formatterMap,
): FunctionComponent<Formatter> => {
  const formatter = formatterMap[type]

  if (!formatter) {
    throw new Error(`Could not find formatter for ${type}`)
  }

  return formatter
}
