import { useMemo } from 'react'

import { isPolicyholderWithAccount } from '../../../../domain'
import {
  AnonymousCondition,
  DisclosureSectionCondition,
} from '../../../../state/configuration'
import { usePolicyholder } from '../../../authentication'

export const useAnonymousCondition = () => {
  const { policyholder } = usePolicyholder()

  return useMemo(
    () => (condition: AnonymousCondition) =>
      isPolicyholderWithAccount(policyholder) !== condition.isAnonymousOnly,
    [policyholder],
  )
}

export const useSectionConditions = () => {
  const anonymousCondition = useAnonymousCondition()

  return useMemo(
    () => (it: DisclosureSectionCondition) => {
      switch (it.type) {
        case 'anonymous':
          return anonymousCondition(it as AnonymousCondition)
      }
    },
    [anonymousCondition],
  )
}
