import React, { FunctionComponent, useCallback } from 'react'

import { Box, mdBumps } from '../atoms'
import { useNotifyingState } from '../extensions'
import { TabAnchor } from './TabAnchor'
import { TabBar, TabBarProps } from './TabBar'

export interface TabItem {
  header: string
  component: (setTabIndex: (index: number) => void) => JSX.Element
}

export interface TabViewProps extends TabBarProps {
  items: TabItem[]
  onTabChange?: (index: number) => void
  className?: string
  tabIndex?: number
}

interface TabProps {
  item: TabItem
  selected: boolean
  onClick: (index: number) => void
  index: number
}

const Tab: FunctionComponent<TabProps> = ({ item, index, selected, onClick }) => {
  const onAnchorClick = useCallback(() => onClick(index), [onClick, index])
  return (
    <TabAnchor
      data-testid='tab-anchor'
      onClick={onAnchorClick}
      selected={selected}
      key={item.header}
      mr={6}
    >
      {item.header}
    </TabAnchor>
  )
}

export const TabView: FunctionComponent<TabViewProps> = ({
  items,
  onTabChange,
  tabIndex,
  ...props
}) => {
  const [current, setCurrent] = useNotifyingState(
    tabIndex ?? 0,
    onTabChange ? [onTabChange] : [],
  )

  const tabs = items.map((item, index) => (
    <Tab
      key={index}
      index={index}
      item={item}
      selected={current === index}
      onClick={setCurrent}
    />
  ))

  return (
    <Box {...props}>
      <TabBar width={1} mb={mdBumps}>
        {tabs}
      </TabBar>
      <Box width={1}>{items[current].component(setCurrent)}</Box>
    </Box>
  )
}
