import { CascadingFilterInput, CascadingFilterInputOption } from '@wrisk/ui-components'
import { uniq } from 'lodash'
import React, { FunctionComponent, useCallback, useMemo } from 'react'

import { tEntries } from '../../../infrastructure/internationalisation'
import { FormInputProps } from '../types'

interface CascadingDropdownItem {
  id: string

  [key: string]: string
}

interface CascadingFormInputMeta {
  items: CascadingDropdownItem[]
  dropdowns: string[]
}

export const CascadingFormInput: FunctionComponent<
  FormInputProps<string, CascadingFormInputMeta>
> = ({ tName, tKey, t, value, onChange, meta: { dropdowns, items } }) => {
  const config = useMemo(() => {
    const optionText = (optionId: string) => t(tEntries(tName, optionId))

    return dropdowns.map((dropdown) => ({
      placeholder: t(tKey(tName, 'placeholder')),
      mapItemsToOptions: (itms: CascadingDropdownItem[]) =>
        uniq(itms.map((it) => it[dropdown]))
          .filter((id) => id !== undefined)
          .map((id) => ({ id, text: optionText(id) })),
      matchItemToOption:
        (option: CascadingFilterInputOption) => (itm: CascadingDropdownItem) =>
          itm[dropdown] === option.id,
      matchOptionToItem:
        (itm: CascadingDropdownItem) => (option: CascadingFilterInputOption) =>
          itm[dropdown] === option.id,
    }))
  }, [dropdowns, t, tKey, tName])

  const onChangeCallback = useCallback(
    (itm: CascadingDropdownItem | undefined) => onChange(itm?.id),
    [onChange],
  )

  return (
    <CascadingFilterInput
      value={items.find((it) => it.id === value)}
      items={items}
      onSelect={onChangeCallback}
      config={config}
    />
  )
}
