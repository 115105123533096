import React, { ChangeEvent, useEffect, useState } from 'react'

import { Box, GhostSelect, InputWrapper, Typo } from '../atoms'

export interface DropDownMenuProps<T> {
  values: T[]
  currentValue?: T
  placeholder?: string
  formatter: (v: T) => string
  onSelectCallback: (value: T) => void
}

export const DropDownMenu: <T>(
  props: DropDownMenuProps<T>,
) => React.ReactElement<DropDownMenuProps<T>> | null = (props) => {
  const { values, currentValue, placeholder, formatter, onSelectCallback } = props
  const [value, setValue] = useState(currentValue)

  useEffect(() => {
    setValue(currentValue)
  }, [currentValue])

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    let index = event.target.selectedIndex
    if (placeholder) index--

    setValue(values[index])
    onSelectCallback(values[index])
  }

  const menuOptions = values.map((v, index) => (
    <option key={index}>{formatter(v)}</option>
  ))
  const selectPickerValue = value !== undefined ? formatter(value) : undefined

  return (
    <InputWrapper width={1}>
      <Box width={1}>
        <Typo fontFamily='input'>
          <GhostSelect
            required
            value={selectPickerValue}
            onChange={onChange}
            defaultValue={placeholder ? placeholder : selectPickerValue}
          >
            {placeholder && (
              <option value={placeholder} disabled hidden>
                {placeholder}
              </option>
            )}
            {menuOptions}
          </GhostSelect>
        </Typo>
      </Box>
    </InputWrapper>
  )
}
