import axios from 'axios'

import { createClaimsClient } from './clients/claims'
import { createCustomerAccountClient } from './clients/customerAccount'
import { createCustomerStatementClient } from './clients/customerStatement'
import { createCustomerVerificationClient } from './clients/customerVerification'
import { createFnolClient } from './clients/fnol'
import { createGoCardlessClient } from './clients/goCardless'
import { createInsightsClient } from './clients/insights'
import { createLookupClient } from './clients/lookup'
import { createPaymentCardClient } from './clients/paymentCard'
import { createPoliciesClient } from './clients/policies'
import { createPolicyAdjustmentsClient } from './clients/policyAdjustments'
import { createPolicyholderClient } from './clients/policyholder'
import { createProposalsClient } from './clients/proposals'
import { ApiClient, ApiConfig } from './types'

export type ApiClientFactory = (
  getAccessToken: () => Promise<string>,
  defaultParams?: Record<string, string | undefined>,
) => ApiClient

export const createApiClientFactory =
  ({ hostname, brand }: ApiConfig): ApiClientFactory =>
  (getAccessToken, defaultParams) => {
    const instance = axios.create({
      baseURL: `${hostname}/api`,
    })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    instance.defaults.headers.common.Accept = 'application/json'
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    instance.defaults.headers.post['Content-Type'] = 'application/json'
    // // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    instance.defaults.params = defaultParams ?? {}

    const getInstance = async () => {
      const token = await getAccessToken()

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      instance.defaults.headers.common.Authorization = `Bearer ${token}`
      return instance
    }

    const goCardlessClient = createGoCardlessClient(getInstance)
    const lookupClient = createLookupClient(getInstance)
    const paymentCardsClient = createPaymentCardClient(getInstance)
    const proposalsClient = createProposalsClient(getInstance, brand)
    const policiesClient = createPoliciesClient(getInstance)
    const policyAdjustmentsClient = createPolicyAdjustmentsClient(getInstance)
    const policyHolderClient = createPolicyholderClient(getInstance)
    const claimsClient = createClaimsClient(getInstance)
    const customerAccountClient = createCustomerAccountClient(getInstance)
    const customerStatementClient = createCustomerStatementClient(getInstance)
    const customerVerificationClient = createCustomerVerificationClient(getInstance)
    const fnolClient = createFnolClient(getInstance)
    const impressionsClient = createInsightsClient(getInstance)

    return {
      ...goCardlessClient,
      ...lookupClient,
      ...paymentCardsClient,
      ...proposalsClient,
      ...policiesClient,
      ...policyAdjustmentsClient,
      ...policyHolderClient,
      ...claimsClient,
      ...customerAccountClient,
      ...customerStatementClient,
      ...customerVerificationClient,
      ...fnolClient,
      ...impressionsClient,
    }
  }
