import { YearMonthInput } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { FormInputProps } from '../types'

export const YearMonthFormInput: FunctionComponent<FormInputProps<string>> = ({
  value,
  validation,
  onChange,
}) => {
  return (
    <YearMonthInput
      onChange={onChange}
      value={value}
      earliestYear={validation?.minYear}
      monthsAhead={1}
    />
  )
}
