import { ExpandableSection, ExpandableSectionProps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { getExposuresContentBaseUrl, useConfig } from '../../../state/configuration'
import { useProduct } from '../../product/productContext'
import { useProposal } from '../../product/proposal/proposalContext'
import { CoverEndorsements } from './CoverEndorsements'
import { IncludedCoverItem } from './IncludedCoverItem'

export interface IncludedCoversExpandableProps extends ExpandableSectionProps {
  covers: string[]
}

export const IncludedCoversExpandable: FunctionComponent<
  IncludedCoversExpandableProps
> = ({ covers, header, ...props }) => {
  const { scheme } = useProduct()
  const {
    proposal: { quote },
  } = useProposal()

  const contentBaseUrl = useConfig(getExposuresContentBaseUrl(scheme.schemeCode))

  const cells = covers.map((it) => {
    return (
      <IncludedCoverItem
        key={it}
        cover={it}
        width={1}
        contentBaseUrl={contentBaseUrl}
        typoSize='sm'
        iconSize='small'
      />
    )
  })

  const endorsementsSection = <CoverEndorsements quote={quote} my={3} />

  return (
    <ExpandableSection header={header} {...props}>
      {cells}
      {endorsementsSection}
    </ExpandableSection>
  )
}
