import React, { FunctionComponent } from 'react'

import { hasPolicyStarted } from '../../../../../domain'
import { ActiveAdjustmentModal } from './ActiveAdjustmentModal'
import { DeferredAdjustmentModal } from './DeferredAdjustmentModal'
import { AdjustmentModalProps } from './types'

export const AdjustmentModal: FunctionComponent<AdjustmentModalProps> = (props) =>
  hasPolicyStarted(props.policy) ? (
    <ActiveAdjustmentModal {...props} />
  ) : (
    <DeferredAdjustmentModal {...props} />
  )
