import React, { FunctionComponent, ReactElement } from 'react'

import { Flex, FlexProps, mdBumps, smBumps, Typo } from '../atoms'

export interface PaymentTotalCellProps extends FlexProps {
  header: string | ReactElement
  premium: string | ReactElement
}

export const PaymentTotalCell: FunctionComponent<PaymentTotalCellProps> = ({
  header,
  premium,
  ...props
}) => (
  <Flex width={1} px={mdBumps} py={smBumps} {...props}>
    <Typo fontWeight='bold'>{header}</Typo>
    <Typo fontWeight='bold' whiteSpace='nowrap'>
      {premium}
    </Typo>
  </Flex>
)
