import { Flex, Heading, LicencePlate, mdBumps, Typo } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { getCoreAsset, getPolicyholder, Quote } from '../../../domain'
import {
  TKey,
  tProduct,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

export interface QuoteHeaderProps {
  quote: Quote
}

const tKey = TKey('components.quote-header')

export const QuoteHeader: FunctionComponent<QuoteHeaderProps> = ({ quote }) => {
  const { t } = useWriskTranslation()

  const asset = getCoreAsset(quote)
  const profile = getPolicyholder(quote)

  return (
    <Flex
      p={mdBumps}
      flexDirection='column'
      justifyContent='flex-start'
      alignItems='flex-start'
    >
      <Typo mb={2}>{t(tProduct('name'))}</Typo>
      <Heading typoSize='lg' mb={[2, 3]}>
        {t(tKey('header'), { profile, asset })}
      </Heading>
      <LicencePlate variant='subtle' licencePlate={asset.data.vrn} />
    </Flex>
  )
}
