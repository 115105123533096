import { Box, Flex, FlexProps, lgBumps, PrimaryButton } from '@wrisk/ui-components'
import React, { FunctionComponent, useMemo } from 'react'

import {
  getCurrentQuote,
  mergePriceBreakdowns,
  PriceOption,
  Proposal,
} from '../../../../domain'
import {
  tDefaults,
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { useProduct } from '../../productContext'
import { ProposalSidebarPricing } from '../pricing'
import { useProposal } from '../proposalContext'

export interface AddOnsSideProps extends FlexProps {
  optionalCovers: PriceOption[]
  onContinue: () => void
  loading: boolean
  disabled: boolean
}

const tKey = TKey('components.proposal-add-ons-sidebar')

export const ProposalAddOnsSidebar: FunctionComponent<AddOnsSideProps> = ({
  optionalCovers,
  onContinue,
  loading,
  disabled,
  ...props
}) => {
  const { t } = useWriskTranslation()

  const { proposal } = useProposal()
  const { product } = useProduct()

  const quote = getCurrentQuote(proposal)

  const updatedProposal = useMemo<Proposal>(() => {
    return {
      ...proposal,
      quote: {
        ...quote,
        priceBreakdown: mergePriceBreakdowns(optionalCovers, quote.priceBreakdown),
      },
    }
  }, [proposal, quote, optionalCovers])

  return (
    <Flex width={1} flexDirection='column' variant='raised' mb={lgBumps} {...props}>
      <ProposalSidebarPricing
        proposal={updatedProposal}
        product={product}
        expanded={true}
        borderBottomWidth={1}
        width={1}
      />
      <Box p={5} width={1} data-testid='button-continue'>
        <PrimaryButton
          loading={loading}
          disabled={disabled}
          width={1}
          onClick={onContinue}
          data-testid='button-continue'
        >
          {t([tKey('actions.continue'), tDefaults('actions.continue')])}
        </PrimaryButton>
      </Box>
    </Flex>
  )
}
