import { PageLoadingIndicator } from '@wrisk/ui-components'
import React, { FunctionComponent, PropsWithChildren } from 'react'

import { useErrorHandlingAsync } from '../../../../hooks/errors'
import { usePrincipal } from '../../../authentication'
import { usePolicy } from '../policyContext'
import { CustomerAccountProvider } from './CustomerAccountProvider'
import { NextStatementProvider } from './NextStatementProvider'
import { PaymentMethodsProvider } from './PaymentMethodsProvider'
import { StatementSummaryProvider } from './StatementSummaryProvider'

export const BillingManager: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { apiClient } = usePrincipal()
  const { policy } = usePolicy()

  const { result, loading } = useErrorHandlingAsync(async () => {
    const [customerAccount] = await apiClient.getCustomerAccounts({
      policyNumber: policy.policyNumber,
    })

    const [paymentMethods, statementSummaries, nextStatement] = await Promise.all([
      apiClient.getPaymentMethods(),
      apiClient.getStatementSummaries(customerAccount.accountId),
      apiClient.getNextStatement(customerAccount.accountId),
    ])

    return { customerAccount, paymentMethods, statementSummaries, nextStatement }
  }, [policy])

  if (loading || !result) {
    return <PageLoadingIndicator />
  }

  const { customerAccount, paymentMethods, statementSummaries, nextStatement } = result

  return (
    <PaymentMethodsProvider paymentMethods={paymentMethods}>
      <CustomerAccountProvider customerAccount={customerAccount}>
        <StatementSummaryProvider statementSummaries={statementSummaries}>
          <NextStatementProvider nextStatement={nextStatement}>
            {children}
          </NextStatementProvider>
        </StatementSummaryProvider>
      </CustomerAccountProvider>
    </PaymentMethodsProvider>
  )
}
