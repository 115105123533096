import { Box, BoxProps, lgBumps } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { Proposal } from '../../domain'
import { ProductConfig, Scheme } from '../../state/configuration'
import { toDateTime } from '../../util/date'
import { ProductContext } from '../product/productContext'
import { ProposalProvider } from '../product/proposal/proposalContext'
import { getProposalCard } from './cards'

export interface ProposalInformation {
  proposal: Proposal
  scheme: Scheme
  product: ProductConfig
}

export interface ProposalsViewProps extends BoxProps {
  proposalInformation: ProposalInformation[]
}

const sortProposals = (left: ProposalInformation, right: ProposalInformation) => {
  const leftStart = toDateTime(left.proposal.startAt)?.toMillis() ?? 0
  const rightStart = toDateTime(right.proposal.startAt)?.toMillis() ?? 0

  return leftStart - rightStart
}

export const isProposalInformation = (
  info: Partial<ProposalInformation>,
): info is ProposalInformation => Boolean(info.proposal && info.product && info.scheme)

export const getProposalInformation =
  (schemes: Scheme[], products: Record<string, ProductConfig>) =>
  (proposals: Proposal[]) =>
    proposals
      .map((proposal) => ({
        proposal,
        scheme: schemes.find((it) => proposal.schemeCode === it.schemeCode),
        product: products[proposal.schemeCode],
      }))
      .filter(isProposalInformation)

export const ProposalsView: FunctionComponent<ProposalsViewProps> = ({
  proposalInformation,
  ...props
}) => {
  const sections = proposalInformation
    .sort(sortProposals)
    .map(({ proposal, product, scheme }) => {
      const ProposalCard = getProposalCard(proposal)

      return (
        <ProductContext.Provider key={proposal.proposalCode} value={{ scheme, product }}>
          <ProposalProvider proposal={proposal}>
            <ProposalCard mb={lgBumps} />
          </ProposalProvider>
        </ProductContext.Provider>
      )
    })

  return <Box {...props}>{sections}</Box>
}
