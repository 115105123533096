import { FlexAnchorProps, HelpFlexAnchor } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

export interface ContextualHelpLinkProps extends Omit<FlexAnchorProps, 'href'> {
  link: string
}

export const ContextualHelpLink: FunctionComponent<ContextualHelpLinkProps> = ({
  link,
  ...props
}) => (
  <HelpFlexAnchor
    fontFamily='body'
    fontWeight='normal'
    textTransform='lowercase'
    color='inherit'
    target='_blank'
    display='inline'
    href={link}
    textDecoration='underline'
    {...props}
  />
)
