import {
  Box,
  Flex,
  FlexProps,
  Icon,
  IconProps,
  smBumps,
  Typo,
  xsBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, PropsWithChildren } from 'react'

interface BenefitListItemProps extends FlexProps, PropsWithChildren {
  flexProps?: Partial<FlexProps>
  iconProps?: Partial<IconProps>
  text?: string
}

const BenefitListItem: FunctionComponent<BenefitListItemProps> = ({
  children,
  flexProps = {},
  iconProps = {},
  text,
  ...props
}) => (
  <Flex justifyContent='flex-start' width={1} mt={smBumps} {...flexProps} {...props}>
    <Icon icon='tick' mr={xsBumps} {...iconProps} />
    <Box>
      <Typo>{children || text}</Typo>
    </Box>
  </Flex>
)

export default BenefitListItem
