/* eslint-disable @typescript-eslint/no-empty-function */
import { ChatClient } from './types'

export const adminChat: ChatClient = {
  show: () => {},
  register: () => {
    // eslint-disable-next-line no-console
    console.log('Registering admin chat...')
  },
  shutDown: () => {},
  updateUser: () => {},
}
