import React, { FunctionComponent, useCallback } from 'react'

import { Box, Flex, FlexAnchor, FlexProps, mdBumps, Radio, smBumps, Typo } from '../atoms'

export interface OptionTileProps extends FlexProps {
  name: string
  value: boolean
  text: string
  checked: boolean
  onSelect: (value: boolean) => void
}

export const OptionTile: FunctionComponent<OptionTileProps> = ({
  name,
  value,
  text,
  checked,
  onSelect,
  ...props
}) => {
  const onInputChange = useCallback(() => onSelect(value), [value, onSelect])

  const uniqueId = name + '-' + value.toString()

  return (
    <Flex width={1} py={2} variant='raised' {...props}>
      <FlexAnchor
        as='label'
        htmlFor={uniqueId}
        variant='inset'
        px={mdBumps}
        py={3}
        width={1}
        justifyContent='flex-start'
      >
        <Box mr={smBumps}>
          <Radio name={name} id={uniqueId} onChange={onInputChange} checked={checked} />
        </Box>
        <Typo fontFamily='input' whiteSpace='nowrap'>
          {text}
        </Typo>
      </FlexAnchor>
    </Flex>
  )
}
