import React, { FunctionComponent } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { getRetrieve, useConfig } from '../../state/configuration'
import { AppPath } from '../AppPath'
import { AnonymousPrincipal } from '../authentication'
import { RetrievePage } from './RetrievePage'
import { RetrievePath } from './RetrievePath'
import { RetrieveProposalPage } from './RetrieveProposalPage'

export interface RetrieveRouterProps {
  principal: AnonymousPrincipal
}

export const RetrieveRouter: FunctionComponent<RetrieveRouterProps> = ({ principal }) => {
  const retrieve = useConfig(getRetrieve)

  return (
    <Routes>
      <Route index element={<RetrievePage tName='saveMyQuote' principal={principal} />} />

      {retrieve?.backBooks?.map((backBook) => (
        <Route
          key={backBook}
          path={backBook}
          element={<RetrievePage tName={backBook} principal={principal} />}
        />
      ))}

      <Route path={RetrievePath.RETRIEVE_PROPOSAL} element={<RetrieveProposalPage />} />

      <Route path='*' element={<Navigate to={AppPath.HOME} />} />
    </Routes>
  )
}
