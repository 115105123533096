import { useMemo } from 'react'

import {
  AccrualType,
  CoverType,
  getCovers,
  getCoversByCoverType,
  PriceOption,
  Quote,
} from '../domain'
import { getCoreAssetType, ProductConfig } from '../state/configuration'

export const useIncludedCovers = (product: ProductConfig): string[] =>
  useMemo(() => getCoreAssetType(product).covers.included, [product])

export const useMainCovers = (quote: Quote | undefined): PriceOption[] =>
  useMemo(() => {
    if (!quote) return []

    return getCoversByCoverType(quote.priceBreakdown, CoverType.MAIN)
  }, [quote])

export const useDiscountCovers = (quote: Quote | undefined): PriceOption[] =>
  useMemo(() => {
    if (!quote) return []

    return getCoversByCoverType(quote.priceBreakdown, CoverType.DISCOUNT)
  }, [quote])

export const useOptionalCovers = (
  quote: Quote | undefined,
  product: ProductConfig,
): PriceOption[] =>
  useMemo(() => {
    if (!quote) return []

    const { covers } = getCoreAssetType(product)

    return (covers.optional ?? []).flatMap((cover) =>
      getCovers(quote.priceBreakdown, AccrualType.MONTHLY, cover.code),
    )
  }, [product, quote])
