import React, { ChangeEvent, FunctionComponent, useCallback, useRef } from 'react'

import { Flex, TextInput } from '../../atoms'
import { useNotifyingState } from '../../extensions'
import { isValidNumber, isValidPartialYearString } from '../../validators'
import { DateInputs } from './types'

export * from './mappers'
export * from './types'

export interface DateInputProps {
  onChange: (inputs: DateInputs) => void
  dateInputs: DateInputs
  placeholders?: DateInputs
}

export const DateInput: FunctionComponent<DateInputProps> = ({
  dateInputs,
  onChange,
  placeholders,
}) => {
  const [inputs, setInputs] = useNotifyingState(dateInputs, [onChange])

  const dayRef = useRef<HTMLInputElement>(null)
  const monthRef = useRef<HTMLInputElement>(null)
  const yearRef = useRef<HTMLInputElement>(null)

  const onDayChanged = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (isValidNumber(target.value)) {
        setInputs({ ...inputs, day: target.value.substring(0, 2) })

        if (target.value.length >= 2) {
          monthRef.current?.focus()
        }
      }
    },
    [inputs, setInputs],
  )

  const onMonthChanged = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (isValidNumber(target.value)) {
        setInputs({ ...inputs, month: target.value.substring(0, 2) })

        if (target.value.length >= 2) {
          yearRef.current?.focus()
        }
      }
    },
    [inputs, setInputs],
  )

  const onYearChanged = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (isValidPartialYearString(target.value)) {
        setInputs({ ...inputs, year: target.value.substring(0, 4) })
      }
    },
    [inputs, setInputs],
  )

  return (
    <Flex width={[1, 1, 2 / 3]} justifyContent='flex-start'>
      <TextInput
        width='28%'
        mr={2}
        type='tel'
        id='day'
        name='day'
        placeholder={placeholders?.day}
        value={inputs.day || ''}
        onChange={onDayChanged}
        ref={dayRef}
      />
      <TextInput
        width='28%'
        mr={2}
        type='tel'
        id='month'
        name='month'
        placeholder={placeholders?.month}
        value={inputs.month || ''}
        onChange={onMonthChanged}
        ref={monthRef}
      />
      <TextInput
        width='44%'
        type='tel'
        id='year'
        name='year'
        placeholder={placeholders?.year}
        value={inputs.year || ''}
        onChange={onYearChanged}
        ref={yearRef}
      />
    </Flex>
  )
}
