import React, { ChangeEvent, FunctionComponent, useCallback } from 'react'

import { Flex, FlexProps, TextInput } from '../atoms'

export interface Name {
  firstName: string
  lastName: string
}

export interface NameInputProps extends FlexProps {
  name: Name
  placeholders?: Name
  onChange: (name: Name) => void
}

export const NameInput: FunctionComponent<NameInputProps> = ({
  name,
  placeholders,
  onChange,
  ...props
}) => {
  const onFirstNameChanged = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) =>
      onChange({ ...name, firstName: target.value.trimStart() }),
    [name, onChange],
  )
  const onLastNameChanged = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) =>
      onChange({ ...name, lastName: target.value.trimStart() }),
    [name, onChange],
  )

  return (
    <Flex width={[1, 1, 4 / 5]} justifyContent='flex-start' {...props}>
      <TextInput
        width={1}
        id='first-name'
        name='first-name'
        placeholder={placeholders?.firstName}
        value={name.firstName}
        onChange={onFirstNameChanged}
        mr={2}
      />
      <TextInput
        width={1}
        id='last-name'
        name='last-name'
        placeholder={placeholders?.lastName}
        value={name.lastName}
        onChange={onLastNameChanged}
      />
    </Flex>
  )
}
