export interface Repository<T> {
  save: (item: T) => void
  get: () => T | null
  remove: () => void
}

export type StorageType = 'local' | 'session'

export const createRepository = <T>(
  key: string,
  storageType: StorageType,
): Repository<T> => {
  const storage = storageType === 'local' ? window.localStorage : window.sessionStorage

  return {
    save: (item) => storage.setItem(key, JSON.stringify(item)),

    get: () => {
      const json = storage.getItem(key)
      return json ? (JSON.parse(json) as T) : null
    },

    remove: () => storage.removeItem(key),
  }
}
