import { isNil } from 'lodash'
import { default as React, FunctionComponent } from 'react'

import { tDefaults } from '../../../infrastructure/internationalisation'
import { Formatter } from '../types'

export const QuestionLoopFormatter: FunctionComponent<Formatter<unknown[]>> = ({
  value,
  t,
}) => {
  return <>{isNil(value) ? t(tDefaults('inputs.no-answer')) : value.length}</>
}
