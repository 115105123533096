import { FeatureToggles } from '../../state/configuration'
import { adminChat } from './admin'
import { twilioChat } from './twilio'
import { ChatClient } from './types'

export * from './types'

export const createChatClient: (toggles: Partial<FeatureToggles>) => ChatClient = (
  toggles,
) => (toggles.isAdmin ? adminChat : twilioChat)
