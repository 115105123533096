import React, { ReactElement, ReactNode } from 'react'

import { Box, BoxProps, Icon, InputWrapper, smBumps } from '../atoms'
import { FreeTextInput } from './FreeTextInput'
import { InputMessage } from './InputMessage'
import { SelectResult } from './SelectResult'
import { SelectResults } from './SelectResults'

interface SearchViewProps<T> extends BoxProps {
  identifier: string
  placeholder: string
  notFoundMessage: string
  onChange: (result: T) => void
  onInputChange: (term: string) => void
  loading: boolean
  results: T[] | undefined
  renderListItemContent: (result: T) => ReactNode
}

export const SearchView = <T,>({
  identifier,
  placeholder,
  notFoundMessage,
  onChange,
  onInputChange,
  loading,
  results,
  renderListItemContent,
  ...props
}: SearchViewProps<T>): ReactElement => {
  const resultsBox = results?.length ? (
    <SelectResults mt={2}>
      {results?.map((result, index) => (
        <SelectResult key={index} onClickParams={result} onClick={onChange}>
          {renderListItemContent(result)}
        </SelectResult>
      ))}
    </SelectResults>
  ) : results ? (
    <InputMessage>{notFoundMessage}</InputMessage>
  ) : null

  return (
    <Box {...props}>
      <InputWrapper width={1}>
        <Icon icon='search' size='small' mr={smBumps} />
        <FreeTextInput
          width={1}
          id={identifier}
          name={identifier}
          placeholder={placeholder}
          onChange={onInputChange}
          onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
        />
        {loading && <Icon icon='loadingDark' size='small' />}
      </InputWrapper>
      {resultsBox}
    </Box>
  )
}
