import { system } from 'styled-system'

export type TransitionProperty = 'default'

export interface TransitionProps {
  transition?: TransitionProperty
}

export const transition = system({
  transition: {
    property: 'transition',
    scale: 'transitions',
  },
})
