import { generatePath, useNavigate } from 'react-router-dom'

import { usePrincipal } from '../../../application/authentication'
import { ExcessPath } from '../../../application/product/policy/excess/ExcessPath'
import { usePolicy } from '../../../application/product/policy/policyContext'
import { PolicyPath } from '../../../application/product/policy/PolicyPath'
import { getCoreAsset, PolicyChangeType } from '../../../domain'
import { useApiErrorHandlingAsyncCallback } from '../../auth'

interface ExcessData {
  excess: number
}

export const usePolicyExcessAdjustment = (onComplete?: () => void) => {
  const navigate = useNavigate()

  const { apiClient } = usePrincipal()
  const { policy } = usePolicy()

  return useApiErrorHandlingAsyncCallback(
    async (data: ExcessData, effectiveAt?: string) => {
      const { quote } = policy.currentPolicyVersion

      const asset = getCoreAsset(quote)

      if (asset.voluntaryExcess !== data.excess) {
        const { adjustmentId } = await apiClient.createPolicyAdjustment({
          policyId: policy.policyId,
          adjustmentType: 'MTA',
          changes: [
            {
              changeType: PolicyChangeType.SELECT_VOLUNTARY_EXCESS,
              excess: data.excess,
            },
          ],
          ...(effectiveAt && { effectiveAt }),
        })
        navigate(
          generatePath([PolicyPath.EXCESS, ExcessPath.REVIEW].join('/'), {
            adjustmentId,
          }),
        )
      }

      onComplete?.()
    },
  )
}
