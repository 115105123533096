import { isNil } from 'lodash'
import React, { ChangeEvent, ReactElement } from 'react'

import { Box, GhostSelect, InputWrapper, Typo } from '../atoms'

export interface SelectInputProps<T> {
  name?: string
  value: T | undefined
  values: { value: T | undefined; text: string }[]
  onChange: (value: T | undefined) => void
  placeholder?: string
}

export const SelectInput: <T extends string | number = string>(
  props: SelectInputProps<T>,
) => ReactElement = ({ placeholder, onChange, value, values, name }) => {
  const options = values.map(({ value, text }) => (
    <Typo as='option' key={value} value={value} color='body'>
      {text}
    </Typo>
  ))

  const onChangeCallback = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault()
    const offset = placeholder ? 1 : 0
    const item = values[e.target.selectedIndex - offset]
    onChange(item?.value)
  }

  const selectedValue = values.find((it) => it.value === value)?.value

  return (
    <InputWrapper width={1}>
      <Box width={1}>
        <Typo
          fontFamily='input'
          color={placeholder && isNil(selectedValue) ? 'placeholder' : 'inherit'}
        >
          <GhostSelect
            name={name}
            onChange={onChangeCallback}
            value={selectedValue ?? placeholder}
          >
            {placeholder && (
              <Typo as='option' color='placeholder' value={placeholder} disabled hidden>
                {placeholder}
              </Typo>
            )}
            {options}
          </GhostSelect>
        </Typo>
      </Box>
    </InputWrapper>
  )
}
