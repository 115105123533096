import {
  mdBumps,
  Section,
  SectionContent,
  SectionDescription,
  SectionProps,
  SectionTitle,
} from '@wrisk/ui-components'
import React from 'react'

import { Quote } from '../../../domain'
import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'
import { ExcessConfig } from '../../../state/configuration'
import { ExcessEndorsements } from './ExcessEndorsements'
import { MainExcesses } from './MainExcesses'
import { OtherExcesses } from './OtherExcesses'

export interface ExcessSectionProps extends SectionProps {
  config: ExcessConfig | undefined
  currentQuote: Quote
  previousQuote?: Quote
  onChange?: () => void
}

const tKey = TKey('components.excess')

export const ExcessSection: React.FC<ExcessSectionProps> = ({
  config,
  currentQuote,
  previousQuote,
  onChange,
  ...props
}) => {
  const { t } = useWriskTranslation()

  return config ? (
    <Section width={1} {...props}>
      <SectionTitle>{t(tKey('header'))}</SectionTitle>
      <SectionDescription>{t(tKey('subheader'))}</SectionDescription>
      <SectionContent>
        <MainExcesses
          config={config}
          currentQuote={currentQuote}
          previousQuote={previousQuote}
          onChange={onChange}
        />
        <ExcessEndorsements quote={currentQuote} mt={mdBumps} />
        <OtherExcesses
          currentQuote={currentQuote}
          previousQuote={previousQuote}
          mt={mdBumps}
        />
      </SectionContent>
    </Section>
  ) : null
}
