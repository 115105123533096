import { ModalProvider, PageLoadingIndicator } from '@wrisk/ui-components'
import React, { FunctionComponent, PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'

import { useGetProposal } from '../../../hooks/proposal'
import { useRequiredParams } from '../../../infrastructure/routing'
import { AppPath } from '../../AppPath'
import { ProposalProvider } from './proposalContext'
import { ProposalParams } from './ProposalPath'

export const ManageProposal: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { proposalCode } = useRequiredParams<ProposalParams>()

  const getProposalAsync = useGetProposal(proposalCode)
  const proposal = getProposalAsync.result
  const isProposalLoading = proposal && proposalCode !== proposal.proposalCode

  return getProposalAsync.loading || isProposalLoading ? (
    <PageLoadingIndicator />
  ) : proposal ? (
    <ProposalProvider proposal={proposal}>
      <ModalProvider>{children}</ModalProvider>
    </ProposalProvider>
  ) : (
    <Navigate to={AppPath.HOME} />
  )
}
