import React, { FunctionComponent, useMemo } from 'react'
import { generatePath, Navigate, Route, Routes } from 'react-router-dom'

import { getAccountInputs, useConfig } from '../../state/configuration'
import { AppPath } from '../AppPath'
import { ProtectedRoutes } from '../authentication'
import { AccountPath } from './AccountPath'
import { AccountSetting } from './AccountSetting'
import { AccountSettings } from './AccountSettings'

export const AccountRouter: FunctionComponent = () => {
  const accountInputs = useConfig(getAccountInputs)

  const homePage = useMemo(() => ({ url: AppPath.HOME, id: 'home' }), [])
  const accountPage = useMemo(
    () => ({ url: AppPath.ACCOUNT, id: 'account-settings' }),
    [],
  )

  return accountInputs.length ? (
    <ProtectedRoutes>
      <Routes>
        <Route index element={<AccountSettings parent={homePage} />} />

        {accountInputs.map((input) => (
          <Route
            key={input.name}
            path={generatePath(AccountPath.ACCOUNT_SETTING, input)}
            element={<AccountSetting input={input} parent={accountPage} />}
          />
        ))}

        <Route path='*' element={<Navigate to={AppPath.HOME} />} />
      </Routes>
    </ProtectedRoutes>
  ) : (
    <Navigate to={AppPath.HOME} />
  )
}
