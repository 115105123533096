import { PageLoadingIndicator } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Navigate } from 'react-router-dom'

import { getCurrentPolicyVersion, QuoteStatus } from '../../../../../domain'
import { useApiErrorHandlingAsync } from '../../../../../hooks/auth'
import { Page } from '../../../../../infrastructure/routing'
import { ManagementConfig } from '../../../../../state/configuration'
import { usePrincipal } from '../../../../authentication'
import { AllowedPolicyAdjustmentPage } from './AllowedPolicyAdjustmentPage'
import { InvalidPolicyAdjustmentPage } from './InvalidPolicyAdjustmentPage'
import { ReferredPolicyAdjustmentPage } from './ReferredPolicyAdjustmentPage'

export interface PolicyAdjustmentResultPageProps {
  adjustmentPage: Page
  config?: ManagementConfig
  adjustmentId: string
}

export const PolicyAdjustmentReviewPage: FunctionComponent<
  PolicyAdjustmentResultPageProps
> = ({ adjustmentPage, config, adjustmentId }) => {
  const { apiClient } = usePrincipal()

  const { result, loading } = useApiErrorHandlingAsync(apiClient.getPolicyAdjustment, [
    adjustmentId,
  ])

  if (loading) return <PageLoadingIndicator />
  if (!result || result?.confirmed) return <Navigate to={adjustmentPage.url} />

  const updatedPolicyVersions = result?.updatedPolicyVersions
  const currentQuote = getCurrentPolicyVersion(updatedPolicyVersions).quote

  const isAllQuote = updatedPolicyVersions.every(
    (policyVersion) => policyVersion.quote.ratingStatus === QuoteStatus.QUOTE,
  )
  const isCurrentVersionReferral = currentQuote.ratingStatus === QuoteStatus.REFERRAL

  if (isAllQuote) {
    return (
      <AllowedPolicyAdjustmentPage
        adjustmentResponse={result}
        adjustmentPage={adjustmentPage}
        config={config}
      />
    )
  } else if (isCurrentVersionReferral) {
    return (
      <ReferredPolicyAdjustmentPage
        quote={currentQuote}
        adjustmentPage={adjustmentPage}
      />
    )
  } else {
    return <InvalidPolicyAdjustmentPage />
  }
}
