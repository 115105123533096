import {
  Flex,
  Heading,
  LicencePlate,
  mdBumps,
  SecondaryButton,
  smBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent, useCallback } from 'react'

import { Specification, toVehicleDescription } from '../../../../domain'
import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { VehicleCardDetails } from './VehicleCardDetails'

export interface VehicleCardProps {
  showReg?: boolean
  specification: Specification
  onChangeButtonClick: (specification?: Specification) => void
}

const tKey = TKey('components.vehicle-card')

export const VehicleCard: FunctionComponent<VehicleCardProps> = ({
  showReg,
  specification,
  onChangeButtonClick,
}) => {
  const { t } = useWriskTranslation()

  const licencePlate = specification?.vrn || undefined

  const handleChangeButtonClick = useCallback(() => {
    onChangeButtonClick?.(specification)
  }, [specification, onChangeButtonClick])

  return (
    <Flex
      width={1}
      variant='raised'
      p={mdBumps}
      flexWrap='wrap'
      data-testid='vehicle-card'
    >
      <Flex width={1} marginBottom={smBumps}>
        <Heading typoSize='md' pb={1} mr={smBumps}>
          {toVehicleDescription(specification)}
        </Heading>
        <SecondaryButton
          data-testid='vehicle-change'
          layout='small'
          onClick={handleChangeButtonClick}
        >
          {t(tKey('actions.change'))}
        </SecondaryButton>
      </Flex>
      {showReg && licencePlate && (
        <Flex>
          <LicencePlate licencePlate={licencePlate} />
        </Flex>
      )}
      <VehicleCardDetails vehicle={specification} />
    </Flex>
  )
}
