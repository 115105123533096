import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
} from 'react'

import { PaymentMethod } from '../../../../domain'

export interface PaymentMethodsContextObj {
  paymentMethods: PaymentMethod[]
}

const PaymentMethodsContext = createContext<PaymentMethodsContextObj | undefined>(
  undefined,
)

interface PaymentMethodsProviderProps extends PropsWithChildren {
  paymentMethods: PaymentMethod[]
}

export const PaymentMethodsProvider: FunctionComponent<PaymentMethodsProviderProps> = ({
  paymentMethods,
  children,
}) => {
  return (
    <PaymentMethodsContext.Provider value={{ paymentMethods }}>
      {children}
    </PaymentMethodsContext.Provider>
  )
}

export const usePaymentMethods = (): PaymentMethodsContextObj => {
  const context = useContext(PaymentMethodsContext)
  if (!context) {
    throw new Error('Cannot use PaymentMethodsContext when undefined.')
  }
  return context
}
