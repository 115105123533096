import {
  Box,
  Flex,
  Icon,
  mdBumps,
  Notification,
  NotificationProps,
  smBumps,
  TertiaryButton,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { getPolicyToEndAt, RenewalPolicy } from '../../../../domain'
import { useContinueProposalCallback } from '../../../../hooks/proposal'
import {
  tFormats,
  TKey,
  useProductTranslation,
} from '../../../../infrastructure/internationalisation'

const tKey = TKey('components.renewal-banner')

export const RenewalReferralBanner: FunctionComponent<
  { policy: RenewalPolicy } & NotificationProps
> = ({ policy, ...props }) => {
  const { t } = useProductTranslation(policy.schemeCode)

  const onContinueProposalCallback = useContinueProposalCallback(policy.renewalProposal)

  return (
    <Notification variant='critical' flexWrap={['wrap', 'wrap', 'nowrap']} {...props}>
      <Flex
        justifyContent='flex-start'
        mb={[4, 4, 0]}
        mr={[0, 0, 4]}
        width={[1, 1, 3 / 5]}
      >
        <Icon icon='info' size='small' mr={smBumps} display={['none', 'none', 'block']} />
        <Typo>
          <Trans
            t={t}
            i18nKey={tKey('status.referral')}
            values={{
              endingAt: t(tFormats('datetime.medium'), {
                value: getPolicyToEndAt(policy),
              }),
            }}
          />
        </Typo>
      </Flex>
      <Box width={[1, 1, 2 / 5]} pl={[0, 0, ...mdBumps.slice(2)]}>
        <TertiaryButton width={1} onClick={onContinueProposalCallback}>
          {t(tKey('actions.learn'))}
        </TertiaryButton>
      </Box>
    </Notification>
  )
}
