import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'

import { Policyholder } from '../../../domain'

interface PolicyholderContextObj {
  policyholder: Policyholder | undefined
  setPolicyholder: (policyholder: Policyholder) => void
}

const PolicyholderContext = createContext<PolicyholderContextObj | undefined>(undefined)

export interface PolicyholderProviderProps extends PropsWithChildren {
  policyholder: Policyholder | undefined
}

export const PolicyholderProvider: FunctionComponent<PolicyholderProviderProps> = (
  props,
) => {
  const [policyholder, setPolicyholder] = useState(props.policyholder)

  useEffect(() => setPolicyholder(props.policyholder), [props.policyholder])

  return (
    <PolicyholderContext.Provider value={{ policyholder, setPolicyholder }}>
      {props.children}
    </PolicyholderContext.Provider>
  )
}

export const usePolicyholder = (): PolicyholderContextObj => {
  const context = useContext(PolicyholderContext)
  if (!context) {
    throw new Error('Cannot use PolicyholderContext when undefined.')
  }
  return context
}
