import React, { FunctionComponent } from 'react'

import { Flex, FlexProps } from '../atoms'
import { SelectItem } from './SelectItem'

export interface SingleSelectProps extends FlexProps {
  name: string
  options: Record<string, string>
  value: string | undefined
  onChange: (selectedValue: string) => void
}

export const SingleSelect: FunctionComponent<SingleSelectProps> = ({
  name,
  value,
  options,
  onChange,
  ...props
}) => {
  const selectItems = Object.entries(options).map(([id, text]) => (
    <SelectItem key={id} name={name} id={id} checked={value === id} onChange={onChange}>
      {text}
    </SelectItem>
  ))

  return (
    <Flex width={[1, 1, 4 / 5]} variant='raised' flexDirection='column' py={2} {...props}>
      {selectItems}
    </Flex>
  )
}
