import css, { ResponsiveStyleValue } from '@styled-system/css'
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'
import styled from 'styled-components'
import {
  color,
  ColorProps,
  compose,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant,
} from 'styled-system'

import { defaultConfig } from '../../extensions'
import { transition, TransitionProps } from '../../transition'
import { Icon } from '../images'
import { Button } from './Button'
import {
  buttonDisabledMapping,
  buttonDisabledVariants,
  defaultButtonStyles,
} from './variants'

export type IconButtonProperty = 'default' | 'primary'

type ButtonProps = Omit<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'color' | 'ref'
>

export interface IconButtonProps
  extends ButtonProps,
    SpaceProps,
    LayoutProps,
    SpaceProps,
    ColorProps,
    TransitionProps {
  variant?: ResponsiveStyleValue<IconButtonProperty>
}

export const IconButton = styled(Button)
  .withConfig(defaultConfig)
  .attrs(buttonDisabledMapping)<IconButtonProps>(
  defaultButtonStyles,
  css({
    borderRadius: '100%',
    p: 3,
  }),
  variant({
    prop: 'disabledState',
    scale: 'IconButton.disabled',
    variants: buttonDisabledVariants,
  }),
  variant({
    scale: 'IconButton.variants',
    variants: {
      default: {
        ':hover': {
          backgroundColor: 'hover',
        },
      },
      primary: {
        backgroundColor: 'primaryAccent',
        [Icon]: {
          'path, ellipse': {
            fill: '#FFF',
          },
        },
        '&:hover:not([disabled])': {
          boxShadow: 'inset 0 0 0 100rem rgba(255,255,255,0.1)',
        },
        '&:active:not([disabled])': {
          boxShadow: 'inset 0 0 0 100rem rgba(255,255,255,0.2)',
        },
      },
    },
  }),
  compose(layout, space, color, transition),
)

IconButton.defaultProps = {
  variant: 'default',
}
