import {
  Box,
  Flex,
  Heading,
  mdBumps,
  PrimaryButton,
  Typo,
  xlBumps,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { AnonymousUser } from '../../../clients/anonymous'
import { User } from '../../../clients/auth'
import { useAuth } from '../../../hooks/auth'
import { useErrorHandlingAsyncCallback } from '../../../hooks/errors'

export const AdminUnauthorised: FunctionComponent<{
  user: User | AnonymousUser | undefined
}> = ({ user }) => {
  const auth = useAuth()
  const onSignInCallback = useErrorHandlingAsyncCallback(async () => await auth.signIn())

  return (
    <Flex width='100vw' height='100vh' justifyContent='center'>
      <Box p={xlBumps} maxWidth='600px' width='80%'>
        <Heading typoSize='lg' mb={mdBumps}>
          Unauthorised
        </Heading>
        <Typo typoSize='md' mb={mdBumps}>
          You must be logged in as an admin with the appropriate role to access this
          resource
        </Typo>
        {!user && (
          <PrimaryButton onClick={onSignInCallback.execute}>Sign in</PrimaryButton>
        )}
      </Box>
    </Flex>
  )
}
