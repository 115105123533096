import { SystemStyleObject } from '@styled-system/css'

import { ButtonProps } from '../Button'

export type ButtonLoadingProperty = 'default'

export const buttonLoadingMapping = ({ loading }: ButtonProps) => ({
  loadingState: loading ? 'default' : undefined,
})

export const buttonLoadingVariants: Record<ButtonLoadingProperty, SystemStyleObject> = {
  default: {
    color: 'transparent',
    'not(.loading)': {
      visibility: 'hidden',
      opacity: 0,
    },
  },
}
