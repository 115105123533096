import { PageLoadingIndicator } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Navigate } from 'react-router-dom'

import { useApiErrorHandlingAsync } from '../../../../../hooks/auth'
import { Page } from '../../../../../infrastructure/routing'
import { usePrincipal } from '../../../../authentication'
import { AdjustmentErrorPage } from './AdjustmentErrorPage'
import { AdjustmentSuccessPage } from './AdjustmentSuccessPage'

export interface PolicyAdjustmentResultPageProps {
  adjustmentId: string
  policyPage: Page
  adjustmentPage: Page
}

export const PolicyAdjustmentResultPage: FunctionComponent<
  PolicyAdjustmentResultPageProps
> = ({ adjustmentPage, policyPage, adjustmentId }) => {
  const { apiClient } = usePrincipal()

  const { result, loading } = useApiErrorHandlingAsync(apiClient.getPolicyAdjustment, [
    adjustmentId,
  ])

  if (loading) return <PageLoadingIndicator />

  return !result ? (
    <Navigate to={adjustmentPage.url} />
  ) : result.confirmed ? (
    <AdjustmentSuccessPage
      adjustmentPage={adjustmentPage}
      policyPage={policyPage}
      policyAdjustmentResponse={result}
    />
  ) : (
    <AdjustmentErrorPage
      policyPage={policyPage}
      verificationClear={result.verificationClear}
    />
  )
}
