import {
  Flex,
  FlexProps,
  mdBumps,
  Status,
  StatusProperty,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { Claim, ClaimStatus } from '../../../domain'
import {
  tEntries,
  tFormats,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'
import { toDateTime } from '../../../util/date'

const statusToVariant: Record<ClaimStatus, StatusProperty> = {
  [ClaimStatus.ACTIVE]: 'info',
  [ClaimStatus.SETTLED]: 'success',
  [ClaimStatus.PENDING]: 'default',
}

export type ClaimCardProps = { claim: Claim } & FlexProps

export const ClaimCard: FunctionComponent<ClaimCardProps> = ({ claim, ...props }) => {
  const { t } = useWriskTranslation()

  const claimType =
    claim.status === ClaimStatus.PENDING
      ? t(tEntries('type', 'Accident'))
      : t(tEntries('type', claim.claimType))

  return (
    <Flex width={1} variant='raised' p={mdBumps} {...props}>
      <Flex flexDirection='column' alignItems='flex-start' justifyContent='center'>
        <Typo mb={1} fontWeight='bold'>
          {claimType}
        </Typo>

        <Flex displayOn='desktop' mr={2}>
          <Typo color='bodySecondary' typoSize='sm'>
            {[
              claim.insuredName,
              t(tFormats('date.medium'), { value: toDateTime(claim.occurredDate) }),
              claim.claimReference,
            ]
              .filter(Boolean)
              .join(' – ')}
          </Typo>
        </Flex>

        <Flex
          displayOn='mobile'
          flexDirection='column'
          alignItems='flex-start'
          color='bodySecondary'
          mr={2}
        >
          {claim.insuredName && (
            <Typo typoSize='sm' mb={1}>
              {claim.insuredName}
            </Typo>
          )}
          <Typo typoSize='sm' mb={1}>
            {t(tFormats('date.medium'), { value: toDateTime(claim.occurredDate) })}
          </Typo>
          <Typo typoSize='sm'>{claim.claimReference}</Typo>
        </Flex>
      </Flex>

      {statusToVariant[claim.status] && (
        <Status variant={statusToVariant[claim.status]} typoSize='xs'>
          {claim.status}
        </Status>
      )}
    </Flex>
  )
}
