import { Box, Flex, FlexProps, mdBumps, PrimaryButton } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { getCurrentQuote } from '../../../../domain'
import { useContinueProposalCallback } from '../../../../hooks/proposal'
import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { useProduct } from '../../../product/productContext'
import { ProposalDashboardPricing } from '../../../product/proposal/pricing'
import { useProposal } from '../../../product/proposal/proposalContext'
import { ManageProposalMenu } from '../../organisms/ManageProposalMenu'
import { QuoteHeader } from '../../organisms/QuoteHeader'

const tKey = TKey('components.view-proposal')

export const ViewProposalCard: FunctionComponent<FlexProps> = (props) => {
  const { product } = useProduct()
  const { proposal } = useProposal()

  const { t } = useWriskTranslation()

  const onContinueProposalCallback = useContinueProposalCallback(proposal)

  const quote = getCurrentQuote(proposal)

  return (
    <Flex width={1} variant='raised' flexWrap='wrap' alignItems='stretch' {...props}>
      <Flex
        alignItems='flex-start'
        width={[1, 1, 3 / 5]}
        borderBottomWidth={[1, 1, 0]}
        borderRightWidth={[0, 0, 1]}
      >
        <QuoteHeader quote={quote} />
        <ManageProposalMenu />
      </Flex>
      <Flex width={[1, 1, 2 / 5]} flexDirection='column'>
        <ProposalDashboardPricing width={1} proposal={proposal} product={product} />
        <Box width={1} p={mdBumps} borderTopWidth={1}>
          <PrimaryButton
            width={1}
            onClick={onContinueProposalCallback}
            data-testid='view-proposal'
          >
            {t(tKey('actions.view'))}
          </PrimaryButton>
        </Box>
      </Flex>
    </Flex>
  )
}
