import { Box, FilterInput, PrimaryButton, xsBumps } from '@wrisk/ui-components'
import { range } from 'lodash'
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import { Specification } from '../../../../../domain'
import {
  TKey,
  useWriskTranslation,
} from '../../../../../infrastructure/internationalisation'
import { VehicleTabsConfig } from '../types'
import { VehicleMakesFilter } from './VehicleMakesFilter'
import { VehicleModelsFilter } from './VehicleModelsFilter'
import { VehicleResultsFilter } from './VehicleResultsFilter'

const renderYear = (year: number) => year.toString()

const matchYear = (text: string) => (yr: number) => yr.toString().includes(text)

export interface VehicleDropdownsProps {
  config?: VehicleTabsConfig
  value?: Specification
  onConfirmButtonClick: (specification?: Specification) => void
  onVehicleDataChange: (specification?: Specification) => void
}

const tKey = TKey('components.vehicle-dropdowns')

export const VehicleDropdowns: FunctionComponent<VehicleDropdownsProps> = ({
  config,
  value,
  onConfirmButtonClick,
  onVehicleDataChange,
}) => {
  const { t } = useWriskTranslation()

  const defaultMake = config?.makes?.length === 1 ? config?.makes[0] : undefined
  const [specification, setSpecification] = useState<Partial<Specification>>(
    value || { make: defaultMake },
  )
  const [selectionComplete, setSelectionComplete] = useState(false)
  const years = useMemo(
    () => range(config?.earliestYear ?? 1910, new Date().getFullYear() + 1).reverse(),
    [config?.earliestYear],
  )

  const handleConfirmButtonClick = useCallback(() => {
    onConfirmButtonClick(specification as Specification)
  }, [specification, onConfirmButtonClick])

  const onResultSelect = useCallback(
    (newSpecification?: Specification) => {
      if (newSpecification === undefined) {
        setSpecification({
          year: specification.year,
          make: specification.make,
          model: specification.model,
        })
        onVehicleDataChange(undefined)
        setSelectionComplete(false)
      } else {
        setSpecification(newSpecification)
        onVehicleDataChange(newSpecification)
        setSelectionComplete(true)
      }
    },
    [specification, onVehicleDataChange],
  )

  const setSpecificationAndResetSelection = useCallback(
    (newSpecification) => {
      setSpecification(newSpecification)
      onVehicleDataChange(undefined)
      setSelectionComplete(false)
    },
    [onVehicleDataChange],
  )

  const setYear = useCallback(
    (year: number | undefined) => {
      setSpecificationAndResetSelection({
        year,
        make: defaultMake,
      })
      setSelectionComplete(false)
    },
    [defaultMake, setSpecificationAndResetSelection],
  )

  const setMake = useCallback(
    (make: string | undefined) => {
      setSpecificationAndResetSelection({
        year: specification.year,
        make,
      })
      setSelectionComplete(false)
    },
    [specification, setSpecificationAndResetSelection],
  )

  const setModel = useCallback(
    (model: string | undefined) => {
      setSpecificationAndResetSelection({
        year: specification.year,
        make: specification.make,
        model,
      })
      setSelectionComplete(false)
    },
    [specification, setSpecificationAndResetSelection],
  )

  const makesDropdown = specification.year && !defaultMake && (
    <VehicleMakesFilter
      onSelect={setMake}
      year={specification.year}
      make={specification.make}
      allowedMakes={config?.makes}
    />
  )

  const modelsDropdown = specification.year && specification.make && (
    <VehicleModelsFilter
      onSelect={setModel}
      year={specification.year}
      make={specification.make}
      model={specification.model}
    />
  )

  const vehiclesResults = specification.year &&
    specification.make &&
    specification.model && (
      <VehicleResultsFilter
        onSelect={onResultSelect}
        year={specification.year}
        make={specification.make}
        model={specification.model}
        initialValue={
          Object.keys(specification).length === 3
            ? undefined
            : (specification as Specification)
        }
      />
    )

  return (
    <Box width={1}>
      <FilterInput
        data-testid='vehicle-year'
        mb={xsBumps}
        width={1}
        placeholder='Year of manufacture' // TODO: Do we need translation for this?
        value={specification.year}
        onSelect={setYear}
        options={years}
        renderOption={renderYear}
        renderOptionText={renderYear}
        matchOption={matchYear}
      />
      {makesDropdown}
      {modelsDropdown}
      {vehiclesResults}

      <PrimaryButton
        data-testid='vehicle-confirm'
        disabled={!selectionComplete}
        onClick={handleConfirmButtonClick}
      >
        {t(tKey('actions.confirm'))}
      </PrimaryButton>
    </Box>
  )
}
