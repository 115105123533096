import { Box, LinkAnchor, LinkAnchorProps, ModalHeader } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { TKey, useWriskTranslation } from '../../../infrastructure/internationalisation'

const tKey = TKey('components.id-check-failed-modal')

const SendTo: FunctionComponent<LinkAnchorProps> = ({ children, ...props }) => (
  <LinkAnchor href={`mailto:${children}`} variant='inline' {...props}>
    {children}
  </LinkAnchor>
)

export const IdCheckFailedModal: FunctionComponent = () => {
  const { t } = useWriskTranslation()

  return (
    <Box>
      <ModalHeader header={t(tKey('header'))} />
      <Trans t={t} i18nKey={tKey('content')} components={{ SendTo: <SendTo /> }} />
    </Box>
  )
}
