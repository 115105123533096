import { Illus, PrimaryButton } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { useNavigateCallback } from '../../../../hooks/navigation'
import {
  TKey,
  useWriskTranslation,
} from '../../../../infrastructure/internationalisation'
import { Page } from '../../../../infrastructure/routing'
import { Chat } from '../../../organisms/Chat'
import { FullPage } from '../../../templates'

export interface UploadPageProps {
  parent: Page
}

const tKey = TKey('pages.upload-confirmation')

export const UploadConfirmation: FunctionComponent<UploadPageProps> = ({ parent }) => {
  const { t } = useWriskTranslation()

  const onContinue = useNavigateCallback(parent.url)

  const subheader = (
    <Trans i18nKey={tKey('subheader')} t={t} components={{ Chat: <Chat /> }} />
  )

  return (
    <FullPage
      pageId='upload-confirmation'
      header={t(tKey('header'))}
      subheader={subheader}
      upperHeader={<Illus alt='Success' data-testid='bind-success' illus='bindSuccess' />}
    >
      <PrimaryButton onClick={onContinue} layout='fixed'>
        {t(tKey('actions.continue'))}
      </PrimaryButton>
    </FullPage>
  )
}
