export const groupBy = <Value, Key>(
  array: Value[],
  keySelector: (v: Value) => Key,
): Map<Key, Value[]> => {
  return array.reduce((acc: Map<Key, Value[]>, current: Value) => {
    const key = keySelector(current)
    const entry = acc.get(key)
    if (entry === undefined) {
      acc.set(key, [current])
    } else {
      entry.push(current)
    }
    return acc
  }, new Map())
}

export const allEqual = (arr: unknown[]) => arr.every((v) => v === arr[0])
