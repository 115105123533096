import { Flex, mdBumps, TertiaryButton, Typo } from '@wrisk/ui-components'
import React, { useCallback } from 'react'

import { getCoreAsset, Quote } from '../../../domain'
import {
  tFormats,
  TKey,
  useWriskTranslation,
} from '../../../infrastructure/internationalisation'

interface VoluntaryExcessProps {
  quote: Quote
  onChange?: (excess: number) => void
}

const tKey = TKey('components.voluntary-excess')

export const VoluntaryExcess: React.FC<VoluntaryExcessProps> = ({ quote, onChange }) => {
  const { t } = useWriskTranslation()

  const { voluntaryExcess } = getCoreAsset(quote)

  const onExcessChange = useCallback(() => {
    onChange?.(voluntaryExcess)
  }, [voluntaryExcess, onChange])

  return (
    <Flex variant='raised' columnGap={2} width={1} p={mdBumps}>
      <Flex
        flexDirection='column'
        alignItems='flex-start'
        justifyContent='center'
        rowGap={1}
      >
        <Typo typoSize='xs'>{t(tKey('header'))}</Typo>
        <Typo>{t(tFormats('currency.short'), { amount: voluntaryExcess })}</Typo>
      </Flex>
      {onChange && (
        <TertiaryButton
          layout='small'
          onClick={onExcessChange}
          data-testid='open-excess-modal'
        >
          {t(tKey('actions.edit'))}
        </TertiaryButton>
      )}
    </Flex>
  )
}
