import React, { FunctionComponent } from 'react'

import { usePrincipal } from '../authentication'
import { DirectDebitCallbackWrapper } from '../organisms/billing'
import { AnonymousHomePage } from './AnonymousHomePage'
import { AuthenticatedHomePage } from './AuthenticatedHomePage'

export const HomePage: FunctionComponent = () => {
  const { isAuthenticated } = usePrincipal()

  const homePage = isAuthenticated ? <AuthenticatedHomePage /> : <AnonymousHomePage />
  return <DirectDebitCallbackWrapper>{homePage}</DirectDebitCallbackWrapper>
}
