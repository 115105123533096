import { Flex, FlexProps, Icon, IconProperty } from '@wrisk/ui-components'
import React, { FunctionComponent } from 'react'

import { CustomerStatementStatus } from '../../../domain'

interface StatementStatusIconProps extends FlexProps {
  status: CustomerStatementStatus
  iconSize?: IconProperty
}

export const StatementStatusIcon: FunctionComponent<StatementStatusIconProps> = ({
  status,
  iconSize,
  ...props
}) => {
  if (status === CustomerStatementStatus.DRAFT) {
    return null
  }

  const { backgroundColor, iconColor, icon } =
    (() => {
      switch (status) {
        case CustomerStatementStatus.PENDING:
          return {
            backgroundColor: 'surfaceHighlightSubdued',
            iconColor: 'textHighlight',
            icon: 'time',
          }
        case CustomerStatementStatus.FAILED:
          return {
            backgroundColor: 'surfaceCriticalSubdued',
            iconColor: 'textCritical',
            icon: 'warning',
          }
        case CustomerStatementStatus.SETTLED:
          return {
            backgroundColor: 'surfaceSuccessSubdued',
            iconColor: 'textSuccess',
            icon: 'tick',
          }
      }
    })() ?? {}

  return (
    <Flex
      p={1}
      borderRadius='100%'
      backgroundColor={backgroundColor}
      justifyContent='center'
      {...props}
    >
      <Icon color={iconColor} size={iconSize ?? 'tiny'} icon={icon} />
    </Flex>
  )
}
