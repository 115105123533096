import { Box, Typo, xxlBumps } from '@wrisk/ui-components'
import { TFunction } from 'i18next'
import React, { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'

import { TKeyBuilder } from '../../../infrastructure/internationalisation'
import { useLinks } from '../../organisms/links/hooks'

export interface FormFooterProps {
  tName?: string
  tKey: TKeyBuilder
  t: TFunction
}

export const FormFooter: FunctionComponent<FormFooterProps> = ({ t, tKey, tName }) => {
  const links = useLinks()

  return tName ? (
    <Box mb={xxlBumps}>
      <Typo as='pre' typoSize='sm' color='bodySecondary'>
        <Trans t={t} i18nKey={tKey('footer')} components={links} />
      </Typo>
    </Box>
  ) : null
}
