import { css } from 'styled-components'

export default css`
  * {
    box-sizing: border-box;
  }

  html,
  body {
    height: 100%;
  }

  body {
    color: ${(props) => props.theme.colors.body};
    font-family: ${(props) => props.theme.fonts.body};
    background-color: ${(props) => props.theme.colors.background};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  button,
  a,
  input,
  label {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:focus {
      outline: 0;
    }
  }

  pre {
    font-family: inherit;
  }

  p {
    margin-block-start: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.body};
  }

  ul,
  ul li {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
  }

  pre {
    white-space: pre-line;
    margin: 0;
  }

  [type='email'],
  [type='text'],
  [type='tel'] {
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: ${(props) => props.theme.colors.placeholder};
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: ${(props) => props.theme.colors.placeholder};
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: ${(props) => props.theme.colors.placeholder};
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: ${(props) => props.theme.colors.placeholder};
    }
  }

  html input[disabled] {
    background-color: transparent;
  }
`
