import css from '@styled-system/css'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'
import {
  AlignItemsProps,
  compose,
  flexbox,
  grid,
  GridProps as SSGridProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system'

import { transition, TransitionProps } from '../../transition'
import { collapse, CollapseProps } from '../containers/collapse'
import { containerStyle, ContainerStyleProps } from './containerStyle'

export type GridProps = SSGridProps &
  LayoutProps &
  SpaceProps &
  ContainerStyleProps &
  TransitionProps &
  AlignItemsProps &
  CollapseProps &
  PropsWithChildren

export const Grid = styled.div<GridProps>(
  css({
    display: 'grid',
  }),
  containerStyle,
  compose(grid, flexbox, layout, space, transition, collapse),
)

Grid.defaultProps = {
  variant: 'default',
}
