import css from '@styled-system/css'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

type RadioProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

const RadioComponent: FunctionComponent<RadioProps> = (props) => {
  return (
    <div className={props.className}>
      <input
        {...props}
        type='radio'
        autoComplete={props.autoComplete || 'off'}
        autoFocus={props.autoFocus || false}
      />
      <label className='fake-radio' htmlFor={props.id} />
    </div>
  )
}

export const Radio = styled(RadioComponent)(
  css({
    width: '1.5rem',
    height: '1.5rem',
    minWidth: '1.5rem',
    minHeight: '1.5rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '1.5rem',
  }),
  (props) => ({
    "[type='radio']:checked, [type='radio']:not(:checked)": {
      position: 'absolute',
      opacity: '0',
    },
    input: {
      ':hover': {
        cursor: 'pointer',
      },
    },
    'input + .fake-radio': {
      width: '1.5rem',
      height: '1.5rem',
      minWidth: '1.5rem',
      minHeight: '1.5rem',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '1.5rem',
      display: 'inline-block',
    },
    'input:not(:checked) + .fake-radio': {
      backgroundImage: `url(${props.theme.icons.radioButtonOff.url})`,
    },
    'input:checked + .fake-radio': {
      backgroundImage: `url(${props.theme.icons.radioButtonOn.url})`,
    },
  }),
)
