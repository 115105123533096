import { FlexProps } from '@wrisk/ui-components'
import { FunctionComponent } from 'react'

import { Policy, PolicyStatus, Proposal, ProposalStatus } from '../../../domain'
import { ActivePolicyCard } from './policy/ActivePolicyCard'
import { CancelledPolicyCard } from './policy/CancelledPolicyCard'
import { LapsedPolicyCard } from './policy/LapsedPolicyCard'
import { PendingPolicyCard } from './policy/PendingPolicyCard'
import { VoidedPolicyCard } from './policy/VoidedPolicyCard'
import { ExpiredProposalCard } from './proposal/ExpiredProposalCard'
import { ResumeProposalCard } from './proposal/ResumeProposalCard'
import { ViewProposalCard } from './proposal/ViewProposalCard'
import { PolicyCardProps } from './types'

const EmptyCard: FunctionComponent<FlexProps> = () => null

const proposalCardMap: Record<ProposalStatus, FunctionComponent<FlexProps>> = {
  [ProposalStatus.INSUFFICIENT_ACCURACY]: ResumeProposalCard,
  [ProposalStatus.QUOTE_EXPIRED]: ExpiredProposalCard,
  [ProposalStatus.QUOTE]: ViewProposalCard,
  [ProposalStatus.REFERRAL]: ResumeProposalCard,
  [ProposalStatus.POLICY]: EmptyCard,
}

const policyCardMap: Record<PolicyStatus, FunctionComponent<PolicyCardProps>> = {
  [PolicyStatus.ACTIVE]: ActivePolicyCard,
  [PolicyStatus.PENDING]: PendingPolicyCard,
  [PolicyStatus.CANCELLED]: CancelledPolicyCard,
  [PolicyStatus.LAPSED]: LapsedPolicyCard,
  [PolicyStatus.VOIDED]: VoidedPolicyCard,
  [PolicyStatus.RENEWED]: EmptyCard,
}

export const getProposalCard = (proposal: Proposal) => proposalCardMap[proposal.status]
export const getPolicyCard = (policy: Policy) =>
  policyCardMap[policy.policyDetail.policyStatus]
