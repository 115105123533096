import { usePrincipal } from '../../../application/authentication'
import { useProposal } from '../../../application/product/proposal/proposalContext'
import { getCoreAsset, getCurrentQuote, PolicyChangeType } from '../../../domain'
import { useApiErrorHandlingAsyncCallback } from '../../auth'

export const useProposalExcessAdjustment = (onComplete?: () => void) => {
  const { apiClient } = usePrincipal()
  const { proposal, setProposal } = useProposal()

  return useApiErrorHandlingAsyncCallback(async (excess: number) => {
    const quote = getCurrentQuote(proposal)
    const asset = getCoreAsset(quote)

    if (excess !== asset.voluntaryExcess) {
      setProposal(
        await apiClient.updateProposal(proposal.proposalCode, {
          applyRating: true,
          changes: [
            {
              changeType: PolicyChangeType.SELECT_VOLUNTARY_EXCESS,
              excess,
            },
          ],
        }),
      )
    }

    onComplete?.()
  })
}
