import {
  Box,
  Flex,
  FlexProps,
  Heading,
  lgBumps,
  mdBumps,
  PrimaryButton,
  Typo,
} from '@wrisk/ui-components'
import React, { FunctionComponent, ReactNode } from 'react'

interface Props extends FlexProps {
  benefits?: ReactNode
  buttonText: ReactNode
  buttonSubText?: ReactNode
  title: ReactNode
  text?: ReactNode
  onButtonClick: () => void
}

export const UpsellSection: FunctionComponent<Props> = ({
  benefits,
  buttonText,
  buttonSubText,
  title,
  text,
  onButtonClick,
  ...props
}) => {
  return (
    <Flex
      backgroundColor='hover'
      borderWidth={1}
      flexDirection='column'
      p={lgBumps}
      width={1}
      {...props}
    >
      <Box width={1}>
        <Heading as='h3' typoSize='lg' variant='h3'>
          {title}
        </Heading>
        {text && <Typo my={mdBumps}>{text}</Typo>}
        {benefits}
      </Box>
      <Box mt={lgBumps} width={1}>
        <PrimaryButton width={1} onClick={onButtonClick}>
          {buttonText}
          {buttonSubText && (
            <Typo fontWeight='normal' textTransform='lowercase' typoSize='sm'>
              {buttonSubText}
            </Typo>
          )}
        </PrimaryButton>
      </Box>
    </Flex>
  )
}
