import { AxiosInstance } from 'axios'

import { withGenericErrorHandling } from '../errors'

export interface FnolClient {
  createFnolLink: (
    policyId: string,
    privacyPolicyUrl: string,
    callbackUrl: string,
    telephone: string,
    email: string,
  ) => Promise<string>
}

export const createFnolClient = (
  getInstance: () => Promise<AxiosInstance>,
): FnolClient => ({
  createFnolLink: (policyId, privacyPolicyUrl, callbackUrl, telephone, email) =>
    withGenericErrorHandling(async () => {
      const instance = await getInstance()
      const response = await instance.post<string>(`/fnol/link`, {
        policyId,
        privacyPolicyUrl,
        callbackUrl,
        telephone,
        email,
      })
      return response.data
    }),
})
